var render, staticRenderFns
import script from "./c_hot_box.vue?vue&type=script&lang=js"
export * from "./c_hot_box.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d478ec6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d478ec6')) {
      api.createRecord('5d478ec6', component.options)
    } else {
      api.reload('5d478ec6', component.options)
    }
    
  }
}
component.options.__file = "src/components/mobileConfigRight/c_hot_box.vue"
export default component.exports