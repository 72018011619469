"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _product = require("@/api/product");
var _index = _interopRequireDefault(require("@/components/serviceGuarantee/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProductGuarantee',
  components: {
    guaranteeService: _index.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        date: '',
        keyword: ''
      },
      timeVal: [],
      props: {},
      tabClickIndex: ''
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 把每一行的索引放进row
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      row.index = rowIndex;
    },
    // 添加明细原因 row 当前行 column 当前列
    tabClick: function tabClick(row) {
      this.tabClickIndex = row.index;
    },
    // 失去焦点初始化
    inputBlur: function inputBlur(scope) {
      var _this = this;
      if (!scope.row.sort || scope.row.sort < 0) scope.row.sort = 0;
      (0, _product.guaranteeSortApi)(scope.row.guarantee_template_id, {
        sort: scope.row.sort
      }).then(function (res) {
        _this.closeEdit();
      }).catch(function (res) {});
    },
    closeEdit: function closeEdit() {
      this.tabClickIndex = null;
    },
    handleCloseItems: function handleCloseItems(tag) {
      var _this2 = this;
      this.termsService.splice(this.termsService.indexOf(tag), 1);
      this.formValidate.template_value = [];
      this.termsService.map(function (item) {
        _this2.formValidate.template_value.push(item.guarantee_id);
      });
    },
    add: function add() {
      this.$refs.serviceGuarantee.add();
    },
    // 修改显示状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      (0, _product.guaranteeStatusApi)(row.guarantee_template_id, {
        status: row.status
      }).then(function (_ref2) {
        var message = _ref2.message;
        _this3.$message.success(message);
        _this3.getList('');
      }).catch(function (_ref3) {
        var message = _ref3.message;
        _this3.$message.error(message);
      });
    },
    // 编辑
    handleEdit: function handleEdit(id) {
      this.$refs.serviceGuarantee.handleEdit(id);
      this.$refs.serviceGuarantee.loading = false;
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _product.guaranteeLstApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this5 = this;
      this.$modalSure().then(function () {
        (0, _product.guaranteeDeleteApi)(id).then(function (_ref4) {
          var message = _ref4.message;
          _this5.$message.success(message);
          _this5.getList('');
        }).catch(function (_ref5) {
          var message = _ref5.message;
          _this5.$message.error(message);
        });
      });
    }
  }
};