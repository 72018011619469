"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_bargain',
  cname: '助力',
  icon: 'iconzhuli',
  configName: 'c_home_bargain',
  type: 1,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'bargain',
  // 外面匹配名称
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'bargain',
        timestamp: this.num,
        setUp: {
          tabVal: '0'
        },
        // numConfig: {
        //     val: 3
        // },
        priceShow: {
          title: '是否显示价格',
          val: true
        },
        bntShow: {
          title: '是否显示按钮',
          val: true
        },
        titleShow: {
          title: '是否显示名称',
          val: true
        },
        barginShow: {
          title: '是否显示助力标签',
          val: true
        },
        joinShow: {
          title: '是否显示参与标签',
          val: true
        },
        tabConfig: {
          title: '展示样式',
          tabVal: 0,
          type: 1,
          tabList: [{
            name: '单行展示',
            icon: 'icondanhang'
          }, {
            name: '多行展示',
            icon: 'iconduohang'
          }, {
            name: '板块模式',
            icon: 'iconyangshi9'
          }]
        },
        bgColor: {
          title: '按钮背景色',
          name: 'bgColor',
          default: [{
            item: '#FF0000'
          }, {
            item: '#FF5400'
          }],
          color: [{
            item: '#FF0000'
          }, {
            item: '#FF5400'
          }]
        },
        themeColor: {
          title: '背景颜色',
          name: 'themeColor',
          default: [{
            item: '#fff'
          }],
          color: [{
            item: '#fff'
          }]
        },
        priceColor: {
          title: '主题颜色',
          name: 'themeColor',
          default: [{
            item: '#E93323'
          }],
          color: [{
            item: '#E93323'
          }]
        },
        bgStyle: {
          title: '背景样式',
          name: 'bgStyle',
          type: 1,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        conStyle: {
          title: '内容样式',
          name: 'conStyle',
          type: 1,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        mbCongfig: {
          title: '页面间距',
          val: 0,
          min: 0
        }
      },
      bgColor: [],
      themeColor: '',
      priceColor: '',
      mTop: '',
      list: [{
        img: '',
        store_name: '双耳戴头式无线...',
        price: '234',
        num: 1245
      }, {
        img: '',
        store_name: '双耳戴头式无线...',
        price: '234',
        num: 1245
      }, {
        img: '',
        store_name: '双耳戴头式无线...',
        price: '234',
        num: 1245
      }, {
        img: '',
        store_name: '双耳戴头式无线...',
        price: '234',
        num: 1245
      }, {
        img: '',
        store_name: '双耳戴头式无线...',
        price: '234',
        num: 1245
      }, {
        img: '',
        store_name: '双耳戴头式无线...',
        price: '234',
        num: 1245
      }],
      priceShow: true,
      bntShow: true,
      titleShow: true,
      barginShow: true,
      joinShow: true,
      pageData: {},
      bgStyle: 1,
      isOne: 0,
      conStyle: 1
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbCongfig) {
        this.isOne = data.tabConfig.tabVal;
        this.bgColor = data.bgColor.color;
        this.themeColor = data.themeColor && data.themeColor.color[0].item;
        this.priceColor = data.priceColor && data.priceColor.color[0].item;
        this.mTop = data.mbCongfig.val;
        this.priceShow = data.priceShow.val;
        this.titleShow = data.titleShow.val;
        this.barginShow = data.barginShow.val;
        this.joinShow = data.joinShow.val;
        this.conStyle = data.conStyle.type;
        this.bgStyle = data.bgStyle.type;
        this.bntShow = data.bntShow.val;
      }
    }
  }
};