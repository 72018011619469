"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Data',
  data: function data() {
    return {
      tableData: {
        page: 1,
        limit: 20,
        data: [],
        total: 0
      },
      loading: false,
      groupId: null,
      groupDetail: null
    };
  },
  computed: {
    columns: function columns() {
      if (!this.groupDetail) return [];
      var colums = [{
        title: 'ID',
        key: 'group_data_id',
        minWidth: 60
      }];
      this.groupDetail.fields.forEach(function (val) {
        colums.push({
          title: val.name,
          key: val.field,
          minWidth: 80
        });
      });
      colums.push({
        title: '添加时间',
        key: 'create_time',
        minWidth: 200
      }
      // {
      //   title: '状态',
      //   key: 'status',
      //   minWidth: 80
      // },
      // {
      //   title: '操作',
      //   slot: 'action',
      //   minWidth: 120,
      //   fixed: 'right'
      // }
      );
      return colums;
    }
  },
  watch: {
    '$route.params.id': function $routeParamsId(n) {
      this.groupId = n;
    },
    groupId: function groupId(n) {
      this.getGroupDetail(n);
    }
  },
  mounted: function mounted() {
    this.groupId = this.$route.params.id;
  },
  methods: {
    getGroupDetail: function getGroupDetail(id) {
      var _this = this;
      (0, _system.groupDetail)(id).then(function (res) {
        _this.groupDetail = res.data;
        _this.tableData.page = 1;
        _this.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _system.groupDataLst)(this.$route.params.id, this.tableData.page, this.tableData.limit).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.loading = false;
      }).catch(function (res) {
        _this2.loading = false;
        _this2.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableData.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableData.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      var _this3 = this;
      this.$modalForm((0, _system.createGroupData)(this.$route.params.id)).then(function () {
        return _this3.getList();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this4 = this;
      this.$modalForm((0, _system.updateGroupData)(this.$route.params.id, id)).then(function () {
        return _this4.getList();
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      // changeConfigClassifyStatus(row.config_classify_id, row.status).then(({ message }) => {
      //   this.$message.success(message)
      // }).catch(({ message }) => {
      //   this.$message.error(message)
      // })
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this5 = this;
      this.$modalSure('删除该数据').then(function () {
        (0, _system.deleteGroupData)(id).then(function (_ref) {
          var message = _ref.message;
          _this5.$message.success(message);
          _this5.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this5.$message.error(message);
        });
      });
    }
  }
};