"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutolabelCreateApi = AutolabelCreateApi;
exports.AutolabelDeleteApi = AutolabelDeleteApi;
exports.AutolabelLstApi = AutolabelLstApi;
exports.AutolabelSyncApi = AutolabelSyncApi;
exports.AutolabelUpdateApi = AutolabelUpdateApi;
exports.captchaApi = captchaApi;
exports.changeGroupApi = changeGroupApi;
exports.editFormApi = editFormApi;
exports.getBaseInfo = getBaseInfo;
exports.getInfo = getInfo;
exports.getMenusApi = getMenusApi;
exports.getVersion = getVersion;
exports.labelDeleteApi = labelDeleteApi;
exports.labelEditApi = labelEditApi;
exports.labelFormApi = labelFormApi;
exports.labelLstApi = labelLstApi;
exports.login = login;
exports.loginConfigApi = loginConfigApi;
exports.logout = logout;
exports.merchantUpdate = merchantUpdate;
exports.passwordFormApi = passwordFormApi;
exports.userCouponApi = userCouponApi;
exports.userLstApi = userLstApi;
exports.userOrderApi = userOrderApi;
exports.userSearchLstApi = userSearchLstApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 验证码
 */
function captchaApi() {
  return _request.default.get("captcha");
}
/**
 * @description 登录
 */
function login(data) {
  return _request.default.post("login", data);
}

/**
 * @description 登录页配置
 */
function loginConfigApi() {
  return _request.default.get("login_config");
}

/**
 * @description 退出登录
 */
function logout() {
  return _request.default.get("logout");
}

/**
 * @description 修改密码
 */
function passwordFormApi() {
  return _request.default.get("system/admin/edit/password/form");
}

/**
 * @description 修改自己的信息
 */
function editFormApi() {
  return _request.default.get("system/admin/edit/form");
}
/**
 * @description 菜单
 */
function getMenusApi() {
  return _request.default.get("menus");
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/vue-element-admin/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
/**
 * @description 标签
 */
function getBaseInfo() {
  return _request.default.get("info");
}
/**
 * @description 用户标签 -- 列表
 */
function labelLstApi(data) {
  return _request.default.get('user/label/lst', data);
}
/**
 * @description 用户标签 -- 添加表单
 */
function labelFormApi() {
  return _request.default.get('user/label/form');
}
/**
 * @description 用户标签 -- 编辑表单
 */
function labelEditApi(id) {
  return _request.default.get('user/label/form/' + id);
}
/**
 * @description 用户标签 -- 删除
 */
function labelDeleteApi(id) {
  return _request.default.delete("user/label/".concat(id));
}
/**
 * @description 自动标签 -- 添加
 */
function AutolabelCreateApi(data) {
  return _request.default.post('auto_label/create', data);
}
/**
 * @description 自动标签 -- 列表
 */
function AutolabelLstApi(data) {
  return _request.default.get('auto_label/lst', data);
}
/**
 * @description 自动标签 -- 编辑
 */
function AutolabelUpdateApi(id, data) {
  return _request.default.post('auto_label/update/' + id, data);
}
/**
 * @description 自动标签 -- 删除
 */
function AutolabelDeleteApi(id) {
  return _request.default.delete("auto_label/delete/".concat(id));
}
/**
 * @description 自动标签 -- 更新
 */
function AutolabelSyncApi(id) {
  return _request.default.post('auto_label/sync/' + id);
}
/**
 * @description 用户 -- 列表
 */
function userLstApi(data) {
  return _request.default.get('user/lst', data);
}
/**
 * @description 用户 -- 详情消费记录
 */
function userOrderApi(uid, data) {
  return _request.default.get("user/order/".concat(uid), data);
}
/**
 * @description 用户 -- 详情优惠券
 */
function userCouponApi(uid, data) {
  return _request.default.get("user/coupon/".concat(uid), data);
}
/**
 * @description 用户 -- 批量设置分组
 */
function changeGroupApi(id) {
  return _request.default.get('user/change_label/form/' + id);
}
/**
 * @description 修改商户基本信息
 */
function merchantUpdate(data) {
  return _request.default.post('/info/update', data);
}
/**
 * @description 用户搜索信息 -- 列表
 */
function userSearchLstApi(data) {
  return _request.default.get('user/search_log', data);
}
/**
 * 获取版权信息
 * @returns
 */
function getVersion() {
  return _request.default.get('../api/version');
}