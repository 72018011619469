var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _vm.modals
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.modals,
                width: "80%",
                title: "免审核商品信息编辑",
                "custom-class": "dialog-scustom",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.modals = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "formValidate",
                  staticClass: "formValidate mt20",
                  attrs: {
                    model: _vm.formValidate,
                    rules: _vm.ruleInline,
                    "label-width": "120px",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "商户商品分类：",
                              prop: "mer_cate_id",
                            },
                          },
                          [
                            _c("el-cascader", {
                              staticClass: "selWidth",
                              attrs: {
                                options: _vm.merCateList,
                                props: _vm.propsMer,
                                clearable: "",
                              },
                              model: {
                                value: _vm.formValidate.mer_cate_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "mer_cate_id", $$v)
                                },
                                expression: "formValidate.mer_cate_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formValidate.spec_type === 1 &&
                        _vm.ManyAttrValue.length > 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "labeltop",
                                attrs: { label: "批量设置：" },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.oneFormBatch,
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "图片",
                                          "min-width": "150",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle row-center-wrapper",
                                            },
                                            [
                                              _vm.oneFormBatch[0].image
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pictrue pictrueTab",
                                                    },
                                                    [
                                                      _c("img", {
                                                        directives: [
                                                          {
                                                            name: "lazy",
                                                            rawName: "v-lazy",
                                                            value:
                                                              _vm
                                                                .oneFormBatch[0]
                                                                .image,
                                                            expression:
                                                              "oneFormBatch[0].image",
                                                          },
                                                        ],
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoad pictrueTab acea-row row-center-wrapper",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-camera cameraIconfont",
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "售价",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceBox",
                                            attrs: {
                                              min: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.oneFormBatch[0].price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].price",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "成本价",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceBox",
                                            attrs: {
                                              min: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.oneFormBatch[0].cost,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "cost",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].cost",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "市场价",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceBox",
                                            attrs: {
                                              min: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value:
                                                _vm.oneFormBatch[0].ot_price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "ot_price",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].ot_price",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "库存",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceBox",
                                            attrs: {
                                              min: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.oneFormBatch[0].stock,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "stock",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].stock",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "商品编号",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.oneFormBatch[0].bar_code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "bar_code",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].bar_code",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "重量(KG)",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceBox",
                                            attrs: {
                                              min: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.oneFormBatch[0].weight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "weight",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].weight",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "体积(m²)",
                                          "min-width": "100",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c("el-input-number", {
                                            staticClass: "priceBox",
                                            attrs: {
                                              min: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.oneFormBatch[0].volume,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.oneFormBatch[0],
                                                  "volume",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "oneFormBatch[0].volume",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "操作",
                                          "min-width": "150",
                                          align: "center",
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ela-btn",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                              on: { click: _vm.batchAdd },
                                            },
                                            [_vm._v("添加")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ela-btn",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                              on: { click: _vm.batchDel },
                                            },
                                            [_vm._v("清空")]
                                          ),
                                        ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formValidate.spec_type === 0
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "labeltop",
                                attrs: { label: "规格列表：" },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "tabNumWidth",
                                    attrs: {
                                      data: _vm.OneattrValue,
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "图片",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    scope.row.image
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pictrue tabPic",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: scope.row
                                                                  .image,
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoad tabPic",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-camera cameraIconfont",
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2631442157
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.attrValue, function (item, iii) {
                                      return _c("el-table-column", {
                                        key: iii,
                                        attrs: {
                                          label: _vm.formThead[iii].title,
                                          align: "center",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm.formThead[iii].title ===
                                                  "商品编号"
                                                    ? _c("el-input", {
                                                        staticClass: "priceBox",
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value: scope.row[iii],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              iii,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[iii]",
                                                        },
                                                      })
                                                    : _c("el-input-number", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          min: 0,
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value: scope.row[iii],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              iii,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[iii]",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }),
                                    _vm._v(" "),
                                    _vm.formValidate.extension_type === 1
                                      ? [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "一级返佣(元)",
                                              "min-width": "100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input-number", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          min: 0,
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .extension_one,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "extension_one",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.extension_one",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1308693019
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "二级返佣(元)",
                                              "min-width": "100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input-number", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          min: 0,
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .extension_two,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "extension_two",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.extension_two",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              899977843
                                            ),
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formValidate.spec_type === 1
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "labeltop",
                                attrs: { label: "规格列表：" },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "tabNumWidth",
                                    attrs: {
                                      data: _vm.ManyAttrValue,
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _vm.manyTabDate
                                      ? _vm._l(
                                          _vm.manyTabDate,
                                          function (item, iii) {
                                            return _c("el-table-column", {
                                              key: iii,
                                              attrs: {
                                                align: "center",
                                                label:
                                                  _vm.manyTabTit[iii].title,
                                                "min-width": "80",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", {
                                                          staticClass:
                                                            "priceBox",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              scope.row[iii]
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "图片",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                    attrs: {
                                                      title: "750*750px",
                                                    },
                                                  },
                                                  [
                                                    scope.row.image
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pictrue tabPic",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: scope.row
                                                                  .image,
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoad tabPic",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-camera cameraIconfont",
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        324277957
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.attrValue, function (item, iii) {
                                      return _c("el-table-column", {
                                        key: iii,
                                        attrs: {
                                          label: _vm.formThead[iii].title,
                                          align: "center",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm.formThead[iii].title ===
                                                  "商品编号"
                                                    ? _c("el-input", {
                                                        staticClass: "priceBox",
                                                        attrs: { type: "text" },
                                                        model: {
                                                          value: scope.row[iii],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              iii,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[iii]",
                                                        },
                                                      })
                                                    : _c("el-input-number", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          min: 0,
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value: scope.row[iii],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              iii,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[iii]",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }),
                                    _vm._v(" "),
                                    _vm.formValidate.extension_type === 1
                                      ? [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "一级返佣(元)",
                                              "min-width": "100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input-number", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          min: 0,
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .extension_one,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "extension_one",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.extension_one",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1308693019
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "二级返佣(元)",
                                              "min-width": "100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input-number", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          min: 0,
                                                          "controls-position":
                                                            "right",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .extension_two,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "extension_two",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.extension_two",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              899977843
                                            ),
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "submission",
                                attrs: {
                                  loading: _vm.loading1,
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSubmit("formValidate")
                                  },
                                },
                              },
                              [_vm._v("提交")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }