"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _uploadPicture = _interopRequireDefault(require("@/components/uploadPicture"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_hot_imgs',
  components: {
    draggable: _vuedraggable.default,
    uploadPictures: _uploadPicture.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      defaults: {},
      menus: [],
      list: [{
        title: 'aa',
        val: ''
      }],
      modalPic: false,
      isChoice: '单选',
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      },
      activeIndex: 0
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
  },
  methods: {
    addBox: function addBox() {
      var obj = {
        img: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1594458238721&di=d9978a807dcbf5d8a01400875bc51162&imgtype=0&src=http%3A%2F%2Fattachments.gfan.com%2Fforum%2F201604%2F23%2F002205xqdkj84gnw4oi85v.jpg',
        info: [{
          title: '标题',
          value: '',
          tips: '选填，不超过4个字',
          max: 4
        }, {
          title: '简介',
          value: '',
          tips: '选填，不超过20个字',
          max: 20
        }],
        link: {
          title: '链接',
          optiops: [{
            type: 0,
            value: '',
            label: '一级>二级分类'
          }, {
            type: 1,
            value: '',
            label: '自定义链接'
          }]
        }
      };
      this.defaults.menu.push(obj);
    },
    // 点击图文封面
    modalPicTap: function modalPicTap(title, index) {
      this.activeIndex = index;
      this.modalPic = true;
    },
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      var _this = this;
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        var dialog = new window.UE.ui.Dialog({
          iframeUrl: '/admin/widget.images/index.html?fodder=dialog',
          editor: editor,
          name: uiName,
          title: '上传图片',
          cssRules: 'width:1200px;height:500px;padding:20px;'
        });
        _this.dialog = dialog;
        // 参考上面的自定义按钮
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(../../../assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            // 渲染dialog
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      }, 37);
    },
    // 获取图片信息
    getPic: function getPic(pc) {
      this.defaults.menu[this.activeIndex].img = pc.att_dir;
      this.modalPic = false;
    }
  }
};