var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c("div", { staticClass: "numbox" }, [
        _c(
          "div",
          { staticClass: "c_row-item" },
          [
            _c("el-col", { staticClass: "label", attrs: { span: 4 } }, [
              _c("span", [_vm._v(_vm._s(_vm.configData.title || "商品数量"))]),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { staticClass: "slider-box", attrs: { span: 19 } },
              [
                _c("el-input-number", {
                  staticStyle: { "text-align": "right" },
                  attrs: {
                    placeholder: "请输入数量",
                    step: 1,
                    max: 100,
                    min: 1,
                  },
                  on: { change: _vm.bindChange },
                  model: {
                    value: _vm.configData.val,
                    callback: function ($$v) {
                      _vm.$set(_vm.configData, "val", $$v)
                    },
                    expression: "configData.val",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }