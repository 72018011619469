"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminCreateApi = adminCreateApi;
exports.adminDeleteApi = adminDeleteApi;
exports.adminListApi = adminListApi;
exports.adminLogApi = adminLogApi;
exports.adminPasswordApi = adminPasswordApi;
exports.adminStatusApi = adminStatusApi;
exports.adminUpdateApi = adminUpdateApi;
exports.captchaApi = captchaApi;
exports.getExpressLst = getExpressLst;
exports.getMerConfig = getMerConfig;
exports.getdumpDataApi = getdumpDataApi;
exports.getdumpLst = getdumpLst;
exports.marginCode = marginCode;
exports.marginRecordLst = marginRecordLst;
exports.marginRefund = marginRefund;
exports.menuRoleApi = menuRoleApi;
exports.payCodeApi = payCodeApi;
exports.payRecordLst = payRecordLst;
exports.printerAddApi = printerAddApi;
exports.printerDeleteApi = printerDeleteApi;
exports.printerLstApi = printerLstApi;
exports.printerStatusApi = printerStatusApi;
exports.printerUpdateApi = printerUpdateApi;
exports.roleCreateApi = roleCreateApi;
exports.roleDeleteApi = roleDeleteApi;
exports.roleStatusApi = roleStatusApi;
exports.roleUpdateApi = roleUpdateApi;
exports.serveInfoApi = serveInfoApi;
exports.serveRecordListApi = serveRecordListApi;
exports.smsPriceApi = smsPriceApi;
exports.storeGetInfoApi = storeGetInfoApi;
exports.storeUpdateApi = storeUpdateApi;
exports.updatedumpDataApi = updatedumpDataApi;
exports.uploadCertificate = uploadCertificate;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 身份管理 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function menuRoleApi(data) {
  return _request.default.get("system/role/lst", data);
}

/**
 * @description 身份管理 -- 新增
 * @param {Object} param params {Object} 传值参数
 */
function roleCreateApi() {
  return _request.default.get("system/role/create/form");
}

/**
 * @description 身份管理 -- 编辑
 * @param {Object} param params {Object} 传值参数
 */
function roleUpdateApi(id) {
  return _request.default.get("system/role/update/form/".concat(id));
}

/**
 * @description 身份管理 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function roleDeleteApi(id) {
  return _request.default.delete("system/role/delete/".concat(id));
}

/**
 * @description 身份管理 -- 修改状态
 * @param {Object} param params {Object} 传值参数
 */
function roleStatusApi(id, status) {
  return _request.default.post("system/role/status/".concat(id), {
    status: status
  });
}
/**
 * @description 管理员 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function adminListApi(data) {
  return _request.default.get("system/admin/lst", data);
}

/**
 * @description 管理员 -- 新增
 * @param {Object} param params {Object} 传值参数
 */
function adminCreateApi() {
  return _request.default.get("/system/admin/create/form");
}

/**
 * @description 管理员 -- 编辑
 * @param {Object} param params {Object} 传值参数
 */
function adminUpdateApi(id) {
  return _request.default.get("system/admin/update/form/".concat(id));
}

/**
 * @description 管理员 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function adminDeleteApi(id) {
  return _request.default.delete("system/admin/delete/".concat(id));
}

/**
 * @description 管理员 -- 修改状态
 * @param {Object} param params {Object} 传值参数
 */
function adminStatusApi(id, status) {
  return _request.default.post("system/admin/status/".concat(id), {
    status: status
  });
}
/**
 * @description 管理员 -- 修改密码表单
 * @param {Object} param params {Object} 传值参数
 */
function adminPasswordApi(id) {
  return _request.default.get("system/admin/password/form/".concat(id));
}
/**
 * @description 操作日志 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function adminLogApi(data) {
  return _request.default.get("system/admin/log", data);
}
/**
 * @description 自提 -- 详情
 * @param {Object} param params {Object} 传值参数
 */
function storeGetInfoApi() {
  return _request.default.get("take/info");
}
/**
 * @description 自提 -- 提交
 * @param {Object} param params {Object} 传值参数
 */
function storeUpdateApi(data) {
  return _request.default.post("take/update", data);
}
/**
 * @description 上传资质图片
 * @param {Object} param params {Object} 传值参数
 */
function uploadCertificate(data) {
  return _request.default.post('upload/certificate', data);
}
/**
 * @description 保证金支付二维码
 * @param {Object} param params {Object} 传值参数
 */
function marginCode() {
  return _request.default.get('margin/code');
}
/**
 * @description 保证金记录
 * @param {Object} param params {Object} 传值参数
 */
function marginRecordLst(data) {
  return _request.default.get('margin/lst', data);
}
/**
 * @description 申请退回保证金
 * @param {Object} param params {Object} 传值参数
 */
function marginRefund() {
  return _request.default.post('financial/refund/margin');
}
/**
 * @description 一号通 -- 用户信息
 * @param {Object} param params {Object} 传值参数
 */
function serveInfoApi() {
  return _request.default.get("serve/info");
}
/**
 * @description 一号通 -- 是否登录
 * @param {Object} param params {Object} 传值参数
 */
function smsPriceApi(data) {
  return _request.default.get("serve/meal", data);
}

/**
 * @description 一号通短信账户 -- 支付二维码
 */
function payCodeApi(data) {
  return _request.default.get('serve/code', data);
}

/**
 * @description 一号通短信账户 -- 列表
 */
function serveRecordListApi(data) {
  return _request.default.get('serve/record', data);
}

/**
 * @description 短信账户签名修改 -- 获取验证码
 */
function captchaApi(phone) {
  return _request.default.get("serve/captcha/".concat(phone));
}

/**
 * @description 一号通 -- 购买记录
 */
function payRecordLst(data) {
  return _request.default.get('serve/paylst', data);
}
/**
 * @description 电子面单配置 -- 快递公司列表
 */
function getExpressLst() {
  return _request.default.get('serve/expr/lst');
}
/**
 * @description 电子面单配置 -- 电子面单列表
 */
function getdumpLst(data) {
  return _request.default.get('expr/temps', data);
}
/**
 * @description 电子面单配置 -- 获取数据
 */
function getdumpDataApi() {
  return _request.default.get('serve/config');
}
/**
 * @description 电子面单配置 -- 提交数据
 */
function updatedumpDataApi(data) {
  return _request.default.post('serve/config', data);
}
/**
 * @description 打印机配置 -- 添加
 */
function printerAddApi() {
  return _request.default.get('store/printer/create/form');
}
/**
 * @description 打印机配置 -- 列表
 */
function printerLstApi(data) {
  return _request.default.get('store/printer/lst', data);
}
/**
 * @description 打印机 -- 是否开启
 */
function printerStatusApi(id, status) {
  return _request.default.post("store/printer/status/".concat(id), status);
}
/**
 * @description 打印机配置 -- 编辑
 */
function printerUpdateApi(id) {
  return _request.default.get("store/printer/update/".concat(id, "/form"));
}
/**
 * @description 打印机配置 -- 删除
 */
function printerDeleteApi(id) {
  return _request.default.delete("store/printer/delete/".concat(id));
}

/**
 * @description 获取商户配置
 */
function getMerConfig(id, data) {
  return _request.default.get("config", data);
}