var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加关键字")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "service_reply_id",
                  label: "ID",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "keyword", label: "关键字", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "回复类型",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == "1"
                          ? _c("span", [_vm._v("文字消息")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type == "2"
                          ? _c("span", [_vm._v("图片消息")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "是否显示",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.service_reply_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableData.limit,
                  "current-page": _vm.tableData.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "addDia",
              attrs: {
                visible: _vm.dialogVisible,
                title: _vm.message_id ? "编辑" : "添加",
                height: "30%",
                "custom-class": "dialog-scustom",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "box-card right ml50" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formValidate",
                      staticClass: "mt20",
                      attrs: {
                        model: _vm.formValidate,
                        rules: _vm.ruleValidate,
                        "label-width": "100px",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键字：", prop: "val" } },
                        [
                          _c(
                            "div",
                            { staticClass: "arrbox" },
                            [
                              _vm._l(_vm.labelarr, function (item, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticClass: "mr5",
                                    attrs: {
                                      type: "success",
                                      closable: "",
                                      "disable-transitions": false,
                                    },
                                    on: {
                                      close: function ($event) {
                                        return _vm.handleClose(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              }),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "arrbox_ip",
                                staticStyle: { width: "90%" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "输入后回车",
                                },
                                on: { change: _vm.addlabel },
                                model: {
                                  value: _vm.val,
                                  callback: function ($$v) {
                                    _vm.val = $$v
                                  },
                                  expression: "val",
                                },
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "规则状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "status", $$v)
                                },
                                expression: "formValidate.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("启用"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("禁用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "消息类型：", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90%" },
                              attrs: { placeholder: "请选择规则状态" },
                              model: {
                                value: _vm.formValidate.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "type", $$v)
                                },
                                expression: "formValidate.type",
                              },
                            },
                            _vm._l(_vm.typeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.type === 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "规则内容：",
                                required: "",
                                prop: "content",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请填写规则内容",
                                },
                                model: {
                                  value: _vm.formValidate.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "content", $$v)
                                  },
                                  expression: "formValidate.content",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.type === 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "图片地址：",
                                required: "",
                                prop: "src",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row row-middle" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      attrs: { title: "750*750px" },
                                      on: { click: _vm.modalPicTap },
                                    },
                                    [
                                      _vm.formValidate.content
                                        ? _c(
                                            "div",
                                            { staticClass: "pictrue" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.formValidate.content,
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "ml50",
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function ($event) {
                            return _vm.submenus("formValidate")
                          },
                        },
                      },
                      [_vm._v("保存并发布")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }