var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台商品分类：", prop: "cate_id" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.categoryList,
                              props: _vm.props,
                              filterable: "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableForm.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableForm, "cate_id", $$v)
                              },
                              expression: "tableForm.cate_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户商品分类：",
                            prop: "mer_cate_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.mer_cate_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "mer_cate_id", $$v)
                                },
                                expression: "tableForm.mer_cate_id",
                              },
                            },
                            _vm._l(_vm.merCateList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键字搜索：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入商品名称、关键字",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "keyword", $$v)
                                },
                                expression: "tableForm.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.headeNum.length > 0
                ? _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableForm.filter_product_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableForm, "filter_product_type", $$v)
                        },
                        expression: "tableForm.filter_product_type",
                      },
                    },
                    _vm._l(_vm.headeNum, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: {
                          name: item.type.toString(),
                          label: item.title,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: {
                rowclick: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeEdit($event)
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "product_id", label: "ID", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "商品名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "商品售价", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "cost", label: "商品成本", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "min_gns",
                  label: "消费券兑换金额",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "min_gns_give_gp",
                  label: "消费券兑换赠送GP",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "销量", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存", "min-width": "70" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  align: "center",
                  label: "排序",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.index === _vm.tabClickIndex
                          ? _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    maxlength: "300",
                                    size: "mini",
                                    autofocus: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.inputBlur(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row["sort"],
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sort", _vm._n($$v))
                                    },
                                    expression: "scope.row['sort']",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              {
                                on: {
                                  dblclick: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.tabClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row["sort"]))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "200", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableForm.filter_product_type === "1"
                          ? [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": "上架",
                                  "inactive-text": "下架",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onchangeIsShow(scope.row, $event)
                                  },
                                },
                                model: {
                                  value: scope.row.gns_sw,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "gns_sw", $$v)
                                  },
                                  expression: "scope.row.gns_sw",
                                },
                              }),
                            ]
                          : _vm.tableForm.filter_product_type === "2"
                          ? [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path:
                                        _vm.roterPre +
                                        "/product/list/addProduct/" +
                                        scope.row.product_id,
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr10",
                                      attrs: { type: "text", size: "small" },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr10",
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpClick(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("上架兑换商品")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.tableForm.filter_product_type == 1
                ? "日账单详情"
                : "月账单详情",
            visible: _vm.dialogVisible,
            width: "830px",
            "before-close": _vm.handleClose,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "ivu-mt mt20", attrs: { align: "middle" } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-vertical-demo",
                      attrs: { "default-active": "0" },
                    },
                    [
                      _c(
                        "el-menu-item",
                        { attrs: { name: _vm.accountDetails.date } },
                        [_c("span", [_vm._v(_vm._s(_vm.accountDetails.date))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("div", { staticClass: "grid-content" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.accountDetails.income &&
                                _vm.accountDetails.income.title
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "color_red" }, [
                          _vm._v(
                            _vm._s(
                              _vm.accountDetails.income &&
                                _vm.accountDetails.income.number
                            ) + "元"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "count" }, [
                          _vm._v(
                            _vm._s(
                              _vm.accountDetails.income &&
                                _vm.accountDetails.income.count
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.accountDetails.income.data
                          ? _c(
                              "div",
                              { staticClass: "list" },
                              _vm._l(
                                _vm.accountDetails.income.data,
                                function (item, index) {
                                  return _c(
                                    "el-row",
                                    { key: index, staticClass: "item" },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "name",
                                          attrs: { span: 12 },
                                        },
                                        [_vm._v(_vm._s(item["0"]))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "cost",
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "cost_num" },
                                            [_vm._v(_vm._s(item["1"]))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "cost_count" },
                                            [_vm._v(_vm._s(item["2"]))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("div", { staticClass: "grid-content" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.accountDetails.expend &&
                                _vm.accountDetails.expend.title
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "color_gray" }, [
                          _vm._v(
                            _vm._s(
                              _vm.accountDetails.expend &&
                                _vm.accountDetails.expend.number
                            ) + "元"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "count" }, [
                          _vm._v(
                            _vm._s(
                              _vm.accountDetails.expend &&
                                _vm.accountDetails.expend.count
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.accountDetails.expend.data
                          ? _c(
                              "div",
                              { staticClass: "list" },
                              _vm._l(
                                _vm.accountDetails.expend.data,
                                function (item, index) {
                                  return _c(
                                    "el-row",
                                    { key: index, staticClass: "item" },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "name",
                                          attrs: { span: 12 },
                                        },
                                        [_vm._v(_vm._s(item["0"]))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "cost",
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "cost_num" },
                                            [_vm._v(_vm._s(item["1"]))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "cost_count" },
                                            [_vm._v(_vm._s(item["2"]))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.accountDetails.charge &&
                              _vm.accountDetails.charge.title
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "color_gray" }, [
                        _vm._v(
                          _vm._s(
                            _vm.accountDetails.charge &&
                              _vm.accountDetails.charge.number
                          ) + "元"
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }