var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { staticClass: "mt30 acea-row row-middle row-center" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formValidate",
                      staticClass: "form mt30",
                      attrs: {
                        rules: _vm.ruleValidate,
                        model: _vm.formValidate,
                        "label-width": "150px",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.current === 0,
                              expression: "current === 0",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "套餐名称：", prop: "title" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入套餐名称" },
                                    model: {
                                      value: _vm.formValidate.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formValidate, "title", $$v)
                                      },
                                      expression: "formValidate.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "套餐时间：",
                                    prop:
                                      _vm.formValidate.is_time == 1
                                        ? "time"
                                        : "",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { "element-id": "is_time" },
                                      model: {
                                        value: _vm.formValidate.is_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "is_time",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.is_time",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("不限时"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "radio",
                                          attrs: { label: 1 },
                                        },
                                        [_vm._v("限时")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.formValidate.is_time == 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "acea-row row-middle",
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "15px",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetimerange",
                                              "range-separator": "至",
                                              "start-placeholder": "开始日期",
                                              "end-placeholder": "结束日期",
                                              align: "right",
                                            },
                                            on: { change: _vm.onchangeTime },
                                            model: {
                                              value: _vm.timeVal,
                                              callback: function ($$v) {
                                                _vm.timeVal = $$v
                                              },
                                              expression: "timeVal",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "套餐类型：",
                                    prop: "type",
                                    "label-for": "type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { "element-id": "type" },
                                      model: {
                                        value: _vm.formValidate.type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "type",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.type",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "radio",
                                          attrs: { label: 0 },
                                        },
                                        [_vm._v("固定套餐")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("搭配套餐"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml100 grey" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.formValidate.type == 0
                                            ? "套餐内所有商品打包销售，消费者需成套购买整个套餐"
                                            : "套餐内主商品必选，搭配商品任意选择1件及以上即可购买套餐"
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formValidate.type == 1
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "套餐主商品：",
                                        prop: "products",
                                        "label-for": "products",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-table",
                                        { attrs: { data: _vm.specsMainData } },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "store_name",
                                              label: "商品名称",
                                              "min-width": "200",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-data",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "image",
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .store_name
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              338878098
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "参与规格",
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return _vm._l(
                                                      scope.row.attr,
                                                      function (item, index) {
                                                        return _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.sku ||
                                                                    "默认"
                                                                ) +
                                                                " | " +
                                                                _vm._s(
                                                                  item.active_price ||
                                                                    item.price
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2052282306
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editGoods(
                                                                scope.row,
                                                                scope.$index,
                                                                "Main"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("设置规格")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteGoods(
                                                                scope.$index,
                                                                "Main"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1942046363
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.specsMainData.length < 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "submission mr15 mt20",
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addGoods("Main")
                                                },
                                              },
                                            },
                                            [_vm._v("添加商品")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.formValidate.type == 1
                                        ? "套餐搭配商品："
                                        : "套餐商品：",
                                    prop: "products",
                                    "label-for": "products",
                                  },
                                },
                                [
                                  _c(
                                    "el-table",
                                    { attrs: { data: _vm.specsData } },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "store_name",
                                          label: "商品名称",
                                          "min-width": "200",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "product-data",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "image",
                                                      attrs: {
                                                        src: scope.row.image,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.store_name
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "参与规格",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return _vm._l(
                                                scope.row.attr,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            item.sku || "默认"
                                                          ) +
                                                          " | " +
                                                          _vm._s(
                                                            item.active_price ||
                                                              item.price
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editGoods(
                                                          scope.row,
                                                          scope.$index,
                                                          "Other"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("设置规格")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteGoods(
                                                          scope.$index,
                                                          "Other"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.specsData.length < 50
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "submission mr15 mt20",
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addGoods("Other")
                                            },
                                          },
                                        },
                                        [_vm._v("添加商品")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "套餐数量：",
                                    prop:
                                      _vm.formValidate.is_limit == 1
                                        ? "limit_num"
                                        : "",
                                    "label-for": "limit_num",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { "element-id": "is_limit" },
                                      model: {
                                        value: _vm.formValidate.is_limit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "is_limit",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.is_limit",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("不限量"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "radio",
                                          attrs: { label: 1 },
                                        },
                                        [_vm._v("限量")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.formValidate.is_limit == 1
                                    ? _c("el-input-number", {
                                        staticStyle: { "margin-left": "15px" },
                                        attrs: {
                                          placeholder: "请输入限量数量",
                                          min: 0,
                                          max: 99999,
                                          precision: 0,
                                        },
                                        model: {
                                          value: _vm.formValidate.limit_num,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "limit_num",
                                              $$v
                                            )
                                          },
                                          expression: "formValidate.limit_num",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "排序：",
                                    "label-for": "sort",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      placeholder: "请输入排序序号",
                                      min: 0,
                                      max: 999999,
                                      precision: 0,
                                    },
                                    model: {
                                      value: _vm.formValidate.sort,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formValidate, "sort", $$v)
                                      },
                                      expression: "formValidate.sort",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "套餐包邮：",
                                    prop: "free_shipping",
                                    "label-for": "status",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": "是",
                                      "inactive-text": "否",
                                    },
                                    model: {
                                      value: _vm.formValidate.free_shipping,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "free_shipping",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.free_shipping",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml100 grey" }, [
                                    _vm._v(
                                      "不包邮时，将按照商品的运费模板进行计算"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "上架状态：",
                                    prop: "status",
                                    "label-for": "status",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": "上架",
                                      "inactive-text": "下架",
                                    },
                                    model: {
                                      value: _vm.formValidate.status,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "submission",
                              attrs: {
                                type: "primary",
                                loading: _vm.submitOpen,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.next("formValidate")
                                },
                              },
                            },
                            [
                              !_vm.submitOpen
                                ? _c("div", [_vm._v("提交")])
                                : _c("div", [_vm._v("提交中")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "paymentFooter",
          attrs: { visible: _vm.modals, title: "商品列表", width: "900px" },
          on: {
            "update:visible": function ($event) {
              _vm.modals = $event
            },
          },
        },
        [
          _vm.modals
            ? _c("goods-list", {
                ref: "goodslist",
                attrs: { ischeckbox: true },
                on: { getProductId: _vm.getProductId },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "paymentFooter",
          attrs: { visible: _vm.ggModel, title: "规格设置", width: "900px" },
          on: {
            "update:visible": function ($event) {
              _vm.ggModel = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "df" },
            [
              _c("span", { staticStyle: { width: "75px" } }, [
                _vm._v(" 优惠价："),
              ]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "m10",
                staticStyle: { width: "100%", "text-align": "left" },
                attrs: { min: 0 },
                model: {
                  value: _vm.rate_price,
                  callback: function ($$v) {
                    _vm.rate_price = $$v
                  },
                  expression: "rate_price",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeRatePrice()
                    },
                  },
                },
                [_c("div", [_vm._v("批量添加")])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "multipleSelection",
              attrs: {
                data: _vm.manyFormValidate,
                "row-key": function (row) {
                  return row.unique
                },
                height: "500",
              },
              on: { "selection-change": _vm.selectOne },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "规格", prop: "sku", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "售价", prop: "price", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "优惠价", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticClass: "priceBox",
                          attrs: {
                            type: "number",
                            min: 0,
                            max: scope.row["price"],
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.limitPrice(scope.row)
                            },
                          },
                          model: {
                            value: scope.row["active_price"],
                            callback: function ($$v) {
                              _vm.$set(scope.row, "active_price", _vm._n($$v))
                            },
                            expression: "scope.row['active_price']",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "mt10",
              staticStyle: { width: "100%" },
              attrs: { type: "primary" },
              on: { click: _vm.getAttr },
            },
            [_c("div", [_vm._v("提交")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }