var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      _vm.getList(1), _vm.getLstFilterApi()
                    },
                  },
                  model: {
                    value: _vm.tableFrom.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "type", $$v)
                    },
                    expression: "tableFrom.type",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: {
                      name: item.type.toString(),
                      label: item.name + "(" + item.count + ")",
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台商品分类：" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.categoryList,
                              props: _vm.props,
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "cate_id", $$v)
                              },
                              expression: "tableFrom.cate_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商户商品分类：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.mer_cate_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "mer_cate_id", $$v)
                                },
                                expression: "tableFrom.mer_cate_id",
                              },
                            },
                            _vm._l(_vm.merCateList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否为礼包：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.is_gift_bag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "is_gift_bag", $$v)
                                },
                                expression: "tableFrom.is_gift_bag",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "否", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: { change: _vm.getList },
                              model: {
                                value: _vm.tableFrom.us_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "us_status", $$v)
                                },
                                expression: "tableFrom.us_status",
                              },
                            },
                            _vm._l(_vm.productStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.mer_labels,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "mer_labels", $$v)
                                },
                                expression: "tableFrom.mer_labels",
                              },
                            },
                            _vm._l(_vm.labelList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "运费模板：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.temp_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "temp_id", $$v)
                                },
                                expression: "tableFrom.temp_id",
                              },
                            },
                            _vm._l(_vm.tempList, function (item) {
                              return _c("el-option", {
                                key: item.shipping_template_id,
                                attrs: {
                                  label: item.name,
                                  value: item.shipping_template_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键字搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入商品名称，关键字" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "" + _vm.roterPre + "/product/list/addProduct",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("添加商品")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.onCopy },
                },
                [_vm._v("商品采集")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled:
                      _vm.tableFrom.type != 1 ||
                      _vm.multipleSelection.length == 0,
                  },
                  on: { click: _vm.batchOff },
                },
                [_vm._v("批量下架")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled:
                      _vm.tableFrom.type != 2 ||
                      _vm.multipleSelection.length == 0,
                  },
                  on: { click: _vm.batchShelf },
                },
                [_vm._v("批量上架")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.multipleSelection.length == 0,
                  },
                  on: { click: _vm.batchLabel },
                },
                [_vm._v("批量设置标签")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.multipleSelection.length == 0,
                  },
                  on: { click: _vm.batchFreight },
                },
                [_vm._v("批量设置运费")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "row-class-name": _vm.tableRowClassName,
                "row-key": function (row) {
                  return row.product_id
                },
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                rowclick: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeEdit($event)
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand demo-table-expand1",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "平台分类：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.storeCategory
                                        ? props.row.storeCategory.cate_name
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品分类：" } },
                              [
                                props.row.merCateId.length
                                  ? _vm._l(
                                      props.row.merCateId,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          { key: index, staticClass: "mr10" },
                                          [
                                            _vm._v(
                                              _vm._s(item.category.cate_name)
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  : _c("span", [_vm._v("-")]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "品牌：" } }, [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(
                                    props.row.brand
                                      ? props.row.brand.brand_name
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "市场价格：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.ot_price)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "成本价：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.cost)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "收藏：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.care_count)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.tableFrom.type === "7"
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: "1",
                                    attrs: { label: "未通过原因：" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.refusal)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "product_id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "商品名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "商品售价", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "cost", label: "商品成本", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "销量", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存", "min-width": "70" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "integral_total",
                  label: "积分抵扣",
                  "min-width": "70",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "integral_price_total",
                  label: "积分抵扣金额",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  align: "center",
                  label: "排序",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.index === _vm.tabClickIndex
                          ? _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    maxlength: "300",
                                    size: "mini",
                                    autofocus: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.inputBlur(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row["sort"],
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sort", _vm._n($$v))
                                    },
                                    expression: "scope.row['sort']",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              {
                                on: {
                                  dblclick: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.tabClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row["sort"]))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              Number(_vm.tableFrom.type) < 5
                ? _c("el-table-column", {
                    key: "1",
                    attrs: {
                      prop: "status",
                      label: "上/下架",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "上架",
                                  "inactive-text": "下架",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onchangeIsShow(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.is_show,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "is_show", $$v)
                                  },
                                  expression: "scope.row.is_show",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      132813036
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "商品状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("productStatusFilter")(scope.row.us_status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "标签", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.mer_labels,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "label-list" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.type != 5
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      _vm.roterPre +
                                      "/product/list/addProduct/" +
                                      scope.row.product_id,
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "small" },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type != 5
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      _vm.roterPre +
                                      "/product/list/addProduct/" +
                                      scope.row.product_id +
                                      "?type=copy",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "small" },
                                  },
                                  [_vm._v("复制")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type != 5
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      _vm.roterPre +
                                      "/product/reviews/?product_id=" +
                                      scope.row.product_id,
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "small" },
                                  },
                                  [_vm._v("查看评价")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "5" && _vm.is_audit == "1"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onAuditFree(scope.row)
                                  },
                                },
                              },
                              [_vm._v("免审编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditLabel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑标签")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type === "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRestore(
                                      scope.row.product_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("恢复商品")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "1" &&
                        _vm.tableFrom.type !== "3" &&
                        _vm.tableFrom.type !== "4"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.product_id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tableFrom.type === "5"
                                      ? "删除"
                                      : "加入回收站"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("tao-bao", {
        ref: "taoBao",
        attrs: {
          "delivery-type": _vm.deliveryType,
          "delivery-list": _vm.deliveryList,
        },
        on: { getSuccess: _vm.getSuccess },
      }),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: {
                      "goods-id": _vm.goodsId,
                      "product-type": 0,
                      "preview-key": _vm.previewKey,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "选择标签",
                visible: _vm.dialogLabel,
                width: "800px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: { model: _vm.labelForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            clearable: "",
                            multiple: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.labelForm.mer_labels,
                            callback: function ($$v) {
                              _vm.$set(_vm.labelForm, "mer_labels", $$v)
                            },
                            expression: "labelForm.mer_labels",
                          },
                        },
                        _vm._l(_vm.labelList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("labelForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("edit-attr", { ref: "editAttr" }),
      _vm._v(" "),
      _vm.dialogFreight
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "选择运费模板",
                visible: _vm.dialogFreight,
                width: "800px",
                "before-close": _vm.handleFreightClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFreight = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "tempForm",
                  attrs: { model: _vm.tempForm, rules: _vm.tempRule },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "temp_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.tempForm.temp_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempForm, "temp_id", $$v)
                            },
                            expression: "tempForm.temp_id",
                          },
                        },
                        _vm._l(_vm.tempList, function (item) {
                          return _c("el-option", {
                            key: item.shipping_template_id,
                            attrs: {
                              label: item.name,
                              value: item.shipping_template_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitTempForm("tempForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }