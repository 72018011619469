"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
var _settings = require("@/settings");
var _this = void 0; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var defaultObj = {
  store_name: '',
  cate_id: '',
  temp_id: '',
  type: 0,
  guarantee_template_id: '',
  keyword: '',
  unit_name: '',
  store_info: '',
  image: '',
  slider_image: [],
  content: '',
  ficti: 0,
  once_count: 0,
  give_integral: 0,
  is_show: 0,
  price: 0,
  cost: 0,
  ot_price: 0,
  stock: 0,
  attrs: [],
  items: [],
  delivery_way: [],
  mer_labels: [],
  delivery_free: 0,
  spec_type: 0,
  is_copoy: 1,
  attrValue: [{
    image: '',
    price: 0,
    cost: 0,
    ot_price: 0,
    stock: 0,
    bar_code: '',
    weight: 0,
    volume: 0
  }]
};
var objTitle = {
  price: {
    title: '售价'
  },
  cost: {
    title: '成本价'
  },
  ot_price: {
    title: '市场价'
  },
  stock: {
    title: '库存'
  },
  bar_code: {
    title: '商品编号'
  },
  weight: {
    title: '重量（KG）'
  },
  volume: {
    title: '体积(m³)'
  }
};
var _default = exports.default = {
  name: 'EditAttr',
  components: {},
  data: function data() {
    return {
      product_id: '',
      roterPre: _settings.roterPre,
      modals: false,
      loading: false,
      loading1: false,
      OneattrValue: [Object.assign({}, defaultObj.attrValue[0])],
      // 单规格
      ManyAttrValue: [Object.assign({}, defaultObj.attrValue[0])],
      // 多规格
      manyTabDate: {},
      count: 0,
      modal_loading: false,
      images: '',
      modalPic: false,
      isChoice: '',
      columns: [],
      merCateList: [],
      // 商户
      propsMer: {
        emitPath: false,
        multiple: true
      },
      ruleInline: {
        mer_cate_id: [{
          required: true,
          message: '请选择商户分类',
          trigger: 'change',
          type: 'array',
          min: '1'
        }]
      },
      formThead: Object.assign({}, objTitle),
      formValidate: Object.assign({}, defaultObj),
      items: [{
        image: '',
        price: 0,
        cost: 0,
        ot_price: 0,
        stock: 0,
        bar_code: '',
        weight: 0,
        volume: 0
      }],
      tableIndex: 0,
      attrInfo: {},
      oneFormBatch: [{
        image: '',
        price: 0,
        cost: 0,
        ot_price: 0,
        stock: 0,
        bar_code: '',
        weight: 0,
        volume: 0
      }]
    };
  },
  computed: {
    attrValue: function attrValue() {
      var obj = Object.assign({}, defaultObj.attrValue[0]);
      delete obj.image;
      return obj;
    }
  },
  watch: {
    'formValidate.attr': {
      handler: function handler(val) {
        if (_this.formValidate.spec_type === 1) _this.watCh(val);
      },
      immediate: false,
      deep: true
    }
  },
  created: function created() {
    this.getCategorySelect();
  },
  mounted: function mounted() {},
  methods: {
    batchDel: function batchDel() {
      this.oneFormBatch = [{
        image: '',
        price: 0,
        cost: 0,
        ot_price: 0,
        stock: 0,
        bar_code: '',
        weight: 0,
        volume: 0
      }];
    },
    batchAdd: function batchAdd() {
      var _iterator = _createForOfIteratorHelper(this.ManyAttrValue),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var val = _step.value;
          this.$set(val, 'image', this.oneFormBatch[0].image);
          this.$set(val, 'price', this.oneFormBatch[0].price);
          this.$set(val, 'cost', this.oneFormBatch[0].cost);
          this.$set(val, 'ot_price', this.oneFormBatch[0].ot_price);
          this.$set(val, 'stock', this.oneFormBatch[0].stock);
          this.$set(val, 'bar_code', this.oneFormBatch[0].bar_code);
          this.$set(val, 'weight', this.oneFormBatch[0].weight);
          this.$set(val, 'volume', this.oneFormBatch[0].volume);
          this.$set(val, 'extension_one', this.oneFormBatch[0].extension_one);
          this.$set(val, 'extension_two', this.oneFormBatch[0].extension_two);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this2.merCateList = res.data;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    watCh: function watCh(val) {
      var _this3 = this;
      var tmp = {};
      var tmpTab = {};
      this.formValidate.attr.forEach(function (o, i) {
        tmp['value' + i] = {
          title: o.value
        };
        tmpTab['value' + i] = '';
      });
      this.ManyAttrValue = this.attrFormat(val);
      console.log(this.ManyAttrValue);
      this.ManyAttrValue.forEach(function (val, index) {
        var key = Object.values(val.detail).sort().join('/');
        if (_this3.attrInfo[key]) _this3.ManyAttrValue[index] = _this3.attrInfo[key];
        val.image = _this3.formValidate.image;
      });
      this.attrInfo = {};
      this.ManyAttrValue.forEach(function (val) {
        if (val.detail !== 'undefined' && val.detail !== null) {
          _this3.attrInfo[Object.values(val.detail).sort().join('/')] = val;
        }
      });
      this.manyTabTit = tmp;
      this.manyTabDate = tmpTab;
      this.formThead = Object.assign({}, this.formThead, tmp);
    },
    attrFormat: function attrFormat(arr) {
      var data = [];
      var res = [];
      return format(arr);
      function format(arr) {
        if (arr.length > 1) {
          arr.forEach(function (v, i) {
            if (i === 0) data = arr[i]['detail'];
            var tmp = [];
            data.forEach(function (vv) {
              arr[i + 1] && arr[i + 1]['detail'] && arr[i + 1]['detail'].forEach(function (g) {
                var rep2 = (i !== 0 ? '' : arr[i]['value'] + '_$_') + vv + '-$-' + arr[i + 1]['value'] + '_$_' + g;
                tmp.push(rep2);
                if (i === arr.length - 2) {
                  var rep4 = {
                    image: '',
                    price: 0,
                    cost: 0,
                    ot_price: 0,
                    stock: 0,
                    bar_code: '',
                    weight: 0,
                    volume: 0,
                    brokerage: 0,
                    brokerage_two: 0
                  };
                  rep2.split('-$-').forEach(function (h, k) {
                    var rep3 = h.split('_$_');
                    if (!rep4['detail']) rep4['detail'] = {};
                    rep4['detail'][rep3[0]] = rep3.length > 1 ? rep3[1] : '';
                  });
                  Object.values(rep4.detail).forEach(function (v, i) {
                    rep4['value' + i] = v;
                  });
                  res.push(rep4);
                }
              });
            });
            data = tmp.length ? tmp : [];
          });
        } else {
          var dataArr = [];
          arr.forEach(function (v, k) {
            v['detail'].forEach(function (vv, kk) {
              dataArr[kk] = v['value'] + '_' + vv;
              res[kk] = {
                image: '',
                price: 0,
                cost: 0,
                ot_price: 0,
                stock: 0,
                bar_code: '',
                weight: 0,
                volume: 0,
                brokerage: 0,
                brokerage_two: 0,
                detail: (0, _defineProperty2.default)({}, v['value'], vv)
              };
              Object.values(res[kk].detail).forEach(function (v, i) {
                res[kk]['value' + i] = v;
              });
            });
          });
          data.push(dataArr.join('$&'));
        }
        return res;
      }
    },
    // 获取规格数据
    getAttrDetail: function getAttrDetail(id) {
      var _this4 = this;
      this.product_id = id;
      this.loading = true;
      this.modals = true;
      (0, _product.productDetailApi)(id).then(function (res) {
        var info = res.data;
        _this4.formValidate = {
          attr: info.attr || [],
          attrValue: info.attrValue,
          mer_cate_id: info.mer_cate_id,
          spec_type: info.spec_type
        };
        if (_this4.formValidate.spec_type === 0) {
          _this4.OneattrValue = info.attrValue;
        } else {
          _this4.ManyAttrValue = info.attrValue;
          _this4.ManyAttrValue.forEach(function (val) {
            if (val.detail !== 'undefined' && val.detail !== null) {
              _this4.attrInfo[Object.values(val.detail).sort().join('/')] = val;
            }
          });
          _this4.$watch('formValidate.attr', _this4.watCh);
        }
        _this4.loading = false;
      }).catch(function (res) {
        _this4.$message.error(res.message);
        _this4.loading = false;
      });
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var that = this;
      that.$refs[name].validate(function (valid) {
        if (valid) {
          if (that.formValidate.spec_type === 1) {
            that.formValidate.attrValue = that.ManyAttrValue;
          } else {
            that.formValidate.attrValue = that.OneattrValue;
            that.formValidate.attr = [];
          }
          that.loading1 = true;
          (0, _product.freeTrialApi)(that.product_id, that.formValidate).then(function (res) {
            that.loading1 = false;
            that.$message.success(res.message);
            setTimeout(function () {
              that.modals = false;
            }, 500);
          }).catch(function (res) {
            that.$message.error(res.message);
            that.loading1 = false;
          });
        } else {
          if (!that.formValidate.mer_cate_id) {
            that.$message.warning('请选择商户商品分类！');
          }
        }
      });
    }
  }
};