var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-fooot", style: { background: _vm.bgColor } },
    _vm._l(_vm.menuList, function (item, index) {
      return _c("div", { key: index, staticClass: "foot-item" }, [
        index == _vm.isSpecial
          ? _c("img", { attrs: { src: item.imgList[0], alt: "" } })
          : _c("img", { attrs: { src: item.imgList[1], alt: "" } }),
        _vm._v(" "),
        index == _vm.isSpecial
          ? _c("p", { style: { color: _vm.activeTxtColor } }, [
              _vm._v(_vm._s(item.name)),
            ])
          : _c("p", { style: { color: _vm.txtColor } }, [
              _vm._v(_vm._s(item.name)),
            ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }