exports = module.exports = require("../../node_modules/_css-loader@1.0.1@css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#6394f9",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#0b1529",
	"menuHover": "#182848",
	"subMenuBg": "#030c17",
	"subMenuHover": "#182848",
	"sideBarWidth": "210px"
};