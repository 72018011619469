"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'BroadcastProDetail',
  data: function data() {
    return {
      dialogVisible: false,
      option: {
        form: {
          labelWidth: '150px'
        }
      },
      FormData: {
        product: {
          stock: '',
          image: ''
        }
      },
      loading: false
    };
  },
  mounted: function mounted() {},
  methods: {
    getData: function getData(id) {
      var _this = this;
      this.loading = true;
      (0, _marketing.broadcastProDetailApi)(id).then(function (res) {
        _this.FormData = res.data;
        _this.loading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.loading = false;
      });
    },
    handleRemarks: function handleRemarks(id) {
      var _this2 = this;
      (0, _marketing.broadcastProRemarksApi)(this.FormData.broadcast_goods_id, this.FormData.mark).then(function (res) {
        _this2.loading = false;
        _this2.$message.success(res.message);
        _this2.dialogVisible = false;
        _this2.$emit('getList');
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.loading = false;
      });
    }
  }
};