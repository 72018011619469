"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _diy = require("@/api/diy");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _index = _interopRequireDefault(require("@/components/mobilePage/index.js"));
var _index2 = _interopRequireDefault(require("@/components/mobileConfig/index.js"));
var _pagesFoot = _interopRequireDefault(require("@/components/pagesFoot"));
var _settings = require("@/settings");
var _vuex = require("vuex");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _clipboard = _interopRequireDefault(require("clipboard"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ['reload'],
  name: 'IndexVue',
  components: _objectSpread(_objectSpread({
    footPage: _pagesFoot.default,
    html2canvas: _html2canvas.default,
    draggable: _vuedraggable.default
  }, _index.default), _index2.default),
  filters: {
    filterTxt: function filterTxt(val) {
      if (val) {
        return val = val.substr(0, val.length - 1);
      }
    }
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      clientHeight: '',
      // 页面动态高度
      rollHeight: '',
      leftMenu: [],
      // 左侧菜单
      lConfig: [],
      // 左侧组件
      mConfig: [],
      // 中间组件渲染
      rConfig: [],
      // 右侧组件配置
      activeConfigName: '',
      propsObj: {},
      // 组件传递的数据,
      activeIndex: -100,
      // 选中的下标
      number: 0,
      pageId: '',
      pageName: '',
      pageType: '',
      category: [],
      tabList: [{
        title: '组件库',
        key: 0
      }, {
        title: '页面链接',
        key: 1
      }],
      tabs: [{
        icon: 'icon-yizhan_o',
        name: '首页',
        value: 1
      }, {
        icon: 'icon-gouwu_o',
        name: '商品',
        value: 3
      }, {
        icon: 'icon-yingyongAPP_o',
        name: '分类',
        value: 2
      }, {
        icon: 'icon-zhuanti',
        name: '专场',
        value: 4
      }],
      tabActive: 1,
      tabCur: 0,
      urlList: [],
      footActive: false,
      loading: false,
      isSearch: false,
      isTab: false,
      isFllow: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    titleTxt: function titleTxt(state) {
      return state.mobildConfig.pageTitle || '首页';
    },
    nameTxt: function nameTxt(state) {
      return state.mobildConfig.pageName || '模板';
    },
    showTxt: function showTxt(state) {
      return state.mobildConfig.pageShow;
    },
    colorTxt: function colorTxt(state) {
      return state.mobildConfig.pageColor;
    },
    picTxt: function picTxt(state) {
      return state.mobildConfig.pagePic;
    },
    colorPickerTxt: function colorPickerTxt(state) {
      return state.mobildConfig.pageColorPicker;
    },
    tabValTxt: function tabValTxt(state) {
      return state.mobildConfig.pageTabVal;
    },
    picUrlTxt: function picUrlTxt(state) {
      return state.mobildConfig.pagePicUrl;
    }
  })),
  created: function created() {
    this.getUrlList();
    this.pageId = this.$route.query.id;
    this.pageName = this.$route.query.name;
    this.pageType = this.$route.query.types;
    this.lConfig = this.objToArr(_index.default);
  },
  mounted: function mounted() {
    var _this = this;
    // this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    var imgList = {
      imgList: [require('@/assets/images/foot-006.png'), require('@/assets/images/foot-005.png')],
      name: '逛逛',
      link: '/pages/order_addcart/order_addcart'
    };
    this.$nextTick(function () {
      _this.$store.commit('mobildConfig/FOOTER', {
        title: '是否自定义',
        name: imgList
      });
      _this.arraySort();
      if (_this.pageId !== 0) {
        _this.getDefaultConfig();
      } else {
        _this.showTitle();
      }
      _this.clientHeight = "".concat(document.documentElement.clientHeight) - 65.81; // 获取浏览器可视区域高度
      var H = "".concat(document.documentElement.clientHeight) - 180;
      _this.rollHeight = H > 650 ? 650 : H;
      var that = _this;
      window.onresize = function () {
        that.clientHeight = "".concat(document.documentElement.clientHeight) - 65.81;
        var H = "".concat(document.documentElement.clientHeight) - 180;
        that.rollHeight = H > 650 ? 650 : H;
      };
    });
    this.$nextTick(function () {
      var clipboard = new _clipboard.default('.copy-data');
      clipboard.on('success', function () {
        _this.$message.success('复制成功');
      });
    });
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.commit('mobildConfig/titleUpdata', '');
    this.$store.commit('mobildConfig/nameUpdata', '');
    this.$store.commit('mobildConfig/showUpdata', 1);
    this.$store.commit('mobildConfig/colorUpdata', 0);
    this.$store.commit('mobildConfig/picUpdata', 0);
    this.$store.commit('mobildConfig/pickerUpdata', '#f5f5f5');
    this.$store.commit('mobildConfig/radioUpdata', 0);
    this.$store.commit('mobildConfig/picurlUpdata', '');
    this.$store.commit('mobildConfig/SETEMPTY');
  },
  destroyed: function destroyed() {
    this.$store.commit('mobildConfig/titleUpdata', '');
    this.$store.commit('mobildConfig/nameUpdata', '');
    this.$store.commit('mobildConfig/showUpdata', 1);
    this.$store.commit('mobildConfig/colorUpdata', 0);
    this.$store.commit('mobildConfig/picUpdata', 0);
    this.$store.commit('mobildConfig/pickerUpdata', '#f5f5f5');
    this.$store.commit('mobildConfig/radioUpdata', 0);
    this.$store.commit('mobildConfig/picurlUpdata', '');
    this.$store.commit('mobildConfig/SETEMPTY');
  },
  methods: {
    leftRemove: function leftRemove(_ref) {
      var to = _ref.to,
        from = _ref.from,
        item = _ref.item,
        clone = _ref.clone,
        oldIndex = _ref.oldIndex,
        newIndex = _ref.newIndex;
      if (this.isSearch && newIndex == 0) {
        if (item._underlying_vm_.name === 'z_wechat_attention') {
          this.isFllow = true;
        } else {
          this.$store.commit('mobildConfig/ARRAYREAST', this.mConfig[0].num);
          this.mConfig.splice(0, 1);
        }
      }
      if (this.isFllow = true && newIndex >= 1) {
        this.$store.commit('mobildConfig/ARRAYREAST', this.mConfig[0].num);
      }
    },
    onMove: function onMove(e) {
      if (e.relatedContext.element.name === 'search_box') return false;
      if (e.relatedContext.element.name === 'nav_bar') return false;
      return true;
    },
    // 返回
    goBack: function goBack() {
      this.$router.push({
        path: "".concat(_settings.roterPre, "/devise/diy/list")
      });
      // this.$confirm('退出后新编辑的数据将无法保存，是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //     }).then(() => {
      //         this.$router.push({ path: `${roterPre}/devise/diy/list` })
      //     }).catch(() => {
      //         this.$message({
      //             type: 'info',
      //             message: '已取消'
      //         });
      //      });
    },
    // 获取url
    getUrlList: function getUrlList() {
      var _this2 = this;
      (0, _diy.getUrl)().then(function (res) {
        _this2.urlList = res.data.list;
      });
    },
    // 左侧tab
    bindTab: function bindTab(index) {
      this.tabCur = index;
    },
    // 页面标题点击
    showTitle: function showTitle() {
      this.activeIndex = -100;
      var obj = {};
      for (var i in _index2.default) {
        if (i === 'pageTitle') {
          // this.rConfig = obj
          obj = _index2.default[i];
          obj.configName = _index2.default[i].name;
          obj.cname = '页面设置';
        }
      }
      this.rConfig = [];
      this.rConfig[0] = JSON.parse(JSON.stringify(obj));
    },
    // 页面底部点击
    showFoot: function showFoot() {
      this.activeIndex = -101;
      var obj = {};
      for (var i in _index2.default) {
        if (i === 'pageFoot') {
          // this.rConfig = obj
          obj = _index2.default[i];
          obj.configName = _index2.default[i].name;
          obj.cname = '底部菜单';
        }
      }
      this.rConfig = [];
      this.rConfig[0] = JSON.parse(JSON.stringify(obj));
    },
    // 对象转数组
    objToArr: function objToArr(data) {
      var obj = Object.keys(data);
      var m = obj.map(function (key) {
        return data[key];
      });
      return m;
    },
    log: function log(evt) {
      // 中间拖拽排序
      if (evt.moved) {
        if (evt.moved.element.name === 'search_box') {
          return this.$message.warning('该组件禁止拖拽');
        }
        evt.moved.oldNum = this.mConfig[evt.moved.oldIndex].num;
        evt.moved.newNum = this.mConfig[evt.moved.newIndex].num;
        evt.moved.status = evt.moved.oldIndex > evt.moved.newIndex;
        this.mConfig.forEach(function (el, index) {
          el.num = new Date().getTime() * 1000 + index;
        });
        evt.moved.list = this.mConfig;
        this.rConfig = [];
        var item = evt.moved.element;
        var tempItem = JSON.parse(JSON.stringify(item));
        this.rConfig.push(tempItem);
        this.activeIndex = evt.moved.newIndex;
        this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
        this.$store.commit('mobildConfig/defaultArraySort', evt.moved);
      }
      // 从左向右拖拽排序
      if (evt.added) {
        var data = evt.added.element;
        var timestamp = new Date().getTime() * 1000;
        data.num = timestamp;
        this.activeConfigName = data.name;
        var _tempItem = JSON.parse(JSON.stringify(data));
        _tempItem.id = 'id' + _tempItem.num;
        this.mConfig[evt.added.newIndex] = _tempItem;
        this.rConfig = [];
        this.rConfig.push(_tempItem);
        this.mConfig.forEach(function (el, index) {
          el.num = new Date().getTime() * 1000 + index;
        });
        evt.added.list = this.mConfig;
        this.activeIndex = evt.added.newIndex;
        // 保存组件名称
        this.$store.commit('mobildConfig/SETCONFIGNAME', data.name);
        this.$store.commit('mobildConfig/defaultArraySort', evt.added);
      }
    },
    cloneDog: function cloneDog(data) {
      return _objectSpread({}, data);
    },
    // 数组元素互换位置
    swapArray: function swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    // 点击上下移动；
    movePage: function movePage(item, index, type) {
      if (type) {
        if (index === 0) {
          return;
        }
      } else {
        if (index === this.mConfig.length - 1) {
          return;
        }
      }
      if (item.name === 'search_box' || item.name === 'nav_bar') {
        return this.$message.warning('该组件禁止移动');
      }
      if (type) {
        if (this.mConfig[index - 1].name === 'search_box' || this.mConfig[index - 1].name === 'nav_bar') {
          return this.$message.warning('搜索框或分类必须为顶部');
        }
        this.swapArray(this.mConfig, index - 1, index);
      } else {
        this.swapArray(this.mConfig, index, index + 1);
      }
      var obj = {};
      this.rConfig = [];
      obj.oldIndex = index;
      if (type) {
        obj.newIndex = index - 1;
      } else {
        obj.newIndex = index + 1;
      }
      this.mConfig.forEach(function (el, index) {
        el.num = new Date().getTime() * 1000 + index;
      });
      var tempItem = JSON.parse(JSON.stringify(item));
      this.rConfig.push(tempItem);
      obj.element = item;
      obj.list = this.mConfig;
      if (type) {
        this.activeIndex = index - 1;
      } else {
        this.activeIndex = index + 1;
      }
      this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
      this.$store.commit('mobildConfig/defaultArraySort', obj);
    },
    // 组件添加
    addDomCon: function addDomCon(item, type, index) {
      if (item.name === 'search_box') {
        if (this.isSearch) return this.$message.error('该组件只能添加一次');
        this.isSearch = true;
      }
      if (item.name === 'nav_bar') {
        if (this.isTab) return this.$message.error('该组件只能添加一次');
        this.isTab = true;
      }
      var obj = {};
      var timestamp = new Date().getTime() * 1000;
      item.num = "".concat(timestamp);
      item.id = "id".concat(timestamp);
      this.activeConfigName = item.name;
      var tempItem = JSON.parse(JSON.stringify(item));
      if (item.name === 'search_box') {
        this.rConfig = [];
        this.mConfig.unshift(tempItem);
        this.activeIndex = 0;
        this.rConfig.push(tempItem);
      } else if (item.name === 'nav_bar') {
        // 动态拖动可上传此部分代码
        this.rConfig = [];
        if (this.mConfig[0] && this.mConfig[0].name === 'search_box') {
          this.mConfig.splice(1, 0, tempItem);
          this.activeIndex = 1;
        } else {
          this.mConfig.splice(0, 0, tempItem);
          this.activeIndex = 0;
        }
        this.rConfig.push(tempItem);
      } else {
        if (type) {
          this.rConfig = [];
          this.mConfig.push(tempItem);
          this.activeIndex = this.mConfig.length - 1;
          this.rConfig.push(tempItem);
        } else {
          this.mConfig.splice(index + 1, 0, tempItem);
          this.activeIndex = index;
        }
      }
      this.mConfig.forEach(function (el, index) {
        el.num = new Date().getTime() * 1000 + index;
      });
      // 保存组件名称
      obj.element = item;
      obj.list = this.mConfig;
      this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
      this.$store.commit('mobildConfig/defaultArraySort', obj);
    },
    // 中间页点击添加模块；
    bindAddDom: function bindAddDom(item, type, index) {
      var i = item;
      this.lConfig.forEach(function (j) {
        if (item.name === j.name) {
          i = j;
        }
      });
      this.addDomCon(i, type, index);
    },
    // 左边配置模块点击添加；
    addDom: function addDom(item, type) {
      this.addDomCon(item, type);
    },
    // 点击显示相应的配置
    bindconfig: function bindconfig(item, index) {
      this.rConfig = [];
      var tempItem = JSON.parse(JSON.stringify(item));
      this.rConfig.push(tempItem);
      this.activeIndex = index;
      this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
    },
    // 组件删除
    bindDelete: function bindDelete(item, key) {
      if (item.name === 'search_box') {
        this.isSearch = false;
      }
      if (item.name === 'nav_bar') {
        this.isTab = false;
      }
      this.mConfig.splice(key, 1);
      this.rConfig.splice(0, 1);
      if (this.mConfig.length !== key) {
        this.rConfig.push(this.mConfig[key]);
      } else {
        if (this.mConfig.length) {
          this.activeIndex = key - 1;
          this.rConfig.push(this.mConfig[key - 1]);
        } else {
          this.showTitle();
        }
      }
      // 删除第几个配置
      this.$store.commit('mobildConfig/DELETEARRAY', item);
    },
    // 组件返回
    config: function config(data) {
      var propsObj = this.propsObj;
      propsObj.data = data;
      propsObj.name = this.activeConfigName;
    },
    addSort: function addSort(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    // 数组排序
    arraySort: function arraySort() {
      var tempArr = [];
      var basis = {
        title: '基础组件',
        list: [],
        isOpen: true
      };
      var marketing = {
        title: '营销组件',
        list: [],
        isOpen: true
      };
      var tool = {
        title: '工具组件',
        list: [],
        isOpen: true
      };
      this.lConfig.map(function (el, index) {
        if (el.type === 0) {
          basis.list.push(el);
        }
        if (el.type === 1) {
          marketing.list.push(el);
        }
        if (el.type === 2) {
          tool.list.push(el);
        }
      });
      tempArr.push(basis, marketing, tool);
      this.leftMenu = tempArr;
    },
    diySaveDate: function diySaveDate(val) {
      var _this3 = this;
      (0, _diy.diySave)(this.pageId, {
        value: val,
        title: this.titleTxt,
        name: this.nameTxt,
        is_show: this.showTxt ? 1 : 0,
        is_bg_color: this.colorTxt ? 1 : 0,
        color_picker: this.colorPickerTxt,
        bg_pic: this.picUrlTxt,
        bg_tab_val: this.tabValTxt,
        is_bg_pic: this.picTxt ? 1 : 0
      }).then(function (res) {
        _this3.loading = false;
        _this3.pageId = res.data.id;
        _this3.$message.success(res.message);
      }).catch(function (res) {
        _this3.loading = false;
        _this3.$message.error(res.message);
      });
    },
    // 保存配置
    saveConfig: function saveConfig() {
      var _this4 = this;
      if (this.mConfig.length === 0) {
        return this.$message.error('暂未添加任何组件，保存失败！');
      }
      this.loading = true;
      var val = this.$store.state.mobildConfig.defaultArray;
      // if (!this.footActive) {
      //     let timestamp = new Date().getTime() * 1000;
      //     val[timestamp] = this.$store.state.mobildConfig.pageFooter;
      //     this.footActive = true;
      // }
      this.$nextTick(function () {
        _this4.diySaveDate(val);
      });
    },
    // 获取默认配置
    getDefaultConfig: function getDefaultConfig() {
      var _this5 = this;
      (0, _diy.diyGetInfo)(this.pageId).then(function (_ref2) {
        var data = _ref2.data;
        var obj = {};
        var tempARR = [];
        _this5.$store.commit('mobildConfig/titleUpdata', data.info.title);
        _this5.$store.commit('mobildConfig/nameUpdata', data.info.name);
        _this5.$store.commit('mobildConfig/showUpdata', data.info.is_show);
        _this5.$store.commit('mobildConfig/colorUpdata', data.info.is_bg_color || 0);
        _this5.$store.commit('mobildConfig/picUpdata', data.info.is_bg_pic || 0);
        _this5.$store.commit('mobildConfig/pickerUpdata', data.info.color_picker || '#f5f5f5');
        _this5.$store.commit('mobildConfig/radioUpdata', data.info.bg_tab_val || 0);
        _this5.$store.commit('mobildConfig/picurlUpdata', data.info.bg_pic || '');
        var newArr = _this5.objToArr(data.info.value);
        function sortNumber(a, b) {
          return a.timestamp - b.timestamp;
        }
        newArr.sort(sortNumber);
        newArr.map(function (el, index) {
          if (el.name === 'headerSerch') {
            _this5.isSearch = true;
          }
          if (el.name === 'tabNav') {
            _this5.isTab = true;
          }
          if (el.name === 'goodList') {
            var storage = window.localStorage;
            storage.setItem(el.timestamp, el.selectConfig.activeValue);
          }
          el.id = 'id' + el.timestamp;
          _this5.lConfig.map(function (item, j) {
            if (el.name === item.defaultName) {
              item.num = el.timestamp;
              item.id = 'id' + el.timestamp;
              var tempItem = JSON.parse(JSON.stringify(item));
              tempARR.push(tempItem);
              obj[el.timestamp] = el;
              _this5.mConfig.push(tempItem);
              // 保存默认组件配置
              _this5.$store.commit('mobildConfig/ADDARRAY', {
                num: el.timestamp,
                val: el
              });
            }
          });
        });
        var objs = newArr[newArr.length - 1];
        if (objs.name === 'pageFoot') {
          _this5.$store.commit('mobildConfig/footPageUpdata', objs);
        }
        _this5.showTitle();
      });
    },
    // 重置
    reast: function reast() {
      var _this6 = this;
      if (this.pageId === 0) {
        this.$message.error('新增页面，无法重置');
      } else {
        this.$modalSure('是否重置当前页面数据?').then(function () {
          _this6.mConfig = [];
          _this6.rConfig = [];
          _this6.activeIndex = -99;
          _this6.getDefaultConfig();
        });
      }
    }
  }
};