"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProductGuarantee',
  components: {},
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        date: '',
        keyword: ''
      },
      timeVal: [],
      props: {}
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 添加
    add: function add() {
      var _this = this;
      this.$modalForm((0, _setting.printerAddApi)()).then(function () {
        return _this.getList('');
      });
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _setting.printerLstApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 是否开启
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      (0, _setting.printerStatusApi)(row.printer_id, {
        status: row.status
      }).then(function (_ref) {
        var message = _ref.message;
        _this3.$message.success(message);
        _this3.getList('');
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this3.$message.error(message);
      });
    },
    // 编辑
    handleEdit: function handleEdit(id) {
      var _this4 = this;
      this.$modalForm((0, _setting.printerUpdateApi)(id)).then(function () {
        return _this4.getList('');
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this5 = this;
      this.$modalSure('删除打印机').then(function () {
        (0, _setting.printerDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this5.$message.success(message);
          _this5.tableData.data.splice(idx, 1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this5.$message.error(message);
        });
      });
    }
  }
};