var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Box" },
    [
      _vm.modals
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.modals,
                width: "70%",
                title: "商品采集",
                "custom-class": "dialog-scustom",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.modals = $event
                },
              },
            },
            [
              _c(
                "el-card",
                [
                  _c("div", [_vm._v("复制淘宝、天猫、京东、苏宁、1688；")]),
                  _vm._v(
                    "\n      生成的商品默认是没有上架的，请手动上架商品！\n      "
                  ),
                  _c("span", { staticStyle: { color: "rgb(237, 64, 20)" } }, [
                    _vm._v("商品复制次数剩余：" + _vm._s(_vm.count) + "次"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path:
                            _vm.roterPre +
                            "/setting/sms/sms_pay/index?type=copy",
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "text" } },
                        [_vm._v("增加采集次数")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.openRecords },
                    },
                    [_vm._v("查看商品复制记录")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "formValidate",
                  staticClass: "formValidate mt20",
                  attrs: {
                    model: _vm.formValidate,
                    rules: _vm.ruleInline,
                    "label-width": "130px",
                    "label-position": "right",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接地址：" } },
                    [
                      _c("el-input", {
                        staticClass: "numPut",
                        attrs: { search: "", placeholder: "请输入链接地址" },
                        model: {
                          value: _vm.soure_link,
                          callback: function ($$v) {
                            _vm.soure_link = $$v
                          },
                          expression: "soure_link",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.loading,
                            size: "small",
                            type: "primary",
                          },
                          on: { click: _vm.add },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm.isData
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品名称：",
                                  prop: "store_name",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入商品名称" },
                                  model: {
                                    value: _vm.formValidate.store_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "store_name",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.store_name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品类型：", prop: "type" } },
                              _vm._l(_vm.virtual, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "virtual",
                                    class:
                                      _vm.formValidate.type == item.id
                                        ? "virtual_boder"
                                        : "virtual_boder2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.virtualbtn(item.id, 2)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "virtual_top" }, [
                                      _vm._v(_vm._s(item.tit)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "virtual_bottom" },
                                      [_vm._v("(" + _vm._s(item.tit2) + ")")]
                                    ),
                                    _vm._v(" "),
                                    _vm.formValidate.type == item.id
                                      ? _c("div", {
                                          staticClass: "virtual_san",
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.formValidate.type == item.id
                                      ? _c(
                                          "div",
                                          { staticClass: "virtual_dui" },
                                          [_vm._v("✓")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品简介：",
                                  prop: "store_info",
                                  "label-for": "store_info",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 3,
                                    placeholder: "请输入商品简介",
                                  },
                                  model: {
                                    value: _vm.formValidate.store_info,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "store_info",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.store_info",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台商品分类：",
                                  prop: "cate_id",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  staticClass: "selWidth",
                                  attrs: {
                                    options: _vm.categoryList,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formValidate.cate_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "cate_id", $$v)
                                    },
                                    expression: "formValidate.cate_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商户商品分类：",
                                  prop: "mer_cate_id",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  staticClass: "selWidth",
                                  attrs: {
                                    options: _vm.merCateList,
                                    props: _vm.propsMer,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formValidate.mer_cate_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "mer_cate_id",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.mer_cate_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "品牌选择：",
                                  prop: "brand_id",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "selWidth",
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.formValidate.brand_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "brand_id",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.brand_id",
                                    },
                                  },
                                  _vm._l(_vm.BrandList, function (item) {
                                    return _c("el-option", {
                                      key: item.brand_id,
                                      attrs: {
                                        label: item.brand_name,
                                        value: item.brand_id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              _vm._b(
                                {
                                  attrs: {
                                    label: "商品关键字：",
                                    prop: "keyword",
                                    "label-for": "keyword",
                                  },
                                },
                                "el-form-item",
                                _vm.grid,
                                false
                              ),
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入商品关键字" },
                                  model: {
                                    value: _vm.formValidate.keyword,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "keyword", $$v)
                                    },
                                    expression: "formValidate.keyword",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "单位：",
                                  prop: "unit_name",
                                  "label-for": "unit_name",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入单位" },
                                  model: {
                                    value: _vm.formValidate.unit_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "unit_name",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.unit_name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "单次最多购买件数：" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 0,
                                    placeholder: "请输入购买件数",
                                  },
                                  model: {
                                    value: _vm.formValidate.once_count,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "once_count",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.once_count",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "送货方式：",
                                  prop: "delivery_way",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "acea-row" },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.formValidate.delivery_way,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "delivery_way",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.delivery_way",
                                        },
                                      },
                                      _vm._l(_vm.deliveryList, function (item) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.name) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.formValidate.delivery_way.length == 2 ||
                            (_vm.formValidate.delivery_way.length == 1 &&
                              _vm.formValidate.delivery_way[0] == 2)
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "是否包邮：" } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.formValidate.delivery_free,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "delivery_free",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.delivery_free",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "radio",
                                            attrs: { label: 0 },
                                          },
                                          [_vm._v("否")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("是")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.formValidate.delivery_free == 0 &&
                            (_vm.formValidate.delivery_way.length == 2 ||
                              (_vm.formValidate.delivery_way.length == 1 &&
                                _vm.formValidate.delivery_way[0] == 2))
                              ? _c(
                                  "el-form-item",
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: "运费模板：",
                                        prop: "temp_id",
                                      },
                                    },
                                    "el-form-item",
                                    _vm.grid,
                                    false
                                  ),
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { clearable: "" },
                                        model: {
                                          value: _vm.formValidate.temp_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "temp_id",
                                              $$v
                                            )
                                          },
                                          expression: "formValidate.temp_id",
                                        },
                                      },
                                      _vm._l(_vm.shippingList, function (item) {
                                        return _c("el-option", {
                                          key: item.shipping_template_id,
                                          attrs: {
                                            label: item.name,
                                            value: item.shipping_template_id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品标签：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "selWidthd",
                                    attrs: {
                                      multiple: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.formValidate.mer_labels,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "mer_labels",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.mer_labels",
                                    },
                                  },
                                  _vm._l(_vm.labelList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "平台保障服务：" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "acea-row" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "selWidthd mr20",
                                        attrs: {
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formValidate
                                              .guarantee_template_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "guarantee_template_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.guarantee_template_id",
                                        },
                                      },
                                      _vm._l(
                                        _vm.guaranteeList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.guarantee_template_id,
                                            attrs: {
                                              label: item.template_name,
                                              value: item.guarantee_template_id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品图：" } },
                              [
                                _c("div", { staticClass: "pictrueBox" }, [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          directives: [
                                            {
                                              name: "lazy",
                                              rawName: "v-lazy",
                                              value: _vm.formValidate.image,
                                              expression: "formValidate.image",
                                            },
                                          ],
                                        }),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品轮播图：" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "acea-row" },
                                  _vm._l(
                                    _vm.formValidate.slider_image,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "lunBox mr15",
                                          attrs: { draggable: "true" },
                                          on: {
                                            dragstart: function ($event) {
                                              return _vm.handleDragStart(
                                                $event,
                                                item
                                              )
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDragOver(
                                                $event,
                                                item
                                              )
                                            },
                                            dragenter: function ($event) {
                                              return _vm.handleDragEnter(
                                                $event,
                                                item
                                              )
                                            },
                                            dragend: function ($event) {
                                              return _vm.handleDragEnd(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pictrue" },
                                            [
                                              _c("img", {
                                                directives: [
                                                  {
                                                    name: "lazy",
                                                    rawName: "v-lazy",
                                                    value: item,
                                                    expression: "item",
                                                  },
                                                ],
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "buttonGroup" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "small-btn",
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.checked(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("主图")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "small-btn",
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleRemove(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("移除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.formValidate.spec_type === 1 &&
                            _vm.ManyAttrValue.length > 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "labeltop",
                                    attrs: { label: "批量设置：" },
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      { attrs: { data: _vm.oneFormBatch } },
                                      [
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "图片",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "acea-row row-middle row-center-wrapper",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.oneFormBatch[0].image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue pictrueTab",
                                                        },
                                                        [
                                                          _c("img", {
                                                            directives: [
                                                              {
                                                                name: "lazy",
                                                                rawName:
                                                                  "v-lazy",
                                                                value:
                                                                  _vm
                                                                    .oneFormBatch[0]
                                                                    .image,
                                                                expression:
                                                                  "oneFormBatch[0].image",
                                                              },
                                                            ],
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad pictrueTab acea-row row-center-wrapper",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "售价",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0].price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].price",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "成本价",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0].cost,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "cost",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].cost",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "市场价",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0]
                                                      .ot_price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "ot_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].ot_price",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "库存",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0].stock,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "stock",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].stock",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "商品编号",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0]
                                                      .bar_code,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "bar_code",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].bar_code",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "重量(KG)",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0].weight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "weight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].weight",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "体积(m²)",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                },
                                                model: {
                                                  value:
                                                    _vm.oneFormBatch[0].volume,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.oneFormBatch[0],
                                                      "volume",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "oneFormBatch[0].volume",
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "操作",
                                              "min-width": "150",
                                              align: "center",
                                            },
                                          },
                                          [
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "ela-btn",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                  on: { click: _vm.batchAdd },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "ela-btn",
                                                  attrs: {
                                                    href: "javascript: void(0);",
                                                  },
                                                  on: { click: _vm.batchDel },
                                                },
                                                [_vm._v("清空")]
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.formValidate.spec_type === 0
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "labeltop",
                                    attrs: { label: "规格列表：" },
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticClass: "tabNumWidth",
                                        attrs: {
                                          data: _vm.OneattrValue,
                                          border: "",
                                          size: "mini",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "图片",
                                            "min-width": "80",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upLoadPicBox",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.modalPicTap(
                                                              "1",
                                                              "dan",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        scope.row.image
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pictrue tabPic",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: scope
                                                                      .row
                                                                      .image,
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "upLoad tabPic",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-camera cameraIconfont",
                                                                }),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2217564926
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.attrValue,
                                          function (item, iii) {
                                            return _c("el-table-column", {
                                              key: iii,
                                              attrs: {
                                                label: _vm.formThead[iii].title,
                                                align: "center",
                                                "min-width": "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "priceBox",
                                                          attrs: {
                                                            type:
                                                              _vm.formThead[iii]
                                                                .title ===
                                                              "商品编号"
                                                                ? "text"
                                                                : "number",
                                                            min: 0,
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row[iii],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                iii,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row[iii]",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.formValidate.extension_type === 1
                                          ? [
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "一级返佣(元)",
                                                  "min-width": "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "priceBox",
                                                            attrs: {
                                                              type: "number",
                                                              min: 0,
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .extension_one,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "extension_one",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.extension_one",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2286159726
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "二级返佣(元)",
                                                  "min-width": "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "priceBox",
                                                            attrs: {
                                                              type: "number",
                                                              min: 0,
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .extension_two,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "extension_two",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.extension_two",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4057305350
                                                ),
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.formValidate.spec_type === 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "labeltop",
                                    attrs: { label: "规格列表：" },
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticClass: "tabNumWidth",
                                        attrs: {
                                          data: _vm.ManyAttrValue,
                                          border: "",
                                          size: "mini",
                                        },
                                      },
                                      [
                                        _vm.manyTabDate
                                          ? _vm._l(
                                              _vm.manyTabDate,
                                              function (item, iii) {
                                                return _c("el-table-column", {
                                                  key: iii,
                                                  attrs: {
                                                    align: "center",
                                                    label:
                                                      _vm.manyTabTit[iii].title,
                                                    "min-width": "80",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c("span", {
                                                              staticClass:
                                                                "priceBox",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    scope.row[
                                                                      iii
                                                                    ]
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "图片",
                                            "min-width": "80",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upLoadPicBox",
                                                        attrs: {
                                                          title: "750*750px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.modalPicTap(
                                                              "2",
                                                              "duo",
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        scope.row.image
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pictrue tabPic",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: scope
                                                                      .row
                                                                      .image,
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "upLoad tabPic",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-camera cameraIconfont",
                                                                }),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2035642848
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.attrValue,
                                          function (item, iii) {
                                            return _c("el-table-column", {
                                              key: iii,
                                              attrs: {
                                                label: _vm.formThead[iii].title,
                                                align: "center",
                                                "min-width": "120",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("el-input", {
                                                          staticClass:
                                                            "priceBox",
                                                          attrs: {
                                                            type:
                                                              _vm.formThead[iii]
                                                                .title ===
                                                              "商品编号"
                                                                ? "text"
                                                                : "number",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row[iii],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                iii,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row[iii]",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.formValidate.extension_type === 1
                                          ? [
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "一级返佣(元)",
                                                  "min-width": "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "priceBox",
                                                            attrs: {
                                                              type: "number",
                                                              min: 0,
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .extension_one,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "extension_one",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.extension_one",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2286159726
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "二级返佣(元)",
                                                  "min-width": "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "priceBox",
                                                            attrs: {
                                                              type: "number",
                                                              min: 0,
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .extension_two,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "extension_two",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.extension_two",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4057305350
                                                ),
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品详情：" } },
                              [
                                _c("ueditorFrom", {
                                  attrs: { content: _vm.formValidate.content },
                                  model: {
                                    value: _vm.formValidate.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "content", $$v)
                                    },
                                    expression: "formValidate.content",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "submission",
                                    attrs: {
                                      loading: _vm.loading1,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSubmit("formValidate")
                                      },
                                    },
                                  },
                                  [_vm._v("提交")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("copy-record", { ref: "copyRecord" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }