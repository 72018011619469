var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.order_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "order_type", $$v)
                                },
                                expression: "tableFrom.order_type",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("待付款"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("待发货"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v("待收货"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "4" } }, [
                                _vm._v("待评价"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "5" } }, [
                                _vm._v("交易完成"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "6" } }, [
                                _vm._v("已退款"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "7" } }, [
                                _vm._v("已删除"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入订单号/收货人/联系方式",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开票状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: { change: _vm.getList },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            _vm._l(_vm.invoiceStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "用户信息：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入用户昵称/手机号",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "username", $$v)
                                },
                                expression: "tableFrom.username",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { display: "block" },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getInvoiceInfo("", "invoice")
                            },
                          },
                        },
                        [_vm._v("\n            合并开票\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_receipt_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "storeOrder.order_sn",
                  label: "订单号",
                  "min-width": "170",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "user.nickname",
                  label: "用户昵称",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "order_price",
                  label: "订单金额",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder &&
                        scope.row.storeOrder.paid === 0 &&
                        scope.row.storeOrder.status === 0
                          ? _c("span", [_vm._v("待付款")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderStatusFilter")(
                                    scope.row.storeOrder &&
                                      scope.row.storeOrder.status
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receipt_price",
                  label: "发票金额",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receipt_sn",
                  label: "发票单号",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发票类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.receipt_info
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.receipt_info.receipt_type == 1
                                    ? "普通发票"
                                    : "专用发票"
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("--")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发票抬头", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.receipt_info.receipt_title_type === "1"
                                ? "个人"
                                : "企业"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发票联系人", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.storeOrder &&
                                scope.row.storeOrder.real_name
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发票联系信息", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.delivery_info.email
                                ? scope.row.delivery_info.email
                                : scope.row.delivery_info.user_address &&
                                    scope.row.delivery_info.user_address +
                                      scope.row.delivery_info.user_phone &&
                                    scope.row.delivery_info.user_phone
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "下单时间",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "开票状态", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.status == 1 ? "已开" : "未开")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mer_mark",
                  label: "发票备注",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "180",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0 &&
                        scope.row.storeOrder &&
                        scope.row.storeOrder.paid === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getInvoiceInfo(
                                      scope.row.order_receipt_id,
                                      "invoice"
                                    )
                                  },
                                },
                              },
                              [_vm._v("开票")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onOrderDetail(scope.row.order_id)
                              },
                            },
                          },
                          [_vm._v("订单详情")]
                        ),
                        _vm._v(" "),
                        scope.row.status
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getInvoiceInfo(
                                      scope.row.order_receipt_id,
                                      "edit"
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.invoiceInfo.title,
                visible: _vm.dialogVisible,
                width: "900px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "box-container" },
                    [
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "list sp" }, [
                          _c(
                            "label",
                            {
                              staticClass: "name",
                              staticStyle: { color: "#333" },
                            },
                            [_vm._v("发票详情")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c(
                            "label",
                            {
                              staticClass: "name",
                              staticStyle: { color: "#333" },
                            },
                            [_vm._v("发票申请单号：")]
                          ),
                          _vm._v(
                            _vm._s(_vm.invoiceInfo.receipt_sn) + "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [_vm._v("发票信息")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票抬头："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.invoiceInfo.receipt_info.receipt_title) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票类型："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.invoiceInfo.receipt_info.receipt_type == 1
                                ? "普通发票"
                                : "专用发票"
                            ) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票抬头类型："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.invoiceInfo.receipt_info.receipt_title_type ==
                                "1"
                                ? "个人"
                                : "企业"
                            ) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票金额："),
                          ]),
                          _vm._v(
                            _vm._s(_vm.invoiceInfo.receipt_price) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.invoiceInfo.receipt_info.receipt_title_type == "2"
                          ? _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("企业税号："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.invoiceInfo.receipt_info.duty_paragraph
                                ) + "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.invoiceInfo.receipt_info.receipt_type == 2
                          ? _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("开户银行："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.receipt_info.bank_name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.invoiceInfo.receipt_info.receipt_type == 2
                          ? _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("银行账号："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.receipt_info.bank_code) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.invoiceInfo.receipt_info.receipt_type == 2
                          ? _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("企业地址："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.receipt_info.address) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.invoiceInfo.receipt_info.receipt_type == 2
                          ? _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("企业电话："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.receipt_info.tel) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _vm._v("联系信息："),
                      ]),
                      _vm._v(" "),
                      _vm.invoiceInfo.receipt_info.receipt_type == 1
                        ? _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系邮箱："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.delivery_info.email) +
                                  "\n          "
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系人姓名："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.invoiceInfo.delivery_info.user_name
                                ) + "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系人电话："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.invoiceInfo.delivery_info.user_phone
                                ) + "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系人地址："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.invoiceInfo.delivery_info.user_address
                                ) + "\n          "
                              ),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("开票状态："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.invoiceInfo.status == 1 ? "已开" : "未开"
                            ) + "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp100" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票号码："),
                          ]),
                          _c(
                            "span",
                            { staticClass: "info" },
                            [
                              _c("el-input", {
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.invoiceData.number =
                                      _vm.invoiceData.number.replace(
                                        /[^\w]/g,
                                        ""
                                      )
                                  },
                                },
                                model: {
                                  value: _vm.invoiceData.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceData, "number", $$v)
                                  },
                                  expression: "invoiceData.number",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp100" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票备注："),
                          ]),
                          _c(
                            "span",
                            { staticClass: "info" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 5 },
                                model: {
                                  value: _vm.invoiceData.mark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceData, "mark", $$v)
                                  },
                                  expression: "invoiceData.mark",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%", "margin-top": "15px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleInvoic },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }