"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchDeliveryApi = batchDeliveryApi;
exports.cardListApi = cardListApi;
exports.chartApi = chartApi;
exports.confirmReceiptApi = confirmReceiptApi;
exports.deliveryOrderCancle = deliveryOrderCancle;
exports.deliveryOrderLst = deliveryOrderLst;
exports.deliveryRecordDetailApi = deliveryRecordDetailApi;
exports.deliveryRecordImportApi = deliveryRecordImportApi;
exports.deliveryRecordListApi = deliveryRecordListApi;
exports.downloadFileApi = downloadFileApi;
exports.exportFileLstApi = exportFileLstApi;
exports.exportInvoiceApi = exportInvoiceApi;
exports.exportOrderApi = exportOrderApi;
exports.exprTempsLst = exprTempsLst;
exports.expressLst = expressLst;
exports.getDeliveryStoreLst = getDeliveryStoreLst;
exports.getEleTempData = getEleTempData;
exports.getExpress = getExpress;
exports.getStoreLst = getStoreLst;
exports.invoiceApi = invoiceApi;
exports.invoiceDetailApi = invoiceDetailApi;
exports.invoiceInfoApi = invoiceInfoApi;
exports.invoiceOrderListApi = invoiceOrderListApi;
exports.invoiceUpdateApi = invoiceUpdateApi;
exports.invoiceorderMarkApi = invoiceorderMarkApi;
exports.orderCancellationApi = orderCancellationApi;
exports.orderDeleteApi = orderDeleteApi;
exports.orderDeliveryApi = orderDeliveryApi;
exports.orderDetailApi = orderDetailApi;
exports.orderHeadListApi = orderHeadListApi;
exports.orderListApi = orderListApi;
exports.orderLogApi = orderLogApi;
exports.orderPrintApi = orderPrintApi;
exports.orderRemarkApi = orderRemarkApi;
exports.orderUpdateApi = orderUpdateApi;
exports.rechargeInfoApi = rechargeInfoApi;
exports.rechargeLst = rechargeLst;
exports.refundListImportApi = refundListImportApi;
exports.refundorderDeleteApi = refundorderDeleteApi;
exports.refundorderDetailApi = refundorderDetailApi;
exports.refundorderExpressApi = refundorderExpressApi;
exports.refundorderListApi = refundorderListApi;
exports.refundorderLogApi = refundorderLogApi;
exports.refundorderMarkApi = refundorderMarkApi;
exports.refundorderStatusApi = refundorderStatusApi;
exports.schlCreate = schlCreate;
exports.takeCardListApi = takeCardListApi;
exports.takeChartApi = takeChartApi;
exports.takeOrderListApi = takeOrderListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 订单 -- 列表
 */
function orderListApi(data) {
  return _request.default.get('store/order/lst', data);
}

/**
 * @description 订单 -- 表头
 */
function chartApi() {
  return _request.default.get('store/order/chart');
}
/**
 * @description 订单 -- 卡片
 */
function cardListApi(data) {
  return _request.default.get('store/order/title', data);
}
/**
 * @description 订单 -- 编辑
 */
function orderUpdateApi(id, data) {
  return _request.default.post("store/order/update/".concat(id), data);
}
/**
 * @description 订单 -- 发货
 */
function orderDeliveryApi(id, data) {
  return _request.default.post("store/order/delivery/".concat(id), data);
}
/**
 * @description 订单 -- 详情
 */
function orderDetailApi(id) {
  return _request.default.get("store/order/detail/".concat(id));
}
/**
 * @description 订单 -- 记录
 */
function orderLogApi(id, data) {
  return _request.default.get("store/order/log/".concat(id), data);
}
/**
 * @description 订单 -- 备注from
 */
function orderRemarkApi(id) {
  return _request.default.get("store/order/remark/".concat(id, "/form"));
}
/**
 * @description 订单 -- 删除
 */
function orderDeleteApi(id) {
  return _request.default.post("store/order/delete/".concat(id));
}
/**
 * @description 订单 -- 打印
 */
function orderPrintApi(id) {
  return _request.default.get("store/order/printer/".concat(id));
}
/**
 * @description 退款订单 -- 列表
 */
function refundorderListApi(data) {
  return _request.default.get('store/refundorder/lst', data);
}
/**
 * @description 退款订单 -- 详情
 */
function refundorderDetailApi(id) {
  return _request.default.get("store/refundorder/detail/".concat(id));
}
/**
 * @description 退款订单 -- 审核from
 */
function refundorderStatusApi(id) {
  return _request.default.get("store/refundorder/status/".concat(id, "/form"));
}
/**
 * @description 退款订单 -- 备注from
 */
function refundorderMarkApi(id) {
  return _request.default.get("store/refundorder/mark/".concat(id, "/form"));
}
/**
 * @description 退款订单 -- 记录from
 */
function refundorderLogApi(id) {
  return _request.default.get("store/refundorder/log/".concat(id));
}
/**
 * @description 退款订单 -- 删除
 */
function refundorderDeleteApi(id) {
  return _request.default.get("store/refundorder/delete/".concat(id));
}
/**
 * @description 退款订单 -- 确认收货
 */
function confirmReceiptApi(id) {
  return _request.default.post("store/refundorder/refund/".concat(id));
}
/**
 * @description 获取物流信息
 */
function getExpress(id) {
  return _request.default.get("store/order/express/".concat(id));
}
/**
 * @description 退款单获取物流信息
 */
function refundorderExpressApi(id) {
  return _request.default.get("store/refundorder/express/".concat(id));
}
/**
 * @description 导出订单
 */
function exportOrderApi(data) {
  return _request.default.get("store/order/excel", data);
}
/**
 * @description 生成发货单
 */
function exportInvoiceApi(data) {
  return _request.default.get("store/order/delivery_export", data);
}
/**
 * @description 导出文件列表
 */
function exportFileLstApi(data) {
  return _request.default.get("excel/lst", data);
}
/**
 * @description 下载
 */
function downloadFileApi(id) {
  return _request.default.get("excel/download/".concat(id));
}
/**
 * @description 订单核销
 */
function orderCancellationApi(code) {
  return _request.default.post("store/order/verify/".concat(code));
}
/**
 * @description 订单 -- 头部
 */
function orderHeadListApi() {
  return _request.default.get("store/order/filtter");
}
/**
 * @description 核销订单 -- 表头
 */
function takeChartApi() {
  return _request.default.get('store/order/takechart');
}
/**
 * @description 核销订单 -- 列表
 */
function takeOrderListApi(data) {
  return _request.default.get('store/order/takelst', data);
}
/**
 * @description 核销订单 -- 卡片
 */
function takeCardListApi(data) {
  return _request.default.get('store/order/take_title', data);
}
/**
 * @description 发票管理 -- 列表
 */
function invoiceOrderListApi(data) {
  return _request.default.get('store/receipt/lst', data);
}
/**
 * @description 发票 -- 备注from
 */
function invoiceorderMarkApi(id) {
  return _request.default.get("store/receipt/mark/".concat(id, "/form"));
}
/**
 * @description 发票 -- 开票信息
 */
function invoiceInfoApi(data) {
  return _request.default.get("store/receipt/set_recipt", data);
}
/**
 * @description 发票 -- 开票
 */
function invoiceApi(data) {
  return _request.default.post("store/receipt/save_recipt", data);
}
/**
 * @description 发票 -- 详情
 */
function invoiceDetailApi(id) {
  return _request.default.get("store/receipt/detail/".concat(id));
}
/**
 * @description 发票 -- 编辑
 */
function invoiceUpdateApi(id, data) {
  return _request.default.post("store/receipt/update/".concat(id), data);
}
/**
 * @description 批量发货记录 -- 列表
 */
function deliveryRecordListApi(data) {
  return _request.default.get('store/import/lst', data);
}
/**
 * @description 批量发货记录 -- 详情
 */
function deliveryRecordDetailApi(id, data) {
  return _request.default.get("store/import/detail/".concat(id), data);
}
/**
 * @description 批量发货记录 -- 导出
 */
function deliveryRecordImportApi(id) {
  return _request.default.get("store/import/excel/".concat(id));
}
/**
 * @description 退款单 -- 导出
 */
function refundListImportApi() {
  return _request.default.get("store/refundorder/excel");
}
/**
 * @description 发送货 -- 物流公司列表
 */
function expressLst() {
  return _request.default.get("expr/options");
}
/**
 * @description 发送货 -- 电子面单列表
 */
function exprTempsLst(data) {
  return _request.default.get("expr/temps", data);
}
/**
 * @description 发送货 -- 批量发送货
 */
function batchDeliveryApi(data) {
  return _request.default.post("store/order/delivery_batch", data);
}
/**
 * @description 发送货 -- 电子面单默认数据
 */
function getEleTempData() {
  return _request.default.get("serve/config");
}
/**
 * @description 发送货 -- 门店列表
 */
function getStoreLst() {
  return _request.default.get("delivery/station/select");
}
/**
 * @description 发送货 -- 门店列表
 */
function getDeliveryStoreLst() {
  return _request.default.get("delivery/station/options");
}
/**
 * @description 同城配送  -- 订单列表
 */
function deliveryOrderLst(data) {
  return _request.default.get("delivery/order/lst", data);
}
/**
 * @description 同城订单 -- 取消
 */
function deliveryOrderCancle(id) {
  return _request.default.get("delivery/order/cancel/".concat(id, "/form"));
}
/**
 * @description 同城配送  -- 充值记录列表
 */
function rechargeLst(data) {
  return _request.default.get("delivery/station/payLst", data);
}
/**
 * @description 同城配送  -- 充值
 */
function rechargeInfoApi(data) {
  return _request.default.get("delivery/station/code", data);
}
/**
 * @description 供应链重新下单
 */
function schlCreate(id) {
  return _request.default.post("store/order/schl_create/".concat(id));
}