"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'TransferAccount',
  components: {
    cardsData: _index.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      arrivalStatusList: [{
        label: '已到账',
        value: 1
      }, {
        label: '未到账',
        value: 0
      }],
      listLoading: true,
      tableFrom: {
        date: '',
        page: 1,
        limit: 20,
        keyword: '',
        financial_type: '',
        status: '',
        financial_status: ''
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      loading: false,
      dialogVisible: false,
      pictureVisible: false,
      pictureUrl: '',
      transferData: {},
      cardLists: []
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 转账信息
    transferDetail: function transferDetail(id) {
      var _this = this;
      (0, _accounts.transferDetailApi)(id).then(function (res) {
        _this.dialogVisible = true;
        _this.transferData = res.data;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 获取支付二维码
    getPicture: function getPicture(url) {
      this.pictureVisible = true;
      this.pictureUrl = url;
    },
    // 备注
    transferMark: function transferMark(id) {
      var _this2 = this;
      this.$modalForm((0, _accounts.transferMarkApi)(id)).then(function () {
        return _this2.getList(1);
      });
    },
    // 申请转账
    applyTransfer: function applyTransfer() {
      var _this3 = this;
      this.$modalForm((0, _accounts.applyTransferApi)()).then(function () {
        return _this3.getList(1);
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this4 = this;
      (0, _accounts.transferRecordsExportApi)(this.tableFrom).then(function (res) {
        var h = _this4.$createElement;
        _this4.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        _this4.$message.error(res.message);
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList();
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _accounts.transferLstApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    }
  }
};