import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6192ebe2"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6192ebe2')) {
      api.createRecord('6192ebe2', component.options)
    } else {
      api.reload('6192ebe2', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=6192ebe2", function () {
      api.rerender('6192ebe2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketing/couponExchange/index.vue"
export default component.exports