"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'FileList',
  data: function data() {
    return {
      fileVisible: false,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        type: ''
      },
      fileTypeList: [{
        name: '订单',
        value: 'order'
      }, {
        name: '流水记录',
        value: 'financial'
      }, {
        name: '发货单',
        value: 'delivery'
      }, {
        name: '导入记录',
        value: 'importDelivery'
      }, {
        name: '账单信息',
        value: 'exportFinancial'
      }, {
        name: '退款单',
        value: 'refundOrder'
      }]
    };
  },
  mounted: function mounted() {
    this.exportFileList('');
  },
  methods: {
    exportFileList: function exportFileList(num) {
      var _this = this;
      this.loading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _order.exportFileLstApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.loading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    // 下载
    downLoad: function downLoad(path) {
      window.open(path);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.exportFileList('');
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.exportFileList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.exportFileList('');
    }
  }
};