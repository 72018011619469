"use strict";

var _typeof = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var monaco = _interopRequireWildcard(require("monaco-editor"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
var _default = exports.default = {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      monacoInstance: null
    };
  },
  mounted: function mounted() {
    this.seteditor();
  },
  beforeDestroy: function beforeDestroy() {
    this.monacoInstance.dispose();
    this.monacoInstance = null;
  },
  methods: {
    setValue: function setValue(val) {
      // this.monacoInstance.setValue(val)
    },
    seteditor: function seteditor() {
      var _this = this;
      // 初始化编辑器实例

      this.monacoInstance = monaco.editor.create(this.$refs.code_box, {
        value: this.value,
        theme: 'vs',
        // vs, hc-black, or vs-dark

        language: 'html',
        // shell、sql、python

        readOnly: false // 不能编辑
      });
      // 编辑器内容发生改变时触发
      this.monacoInstance.onDidChangeModelContent(function () {
        _this.$emit('change', _this.monacoInstance.getValue());
      });
    }
  }
};