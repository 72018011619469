"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      loading: true,
      tableFrom: {
        keyword: '',
        order_sn: '',
        date: '',
        station_id: '',
        page: 1,
        limit: 20
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      storeList: [],
      statusList: [{
        label: '已取消',
        value: '-1'
      }, {
        label: '待接单',
        value: '0'
      }, {
        label: '待取货',
        value: '2'
      }, {
        label: '配送中',
        value: '3'
      }, {
        label: '已完成',
        value: '4'
      }, {
        label: '物品返回中',
        value: '9'
      }, {
        label: '物品返回完成',
        value: '10'
      }, {
        label: '骑士到店',
        value: '100'
      }]
    };
  },
  mounted: function mounted() {
    this.getList(1);
    this.getStoreList();
  },
  methods: {
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 获取发货点列表
    getStoreList: function getStoreList() {
      var _this = this;
      (0, _order.getStoreLst)(this.tableFrom).then(function (res) {
        _this.storeList = res.data;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _order.deliveryOrderLst)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 取消
    toCancle: function toCancle(id) {
      var _this3 = this;
      this.$modalForm((0, _order.deliveryOrderCancle)(id)).then(function () {
        return _this3.getList('');
      });
    },
    // 详情
    onDetails: function onDetails(id) {}
  }
};