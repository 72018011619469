"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _settings = require("@/settings");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  components: {
    VueUeditorWrap: _vueUeditorWrap.default
  },
  scrollerHeight: {
    content: String,
    default: ''
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var url = _settingMer.default.https + '/upload/image/0/file?ueditor=1&token=' + (0, _auth.getToken)();
    return {
      contents: this.content,
      myConfig: {
        autoHeightEnabled: false,
        // 编辑器不自动被内容撑高
        initialFrameHeight: 500,
        // 初始容器高度
        initialFrameWidth: '100%',
        // 初始容器宽度
        UEDITOR_HOME_URL: '/UEditor/',
        serverUrl: url,
        imageUrl: url,
        imageFieldName: 'file',
        imageUrlPrefix: '',
        imageActionName: 'upfile',
        imageMaxSize: 2048000,
        imageAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
      }
    };
  },
  watch: {
    content: function content(val) {
      this.contents = this.content;
      // this.$emit('input', val)
    },
    contents: function contents(val) {
      this.$emit('input', val);
    }
  },
  created: function created() {},
  methods: {
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        // 创建 dialog
        var dialog = new window.UE.ui.Dialog({
          // 指定弹出层中页面的路径，这里只能支持页面，路径参考常见问题 2
          iframeUrl: _settings.roterPre + '/setting/uploadPicture?field=dialog',
          // 需要指定当前的编辑器实例
          editor: editor,
          // 指定 dialog 的名字
          name: uiName,
          // dialog 的标题
          title: '上传图片',
          // 指定 dialog 的外围样式
          cssRules: 'width:1000px;height:620px;padding:20px;'
        });
        this.dialog = dialog;
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(@/assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            // 渲染dialog
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      }, 37);
    }
  }
};