"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _uploadPicture = _interopRequireDefault(require("@/components/uploadPicture"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_custom_menu_list',
  components: {
    draggable: _vuedraggable.default,
    linkaddress: _linkaddress.default,
    uploadPictures: _uploadPicture.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    },
    index: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      menus: [],
      list: [{
        title: 'aa',
        val: ''
      }],
      modalPic: false,
      isChoice: '单选',
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      },
      activeIndex: 0,
      indexLast: 0,
      lastObj: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.defaults = _this2.configObj;
      _this2.configData = _this2.configObj[_this2.configNme];
    });
  },
  methods: {
    linkUrl: function linkUrl(e) {
      this.configData.list[this.activeIndex].info[this.indexLast].value = e;
      if (this.defaults.name === 'pictureCube') {
        this.defaults.picStyle.picList[this.defaults.picStyle.tabVal].link = e;
      }
    },
    getLink: function getLink(index, key, item) {
      this.indexLast = item.length - 1;
      if (key !== item.length - 1) {
        return;
      }
      this.activeIndex = index;
      // this.$refs.linkaddres.modals = true
      this.$refs.linkaddres.init();
    },
    addBox: function addBox() {
      if (this.configData.list.length === 0) {
        this.lastObj.info[0].value = '';
        this.lastObj.info[1].value = '';
        this.configData.list.push(this.lastObj);
      } else {
        var obj = JSON.parse(JSON.stringify(this.configData.list[this.configData.list.length - 1]));
        obj.img = '';
        obj.info[0].value = '';
        obj.info[1].value = '';
        this.configData.list.push(obj);
      }
    },
    // 点击图文封面
    modalPicTap: function modalPicTap(item, index) {
      // this.activeIndex = index
      // this.modalPic = true;
      var _this = this;
      _this.$modalUpload(function (img) {
        item.img = img[0];
      });
    },
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      var _this3 = this;
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        var dialog = new window.UE.ui.Dialog({
          iframeUrl: '/admin/widget.images/index.html?fodder=dialog',
          editor: editor,
          name: uiName,
          title: '上传图片',
          cssRules: 'width:1200px;height:500px;padding:20px;'
        });
        _this3.dialog = dialog;
        // 参考上面的自定义按钮
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(../../../assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            // 渲染dialog
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      }, 37);
    },
    // 获取图片信息
    getPic: function getPic(pc) {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.configData.list[_this4.activeIndex].img = pc.att_dir;
        var data = _this4.defaults.menuConfig;
        if (data && data.isCube) {
          console.log('kkkkkkkkkkk11', data.list[0].info[0].value);
          _this4.defaults.picStyle.picList.splice(_this4.defaults.picStyle.tabVal, 1, {
            image: pc.att_dir,
            link: data.list[0].info[0].value
          });
        }
        _this4.modalPic = false;
      });
    },
    onBlur: function onBlur() {
      var data = this.defaults.menuConfig;
      console.log('kkkkkkkkkkk22', data.list[0].info[0].value);
      this.defaults.picStyle.picList[this.defaults.picStyle.tabVal].link = data.list[0].info[0].value;
    },
    // 删除
    bindDelete: function bindDelete(item, index) {
      if (this.configData.list.length === 1) {
        this.lastObj = this.configData.list[0];
      }
      this.configData.list.splice(index, 1);
    }
  }
};