var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司：", prop: "mer_from_com" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item selWidth mr20",
                      attrs: { placeholder: "请选择快递公司" },
                      on: {
                        change: function ($event) {
                          return _vm.getDumpList("")
                        },
                      },
                      model: {
                        value: _vm.formValidate.mer_from_com,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "mer_from_com", $$v)
                        },
                        expression: "formValidate.mer_from_com",
                      },
                    },
                    _vm._l(_vm.deliveryList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电子面单：", prop: "mer_config_temp_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item selWidth mr20",
                      attrs: { placeholder: "请选择电子面单" },
                      model: {
                        value: _vm.formValidate.mer_config_temp_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "mer_config_temp_id", $$v)
                        },
                        expression: "formValidate.mer_config_temp_id",
                      },
                    },
                    _vm._l(_vm.dumpList, function (item) {
                      return _c("el-option", {
                        key: item.temp_id,
                        attrs: { label: item.title, value: item.temp_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "云打印机编号：", prop: "mer_config_siid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入云打印机编号" },
                    model: {
                      value: _vm.formValidate.mer_config_siid,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "mer_config_siid", $$v)
                      },
                      expression: "formValidate.mer_config_siid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "寄件人姓名：", prop: "mer_from_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入寄件人姓名" },
                    model: {
                      value: _vm.formValidate.mer_from_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "mer_from_name", $$v)
                      },
                      expression: "formValidate.mer_from_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "寄件人电话：", prop: "mer_from_tel" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入寄件人电话" },
                    model: {
                      value: _vm.formValidate.mer_from_tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "mer_from_tel", $$v)
                      },
                      expression: "formValidate.mer_from_tel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "寄件人地址：", prop: "mer_from_addr" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入寄件人地址",
                    },
                    model: {
                      value: _vm.formValidate.mer_from_addr,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "mer_from_addr", $$v)
                      },
                      expression: "formValidate.mer_from_addr",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }