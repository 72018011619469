var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "100px",
                        inline: "",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "block" },
                          attrs: { label: "订单状态：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  _vm.getList(1), _vm.getCardList()
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v(
                                  "全部\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.all
                                        ? _vm.orderChartType.all
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v(
                                  "待付款\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.unpaid
                                        ? _vm.orderChartType.unpaid
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v(
                                  "待发货\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.unshipped
                                        ? _vm.orderChartType.unshipped
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v(
                                  "待收货\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.untake
                                        ? _vm.orderChartType.untake
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "4" } }, [
                                _vm._v(
                                  "待评价\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.unevaluate
                                        ? _vm.orderChartType.unevaluate
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "5" } }, [
                                _vm._v(
                                  "交易完成\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.complete
                                        ? _vm.orderChartType.complete
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "6" } }, [
                                _vm._v(
                                  "已退款\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.refund
                                        ? _vm.orderChartType.refund
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "7" } }, [
                                _vm._v(
                                  "已删除\n                " +
                                    _vm._s(
                                      "(" + _vm.orderChartType.del
                                        ? _vm.orderChartType.del
                                        : 0 + ")"
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "block" },
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "width100",
                              attrs: { label: "商品名称" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    placeholder: "请输入商品名称",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.getList(1), _vm.getCardList()
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.store_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "store_name", $$v)
                                    },
                                    expression: "tableFrom.store_name",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "el-button-solt",
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.getList(1), _vm.getCardList()
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "width100",
                              attrs: { label: "总单单号：" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    placeholder: "请输入总单订单号",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      _vm.getList(1), _vm.getCardList()
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.group_order_sn,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableFrom,
                                        "group_order_sn",
                                        $$v
                                      )
                                    },
                                    expression: "tableFrom.group_order_sn",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "el-button-solt",
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.getList(1), _vm.getCardList()
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "width100",
                              attrs: { label: "活动类型：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item selWidth mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.getList(1), _vm.getCardList()
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.activity_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableFrom,
                                        "activity_type",
                                        $$v
                                      )
                                    },
                                    expression: "tableFrom.activity_type",
                                  },
                                },
                                _vm._l(_vm.activityList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "block" },
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入订单号/收货人/联系方式",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.getList(1), _vm.getCardList()
                                },
                              },
                              model: {
                                value: _vm.tableFrom.keywords,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keywords", $$v)
                                },
                                expression: "tableFrom.keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.getList(1), _vm.getCardList()
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown",
                            { staticClass: "dropdown" },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v("\n                列表导出"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.exportInvoice($event)
                                        },
                                      },
                                    },
                                    [_vm._v("生成发货单")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.exportOrder($event)
                                        },
                                      },
                                    },
                                    [_vm._v("生成订单")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "用户信息：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入用户昵称/手机号",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  _vm.getList(1), _vm.getCardList()
                                },
                              },
                              model: {
                                value: _vm.tableFrom.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "username", $$v)
                                },
                                expression: "tableFrom.username",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.getList(1), _vm.getCardList()
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.orderCancellation },
                            },
                            [_vm._v("订单核销")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.fileUrl,
                                "on-success": _vm.handleSuccess,
                                "show-file-list": false,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("导入批量发货")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { on: { click: _vm.getDeliveryList } },
                            [_vm._v("批量发货记录")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { on: { click: _vm.downloadLogistics } },
                            [_vm._v("下载物流公司对照表")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.batchSend },
                            },
                            [_vm._v("批量发送货")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.headeNum.length > 0
                ? _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function ($event) {
                          _vm.getList(1), _vm.getCardList(), _vm.getHeaderList()
                        },
                      },
                      model: {
                        value: _vm.tableFrom.order_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "order_type", $$v)
                        },
                        expression: "tableFrom.order_type",
                      },
                    },
                    _vm._l(_vm.headeNum, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: {
                          name: item.order_type.toString(),
                          label: item.title + "(" + item.count + ")",
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("cards-data", { attrs: { "card-lists": _vm.cardLists } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "cell-class-name": _vm.addTdClass,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品总价：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.total_price
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "下单时间：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.create_time)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "用户备注：" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "200px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("filterEmpty")(props.row.mark)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商家备注：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.remark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "duo" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("duo")
                                    },
                                  },
                                },
                                [_vm._v("选中全部")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.tableFrom.page
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.order_id) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.order_id) ===
                                  -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单编号", "min-width": "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: { textContent: _vm._s(scope.row.order_sn) },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.is_del > 0,
                                expression: "scope.row.is_del > 0",
                              },
                            ],
                            staticStyle: { color: "#ed4014", display: "block" },
                          },
                          [_vm._v("用户已删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.is_virtual == 1
                                ? "虚拟订单"
                                : scope.row.order_type == 0
                                ? "普通订单"
                                : "核销订单"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activity_type != 4
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.activity_type === 1
                                    ? "秒杀"
                                    : scope.row.activity_type === 2
                                    ? "预售"
                                    : scope.row.activity_type === 3
                                    ? "助力"
                                    : scope.row.activity_type === 5
                                    ? "0元购"
                                    : scope.row.activity_type === 11
                                    ? "消费券"
                                    : "--"
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v("拼团订单\n            "),
                              scope.row.groupUser &&
                              scope.row.groupUser.groupBuying
                                ? _c("span", [
                                    _vm._v(
                                      "-" +
                                        _vm._s(
                                          _vm._f("activityOrderStatus")(
                                            scope.row.groupUser.groupBuying
                                              .status
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "real_name",
                  label: "收货人/订购人",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品信息", "min-width": "330" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.orderProduct, function (val, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "tabBox acea-row row-middle" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: val.cart_info.product.image,
                                    "preview-src-list": [
                                      val.cart_info.product.image,
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "tabBox_tit" }, [
                              _vm._v(
                                _vm._s(
                                  val.cart_info.product.store_name + " | "
                                ) + _vm._s(val.cart_info.productAttr.sku)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "tabBox_pice" }, [
                              scope.row.activity_type === 2 &&
                              val.cart_info.productPresellAttr
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        "￥" +
                                          val.cart_info.productPresellAttr
                                            .presell_price +
                                          " x " +
                                          val.product_num
                                      )
                                    ),
                                  ])
                                : scope.row.activity_type === 3 &&
                                  val.cart_info.productAssistAttr
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        "￥" +
                                          val.cart_info.productAssistAttr
                                            .assist_price +
                                          " x " +
                                          val.product_num
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        "￥" +
                                          val.cart_info.productAttr.price +
                                          " x " +
                                          val.product_num
                                      )
                                    ),
                                  ]),
                              _vm._v(" "),
                              val.refund_num < val.product_num &&
                              val.refund_num >= 0
                                ? _c(
                                    "em",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-style": "normal",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(-" +
                                          _vm._s(
                                            val.product_num - val.refund_num
                                          ) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "实际支付", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.pay_price))]),
                        _vm._v(" "),
                        scope.row.finalOrder
                          ? _c("p", [
                              _vm._v(
                                "尾款：" +
                                  _vm._s(scope.row.finalOrder.pay_price)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单佣金", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (
                                parseFloat(scope.row.extension_one) +
                                parseFloat(scope.row.extension_two) +
                                parseFloat(scope.row.refund_extension_one) +
                                parseFloat(scope.row.refund_extension_two)
                              ).toFixed(2)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.refund_extension_one > 0 ||
                        scope.row.refund_extension_two > 0
                          ? _c(
                              "em",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-style": "normal",
                                },
                              },
                              [
                                _vm._v(
                                  "(-" +
                                    _vm._s(
                                      (
                                        parseFloat(
                                          scope.row.refund_extension_one
                                        ) +
                                        parseFloat(
                                          scope.row.refund_extension_two
                                        )
                                      ).toFixed(2)
                                    ) +
                                    ")"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "获得GP",
                  "min-width": "80",
                  prop: "give_integral",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "平台预留佣金",
                  "min-width": "120",
                  prop: "order_system",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品成本", "min-width": "80", prop: "cost" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.paid === 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderPayType")(scope.row.pay_type)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("--")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "支付状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.paid === 0 ? "未支付" : "已支付")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.is_del === 0
                          ? _c("span", [
                              scope.row.paid === 0
                                ? _c("span", [_vm._v("待付款")])
                                : _c("span", [
                                    scope.row.order_type === 0 ||
                                    scope.row.order_type === 2
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("orderStatusFilter")(
                                                scope.row.status
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("takeOrderStatusFilter")(
                                                scope.row.status
                                              )
                                            )
                                          ),
                                        ]),
                                  ]),
                            ])
                          : _c("span", [_vm._v("已删除")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "供应链下单状态 | 备注", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              [, "成功", "失败"][scope.row.supply_chain_status]
                            ) +
                              "\n            " +
                              _vm._s(
                                scope.row.supply_chain_status &&
                                  scope.row.supply_chain_status_msg
                                  ? " | "
                                  : ""
                              ) +
                              "\n            " +
                              _vm._s(scope.row.supply_chain_status_msg)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "下单时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "推广人", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              "" +
                                (scope.row.spread && scope.row.spread.nickname
                                  ? scope.row.spread.nickname +
                                    " / " +
                                    scope.row.spread_uid
                                  : "无")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上级推广人", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              "" +
                                (scope.row.TopSpread &&
                                scope.row.TopSpread.nickname
                                  ? scope.row.TopSpread.nickname +
                                    " / " +
                                    scope.row.top_uid
                                  : "无")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "8",
                attrs: {
                  label: "操作",
                  "min-width": "220",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.orderFilter(scope.row)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onRefundDetail(
                                      scope.row.order_sn
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看退款单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.paid === 0 &&
                        scope.row.is_del === 0 &&
                        scope.row.activity_type != 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row.order_id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (scope.row.order_type == 0 ||
                          scope.row.order_type == 2) &&
                        scope.row.status === 0 &&
                        scope.row.paid === 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.send(
                                      scope.row,
                                      scope.row.order_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("发送货")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status > 0 || scope.row.is_del === 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOrderDetails(
                                      scope.row.order_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("订单详情")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.order_type == 1 &&
                        scope.row.paid == 1 &&
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancellation(
                                      scope.row.verify_code
                                    )
                                  },
                                },
                              },
                              [_vm._v("去核销")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.supply_chain_status === 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReorderClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v("重新下单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多\n              "),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                scope.row.paid === 1 &&
                                scope.row.order_type === 0
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.printOrder(
                                              scope.row.order_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("订单打印")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.status < 1 && scope.row.is_del === 0
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onOrderDetails(
                                              scope.row.order_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("订单详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderLog(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("订单记录")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderMark(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("订单备注")]
                                ),
                                _vm._v(" "),
                                scope.row.is_del !== 0
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.handleDelete(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除订单")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.order_type == 0 &&
                                scope.row.status === 1 &&
                                scope.row.paid === 1
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.reSend(
                                              scope.row.order_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改发货信息")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "order_id",
                  align: "center",
                  label: "订单ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_message",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_time",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改订单",
            visible: _vm.editVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: { model: _vm.formValidate, "label-width": "120px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单总价：" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入订单总价" },
                    on: { change: _vm.changePrice },
                    model: {
                      value: _vm.formValidate.total_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "total_price", $$v)
                      },
                      expression: "formValidate.total_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实际支付邮费：" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入订单油费" },
                    on: { change: _vm.changePrice },
                    model: {
                      value: _vm.formValidate.pay_postage,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "pay_postage", $$v)
                      },
                      expression: "formValidate.pay_postage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "优惠金额" } }, [
                _c("span", [_vm._v(_vm._s(_vm.formValidate.coupon_price))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "实际支付金额：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.formValidate.pay_price))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isBatch ? "批量发货" : "订单发送货",
            visible: _vm.sendVisible,
            width: "800px",
            "before-close": _vm.handleClose,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sendVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "shipment",
              attrs: {
                model: _vm.shipment,
                rules: _vm.rules,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.isResend &&
              _vm.noLogistics != 3 &&
              _vm.tableFrom.order_type != 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.shipment.delivery_type == 1 ||
                          _vm.shipment.delivery_type == 4
                            ? "原快递公司："
                            : "送货人姓名：",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.original.delivery_name))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isResend &&
              _vm.noLogistics != 3 &&
              _vm.tableFrom.order_type != 2
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.shipment.delivery_type == 1 ||
                          _vm.shipment.delivery_type == 4
                            ? "原快递单号："
                            : "送货人手机号：",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.original.delivery_id))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择类型：", prop: "delivery_type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeSend },
                      model: {
                        value: _vm.shipment.delivery_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.shipment, "delivery_type", $$v)
                        },
                        expression: "shipment.delivery_type",
                      },
                    },
                    [
                      !_vm.isBatch &&
                      _vm.tableFrom.order_type != 2 &&
                      _vm.orderType != 1
                        ? _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("手动发货"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { staticClass: "radio", attrs: { label: 3 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.orderType == 1 ? "虚拟发货" : "无需物流"
                              )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isDump == 1 &&
                      _vm.tableFrom.order_type != 2 &&
                      _vm.orderType != 1
                        ? _c(
                            "el-radio",
                            { staticClass: "radio", attrs: { label: 4 } },
                            [_vm._v("电子面单打印")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tableFrom.order_type !== 2 && _vm.orderType !== 1
                        ? _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("自己配送"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tableFrom.order_type !== 2 &&
                      _vm.orderType !== 1 &&
                      !_vm.isBatch
                        ? _c("el-radio", { attrs: { label: 5 } }, [
                            _vm._v("同城配送"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.shipment.delivery_type == 5 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择发货点：", prop: "station_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth mr20",
                          attrs: { placeholder: "请选择配送发货点" },
                          model: {
                            value: _vm.shipment.station_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipment, "station_id", $$v)
                            },
                            expression: "shipment.station_id",
                          },
                        },
                        _vm._l(_vm.storeList, function (item, index) {
                          return _c("el-option", {
                            key: item.value + index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.shipment.delivery_type == 1 ||
                _vm.shipment.delivery_type == 4) &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "快递公司：", prop: "delivery_name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth mr20",
                          attrs: {
                            filterable: "",
                            placeholder: "请选择快递公司",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getTempsLst(_vm.shipment.delivery_name)
                            },
                          },
                          model: {
                            value: _vm.shipment.delivery_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipment, "delivery_name", $$v)
                            },
                            expression: "shipment.delivery_name",
                          },
                        },
                        _vm._l(_vm.deliveryList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 5 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "包裹重量(kg)：", prop: "cargo_weight" },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { placeholder: "请输入包裹重量" },
                        model: {
                          value: _vm.shipment.cargo_weight,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "cargo_weight", $$v)
                          },
                          expression: "shipment.cargo_weight",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 5 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "配送备注：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入配送单备注",
                        },
                        model: {
                          value: _vm.shipment.mark,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "mark", $$v)
                          },
                          expression: "shipment.mark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 1 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "快递单号：", prop: "delivery_id" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入快递单号" },
                        model: {
                          value: _vm.shipment.delivery_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "delivery_id", $$v)
                          },
                          expression: "shipment.delivery_id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 4 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "电子面单：", prop: "temp_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth mr20",
                          attrs: { placeholder: "请选择电子面单" },
                          model: {
                            value: _vm.shipment.temp_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.shipment, "temp_id", $$v)
                            },
                            expression: "shipment.temp_id",
                          },
                        },
                        _vm._l(_vm.eleTempsLst, function (item, index) {
                          return _c("el-option", {
                            key: item.temp_id + index,
                            attrs: { label: item.title, value: item.temp_id },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.getPicture()
                            },
                          },
                        },
                        [_vm._v("预览")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 4 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "寄件人姓名：", prop: "from_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入寄件人姓名" },
                        model: {
                          value: _vm.shipment.from_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "from_name", $$v)
                          },
                          expression: "shipment.from_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 4 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "寄件人电话：", prop: "from_tel" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入寄件人电话" },
                        model: {
                          value: _vm.shipment.from_tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "from_tel", $$v)
                          },
                          expression: "shipment.from_tel",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 2 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "送货人姓名：", prop: "to_name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          placeholder: "请输入送货人姓名",
                        },
                        model: {
                          value: _vm.shipment.to_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "to_name", $$v)
                          },
                          expression: "shipment.to_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 2 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "送货人手机号：", prop: "to_phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入送货人手机号" },
                        model: {
                          value: _vm.shipment.to_phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "to_phone", $$v)
                          },
                          expression: "shipment.to_phone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.delivery_type == 4 &&
              _vm.tableFrom.order_type != 2 &&
              _vm.orderType != 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "寄件人地址：", prop: "from_addr" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入寄件人地址",
                        },
                        model: {
                          value: _vm.shipment.from_addr,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "from_addr", $$v)
                          },
                          expression: "shipment.from_addr",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.type != 4 &&
              _vm.activityType != 2 &&
              (_vm.productList.length > 1 || _vm.productNum > 1)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分单发货：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-text": "开启",
                          "inactive-text": "关闭",
                        },
                        model: {
                          value: _vm.shipment.is_split,
                          callback: function ($$v) {
                            _vm.$set(_vm.shipment, "is_split", $$v)
                          },
                          expression: "shipment.is_split",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "area-desc" }, [
                        _vm._v(
                          "\n          可选择表格中的商品单独发货，发货后会生成新的订单且不能撤回，请谨慎操作！\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shipment.is_split == 1 &&
              _vm.tableFrom.order_type != 2 &&
              (_vm.productList.length > 1 || _vm.productNum > 1)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleSelection",
                          attrs: {
                            data: _vm.productList,
                            "tooltip-effect": "dark",
                            size: "mini",
                            "row-key": function (row) {
                              return row.product_id
                            },
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "selection",
                              "reserve-selection": true,
                              "min-width": "50",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品信息",
                              "min-width": "200",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "acea-row",
                                          staticStyle: {
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview",
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: scope.row.cart_info
                                                    .product.image,
                                                  "preview-src-list": [
                                                    scope.row.cart_info.product
                                                      .image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "priceBox",
                                              staticStyle: { width: "150px" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.cart_info.product
                                                    .store_name
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1334329387
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "规格",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", { staticClass: "priceBox" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.cart_info.productAttr.sku
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2489556760
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品售价",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", { staticClass: "priceBox" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.cart_info.productAttr
                                              .price
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3535341656
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "总数",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", { staticClass: "priceBox" }, [
                                        _vm._v(_vm._s(scope.row.stock_num)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              13674865
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "待发数量",
                              align: "center",
                              "min-width": "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticClass: "priceBox",
                                        attrs: {
                                          type: "number",
                                          min: 0,
                                          max: scope.row.refund_num,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.limitCount(scope.row)
                                          },
                                        },
                                        model: {
                                          value: scope.row["product_num_input"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "product_num_input",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row['product_num_input']",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4294881726
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入备注" },
                    model: {
                      value: _vm.shipment.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.shipment, "remark", $$v)
                      },
                      expression: "shipment.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("shipment")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.pictureVisible
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.pictureVisible, width: "500px" },
              on: {
                "update:visible": function ($event) {
                  _vm.pictureVisible = $event
                },
              },
            },
            [
              _c("img", {
                staticClass: "pictures",
                attrs: { src: _vm.pictureUrl },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("details-from", { ref: "orderDetail" }),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("delivery-record", { ref: "deliveryList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }