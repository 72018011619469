"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountUpdate = accountUpdate;
exports.ajCaptcha = ajCaptcha;
exports.ajCaptchaCheck = ajCaptchaCheck;
exports.ajCaptchaStatus = ajCaptchaStatus;
exports.applymentDetail = applymentDetail;
exports.applymentLstApi = applymentLstApi;
exports.attachmentCreateApi = attachmentCreateApi;
exports.attachmentDeleteApi = attachmentDeleteApi;
exports.attachmentListApi = attachmentListApi;
exports.attachmentUpdateApi = attachmentUpdateApi;
exports.categoryApi = categoryApi;
exports.changeConfigClassifyStatus = changeConfigClassifyStatus;
exports.changeConfigSettingStatus = changeConfigSettingStatus;
exports.classifyDelApi = classifyDelApi;
exports.configClassifyLst = configClassifyLst;
exports.configSettingLst = configSettingLst;
exports.createApplyment = createApplyment;
exports.createConfigClassifyTable = createConfigClassifyTable;
exports.createConfigSettingTable = createConfigSettingTable;
exports.createGroupData = createGroupData;
exports.createGroupTable = createGroupTable;
exports.deleteGroupData = deleteGroupData;
exports.expressLst = expressLst;
exports.formatLstApi = formatLstApi;
exports.getFinancialRecordType = getFinancialRecordType;
exports.goupDataStatusApi = goupDataStatusApi;
exports.groupDataLst = groupDataLst;
exports.groupDetail = groupDetail;
exports.groupLst = groupLst;
exports.ledgerManageExportApi = ledgerManageExportApi;
exports.menuCreateApi = menuCreateApi;
exports.menuDeleteApi = menuDeleteApi;
exports.menuListApi = menuListApi;
exports.menuUpdateApi = menuUpdateApi;
exports.picDeleteApi = picDeleteApi;
exports.picNameEditApi = picNameEditApi;
exports.picNameUpdateApi = picNameUpdateApi;
exports.serviceChatListApi = serviceChatListApi;
exports.serviceChatUidListApi = serviceChatUidListApi;
exports.serviceCreateApi = serviceCreateApi;
exports.serviceDeleteApi = serviceDeleteApi;
exports.serviceListApi = serviceListApi;
exports.serviceLoginApi = serviceLoginApi;
exports.serviceStatusApi = serviceStatusApi;
exports.serviceUpdateApi = serviceUpdateApi;
exports.settingDelApi = settingDelApi;
exports.stationNewsList = stationNewsList;
exports.stationNoticeRead = stationNoticeRead;
exports.unreadCountApi = unreadCountApi;
exports.updateApplyment = updateApplyment;
exports.updateConfigClassifyTable = updateConfigClassifyTable;
exports.updateConfigSettingTable = updateConfigSettingTable;
exports.updateGroupData = updateGroupData;
exports.updateGroupTable = updateGroupTable;
exports.userListApi = userListApi;
exports.userLstApi = userLstApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 配置分类 -- 编辑表单
 * @param {Object} param params {Object} 传值参数
 */
function updateConfigClassifyTable(id) {
  return _request.default.get('config/classify/update/table/' + id);
}
/**
 * @description 配置分类 -- 添加表单
 */
function createConfigClassifyTable() {
  return _request.default.get('config/classify/create/table');
}
/**
 * @description 配置分类 -- 列表
 */
function configClassifyLst(page, limit) {
  return _request.default.get('config/classify/lst', {
    page: page,
    limit: limit
  });
}
/**
 * @description 配置分类 -- 修改状态
 */
function changeConfigClassifyStatus(id, status) {
  return _request.default.post('group/data/status/' + id, {
    status: status
  });
}

/**
 * @description 配置分类 -- 删除
 */
function classifyDelApi(id) {
  return _request.default.delete("config/classify/delete/".concat(id));
}

/**
 * @description 配置 -- 删除
 */
function settingDelApi(id) {
  return _request.default.delete("config/setting/delete/".concat(id));
}
function updateConfigSettingTable(id) {
  return _request.default.get('config/setting/update/table/' + id);
}
function createConfigSettingTable() {
  return _request.default.get('config/setting/create/table');
}
function configSettingLst(page, limit) {
  return _request.default.get('config/setting/lst', {
    page: page,
    limit: limit
  });
}
function changeConfigSettingStatus(id, status) {
  return _request.default.post('config/setting/status/' + id, {
    status: status
  });
}
function groupLst(page, limit) {
  return _request.default.get('group/lst', {
    page: page,
    limit: limit
  });
}
function createGroupTable() {
  return _request.default.get('group/create/table');
}
function updateGroupTable(id) {
  return _request.default.get('group/update/table/' + id);
}
/**
 * @description 专题详情 -- 表格表头
 * @param {Object} param params {Object} 传值参数
 */
function groupDetail(id) {
  return _request.default.get('group/detail/' + id);
}
/**
 * @description 专题列表 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
function groupDataLst(groupId, page, limit) {
  return _request.default.get('group/data/lst/' + groupId, {
    page: page,
    limit: limit
  });
}
/**
 * @description 专题列表 -- 新增表单
 * @param {Object} param params {Object} 传值参数
 */
function createGroupData(groupId) {
  return _request.default.get('group/data/create/table/' + groupId);
}
/**
 * @description 专题列表 -- 编辑表单
 * @param {Object} param params {Object} 传值参数
 */
function updateGroupData(groupId, id) {
  return _request.default.get("group/data/update/table/".concat(groupId, "/").concat(id));
}
/**
 * @description 客服管理 -- 修改状态
 */
function goupDataStatusApi(id, status) {
  return _request.default.post("/group/data/status/".concat(id), {
    status: status
  });
}
/**
 * @description 专题列表 -- 删除
 * @param {Object} param params {Object} 传值参数
 */
function deleteGroupData(id) {
  return _request.default.delete('group/data/delete/' + id);
}
/**
 * @description 菜单管理 -- 列表
 */
function menuListApi(data) {
  return _request.default.get('system/menu/lst', data);
}
/**
 * @description 菜单管理 -- 新增表单
 */
function menuCreateApi() {
  return _request.default.get('system/menu/create/form');
}
/**
 * @description 菜单管理 -- 编辑表单
 */
function menuUpdateApi(id) {
  return _request.default.get("system/menu/update/form/".concat(id));
}
/**
 * @description 菜单管理 -- 删除
 */
function menuDeleteApi(id) {
  return _request.default.delete("system/menu/delete/".concat(id));
}
/**
 * @description 附件分类 -- 所有分类
 */
function formatLstApi() {
  return _request.default.get("system/attachment/category/formatLst");
}
/**
 * @description 附件分类 -- 添加分类
 */
function attachmentCreateApi() {
  return _request.default.get("system/attachment/category/create/form");
}
/**
 * @description 附件分类 -- 编辑分类
 */
function attachmentUpdateApi(id) {
  return _request.default.get("system/attachment/category/update/form/".concat(id));
}
/**
 * @description 附件分类 -- 编辑名称
 */
function picNameUpdateApi(id, data) {
  return _request.default.get("system/attachment/update/".concat(id, "/form"), data);
}
/**
 * @description 附件分类 -- 编辑名称升级
 */
function picNameEditApi(id, data) {
  return _request.default.post("system/attachment/update/".concat(id, ".html"), data);
}
/**
 * @description 附件分类 -- 删除分类
 */
function attachmentDeleteApi(id) {
  return _request.default.delete("system/attachment/category/delete/".concat(id));
}
/**
 * @description 图片列表
 */
function attachmentListApi(data) {
  return _request.default.get("system/attachment/lst", data);
}
/**
 * @description 图片列表 -- 删除
 */
function picDeleteApi(id) {
  return _request.default.delete("system/attachment/delete", id);
}
/**
 * @description 图片列表 -- 修改附件分类
 */
function categoryApi(ids, attachment_category_id) {
  return _request.default.post("system/attachment/category", {
    ids: ids,
    attachment_category_id: attachment_category_id
  });
}
/**
 * @description 客服管理 -- 创建表单
 */
function serviceCreateApi() {
  return _request.default.get("service/create/form");
}
/**
 * @description 客服管理 -- 编辑表单
 */
function serviceUpdateApi(id) {
  return _request.default.get("service/update/form/".concat(id));
}
/**
 * @description 客服管理 -- 列表
 */
function serviceListApi(data) {
  return _request.default.get("service/list", data);
}
/**
 * @description 客服管理 -- 修改状态
 */
function serviceStatusApi(id, status) {
  return _request.default.post("service/status/".concat(id), {
    status: status
  });
}
/**
 * @description 客服管理 -- 删除
 */
function serviceDeleteApi(id) {
  return _request.default.delete("service/delete/".concat(id));
}
/**
 * @description 客服管理 -- 用户列表
 */
function userLstApi(data) {
  return _request.default.get("user/lst", data);
}
/**
 * @description 客服管理 -- 用户列表
 */
function userListApi(data) {
  return _request.default.get("user/list", data);
}
/**
 * @description 客服管理 -- 用户列表
 */
function serviceChatListApi(id, data) {
  return _request.default.get("service/".concat(id, "/user"), data);
}
/**
 * @description 客服管理 -- 客服与用户的聊天记录
 */
function serviceChatUidListApi(id, uid, data) {
  return _request.default.get("service/".concat(id, "/").concat(uid, "/lst"), data);
}
/**
 * @description 客服管理 -- 登录
 */
function serviceLoginApi(id) {
  return _request.default.post("service/login/" + id);
}
/**
 * @description 站内消息 -- 列表
 */
function stationNewsList(data) {
  return _request.default.get("notice/lst", data);
}
/**
 * @description 站内消息列表 -- 设为已读
 */
function stationNoticeRead(id) {
  return _request.default.post("notice/read/".concat(id));
}
/**
 * @description 消息 -- 未读数量
 */
function unreadCountApi() {
  return _request.default.get("notice/unread_count");
}
/**
 * @description 设置 -- 电商服务申请
 */
function createApplyment(data) {
  return _request.default.post("applyments/create", data);
}
/**
 * @description 设置 -- 电商服务详情
 */
function applymentDetail() {
  return _request.default.get("applyments/detail");
}
/**
 * @description 设置 -- 电商服务编辑
 */
function updateApplyment(id, data) {
  return _request.default.post("applyments/update/".concat(id), data);
}
/**
 * @description 设置 -- 分账列表
 */
function applymentLstApi(data) {
  return _request.default.get("profitsharing/lst", data);
}
/**
 * @description 设置 -- 物流公司列表
 */
function expressLst(data) {
  return _request.default.get("expr/lst", data);
}
/**
 * @description 设置 -- 物流公司列表
 */
function accountUpdate(id) {
  return _request.default.get("expr/partner/".concat(id, "/form"));
}
/**
 * @description 分账管理 -- 导出
 */
function ledgerManageExportApi(data) {
  return _request.default.get("profitsharing/export", data);
}
/**
 * @description 滑块 -- 请求滑块验证码
 */
function ajCaptcha(data) {
  return _request.default.get("ajcaptcha", data);
}
/**
 * @description 滑块 -- 请求滑块验证码
 */
function ajCaptchaCheck(data) {
  return _request.default.post("ajcheck", data);
}

/**
 * @description 滑块 -- 请求滑块验证码
 */
function ajCaptchaStatus(data) {
  return _request.default.post("ajstatus", data);
}

/**
 * @description 资金流水类型
 */
function getFinancialRecordType(data) {
  return _request.default.get("financial_record/type", data);
}