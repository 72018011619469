"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _user = require("@/api/user");
var _userDetails = _interopRequireDefault(require("./userDetails"));
var _couponList = _interopRequireDefault(require("./couponList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  components: {
    userDetails: _userDetails.default,
    couponList: _couponList.default
  },
  data: function data() {
    return {
      moren: require('@/assets/images/f.png'),
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)));
            end.setTime(end.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本年',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.setTime(new Date(new Date().getFullYear(), 0, 1)));
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeVal: [],
      collapse: false,
      visibleDetail: false,
      visibleCoupon: false,
      maxCols: 3,
      isShowSend: true,
      visible: false,
      user_type: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      wechatIds: '',
      row: '',
      labelPosition: 'right',
      userProps: {
        children: 'children',
        label: 'name',
        value: 'name'
      },
      userFrom: {
        label_id: '',
        user_type: '',
        sex: '',
        is_promoter: '',
        country: '',
        pay_count: '',
        user_time_type: '',
        user_time: '',
        nickname: '',
        uid: '',
        phone: '',
        province: '',
        city: '',
        page: 1,
        limit: 20,
        group_id: ''
      },
      couponForm: {
        用户标签: '',
        用户类型: '',
        性别: '',
        身份: '',
        消费情况: '',
        访问情况: '',
        访问时间: '',
        昵称: ''
      },
      address: [],
      grid: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid2: {
        xl: 18,
        lg: 16,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid3: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      addresData: [],
      groupList: [],
      labelLists: [],
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false
    };
  },
  mounted: function mounted() {
    this.getTagList();
    this.getList('');
  },
  methods: {
    // 用户
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.userFrom.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.userFrom.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this = this;
      var ids = this.tableData.data.map(function (v) {
        return v.uid;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this.checkedIds.indexOf(id);
          if (index === -1) {
            _this.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this.checkedIds.indexOf(id);
          if (index > -1) {
            _this.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 发送优惠券
    sendCoupon: function sendCoupon() {
      if (this.checkedIds.length === 0 && this.allCheck === false) {
        this.$message.warning('请选择用户');
      } else {
        this.visibleCoupon = true;
      }
    },
    // 分开选择
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.uid);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.uid);
          if (_index === -1) this.checkedIds.push(user.uid);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.uid);
          if (_index2 === -1) this.noChecked.push(user.uid);
        } else {
          var _index3 = this.checkedIds.indexOf(user.uid);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    sendSuccess: function sendSuccess() {
      this.visibleCoupon = false;
    },
    getCoupounParmas: function getCoupounParmas() {
      var label_id = this.userFrom.label_id === '' ? '' : this.getLabelValue();
      var user_type = this.findKey(this.userFrom.user_type, {
        '': '',
        微信用户: 'wechat',
        小程序用户: 'routine',
        H5用户: 'h5'
      });
      var sex = this.findKey(this.userFrom.sex, {
        男: '1',
        女: '2',
        保密: '0',
        '': ''
      });
      var pay_count = this.findKey(this.userFrom.sex, {
        '0次': '-1',
        '1次及以上': '0',
        '2次及以上': '1',
        '3次及以上': '2',
        '4次及以上': '3',
        '5次及以上': '4',
        '': ''
      });
      var is_promoter = this.findKey(this.userFrom.is_promoter, {
        推广员: '1',
        普通用户: '0',
        '': ''
      });
      var user_time_type = this.userFrom.user_time_type === 'visit' ? '最后访问' : this.userFrom.user_time_type === 'add_time' ? '首次访问' : '';
      this.couponForm = {
        用户标签: label_id,
        用户类型: user_type,
        性别: sex,
        消费情况: pay_count,
        身份: is_promoter,
        访问情况: user_time_type,
        访问时间: this.userFrom.user_time,
        昵称: this.userFrom.nickname
      };
    },
    findKey: function findKey(value, data) {
      var compare = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (a, b) {
        return a === b;
      };
      return Object.keys(data).find(function (k) {
        return compare(data[k], value);
      });
    },
    getLabelValue: function getLabelValue() {
      var labelName = '';
      for (var i = 0; i < this.labelLists.length; i++) {
        if (this.labelLists[i]['label_id'] === this.userFrom.label_id) {
          labelName = this.labelLists[i]['label_name'];
          return labelName;
        }
      }
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.userFrom.user_time = tab;
      this.getList('');
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.userFrom.user_time = e ? this.timeVal.join('-') : '';
    },
    userSearchs: function userSearchs() {
      if (this.userFrom.user_time_type && !this.userFrom.user_time) {
        this.$message.error('请选择访问时间');
      } else if (!this.userFrom.user_time_type && this.userFrom.user_time) {
        this.$message.error('请选择访问情况');
      } else {
        this.getList(1);
      }
    },
    // 标签列表
    getTagList: function getTagList() {
      var _this2 = this;
      (0, _user.labelLstApi)({
        page: 1,
        limit: 9999,
        all: 1
      }).then(function (res) {
        _this2.labelLists = res.data.list;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      this.visibleDetail = true;
    },
    Close: function Close() {
      this.visibleDetail = false;
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    // 修改标签
    setLabel: function setLabel(id) {
      var _this3 = this;
      this.$modalForm((0, _user.changeGroupApi)(id)).then(function () {
        return _this3.getList('');
      });
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.userFrom.page = num || this.userFrom.page;
      this.userFrom.user_type = this.user_type;
      this.userFrom.province = this.address[0];
      this.userFrom.city = this.address[1];
      if (this.userFrom.user_type === '0') this.userFrom.user_type = '';
      (0, _user.userLstApi)(this.userFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
        _this4.getCoupounParmas();
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList('');
    },
    handleClick: function handleClick() {
      this.getList('');
    },
    // 重置
    reset: function reset() {
      this.userFrom = {
        label_id: '',
        user_type: '',
        sex: '',
        is_promoter: '',
        country: '',
        pay_count: '',
        user_time_type: '',
        user_time: '',
        nickname: '',
        uid: '',
        phone: '',
        province: '',
        city: '',
        page: 1,
        limit: 20,
        group_id: '',
        date: ''
        // eslint-disable-next-line no-sequences
      }, this.timeVal = [];
    }
  }
};