var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-page" }, [
    _c(
      "div",
      {
        staticClass: "home-hot",
        style: { marginTop: _vm.slider + "px", background: _vm.boxColor },
      },
      [
        _vm.isOne
          ? _c(
              "div",
              { staticClass: "bd" },
              _vm._l(_vm.hotList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.info[0].value)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "des" }, [
                        _vm._v(_vm._s(item.info[1].value)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-box" }, [
                    item.img
                      ? _c("img", { attrs: { src: item.img, alt: "" } })
                      : _c("div", { staticClass: "empty-box on" }, [
                          _c("span", {
                            staticClass: "iconfont-diy icontupian",
                          }),
                        ]),
                  ]),
                ])
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "bd" },
              _vm._l(_vm.hotList, function (item, index) {
                return _c("div", { key: index, staticClass: "item one_item" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.info[0].value)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "des" }, [
                        _vm._v(_vm._s(item.info[1].value)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "img-box" }, [
                    item.img
                      ? _c("img", { attrs: { src: item.img, alt: "" } })
                      : _c("div", { staticClass: "empty-box on" }, [
                          _c("span", {
                            staticClass: "iconfont-diy icontupian",
                          }),
                        ]),
                  ]),
                ])
              }),
              0
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }