"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
var _default = exports.default = {
  inserted: function inserted(el, binding, vnode) {
    // 当被绑定的元素插入到 DOM 中时……
    var value = binding.value;
    var all_permission = '*:*:*';
    // 超管标识
    var permissions = _store.default.getters && _store.default.getters.permissions;
    // 从getters中取出从接口请求到的权限标识数组
    if (value && value instanceof Array && value.length > 0) {
      // value为指令的绑定值，并且要求是一个非空数组
      var permissionFlag = value;
      var hasPermissions = permissions.some(function (permission) {
        // some() 方法用于检测数组中的元素是否满足指定条件（函数提供）
        // 如果是超管或者其他管理员有对应的权限标识
        return all_permission === permission || permissionFlag.includes(permission);
        // 检测数组 permissionFlag 是否包含 permission
      });
      if (!hasPermissions) {
        el && el.parentNode && el.parentNode.removeChild(el);
        // 否则就删除该节点，体现在页面上就是没有按钮对应的权限标识就不显示该按钮
      }
      if (!el) {
        return hasPermissions;
      }
    }
  }
};