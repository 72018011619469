var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-page" }, [
    _c(
      "div",
      {
        staticClass: "advert",
        class: { pageOn: _vm.bgStyle === 1 },
        style: {
          marginLeft: _vm.prConfig + "px",
          marginRight: _vm.prConfig + "px",
          marginTop: _vm.slider + "px",
          background: _vm.bgColor,
        },
      },
      [
        _vm._l(_vm.picList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "advertItem01 acea-row" },
            [
              _vm.style === 0
                ? [
                    item.image
                      ? _c("img", { attrs: { src: item.image } })
                      : _c("div", { staticClass: "empty-box" }, [
                          _c("span", {
                            staticClass: "iconfont-diy icontupian",
                          }),
                        ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        _vm._v(" "),
        _vm.style === 1
          ? _c(
              "div",
              { staticClass: "advertItem02 acea-row" },
              _vm._l(_vm.picList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  item.image
                    ? _c("img", { attrs: { src: item.image } })
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("span", { staticClass: "iconfont-diy icontupian" }),
                      ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.style === 2
          ? _c(
              "div",
              { staticClass: "advertItem02 advertItem03 acea-row" },
              _vm._l(_vm.picList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  item.image
                    ? _c("img", { attrs: { src: item.image } })
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("span", { staticClass: "iconfont-diy icontupian" }),
                      ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.style === 3
          ? _c("div", { staticClass: "advertItem04 acea-row" }, [
              _c("div", { staticClass: "item" }, [
                _vm.picList[0].image
                  ? _c("img", { attrs: { src: _vm.picList[0].image } })
                  : _c("div", { staticClass: "empty-box" }, [
                      _c("span", { staticClass: "iconfont-diy icontupian" }),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "pic" }, [
                  _vm.picList[1].image
                    ? _c("img", { attrs: { src: _vm.picList[1].image } })
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("span", { staticClass: "iconfont-diy icontupian" }),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pic" }, [
                  _vm.picList[2].image
                    ? _c("img", { attrs: { src: _vm.picList[2].image } })
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("span", { staticClass: "iconfont-diy icontupian" }),
                      ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.style === 4
          ? _c(
              "div",
              { staticClass: "advertItem02 advertItem05 acea-row" },
              _vm._l(_vm.picList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  item.image
                    ? _c("img", { attrs: { src: item.image } })
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("span", { staticClass: "iconfont-diy icontupian" }),
                      ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.style === 5
          ? _c(
              "div",
              { staticClass: "advertItem06 acea-row" },
              _vm._l(_vm.picList, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  item.image
                    ? _c("img", { attrs: { src: item.image } })
                    : _c("div", { staticClass: "empty-box" }, [
                        _c("span", { staticClass: "iconfont-diy icontupian" }),
                      ]),
                ])
              }),
              0
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }