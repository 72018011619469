var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "未开启", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "开启", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券名称：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth mr20",
                              attrs: {
                                placeholder: "请输入优惠券名称",
                                clearable: "",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.coupon_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "coupon_name", $$v)
                                },
                                expression: "tableFrom.coupon_name",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "店铺券", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "商品券", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "获取方式：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.send_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "send_type", $$v)
                                },
                                expression: "tableFrom.send_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.sendType, function (item, key) {
                                return _c("el-option", {
                                  key: key,
                                  attrs: { label: item, value: key },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "" + _vm.roterPre + "/marketing/coupon/creatCoupon",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("添加优惠劵")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "coupon_id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "优惠劵名称",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "优惠劵类型", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("couponTypeFilter")(row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "领取日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.start_time
                          ? _c("div", [
                              _vm._v(_vm._s(row.start_time) + " "),
                              _c("br"),
                              _vm._v("- " + _vm._s(row.end_time)),
                            ])
                          : _c("span", [_vm._v("不限时")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "150", label: "获取方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.sendType[row.send_type]) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "使用时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.use_start_time && row.use_end_time
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.use_start_time) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "- " + _vm._s(row.use_end_time) + "\n          "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(row.coupon_time) + "天"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "product[0].product.store_name",
                  label: "商品名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "100", label: "发布数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.is_limited === 0
                          ? _c("span", [_vm._v("不限量")])
                          : _c("div", [
                              _c("span", { staticClass: "fa" }, [
                                _vm._v("发布：" + _vm._s(row.total_count)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "sheng" }, [
                                _vm._v("剩余：" + _vm._s(row.remain_count)),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "100", label: "使用数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _c("span", [
                            _vm._v("已领取/发放总数：" + _vm._s(row.send_num)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "sheng" }, [
                            _vm._v("已使用总数：" + _vm._s(row.used_num)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.details(scope.row.coupon_id)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.receive(scope.row.coupon_id)
                              },
                            },
                          },
                          [_vm._v("领取/发放记录")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row.coupon_id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  "" +
                                  _vm.roterPre +
                                  "/marketing/coupon/creatCoupon/" +
                                  scope.row.coupon_id,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [_vm._v("复制")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.coupon_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "优惠券详情",
                visible: _vm.detailDialog,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailDialog = $event
                },
              },
            },
            [
              _c("div", [
                _c("div", { staticClass: "box-container" }, [
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("优惠券名称："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("优惠券类型："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("couponTypeFilter")(_vm.couponDetail.type)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("优惠券面值："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.coupon_price)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("使用门槛："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.couponDetail.use_min_price == "0.00"
                            ? "无门槛"
                            : "最低消费" + _vm.couponDetail.use_min_price
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("使用有效期："),
                    ]),
                    _vm._v(" "),
                    _vm.couponDetail.coupon_time &&
                    _vm.couponDetail.coupon_type == 0
                      ? _c("span", { staticClass: "info" }, [
                          _vm._v(_vm._s(_vm.couponDetail.coupon_time) + "天"),
                        ])
                      : _vm.couponDetail.coupon_type == 1
                      ? _c("span", { staticClass: "info" }, [
                          _vm._v(
                            _vm._s(
                              _vm.couponDetail.use_start_time +
                                " - " +
                                _vm.couponDetail.use_end_time
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("领取时间："),
                    ]),
                    _vm._v(" "),
                    _vm.couponDetail.is_timeout == 1
                      ? _c("span", { staticClass: "info" }, [
                          _vm._v(
                            _vm._s(_vm.couponDetail.start_time) +
                              " - " +
                              _vm._s(_vm.couponDetail.end_time)
                          ),
                        ])
                      : _c("span", { staticClass: "info" }, [_vm._v("不限时")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("获取方式："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("couponUseTypeFilter")(
                            _vm.couponDetail.send_type
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("是否限量："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterClose")(_vm.couponDetail.is_limited)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("已发布总数："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.couponDetail.is_limited == 0
                            ? "不限量"
                            : _vm.couponDetail.total_count
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("剩余总数："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.couponDetail.is_limited == 0
                            ? "不限量"
                            : _vm.couponDetail.remain_count
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list sp100" },
                    [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("已领取/发放总数："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.couponDetail.send_num)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.receive(_vm.couponDetail.coupon_id)
                            },
                          },
                        },
                        [_vm._v("已领取/发放记录")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list sp100" },
                    [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("已使用总数："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.couponDetail.used_num)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.usedRecord(_vm.couponDetail.coupon_id)
                            },
                          },
                        },
                        [_vm._v("使用记录")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("排序：")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.sort)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("状态：")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.status ? "开启" : "关闭")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.type == 1
                    ? _c(
                        "div",
                        { staticClass: "list sp100" },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              attrs: { data: _vm.relateData.data },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "product.product_id",
                                  label: "ID",
                                  "min-width": "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "商品图", "min-width": "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.product.image
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview",
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "36px",
                                                      height: "36px",
                                                    },
                                                    attrs: {
                                                      src: scope.row.product
                                                        .image,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview",
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "36px",
                                                      height: "36px",
                                                    },
                                                    attrs: {
                                                      src: require("../../../../assets/images/f.png"),
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1223246422
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "product.store_name",
                                  label: "商品名称",
                                  "min-width": "150",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "product.stock",
                                  label: "库存",
                                  "min-width": "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "product.price",
                                  label: "商品售价",
                                  "min-width": "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "product.sales",
                                  label: "销售数量",
                                  "min-width": "50",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "block mb20" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "page-sizes": [5, 10],
                                  "page-size": _vm.tableFromRelate.limit,
                                  "current-page": _vm.tableFromRelate.page,
                                  layout:
                                    "total, sizes, prev, pager, next, jumper",
                                  total: _vm.relateData.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeChangeRelate,
                                  "current-change": _vm.pageChangeRelate,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "modalbox",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "min-width": "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.Loading,
                  expression: "Loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.issueData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "user.nickname",
                  label: "用户名",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user.avatar
                          ? _c("div", { staticClass: "demo-image__preview" }, [
                              _c("img", {
                                staticStyle: { width: "36px", height: "36px" },
                                attrs: { src: scope.row.user.avatar },
                              }),
                            ])
                          : _c("div", { staticClass: "demo-image__preview" }, [
                              _c("img", {
                                staticStyle: { width: "36px", height: "36px" },
                                attrs: {
                                  src: require("../../../../assets/images/f.png"),
                                },
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: _vm.receiveTime, "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.receiveType === 0
                          ? _c("span", [_vm._v(_vm._s(scope.row.create_time))])
                          : _c("span", [_vm._v(_vm._s(scope.row.use_time))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFromIssue.limit,
                  "current-page": _vm.tableFromIssue.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.issueData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeIssue,
                  "current-change": _vm.pageChangeIssue,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }