"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/wangEditor/index.vue"));
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_page_ueditor',
  components: {
    WangEditor: _index.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      myConfig: {
        autoHeightEnabled: false,
        // 编辑器不自动被内容撑高
        initialFrameHeight: 350,
        // 初始容器高度
        initialFrameWidth: '100%',
        // 初始容器宽度
        UEDITOR_HOME_URL: '/admin/UEditor/',
        serverUrl: ''
      },
      description: '',
      defaults: {},
      configData: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  methods: {
    getEditorContent: function getEditorContent(data) {
      this.configData.val = data;
    },
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        // 创建 dialog
        var dialog = new window.UE.ui.Dialog({
          iframeUrl: '/admin/widget.images/index.html?fodder=dialog',
          editor: editor,
          name: uiName,
          title: '上传图片',
          cssRules: 'width:1200px;height:500px;padding:20px;'
        });
        this.dialog = dialog;
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(../../../assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            // 渲染dialog
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      }, 37);
      window.UE.registerUI('video-dialog', function (editor, uiName) {
        var dialog = new window.UE.ui.Dialog({
          iframeUrl: '/admin/widget.video/index.html?fodder=video',
          editor: editor,
          name: uiName,
          title: '上传视频',
          cssRules: 'width:1000px;height:500px;padding:20px;'
        });
        this.dialog = dialog;
        var btn = new window.UE.ui.Button({
          name: 'video-button',
          title: '上传视频',
          cssRules: "background-image: url(../../../assets/images/icons.png);background-position: -320px -20px;",
          onclick: function onclick() {
            // 渲染dialog
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      }, 38);
    }
  }
};