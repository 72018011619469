"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _studioDetail = _interopRequireDefault(require("./studioDetail"));
var _index = _interopRequireDefault(require("@/components/importGoods/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StudioList',
  components: {
    detailsFrom: _studioDetail.default,
    importGoods: _index.default
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      dialogVisible: false,
      importVisible: false,
      listLoading: true,
      studioShowStatusList: [{
        label: '显示',
        value: 3
      }, {
        label: '商户关闭',
        value: 2
      }, {
        label: '平台关闭',
        value: 1
      }, {
        label: '关闭',
        value: 0
      }],
      studioStatusList: [{
        label: '正在进行',
        value: '101'
      }, {
        label: '已结束',
        value: '103'
      }, {
        label: '未开始',
        value: '102'
      }, {
        label: '已过期',
        value: '107'
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        status_tag: this.$route.query.status ? this.$route.query.status : '',
        keyword: '',
        show_type: '',
        status: '',
        broadcast_room_id: this.$route.query.id ? this.$route.query.id : ''
      },
      broadcast_room_id: this.$route.query.id ? this.$route.query.id : '',
      liveRoomStatus: ''
    };
  },
  watch: {
    broadcast_room_id: function broadcast_room_id(newName, oldName) {
      this.getList('');
    }
  },
  mounted: function mounted() {
    this.getList('');
  },
  methods: {
    // 详情
    onStudioDetails: function onStudioDetails(id, type) {
      this.broadcast_room_id = id;
      this.$refs.studioDetail.dialogVisible = true;
      this.$refs.studioDetail.getData(id, type);
    },
    // 导入
    handleImport: function handleImport(row) {
      this.$refs.uploadGoods.importVisible = true;
      this.$refs.uploadGoods.broadcast_id = row.broadcast_room_id;
      this.$refs.uploadGoods.broadcast_room = row.name;
      this.$refs.uploadGoods.image = '';
      localStorage.setItem('liveRoom_id', row.broadcast_room_id);
    },
    // 编辑助手
    onEditAssistant: function onEditAssistant(id) {
      var _this = this;
      this.$modalForm((0, _marketing.studioEditAssistant)(id)).then(function () {
        return _this.getList('');
      });
    },
    // 删除
    handleDelete: function handleDelete(item, idx) {
      var _this2 = this;
      item.status === 2 && item.live_status === 101 ? this.$confirm('该直播间正在进行直播，删除后不可恢复，您确认删除吗？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        (0, _marketing.broadcastDeleteApi)(item.broadcast_room_id).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success(message);
          _this2.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this2.$message.error(message);
        });
      }).catch(function (action) {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      }) : this.$modalSureDelete().then(function () {
        (0, _marketing.broadcastDeleteApi)(item.broadcast_room_id).then(function (_ref3) {
          var message = _ref3.message;
          _this2.$message.success(message);
          _this2.tableData.data.splice(idx, 1);
          _this2.getList();
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this2.$message.error(message);
        });
      });
    },
    // 推送消息
    onPushMessage: function onPushMessage(id) {
      var _this3 = this;
      this.$confirm('给长期订阅用户推送消息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.studioPushMessageApi)(id).then(function (_ref5) {
          var message = _ref5.message;
          _this3.$message.success(message);
        }).catch(function (_ref6) {
          var message = _ref6.message;
          _this3.$message.error(message);
        });
      }).catch(function (action) {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this4 = this;
      this.$modalForm((0, _marketing.studioEdit)(id)).then(function () {
        return _this4.getList('');
      });
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.tableData.page = num || this.tableData.page;
      console.log(this.tableForm);
      (0, _marketing.broadcastListApi)(this.tableForm).then(function (res) {
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
        _this5.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList('');
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this6 = this;
      (0, _marketing.changeStudioRoomDisplayApi)(row.broadcast_room_id, {
        is_show: row.is_mer_show
      }).then(function (_ref7) {
        var message = _ref7.message;
        _this6.$message.success(message);
        _this6.getList('');
      }).catch(function (_ref8) {
        var message = _ref8.message;
        _this6.$message.error(message);
      });
    },
    // 开启收录
    onchangeIsFeeds: function onchangeIsFeeds(row) {
      var _this7 = this;
      (0, _marketing.openCollectionApi)(row.broadcast_room_id, row.is_feeds_public).then(function (_ref9) {
        var message = _ref9.message;
        _this7.$message.success(message);
        _this7.getList('');
      }).catch(function (_ref10) {
        var message = _ref10.message;
        _this7.$message.error(message);
      });
    },
    // 禁言
    onchangeIsCommen: function onchangeIsCommen(row) {
      var _this8 = this;
      (0, _marketing.openCommontApi)(row.broadcast_room_id, row.close_comment).then(function (_ref11) {
        var message = _ref11.message;
        _this8.$message.success(message);
        _this8.getList('');
      }).catch(function (_ref12) {
        var message = _ref12.message;
        _this8.$message.error(message);
      });
    },
    // 客服
    onchangeIsKf: function onchangeIsKf(row) {
      var _this9 = this;
      (0, _marketing.studioCloseKfApi)(row.broadcast_room_id, row.close_kf).then(function (_ref13) {
        var message = _ref13.message;
        _this9.$message.success(message);
        _this9.getList('');
      }).catch(function (_ref14) {
        var message = _ref14.message;
        _this9.$message.error(message);
      });
    }
  }
};