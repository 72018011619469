var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "importedGoods" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "samll",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "broadcast_goods_id",
              label: "ID",
              "min-width": "50",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品图", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "36px", height: "36px" },
                      attrs: {
                        src: scope.row.goods && scope.row.goods.cover_img,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "goods.name",
              label: "商品名称",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "库存", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.goods &&
                            scope.row.goods.product &&
                            scope.row.goods.product.stock
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "goods.price", label: "直播价", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "goods.pay_num",
              label: "销售数量",
              "min-width": "50",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "goods.pay_price",
              label: "销售金额",
              "min-width": "50",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上下架", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-text": "上架",
                        "inactive-text": "下架",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onchangeIsShow(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.on_sale,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "on_sale", $$v)
                        },
                        expression: "scope.row.on_sale",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block mb20" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [3, 6, 9],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }