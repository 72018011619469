"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _index = _interopRequireDefault(require("@/components/cards/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponList',
  components: {
    cardsData: _index.default
  },
  data: function data() {
    return {
      Loading: false,
      dialogVisible: false,
      detailDialog: false,
      roterPre: _settings.roterPre,
      listLoading: true,
      timeVal: [],
      cardLists: [],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keyword: '',
        date: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
    this.getIntegralTitle();
  },
  methods: {
    // 获取统计数据
    getIntegralTitle: function getIntegralTitle() {
      var _this = this;
      (0, _marketing.integralTitleApi)({
        date: this.tableFrom.date
      }).then(function (res) {
        _this.cardLists = res.data;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.integralLstApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};