"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/uploadPicture/index.vue"));
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UploadFroms',
  components: {
    UploadIndex: _index.default
  },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      isMore: ''
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getImage: function getImage(img) {
      this.callback(img);
      this.visible = false;
    }
  }
};