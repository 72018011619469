"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'PreviewBox',
  props: {
    goodsId: {
      type: [String, Number],
      default: ''
    },
    productType: {
      type: [String, Number],
      default: ''
    },
    previewKey: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    getProListUrl: function getProListUrl() {}
  }
};