var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-config" },
    [
      _c(
        "Form",
        { ref: "formInline" },
        _vm._l(_vm.rCom, function (item, key) {
          return _c(
            "div",
            { key: key },
            [
              _c(item.components.name, {
                ref: "childData",
                refInFor: true,
                tag: "component",
                attrs: {
                  "config-obj": _vm.configObj,
                  "config-nme": item.configNme,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }