var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-count",
      class: _vm.bgStyle ? "" : "pageOn",
      style: {
        marginLeft: _vm.prConfig + "px",
        marginRight: _vm.prConfig + "px",
        marginTop: _vm.slider + "px",
        background: _vm.colorShow ? _vm.bgColor : "transparent",
      },
    },
    [
      _c("div", { staticClass: "home_topic" }, [
        _c(
          "div",
          {
            staticClass: "title-wrapper",
            class: _vm.bgStyle ? "" : "presellOn",
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/topic_title.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]
        ),
        _vm._v(" "),
        _vm.isOne == 1
          ? _c("div", { staticClass: "mobile-page" }, [
              _c(
                "div",
                { staticClass: "home_menu" },
                _vm._l(_vm.vuexMenu, function (item, index) {
                  return _c("div", { key: index, staticClass: "menu-item" }, [
                    _c("div", { staticClass: "img-box" }, [
                      item.img
                        ? _c("img", { attrs: { src: item.img, alt: "" } })
                        : _c(
                            "div",
                            {
                              staticClass: "empty-box",
                              class: _vm.conStyle ? "" : "pageOn",
                            },
                            [
                              _c("span", {
                                staticClass: "iconfont-diy icontupian",
                              }),
                            ]
                          ),
                    ]),
                  ])
                }),
                0
              ),
            ])
          : _c("div", { staticClass: "mobile-page" }, [
              _c(
                "div",
                { staticClass: "list_menu" },
                _vm._l(_vm.vuexMenu, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "item" },
                    [
                      index < 1
                        ? [
                            _c("div", { staticClass: "img-box" }, [
                              item.img
                                ? _c("img", {
                                    attrs: { src: item.img, alt: "" },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass: "empty-box",
                                      class: _vm.conStyle ? "" : "pageOn",
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont-diy icontupian",
                                      }),
                                    ]
                                  ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
        _vm._v(" "),
        _vm.isOne > 1 && _vm.pointerStyle < 2
          ? _c(
              "div",
              {
                staticClass: "dot",
                class: {
                  "line-dot": _vm.pointerStyle === 0,
                  "": _vm.pointerStyle === 1,
                },
                style: {
                  justifyContent:
                    _vm.dotPosition === 1
                      ? "center"
                      : _vm.dotPosition === 2
                      ? "flex-end"
                      : "flex-start",
                },
              },
              [
                _c("div", {
                  staticClass: "dot-item",
                  style: { background: "" + _vm.pointerColor },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "dot-item" }),
                _vm._v(" "),
                _c("div", { staticClass: "dot-item" }),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("进入专场 "),
      _c("span", { staticClass: "iconfont-diy iconjinru" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }