var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "商品信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "商品详情" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "其他设置" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "规格设置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "130px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品类型：", required: "" } },
                        _vm._l(_vm.virtual, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "virtual",
                              class:
                                _vm.formValidate.type == item.id
                                  ? "virtual_boder"
                                  : "virtual_boder2",
                              on: {
                                click: function ($event) {
                                  return _vm.virtualbtn(item.id, 2)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "virtual_top" }, [
                                _vm._v(_vm._s(item.tit)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "virtual_bottom" }, [
                                _vm._v("(" + _vm._s(item.tit2) + ")"),
                              ]),
                              _vm._v(" "),
                              _vm.formValidate.type == item.id
                                ? _c("div", { staticClass: "virtual_san" })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.type == item.id
                                ? _c("div", { staticClass: "virtual_dui" }, [
                                    _vm._v("✓"),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：", prop: "store_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入商品名称" },
                            model: {
                              value: _vm.formValidate.store_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "store_name", $$v)
                              },
                              expression: "formValidate.store_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户商品分类：",
                            prop: "mer_cate_id",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.merCateList,
                              props: _vm.propsMer,
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formValidate.mer_cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "mer_cate_id", $$v)
                              },
                              expression: "formValidate.mer_cate_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台商品分类：", prop: "cate_id" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.categoryList,
                              props: _vm.props,
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formValidate.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cate_id", $$v)
                              },
                              expression: "formValidate.cate_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "品牌选择：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: { filterable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.formValidate.brand_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "brand_id", $$v)
                                },
                                expression: "formValidate.brand_id",
                              },
                            },
                            _vm._l(_vm.BrandList, function (item) {
                              return _c("el-option", {
                                key: item.brand_id,
                                attrs: {
                                  label: item.brand_name,
                                  value: item.brand_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品关键字：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入商品关键字" },
                            model: {
                              value: _vm.formValidate.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "keyword", $$v)
                              },
                              expression: "formValidate.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位：", prop: "unit_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入单位" },
                            model: {
                              value: _vm.formValidate.unit_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "unit_name", $$v)
                              },
                              expression: "formValidate.unit_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品简介：", prop: "store_info" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: "请输入商品简介",
                            },
                            model: {
                              value: _vm.formValidate.store_info,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "store_info", $$v)
                              },
                              expression: "formValidate.store_info",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品封面图：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "750*750px" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品轮播图：",
                            prop: "slider_image",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.slider_image,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "false" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.slider_image.length < 10
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "uploadCont",
                                      attrs: { title: "750*750px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主图视频：", prop: "video_link" } },
                        [
                          _c("el-input", {
                            staticClass: "perW50",
                            attrs: { placeholder: "请输入视频链接" },
                            model: {
                              value: _vm.videoLink,
                              callback: function ($$v) {
                                _vm.videoLink = $$v
                              },
                              expression: "videoLink",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            ref: "refid",
                            staticStyle: { display: "none" },
                            attrs: { type: "file" },
                            on: { change: _vm.zh_uploadFile_change },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "uploadVideo",
                              attrs: {
                                type: "primary",
                                icon: "ios-cloud-upload-outline",
                              },
                              on: { click: _vm.zh_uploadFile },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.videoLink ? "确认添加" : "上传视频"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.upload.videoIng
                            ? _c("el-progress", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: {
                                  percentage: _vm.progress,
                                  "text-inside": true,
                                  "stroke-width": 26,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formValidate.video_link
                            ? _c("div", { staticClass: "iview-video-style" }, [
                                _c(
                                  "video",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100% !important",
                                      "border-radius": "10px",
                                    },
                                    attrs: {
                                      src: _vm.formValidate.video_link,
                                      controls: "controls",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                您的浏览器不支持 video 标签。\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mark" }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-delete iconv",
                                  on: { click: _vm.delVideo },
                                }),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.deliveryList.length > 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "送货方式：",
                                prop: "delivery_way",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.formValidate.delivery_way,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "delivery_way",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.delivery_way",
                                      },
                                    },
                                    _vm._l(_vm.deliveryList, function (item) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: item.value,
                                          attrs: { label: item.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.name) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.formValidate.delivery_way.length == 2 ||
                    (_vm.formValidate.delivery_way.length == 1 &&
                      _vm.formValidate.delivery_way[0] == 2)) &&
                  _vm.formValidate.type == 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否包邮：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.delivery_free,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "delivery_free",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.delivery_free",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 0 },
                                    },
                                    [_vm._v("否")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.delivery_free == 0 &&
                  (_vm.formValidate.delivery_way.length == 2 ||
                    (_vm.formValidate.delivery_way.length == 1 &&
                      _vm.formValidate.delivery_way[0] == 2)) &&
                  _vm.formValidate.type == 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "运费模板：", prop: "temp_id" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd mr20",
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formValidate.temp_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "temp_id",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.temp_id",
                                      },
                                    },
                                    _vm._l(_vm.shippingList, function (item) {
                                      return _c("el-option", {
                                        key: item.shipping_template_id,
                                        attrs: {
                                          label: item.name,
                                          value: item.shipping_template_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: { size: "small" },
                                      on: { click: _vm.addTem },
                                    },
                                    [_vm._v("添加运费模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "proCoupon",
                          attrs: { label: "优惠券：" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.couponData,
                                function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "mr10",
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleCloseCoupon(tag)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(tag.title) + "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr15",
                                  attrs: { size: "mini" },
                                  on: { click: _vm.addCoupon },
                                },
                                [_vm._v("选择优惠券")]
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情：" } },
                        [
                          _c("vue-ueditor-wrap", {
                            attrs: { config: _vm.myConfig },
                            on: { beforeInit: _vm.addCustomDialog },
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "最少购买件数：" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: "请输入购买件数",
                                },
                                model: {
                                  value: _vm.formValidate.once_min_count,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "once_min_count",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.once_min_count",
                                },
                              }),
                              _vm._v(
                                "\n                默认为0，则不限制购买件数\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "限购类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.pay_limit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "pay_limit", $$v)
                                },
                                expression: "formValidate.pay_limit",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 0 } },
                                [_vm._v("不限购")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("单次限购"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("长期限购"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.pay_limit != 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "限购数量",
                                    prop: "once_max_count",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: _vm.formValidate.once_min_count,
                                      placeholder: "请输入购买件数",
                                    },
                                    model: {
                                      value: _vm.formValidate.once_max_count,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "once_max_count",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.once_max_count",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                单次限购是限制每次下单最多购买的数量，长期限购是限制一个用户总共可以购买的数量\n            "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序：" } },
                            [
                              _c("el-input-number", {
                                attrs: { placeholder: "请输入排序" },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品推荐：" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { size: "small" },
                              on: { change: _vm.onChangeGroup },
                              model: {
                                value: _vm.checkboxGroup,
                                callback: function ($$v) {
                                  _vm.checkboxGroup = $$v
                                },
                                expression: "checkboxGroup",
                              },
                            },
                            _vm._l(_vm.recommend, function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.name) + "\n              ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.labelList.length
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品标签：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidthd",
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.formValidate.mer_labels,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "mer_labels",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.mer_labels",
                                  },
                                },
                                _vm._l(_vm.labelList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台保障服务：" } },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidthd mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.guarantee_template_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "guarantee_template_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.guarantee_template_id",
                                  },
                                },
                                _vm._l(_vm.guaranteeList, function (item) {
                                  return _c("el-option", {
                                    key: item.guarantee_template_id,
                                    attrs: {
                                      label: item.template_name,
                                      value: item.guarantee_template_id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr15",
                                  attrs: { size: "small" },
                                  on: { click: _vm.addServiceTem },
                                },
                                [_vm._v("添加服务说明模板")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启礼包：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.$route.params.id ? true : false,
                              },
                              model: {
                                value: _vm.formValidate.is_gift_bag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "is_gift_bag", $$v)
                                },
                                expression: "formValidate.is_gift_bag",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 0 } },
                                [_vm._v("否")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "备注：" } }, [
                        _c("div", [_vm._v("1. 选择开启礼包后，不可修改")]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              2.\n              用户购买该分销礼包商品后，可自动成为分销员（即已成为分销员的用户在移动端看不到该分销礼包商品）\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              3.\n              该商品设置为分销礼包后会展示在平台后台的【分销】-【分销礼包】（即不会展示在平台后台-【商品列表】）\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.type == 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自定义留言：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.customMessBtn },
                                  model: {
                                    value: _vm.customBtn,
                                    callback: function ($$v) {
                                      _vm.customBtn = $$v
                                    },
                                    expression: "customBtn",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("关闭"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("开启"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.customBtn
                                ? _c(
                                    "div",
                                    { staticClass: "addCustom_content" },
                                    _vm._l(
                                      _vm.formValidate.extend,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "custom_box",
                                            attrs: { type: "flex" },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "100px",
                                                "margin-right": "10px",
                                              },
                                              attrs: {
                                                placeholder:
                                                  "留言标题" + (index + 1),
                                              },
                                              model: {
                                                value: item.title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "title",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.title",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: {
                                                  width: "200px",
                                                  "margin-left": "6px",
                                                  "margin-right": "10px",
                                                },
                                                model: {
                                                  value: item.key,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "key", $$v)
                                                  },
                                                  expression: "item.key",
                                                },
                                              },
                                              _vm._l(
                                                _vm.CustomList,
                                                function (items) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: items.value,
                                                      attrs: {
                                                        value: items.value,
                                                        label: items.label,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(items.label) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                model: {
                                                  value: item.require,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "require",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.require",
                                                },
                                              },
                                              [_vm._v("必填")]
                                            ),
                                            _vm._v(" "),
                                            _vm.formValidate.extend.length - 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "addfont",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delcustom(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  删除\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.customBtn,
                                      expression: "customBtn",
                                    },
                                  ],
                                  staticClass: "addCustomBox",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn",
                                      on: { click: _vm.addcustom },
                                    },
                                    [_vm._v("+ 添加表单")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "remark" }, [
                                    _c("div", [_vm._v("备注：")]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("div", { staticClass: "titTip" }, [
                                        _vm._v(
                                          "1.用户下单时需填写的信息，最多可设置10条"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "titTip" }, [
                                        _vm._v(
                                          "2.虚拟物品不可加入购物车，用户可直接购买"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 3,
                      expression: "currentTab === 3",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.extensionStatus > 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "佣金设置：",
                                props: "extension_type",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangetype(
                                        _vm.formValidate.extension_type
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formValidate.extension_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "extension_type",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.extension_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: {
                                        disabled:
                                          _vm.merDistributionStatus === 0,
                                        label: 1,
                                      },
                                    },
                                    [_vm._v("单独设置")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("默认设置"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deductionStatus > 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "积分抵扣比例：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeIntergral(
                                        _vm.deduction_set
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.deduction_set,
                                    callback: function ($$v) {
                                      _vm.deduction_set = $$v
                                    },
                                    expression: "deduction_set",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 1 },
                                    },
                                    [_vm._v("单独设置")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: -1 } }, [
                                    _vm._v("默认设置"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.deduction_set == 1
                                ? _c(
                                    "div",
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          min: 0,
                                          placeholder: "请输入抵扣比例",
                                        },
                                        model: {
                                          value: _vm.formValidate.integral_rate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "integral_rate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.integral_rate",
                                        },
                                      }),
                                      _vm._v("\n              %\n            "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品规格：", props: "spec_type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeSpec(
                                    _vm.formValidate.spec_type
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.spec_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "spec_type", $$v)
                                },
                                expression: "formValidate.spec_type",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 0 } },
                                [_vm._v("单规格")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("多规格"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.spec_type === 1
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择规格：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.selectRule,
                                        callback: function ($$v) {
                                          _vm.selectRule = $$v
                                        },
                                        expression: "selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.attr_template_id,
                                        attrs: {
                                          label: item.template_name,
                                          value: item.attr_template_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addRule },
                                    },
                                    [_vm._v("添加规格模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.formValidate.attr.length > 0
                            ? _c(
                                "el-form-item",
                                _vm._l(
                                  _vm.formValidate.attr,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "acea-row row-middle" },
                                        [
                                          _c("span", { staticClass: "mr5" }, [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-circle-close",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemoveAttr(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "rulesBox" },
                                        [
                                          _vm._l(
                                            item.detail,
                                            function (j, indexn) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: indexn,
                                                  staticClass: "mb5 mr10",
                                                  attrs: {
                                                    closable: "",
                                                    size: "medium",
                                                    "disable-transitions": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.handleClose(
                                                        item.detail,
                                                        indexn
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(j) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          item.inputVisible
                                            ? _c("el-input", {
                                                ref: "saveTagInput",
                                                refInFor: true,
                                                staticClass: "input-new-tag",
                                                attrs: { size: "small" },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.createAttr(
                                                      item.detail.attrsVal,
                                                      index
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.createAttr(
                                                      item.detail.attrsVal,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.detail.attrsVal,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.detail,
                                                      "attrsVal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.detail.attrsVal",
                                                },
                                              })
                                            : _c(
                                                "el-button",
                                                {
                                                  staticClass: "button-new-tag",
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showInput(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("+ 添加")]
                                              ),
                                        ],
                                        2
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isBtn
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "规格：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入规格",
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "规格值：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入规格值",
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsVal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 12,
                                        lg: 6,
                                        md: 6,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { staticClass: "noLeft" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "mr15",
                                              attrs: { type: "primary" },
                                              on: { click: _vm.createAttrName },
                                            },
                                            [_vm._v("确定")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            { on: { click: _vm.offAttrName } },
                                            [_vm._v("取消")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isBtn
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.addBtn },
                                    },
                                    [_vm._v("添加新规格")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.spec_type === 1 &&
                  _vm.formValidate.attr.length > 0
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "批量设置：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.oneFormBatch,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  attrs: { title: "750*750px" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      772438934
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "140",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm.formThead[iii].title ===
                                                "商品编号"
                                                  ? _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "消费券兑换金额"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        precision: 4,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "消费券兑换赠送GP"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        precision: 2,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.extension_type === 1
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1308693019
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1308693019
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        align: "center",
                                        label: "操作",
                                        "min-width": "80",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "submission",
                                            attrs: { type: "text" },
                                            on: { click: _vm.batchAdd },
                                          },
                                          [_vm._v("批量添加")]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                    [
                      _vm.formValidate.spec_type === 0
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.OneattrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.formValidate.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1357914119
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "140",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm.formThead[iii].title ===
                                                "商品编号"
                                                  ? _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "消费券兑换金额"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        precision: 4,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "消费券兑换赠送GP"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        precision: 2,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.extension_type === 1
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1308693019
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_two,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_two",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_two",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            899977843
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.spec_type === 1 &&
                      _vm.formValidate.attr.length > 0
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "规格列表：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  attrs: { title: "750*750px" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1344940579
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "140",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm.formThead[iii].title ===
                                                "商品编号"
                                                  ? _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "消费券兑换金额"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        precision: 4,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ===
                                                    "消费券兑换赠送GP"
                                                  ? _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        precision: 2,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.extension_type === 1
                                    ? [
                                        _c("el-table-column", {
                                          key: "1",
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1308693019
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          key: "2",
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input-number", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        min: 0,
                                                        "controls-position":
                                                          "right",
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_two,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_two",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_two",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            899977843
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    key: "3",
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "submission",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delAttrTable(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2803824461
                                    ),
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab < 3,
                          expression: "currentTab < 3",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 3 || _vm.$route.params.id,
                          expression: "currentTab === 3 || $route.params.id",
                        },
                      ],
                      staticClass: "submission",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("guarantee-service", {
        ref: "serviceGuarantee",
        on: { "get-list": _vm.getGuaranteeList },
      }),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: { "preview-key": _vm.previewKey },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }