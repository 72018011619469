var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "title-box",
      class: _vm.bgStyle === 0 ? "" : "titleOn",
      style: [
        { textAlign: _vm.txtPosition },
        { fontStyle: _vm.txtStyle != "bold" ? _vm.txtStyle : "" },
        { fontWeight: _vm.txtStyle == "bold" ? _vm.txtStyle : "" },
        { fontSize: _vm.fontSize + "px" },
        { marginTop: _vm.mTOP + "px" },
        { background: _vm.titleColor },
        { margin: "0 " + _vm.prConfig + "px" },
        { color: _vm.themeColor },
      ],
    },
    [_vm._v("\n  " + _vm._s(_vm.titleTxt) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }