"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
var _settings = require("@/settings");
var _index = _interopRequireDefault(require("@/components/cards/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Record',
  components: {
    cardsData: _index.default
  },
  data: function data() {
    return {
      loading: false,
      roterPre: _settings.roterPre,
      timeVal: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 10,
        date: '',
        type: '1'
      },
      ruleForm: {
        status: '0'
      },
      headeNum: [{
        type: 1,
        title: '日账单'
      }, {
        type: 2,
        title: '月账单'
      }],
      dialogVisible: false,
      rules: {
        status: [{
          required: true,
          message: '请选择对账状态',
          trigger: 'change'
        }]
      },
      reconciliationId: 0,
      cardLists: [],
      accountDetails: {
        date: '',
        charge: {},
        expend: {},
        income: {}
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    this.getList('');
    this.getHeaderData();
  },
  methods: {
    onDetails: function onDetails(date) {
      var _this = this;
      (0, _accounts.financialDetailApi)(this.tableForm.type, {
        date: date
      }).then(function (res) {
        _this.dialogVisible = true;
        _this.accountDetails = res.data;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    getHeaderData: function getHeaderData() {
      var _this2 = this;
      (0, _accounts.finaHeaderDataApi)({
        date: this.tableForm.date
      }).then(function (res) {
        _this2.cardLists = res.data.stat;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 下载账单
    downloadAccounts: function downloadAccounts(time) {
      var _this3 = this;
      (0, _accounts.downloadFinancialApi)(this.tableForm.type, {
        date: time
      }).then(function (res) {
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {
          _this3.$router.push("".concat(_settings.roterPre, "/export/list"));
        });
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableForm.date = this.timeVal ? this.timeVal.join('-') : '';
      this.getList('');
      this.getHeaderData();
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableForm.page = num || this.tableForm.page;
      (0, _accounts.financialLstApi)(this.tableForm).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.chkName = '';
      this.getList('');
    }
  }
};