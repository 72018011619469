var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { attrs: { bordered: false, "dis-hover": "" } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.onChangeType },
              model: {
                value: _vm.tableFrom.type,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "type", $$v)
                },
                expression: "tableFrom.type",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "商品采集", name: "copy" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "电子面单打印", name: "mer_dump" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "record_count", attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.getPurchase },
                    },
                    [_vm._v("购买记录")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticClass: "table",
                  attrs: {
                    data: _vm.tableList,
                    loading: _vm.loading,
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    key: "7",
                    attrs: { label: "序号", "min-width": "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.$index +
                                    (_vm.tableFrom.page - 1) *
                                      _vm.tableFrom.limit +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.tableFrom.type == "mer_dump"
                    ? _c("el-table-column", {
                        key: "1",
                        attrs: {
                          prop: "info.order_sn",
                          label: "订单号",
                          "min-width": "200",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tableFrom.type == "mer_dump"
                    ? _c("el-table-column", {
                        key: "2",
                        attrs: {
                          prop: "info.from_name",
                          label: "发货人",
                          "min-width": "90",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tableFrom.type == "mer_dump"
                    ? _c("el-table-column", {
                        attrs: { label: "收货人", "min-width": "90" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.info && scope.row.info.to_name
                                          ? scope.row.info.to_name
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1225019533
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tableFrom.type == "mer_dump"
                    ? _c("el-table-column", {
                        key: "3",
                        attrs: {
                          prop: "info.delivery_id",
                          label: "快递单号",
                          "min-width": "90",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tableFrom.type == "mer_dump"
                    ? _c("el-table-column", {
                        key: "4",
                        attrs: {
                          prop: "info.delivery_name",
                          label: "快递公司编码",
                          "min-width": "90",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tableFrom.type == "copy"
                    ? _c("el-table-column", {
                        key: "6",
                        attrs: {
                          prop: "info",
                          label: "复制URL",
                          "min-width": "200",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "8",
                    attrs: {
                      prop: "create_time",
                      label:
                        _vm.tableFrom.type != "copy" ? "打印时间" : "添加时间",
                      "min-width": "90",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title:
                  _vm.tableFrom.type == "copy"
                    ? "商品采集购买记录"
                    : "电子面单购买记录",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  staticClass: "mt25",
                  attrs: { data: _vm.tableData, loading: _vm.loading },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", "min-width": "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.$index +
                                      (_vm.purchaseForm.page - 1) *
                                        _vm.purchaseForm.limit +
                                      1
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      81729880
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "order_sn",
                      label: "订单号",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "购买记录", "min-width": "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.order_info
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.order_info.price) +
                                        "元 / " +
                                        _vm._s(scope.row.order_info.num) +
                                        "次"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3496719446
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "购买时间",
                      "min-width": "90",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.purchaseForm.limit,
                      "current-page": _vm.purchaseForm.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChangeOther,
                      "current-change": _vm.pageChangeOther,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }