var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    this.$route.query.type !== 2
      ? _c(
          "div",
          { staticClass: "c_row-item" },
          [
            _c("el-col", { staticClass: "label", attrs: { span: 4 } }, [
              _vm._v(" 模板名称 "),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { staticClass: "slider-box", attrs: { span: 19 } },
              [
                _c("el-input", {
                  attrs: { placeholder: "选填不超过15个字", maxlength: "15" },
                  on: { change: _vm.changName },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c_row-item" },
      [
        _c("el-col", { staticClass: "label", attrs: { span: 4 } }, [
          _vm._v(" 页面标题 "),
        ]),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "slider-box", attrs: { span: 19 } },
          [
            _c("el-input", {
              attrs: { placeholder: "选填不超过30个字", maxlength: "30" },
              on: { change: _vm.changVal },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.modalPic,
              width: "950px",
              title: "上传背景图",
            },
            on: {
              "update:visible": function ($event) {
                _vm.modalPic = $event
              },
            },
          },
          [
            _vm.modalPic
              ? _c("uploadPictures", {
                  attrs: {
                    "is-choice": _vm.isChoice,
                    "grid-btn": _vm.gridBtn,
                    "grid-pic": _vm.gridPic,
                  },
                  on: { getPic: _vm.getPic },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }