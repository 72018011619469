"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategoryApi = addCategoryApi;
exports.addLinkApi = addLinkApi;
exports.categoryList = categoryList;
exports.categoryListApi = categoryListApi;
exports.createCategoryApi = createCategoryApi;
exports.deleteLinkApi = deleteLinkApi;
exports.diyCopy = diyCopy;
exports.diyDel = diyDel;
exports.diyGetInfo = diyGetInfo;
exports.diyList = diyList;
exports.diySave = diySave;
exports.editLinkApi = editLinkApi;
exports.getByCategory = getByCategory;
exports.getCategory = getCategory;
exports.getGroomList = getGroomList;
exports.getMember = getMember;
exports.getPageUrl = getPageUrl;
exports.getProduct = getProduct;
exports.getRoutineCode = getRoutineCode;
exports.getUrl = getUrl;
exports.linkListApi = linkListApi;
exports.memberSave = memberSave;
exports.pageCategory = pageCategory;
exports.pageLink = pageLink;
exports.recovery = recovery;
exports.saveLink = saveLink;
exports.setDefault = setDefault;
exports.setStatus = setStatus;
exports.storeStatus = storeStatus;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 商品分类 -- 列表
 */
function getCategory() {
  return _request.default.get("store/category/list");
}
/**
 * @description 商品列表 -- 列表
 */
function getProduct(data) {
  return _request.default.get("diy/product/lst", data);
}
/**
 * @description 首页diy -- 保存
 */
function diySave(id, data) {
  return _request.default.post("diy/create/".concat(id), data);
}
/**
 * @description 店铺装修 -- 列表
 */
function diyList(data) {
  return _request.default.get("diy/lst", data);
}
/**
 * @description 获取diy数据
 */
function diyGetInfo(id) {
  return _request.default.get("diy/detail/".concat(id));
}
/**
 * @description diy列表数据 -- 删除
 */
function diyDel(id, data) {
  return _request.default.delete("diy/delete/".concat(id), data);
}
/**
 * @description diy列表数据 -- 使用diy模板
 */
function setStatus(id) {
  return _request.default.post("diy/status/".concat(id));
}
/**
 * @description diy -- 恢复模板初始数据
 */
function recovery(id) {
  return _request.default.get("diy/recovery/".concat(id));
}
/**
 * @description diy -- 设置初始数据
 */
function setDefault(id) {
  return _request.default.post("diy/set_default_data/".concat(id));
}
/**
 * @description 获取分类
 */
function categoryList() {
  return _request.default.get('/cms/category_list');
}
/**
 * @description 获取链接列表
 */
function getUrl(data) {
  return _request.default.get('diy/link/lst', data);
}
/**
 * @description 获取产品一或二级分类
 */
function getByCategory(data) {
  return _request.default.get('diy/get_by_category', data);
}
/**
 * @description 使用diy模板(判断是否显示周边门店列表)
 */
function storeStatus() {
  return _request.default.get('diy/get_store_status');
}
/**
 * @description 使用diy模板(活动商品)
 */
function getGroomList(type, data) {
  return _request.default.get("diy/groom_list/".concat(type), data);
}
/**
 * @description 小程序 -- 二维码；
 */
function getRoutineCode(id) {
  return _request.default.get("diy/get_routine_code/".concat(id));
}
/**
 * @description 个人中心-获取信息；
 */
function getMember() {
  return _request.default.get("diy/get_member");
}
/**
 * @description 个人中心-提交信息；
 */
function memberSave(data) {
  return _request.default.post("diy/member_save", data);
}
/**
 * @description 页面链接-获取分类；
 */
function pageCategory() {
  return _request.default.get("diy/categroy/options");
}
/**
 * @description 页面链接-获取链接；
 */
function pageLink(id, data) {
  return _request.default.get("diy/link/getLinks/".concat(id), data);
}
/**
 * @description 页面链接-自定义链接提交；
 */
function saveLink(data, id) {
  return _request.default.post("diy/save_link/".concat(id), data);
}
/**
 * @description 页面链接-获取链接；
 */
function getPageUrl() {
  return _request.default.get("diy/show");
}
/**
 * @description diy-添加分类
 */
function addCategoryApi() {
  return _request.default.get("diy/categroy/form");
}
/**
 * @description diy-提交分类
 */
function createCategoryApi() {
  return _request.default.post("diy/categroy/create");
}
/**
 * @description diy-分类列表
 */
function categoryListApi(data) {
  return _request.default.get('diy/categroy/lst', data);
}
/**
 * @description diy-添加链接
 */
function addLinkApi() {
  return _request.default.get("diy/link/form");
}
/**
 * @description diy-编辑链接
 */
function editLinkApi(id) {
  return _request.default.get("diy/link/".concat(id, "/form"));
}
/**
 * @description diy-删除链接
 */
function deleteLinkApi(id) {
  return _request.default.delete("diy/link/delete/".concat(id));
}
/**
 * @description diy-链接列表
 */
function linkListApi(data) {
  return _request.default.get('diy/link/lst', data);
}
/**
 * @description 可视化-商城首页复制
 */
function diyCopy(id) {
  return _request.default.get("diy/copy/".concat(id));
}