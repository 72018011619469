"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductPlusApi = ProductPlusApi;
exports.customerApi = customerApi;
exports.customerRatioApi = customerRatioApi;
exports.mainDataApi = mainDataApi;
exports.orderApi = orderApi;
exports.rankingApi = rankingApi;
exports.visitorRankingApi = visitorRankingApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 首页 -- 主要数据*/
function mainDataApi() {
  return _request.default.get('statistics/main');
}
/**
 * @description 首页 -- 支付订单
 */
function orderApi(data) {
  return _request.default.get('statistics/order', data);
}
/**
 * @description 首页 -- 成交客户
 */
function customerApi(data) {
  return _request.default.get('statistics/user', data);
}
/**
 * @description 首页 -- 成交客户比例
 */
function customerRatioApi(data) {
  return _request.default.get('statistics/user_rate', data);
}
/**
 * @description 首页 -- 商品支付排行
 */
function rankingApi(data) {
  return _request.default.get('statistics/product', data);
}
/**
 * @description 首页 -- 商品访客排行
 */
function visitorRankingApi(data) {
  return _request.default.get('statistics/product_visit', data);
}
/**
 * @description 首页 -- 商品加购排行
 */
function ProductPlusApi(data) {
  return _request.default.get('statistics/product_cart', data);
}