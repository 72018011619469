"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assistCreateProApi = assistCreateProApi;
exports.assistDeleteApi = assistDeleteApi;
exports.assistDetailApi = assistDetailApi;
exports.assistListApi = assistListApi;
exports.assistPreviewApi = assistPreviewApi;
exports.assistProDetailApi = assistProDetailApi;
exports.assistProListApi = assistProListApi;
exports.assistStatusApi = assistStatusApi;
exports.assistUpdateProApi = assistUpdateProApi;
exports.attrCreatApi = attrCreatApi;
exports.attrDeleteApi = attrDeleteApi;
exports.attrEdittApi = attrEdittApi;
exports.batchesLabelsApi = batchesLabelsApi;
exports.batchesOnOffApi = batchesOnOffApi;
exports.batchesTempApi = batchesTempApi;
exports.categoryBrandListApi = categoryBrandListApi;
exports.categoryListApi = categoryListApi;
exports.categorySelectApi = categorySelectApi;
exports.crawlFromApi = crawlFromApi;
exports.destoryApi = destoryApi;
exports.freeTrialApi = freeTrialApi;
exports.getProductLabelApi = getProductLabelApi;
exports.goodLstApi = goodLstApi;
exports.groupPreviewApi = groupPreviewApi;
exports.guaranteeAddApi = guaranteeAddApi;
exports.guaranteeDeleteApi = guaranteeDeleteApi;
exports.guaranteeDetailApi = guaranteeDetailApi;
exports.guaranteeListApi = guaranteeListApi;
exports.guaranteeLstApi = guaranteeLstApi;
exports.guaranteeSelectApi = guaranteeSelectApi;
exports.guaranteeSortApi = guaranteeSortApi;
exports.guaranteeStatusApi = guaranteeStatusApi;
exports.guaranteeUpdateApi = guaranteeUpdateApi;
exports.labelCreateApi = labelCreateApi;
exports.labelDeleteApi = labelDeleteApi;
exports.labelListApi = labelListApi;
exports.labelStatusApi = labelStatusApi;
exports.labelUpdateApi = labelUpdateApi;
exports.lstFilterApi = lstFilterApi;
exports.preSaleCreateProApi = preSaleCreateProApi;
exports.preSaleProDetailApi = preSaleProDetailApi;
exports.preSaleProListApi = preSaleProListApi;
exports.preSaleProUnmApi = preSaleProUnmApi;
exports.preSaleUpdateProApi = preSaleUpdateProApi;
exports.preSellDeleteApi = preSellDeleteApi;
exports.presellPreviewApi = presellPreviewApi;
exports.presellStatusApi = presellStatusApi;
exports.productConfigApi = productConfigApi;
exports.productCopyApi = productCopyApi;
exports.productCopyCountApi = productCopyCountApi;
exports.productCopyRecordApi = productCopyRecordApi;
exports.productCreateApi = productCreateApi;
exports.productDeleteApi = productDeleteApi;
exports.productDetailApi = productDetailApi;
exports.productGetTempKeysApi = productGetTempKeysApi;
exports.productLstApi = productLstApi;
exports.productPreviewApi = productPreviewApi;
exports.productSort = productSort;
exports.productUpdateApi = productUpdateApi;
exports.restoreApi = restoreApi;
exports.reviewLstApi = reviewLstApi;
exports.reviewReplyApi = reviewReplyApi;
exports.reviewsSort = reviewsSort;
exports.seckillPreviewApi = seckillPreviewApi;
exports.seckillProTimeApi = seckillProTimeApi;
exports.seckillProductCreateApi = seckillProductCreateApi;
exports.seckillProductDetailApi = seckillProductDetailApi;
exports.seckillProductLstApi = seckillProductLstApi;
exports.seckillProductUpdateApi = seckillProductUpdateApi;
exports.shippingListApi = shippingListApi;
exports.spikeDestoryApi = spikeDestoryApi;
exports.spikeProductDeleteApi = spikeProductDeleteApi;
exports.spikeRestoreApi = spikeRestoreApi;
exports.spikeStatusApi = spikeStatusApi;
exports.spikelstFilterApi = spikelstFilterApi;
exports.statusApi = statusApi;
exports.storeCategoryCreateApi = storeCategoryCreateApi;
exports.storeCategoryDeleteApi = storeCategoryDeleteApi;
exports.storeCategoryListApi = storeCategoryListApi;
exports.storeCategoryStatusApi = storeCategoryStatusApi;
exports.storeCategoryUpdateApi = storeCategoryUpdateApi;
exports.templateListApi = templateListApi;
exports.templateLsitApi = templateLsitApi;
exports.updatetAssistLabel = updatetAssistLabel;
exports.updatetCombinationLabel = updatetCombinationLabel;
exports.updatetPresellLabel = updatetPresellLabel;
exports.updatetProductLabel = updatetProductLabel;
exports.updatetSeckillLabel = updatetSeckillLabel;
exports.uploadVideoOfLocal = uploadVideoOfLocal;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 商品分类 -- 列表
 */
function storeCategoryListApi() {
  return _request.default.get('store/category/lst');
}
/**
 * @description 商品分类 -- 新增表单
 */
function storeCategoryCreateApi() {
  return _request.default.get('store/category/create/form');
}
/**
 * @description 商品分类 -- 编辑表单
 */
function storeCategoryUpdateApi(id) {
  return _request.default.get("store/category/update/form/".concat(id));
}
/**
 * @description 商品分类 -- 删除
 */
function storeCategoryDeleteApi(id) {
  return _request.default.delete("store/category/delete/".concat(id));
}
/**
 * @description 商品分类 -- 修改状态
 */
function storeCategoryStatusApi(id, status) {
  return _request.default.post("store/category/status/".concat(id), {
    status: status
  });
}
/**
 * @description 属性规则 -- 列表
 */
function templateListApi(data) {
  return _request.default.get('store/attr/template/lst', data);
}
/**
 * @description 属性规则 -- 添加
 */
function attrCreatApi(data) {
  return _request.default.post('store/attr/template/create', data);
}
/**
 * @description 属性规则 -- 编辑
 */
function attrEdittApi(id, data) {
  return _request.default.post("store/attr/template/".concat(id), data);
}
/**
 * @description 属性规则 -- 删除
 */
function attrDeleteApi(id) {
  return _request.default.delete("store/attr/template/".concat(id));
}
/**
 * @description 商品添加 -- 属性规则
 */
function templateLsitApi() {
  return _request.default.get("/store/attr/template/list");
}
/**
 * @description 商品列表 -- 列表
 */
function productLstApi(data) {
  return _request.default.get("store/product/lst", data);
}
/**
 * @description 商品列表 -- 删除
 */
function productDeleteApi(id) {
  return _request.default.delete("store/product/delete/".concat(id));
}
/**
 * @description 秒杀商品列表 -- 删除
 */
function spikeProductDeleteApi(id) {
  return _request.default.delete("store/seckill_product/delete/".concat(id));
}
/**
 * @description 商品列表 -- 添加
 */
function productCreateApi(data) {
  return _request.default.post("store/product/create", data);
}
/**
 * @description 商品列表 -- 预览
 */
function productPreviewApi(data) {
  return _request.default.post("store/product/preview", data);
}
/**
 * @description 商品列表 -- 复制
 */
function productCopyApi(data) {
  return _request.default.post("store/productcopy/save", data);
}
/**
 * @description 秒杀品列表 -- 添加
 */
function seckillProductCreateApi(data) {
  return _request.default.post("store/seckill_product/create", data);
}
/**
 * @description 秒杀 -- 预览
 */
function seckillPreviewApi(data) {
  return _request.default.post("store/seckill_product/preview", data);
}
/**
 * @description 商品列表 -- 编辑
 */
function productUpdateApi(id, data) {
  return _request.default.post("store/product/update/".concat(id), data);
}
/**
 * @description 商品列表 -- 详情
 */
function productDetailApi(id) {
  return _request.default.get("store/product/detail/".concat(id));
}
/**
 * @description 秒杀商品 -- 详情
 */
function seckillProductDetailApi(id) {
  return _request.default.get("store/seckill_product/detail/".concat(id));
}
/**
 * @description 商品列表 -- 商户分类
 */
function categorySelectApi() {
  return _request.default.get("store/category/select");
}

/**
 * @description 商品列表 -- 平台分类
 */
function categoryListApi() {
  return _request.default.get("store/category/list");
}
/**
 * @description 商品列表 -- 品牌分类
 */
function categoryBrandListApi() {
  return _request.default.get("store/category/brandlist");
}
/**
 * @description 商品列表 -- 运费模板筛选
 */
function shippingListApi() {
  return _request.default.get("store/shipping/list");
}
/**
 * @description 商品列表 -- 列表表头
 */
function lstFilterApi() {
  return _request.default.get("store/product/lst_filter");
}
/**
 * @description 秒杀商品列表 -- 列表表头
 */
function spikelstFilterApi() {
  return _request.default.get("store/seckill_product/lst_filter");
}
/**
 * @description 商品列表 -- 上下架
 */
function statusApi(id, status) {
  return _request.default.post("store/product/status/".concat(id), {
    status: status
  });
}
/**
 * @description 秒杀商品列表 -- 上下架
 */
function spikeStatusApi(id, status) {
  return _request.default.post("store/seckill_product/status/".concat(id), {
    status: status
  });
}
/**
 * @description 组件商品列表 -- 列表
 */
function goodLstApi(data) {
  return _request.default.get("store/product/list", data);
}
/**
 * @description 配置状态
 */
function productConfigApi() {
  return _request.default.get("store/product/config");
}
/**
 * @description 商品列表 -- 评价列表
 */
function reviewLstApi(data) {
  return _request.default.get("store/reply/lst", data);
}
/**
 * @description 商品列表 -- 评价回复
 */
function reviewReplyApi(id) {
  return _request.default.get("store/reply/form/".concat(id));
}
/**
 * @description 商品列表 -- 评价回复
 */
function destoryApi(id) {
  return _request.default.delete("store/product/destory/".concat(id));
}
/**
 * @description 秒杀商品列表 -- 加入回收站
 */
function spikeDestoryApi(id) {
  return _request.default.delete("store/seckill_product/destory/".concat(id));
}
/**
 * @description 商品列表 -- 恢复
 */
function restoreApi(id) {
  return _request.default.post("store/product/restore/".concat(id));
}
/**
 * @description 秒杀商品列表 -- 恢复
 */
function spikeRestoreApi(id) {
  return _request.default.post("store/seckill_product/restore/".concat(id));
}
/**
 * @description 商品列表 -- 复制商品
 */
function crawlFromApi(data) {
  return _request.default.get("store/productcopy/get", data);
}
/**
 * @description 秒杀商品列表 -- 列表
 */
function seckillProductLstApi(data) {
  return _request.default.get("store/seckill_product/lst", data);
}
/**
 * @description 秒杀商品 -- 可选时间表
 */
function seckillProTimeApi() {
  return _request.default.get("store/seckill_product/lst_time");
}
/**
 * @description 秒杀商品列表 -- 编辑
 */
function seckillProductUpdateApi(id, data) {
  return _request.default.post("store/seckill_product/update/".concat(id), data);
}
/**
 * @description 复制商品 -- 剩余次数
 */
function productCopyCountApi() {
  return _request.default.get("store/productcopy/count");
}
/**
 * @description 复制商品 -- 复制记录
 */
function productCopyRecordApi(data) {
  return _request.default.get("store/productcopy/lst", data);
}
/**
 * @description 预售 -- 添加预售商品
 */
function preSaleCreateProApi(data) {
  return _request.default.post("store/product/presell/create", data);
}
/**
 * @description 预售 -- 编辑预售商品
 */
function preSaleUpdateProApi(id, data) {
  return _request.default.post("store/product/presell/update/".concat(id), data);
}
/**
 * @description 预售 -- 列表
 */
function preSaleProListApi(data) {
  return _request.default.get("store/product/presell/lst", data);
}
/**
 * @description 预售商品 -- 详情
 */
function preSaleProDetailApi(id) {
  return _request.default.get("store/product/presell/detail/".concat(id));
}
/**
 * @description 预售商品 -- 头部数量
 */
function preSaleProUnmApi() {
  return _request.default.get("store/product/presell/number");
}
/**
 * @description 预售商品列表 -- 显示状态（上下架）
 */
function presellStatusApi(id, status) {
  return _request.default.post("store/product/presell/status/".concat(id), {
    status: status
  });
}
/**
 * @description 预售商品列表 -- 删除
 */
function preSellDeleteApi(id) {
  return _request.default.delete("store/product/presell/delete/".concat(id));
}
/**
 * @description 预售 -- 预览
 */
function presellPreviewApi(data) {
  return _request.default.post("store/product/presell/preview", data);
}
/**
 * @description 拼团 -- 预览
 */
function groupPreviewApi(data) {
  return _request.default.post("store/product/group/preview", data);
}
/**
 * @description 助力 -- 添加助力商品
 */
function assistCreateProApi(data) {
  return _request.default.post("store/product/assist/create", data);
}
/**
 * @description 助力 -- 编辑助力商品
 */
function assistUpdateProApi(id, data) {
  return _request.default.post("store/product/assist/update/".concat(id), data);
}
/**
 * @description 助力 -- 列表
 */
function assistProListApi(data) {
  return _request.default.get("store/product/assist/lst", data);
}
/**
 * @description 助力商品 -- 详情
 */
function assistProDetailApi(id) {
  return _request.default.get("store/product/assist/detail/".concat(id));
}
/**
 * @description 助力 -- 预览
 */
function assistPreviewApi(data) {
  return _request.default.post("store/product/assist/preview", data);
}
/**
 * @description 助力商品列表 -- 删除
 */
function assistDeleteApi(id) {
  return _request.default.delete("store/product/assist/delete/".concat(id));
}
/**
 * @description 助力活动 -- 列表
 */
function assistListApi(data) {
  return _request.default.get("store/product/assist_set/lst", data);
}
/**
 * @description 助力商品列表 -- 显示状态（上下架）
 */
function assistStatusApi(id, status) {
  return _request.default.post("store/product/assist/status/".concat(id), {
    status: status
  });
}
/**
 * @description 助力活动列表 -- 查看详情
 */
function assistDetailApi(id, data) {
  return _request.default.get("store/product/assist_set/detail/".concat(id), data);
}
/**
 * @description 助力活动列表 -- 查看详情
 */
function productGetTempKeysApi() {
  return _request.default.get("store/product/temp_key");
}
/**
 * @description 普通商品 -- 列表排序
 */
function productSort(id, data) {
  return _request.default.post("/store/product/sort/".concat(id), data);
}

/**
 * @description 评论 -- 列表排序
 */
function reviewsSort(id, data) {
  return _request.default.post("/store/reply/sort/".concat(id), data);
}
/**
 * @description 服务保障 -- 添加
 */
function guaranteeAddApi(data) {
  return _request.default.post("guarantee/create", data);
}
/**
 * @description 服务保障 -- 列表
 */
function guaranteeLstApi(data) {
  return _request.default.get("guarantee/lst", data);
}
/**
 * @description 服务保障 -- 编辑排序
 */
function guaranteeSortApi(id, data) {
  return _request.default.post("guarantee/sort/".concat(id), data);
}
/**
 * @description 服务保障 -- 编辑
 */
function guaranteeUpdateApi(id, data) {
  return _request.default.post("guarantee/update/".concat(id), data);
}
/**
 * @description 服务保障 -- 详情
 */
function guaranteeDetailApi(id) {
  return _request.default.get("guarantee/detail/".concat(id));
}
/**
 * @description 服务保障 -- 删除
 */
function guaranteeDeleteApi(id) {
  return _request.default.delete("guarantee/delete/".concat(id));
}
/**
 * @description 服务保障 -- 获取平台条款
 */
function guaranteeSelectApi(data) {
  return _request.default.get("guarantee/select", data);
}
/**
 * @description 服务保障 -- 修改显示状态
 */
function guaranteeStatusApi(id, status) {
  return _request.default.post("guarantee/status/".concat(id), status);
}
/**
 * @description 添加商品 -- 获取服务保障模板列表
 */
function guaranteeListApi() {
  return _request.default.get("guarantee/list");
}

/*
  上传视频 local
*/
function uploadVideoOfLocal(data) {
  return _request.default.post('upload/video', data);
}
/**
 * @description 标签 -- 新增表单
 */
function labelCreateApi() {
  return _request.default.get('product/label/create/form');
}
/**
 * @description 标签 -- 编辑表单
 */
function labelUpdateApi(id) {
  return _request.default.get("product/label/update/".concat(id, "/form"));
}
/**
 * @description 标签 -- 列表
 */
function labelListApi(data) {
  return _request.default.get('product/label/lst', data);
}
/**
 * @description 标签 -- 删除
 */
function labelDeleteApi(id) {
  return _request.default.delete("product/label/delete/".concat(id));
}
/**
 * @description 标签列表 -- 修改状态
 */
function labelStatusApi(id, status) {
  return _request.default.post("product/label/status/".concat(id), {
    status: status
  });
}
/** 商品列表 -- 获取标签项 */
function getProductLabelApi() {
  return _request.default.get("product/label/option");
}
/** 商品列表 -- 编辑标签 */
function updatetProductLabel(id, data) {
  return _request.default.post("store/product/labels/".concat(id), data);
}
/** 秒杀列表 -- 编辑标签 */
function updatetSeckillLabel(id, data) {
  return _request.default.post("store/seckill_product/labels/".concat(id), data);
}
/** 预售列表 -- 编辑标签 */
function updatetPresellLabel(id, data) {
  return _request.default.post("store/product/presell/labels/".concat(id), data);
}
/** 助力列表 -- 编辑标签 */
function updatetAssistLabel(id, data) {
  return _request.default.post("store/product/assist/labels/".concat(id), data);
}
/** 拼团列表 -- 编辑标签 */
function updatetCombinationLabel(id, data) {
  return _request.default.post("store/product/group/labels/".concat(id), data);
}
/** 商品列表 -- 免审核编辑 */
function freeTrialApi(id, data) {
  return _request.default.post("store/product/free_trial/".concat(id), data);
}
/** 商品列表 -- 批量上下架 */
function batchesOnOffApi(data) {
  return _request.default.post("store/product/batch_status", data);
}
/** 商品列表 -- 批量设置标签 */
function batchesLabelsApi(data) {
  return _request.default.post("store/product/batch_labels", data);
}
/** 商品列表 -- 批量设置运费模板 */
function batchesTempApi(data) {
  return _request.default.post("store/product/batch_temp", data);
}