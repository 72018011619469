"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _goodsList = _interopRequireDefault(require("@/components/goodsList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_goods',
  components: {
    goodsList: _goodsList.default,
    draggable: _vuedraggable.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      modals: false,
      goodsList: [],
      tempGoods: {},
      defaults: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
  },
  methods: {
    // 对象数组去重；
    unique: function unique(arr) {
      var res = new Map();
      return arr.filter(function (arr) {
        return !res.has(arr.product_id) && res.set(arr.product_id, 1);
      });
    },
    getProductId: function getProductId(data) {
      this.modals = false;
      var list = this.defaults.goodsList.list.concat(data);
      this.defaults.goodsList.list = this.unique(list);
      this.defaults.goodsList.ids = this.defaults.goodsList.list.map(function (item) {
        return item.product_id;
      });
    },
    cancel: function cancel() {
      this.modals = false;
      // this.tempGoods = {}
    },
    ok: function ok() {
      this.defaults.goodsList.list.push(this.tempGoods);
    },
    bindDelete: function bindDelete(index) {
      this.defaults.goodsList.list.splice(index, 1);
    }
  }
};