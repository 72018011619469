"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _ase = require("./../utils/ase");
var _util = require("./../utils/util");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
 * VerifySlide
 * @description 滑块
 * */
//  "captchaType":"blockPuzzle",
var _default2 = exports.default = {
  name: 'VerifySlide',
  props: {
    captchaType: {
      type: String,
      default: 'blockPuzzle'
    },
    type: {
      type: String,
      default: '1'
    },
    // 弹出式pop，固定fixed
    mode: {
      type: String,
      default: 'fixed'
    },
    vSpace: {
      type: Number,
      default: 5
    },
    explain: {
      type: String,
      default: '向右滑动完成验证'
    },
    imgSize: {
      type: Object,
      default: function _default() {
        return {
          width: '310px',
          height: '155px'
        };
      }
    },
    blockSize: {
      type: Object,
      default: function _default() {
        return {
          width: '50px',
          height: '50px'
        };
      }
    },
    barSize: {
      type: Object,
      default: function _default() {
        return {
          width: '310px',
          height: '40px'
        };
      }
    },
    defaultImg: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      secretKey: '',
      // 后端返回的加密秘钥 字段
      passFlag: '',
      // 是否通过的标识
      backImgBase: '',
      // 验证码背景图片
      blockBackImgBase: '',
      // 验证滑块的背景图片
      backToken: '',
      // 后端返回的唯一token值
      startMoveTime: '',
      // 移动开始的时间
      endMovetime: '',
      // 移动结束的时间
      tipsBackColor: '',
      // 提示词的背景颜色
      tipWords: '',
      text: '',
      finishText: '',
      setSize: {
        imgHeight: 0,
        imgWidth: 0,
        barHeight: 0,
        barWidth: 0
      },
      top: 0,
      left: 0,
      moveBlockLeft: undefined,
      leftBarWidth: undefined,
      // 移动中样式
      moveBlockBackgroundColor: undefined,
      leftBarBorderColor: '#ddd',
      iconColor: undefined,
      iconClass: 'icon-right',
      status: false,
      // 鼠标状态
      isEnd: false,
      // 是够验证完成
      showRefresh: true,
      transitionLeft: '',
      transitionWidth: ''
    };
  },
  computed: {
    barArea: function barArea() {
      return this.$el.querySelector('.verify-bar-area');
    },
    resetSize: function resetSize() {
      return _util.resetSize;
    }
  },
  watch: {
    // type变化则全面刷新
    type: {
      immediate: true,
      handler: function handler() {
        this.init();
      }
    }
  },
  mounted: function mounted() {
    // 禁止拖拽
    this.$el.onselectstart = function () {
      return false;
    };
    console.log(this.defaultImg);
  },
  methods: {
    init: function init() {
      var _this2 = this;
      this.text = this.explain;
      this.getPictrue();
      this.$nextTick(function () {
        var setSize = _this2.resetSize(_this2); // 重新设置宽度高度
        for (var key in setSize) {
          _this2.$set(_this2.setSize, key, setSize[key]);
        }
        _this2.$parent.$emit('ready', _this2);
      });
      var _this = this;
      window.removeEventListener('touchmove', function (e) {
        _this.move(e);
      });
      window.removeEventListener('mousemove', function (e) {
        _this.move(e);
      });

      // 鼠标松开
      window.removeEventListener('touchend', function () {
        _this.end();
      });
      window.removeEventListener('mouseup', function () {
        _this.end();
      });
      window.addEventListener('touchmove', function (e) {
        _this.move(e);
      });
      window.addEventListener('mousemove', function (e) {
        _this.move(e);
      });

      // 鼠标松开
      window.addEventListener('touchend', function () {
        _this.end();
      });
      window.addEventListener('mouseup', function () {
        _this.end();
      });
    },
    // 鼠标按下
    start: function start(e) {
      e = e || window.event;
      var x;
      if (!e.touches) {
        // 兼容PC端
        x = e.clientX;
      } else {
        // 兼容移动端
        x = e.touches[0].pageX;
      }
      this.startLeft = Math.floor(x - this.barArea.getBoundingClientRect().left);
      this.startMoveTime = +new Date(); // 开始滑动的时间
      if (this.isEnd === false) {
        this.text = '';
        this.moveBlockBackgroundColor = '#337ab7';
        this.leftBarBorderColor = '#337AB7';
        this.iconColor = '#fff';
        e.stopPropagation();
        this.status = true;
      }
    },
    // 鼠标移动
    move: function move(e) {
      e = e || window.event;
      if (this.status && this.isEnd === false) {
        var x;
        if (!e.touches) {
          // 兼容PC端
          x = e.clientX;
        } else {
          // 兼容移动端
          x = e.touches[0].pageX;
        }
        var bar_area_left = this.barArea.getBoundingClientRect().left;
        var move_block_left = x - bar_area_left; // 小方块相对于父元素的left值
        if (move_block_left >= this.barArea.offsetWidth - parseInt(parseInt(this.blockSize.width) / 2) - 2) {
          move_block_left = this.barArea.offsetWidth - parseInt(parseInt(this.blockSize.width) / 2) - 2;
        }
        if (move_block_left <= 0) {
          move_block_left = parseInt(parseInt(this.blockSize.width) / 2);
        }
        // 拖动后小方块的left值
        this.moveBlockLeft = move_block_left - this.startLeft + 'px';
        this.leftBarWidth = move_block_left - this.startLeft + 'px';
      }
    },
    // 鼠标松开
    end: function end() {
      var _this3 = this;
      this.endMovetime = +new Date();
      var _this = this;
      // 判断是否重合
      if (this.status && this.isEnd === false) {
        var moveLeftDistance = parseInt((this.moveBlockLeft || '').replace('px', ''));
        moveLeftDistance = moveLeftDistance * 310 / parseInt(this.setSize.imgWidth);
        var data = {
          captchaType: this.captchaType,
          pointJson: this.secretKey ? (0, _ase.aesEncrypt)(JSON.stringify({
            x: moveLeftDistance,
            y: 5.0
          }), this.secretKey) : JSON.stringify({
            x: moveLeftDistance,
            y: 5.0
          }),
          token: this.backToken
        };
        // this.$axios
        //   .post(`/api/ajcheck`, data)
        //   .then(res => {
        //     this.moveBlockBackgroundColor = "#5cb85c";
        //     this.leftBarBorderColor = "#5cb85c";
        //     this.iconColor = "#fff";
        //     this.iconClass = "icon-check";
        //     this.showRefresh = false;
        //     this.isEnd = true;
        //     if (this.mode == "pop") {
        //       setTimeout(() => {
        //         this.$parent.clickShow = false;
        //         this.refresh();
        //       }, 1500);
        //     }
        //     this.passFlag = true;
        //     this.tipWords = `${(
        //       (this.endMovetime - this.startMoveTime) /
        //       1000
        //     ).toFixed(2)}s验证成功`;
        //     var captchaVerification = this.secretKey
        //       ? aesEncrypt(
        //           this.backToken +
        //             "---" +
        //             JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
        //           this.secretKey
        //         )
        //       : this.backToken +
        //         "---" +
        //         JSON.stringify({ x: moveLeftDistance, y: 5.0 });
        //     setTimeout(() => {
        //       this.tipWords = "";
        //       this.$parent.closeBox();
        //       this.$parent.$emit("success", { captchaVerification });
        //     }, 1000);
        //   })
        //   .catch(err => {
        //     this.moveBlockBackgroundColor = "#d9534f";
        //     this.leftBarBorderColor = "#d9534f";
        //     this.iconColor = "#fff";
        //     this.iconClass = "icon-close";
        //     this.passFlag = false;
        //     setTimeout(function() {
        //       _this.refresh();
        //     }, 1000);
        //     this.$parent.$emit("error", this);
        //     this.tipWords = "验证失败";
        //     setTimeout(() => {
        //       this.tipWords = "";
        //     }, 1000);
        //   });
        (0, _system.ajCaptchaCheck)(data).then(function (res) {
          _this3.moveBlockBackgroundColor = '#5cb85c';
          _this3.leftBarBorderColor = '#5cb85c';
          _this3.iconColor = '#fff';
          _this3.iconClass = 'icon-check';
          _this3.showRefresh = false;
          _this3.isEnd = true;
          if (_this3.mode === 'pop') {
            setTimeout(function () {
              _this3.$parent.clickShow = false;
              _this3.refresh();
            }, 1500);
          }
          _this3.passFlag = true;
          _this3.tipWords = "".concat(((_this3.endMovetime - _this3.startMoveTime) / 1000).toFixed(2), "s\u9A8C\u8BC1\u6210\u529F");
          var captchaVerification = _this3.secretKey ? (0, _ase.aesEncrypt)(_this3.backToken + '---' + JSON.stringify({
            x: moveLeftDistance,
            y: 5.0
          }), _this3.secretKey) : _this3.backToken + '---' + JSON.stringify({
            x: moveLeftDistance,
            y: 5.0
          });
          setTimeout(function () {
            _this3.tipWords = '';
            _this3.$parent.closeBox();
            _this3.$parent.$emit('success', {
              captchaVerification: captchaVerification
            });
          }, 1000);
        }).catch(function () {
          _this3.moveBlockBackgroundColor = '#d9534f';
          _this3.leftBarBorderColor = '#d9534f';
          _this3.iconColor = '#fff';
          _this3.iconClass = 'icon-close';
          _this3.passFlag = false;
          setTimeout(function () {
            _this.refresh();
          }, 1000);
          _this3.$parent.$emit('error', _this3);
          _this3.tipWords = '验证失败';
          setTimeout(function () {
            _this3.tipWords = '';
          }, 1000);
        });
        this.status = false;
      }
    },
    refresh: function refresh() {
      var _this4 = this;
      this.showRefresh = true;
      this.finishText = '';
      this.transitionLeft = 'left .3s';
      this.moveBlockLeft = 0;
      this.leftBarWidth = undefined;
      this.transitionWidth = 'width .3s';
      this.leftBarBorderColor = '#ddd';
      this.moveBlockBackgroundColor = '#fff';
      this.iconColor = '#000';
      this.iconClass = 'icon-right';
      this.isEnd = false;
      this.getPictrue();
      setTimeout(function () {
        _this4.transitionWidth = '';
        _this4.transitionLeft = '';
        _this4.text = _this4.explain;
      }, 300);
    },
    // 请求背景图片和验证图片
    getPictrue: function getPictrue() {
      var _this5 = this;
      console.log('sssss');
      var data = {
        captchaType: this.captchaType,
        clientUid: localStorage.getItem('slider'),
        ts: Date.now() // 现在的时间戳
      };
      console.log(data);
      // this.$axios
      //   .get(`/api/ajcaptcha`, { params: data })
      //   .then(res => {
      //     this.backImgBase = res.data.originalImageBase64;
      //     this.blockBackImgBase = res.data.jigsawImageBase64;
      //     this.backToken = res.data.token;
      //     this.secretKey = res.data.secretKey;
      //   })
      //   .catch(err => {
      //     this.tipWords = err.msg;
      //     this.backImgBase = null;
      //     this.blockBackImgBase = null;
      //   });
      (0, _system.ajCaptcha)(data).then(function (res) {
        _this5.backImgBase = res.data.originalImageBase64;
        _this5.blockBackImgBase = res.data.jigsawImageBase64;
        _this5.backToken = res.data.token;
        _this5.secretKey = res.data.secretKey;
      }).catch(function (res) {
        _this5.tipWords = res.msg;
        _this5.backImgBase = null;
        _this5.blockBackImgBase = null;
      });
    }
  }
};