"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'IndexVue',
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      status: true,
      txtColor: '',
      activeTxtColor: '',
      bgColor: '',
      menuList: [],
      isSpecial: 2
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['pageFooter'])),
  watch: {
    pageFooter: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var data = this.$store.state.mobildConfig.pageFooter;
    this.setConfig(data);
  },
  methods: {
    setConfig: function setConfig(data) {
      this.status = data.status;
      this.txtColor = data.txtColor.color[0].item;
      this.activeTxtColor = data.activeTxtColor.color[0].item;
      this.bgColor = data.bgColor.color[0].item;
      this.menuList = [];
      this.$set(this, 'menuList', data.menuList);
      if (data.status.title === '是否显示') {
        this.isSpecial = 2;
      } else {
        this.isSpecial = 0;
      }
    }
  }
};