var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: { change: _vm.getList },
                              model: {
                                value: _vm.tableForm.status_tag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "status_tag", $$v)
                                },
                                expression: "tableForm.status_tag",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待审核"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("已审核"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("审核失败")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入直播商品名称/ID",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList($event)
                                },
                              },
                              model: {
                                value: _vm.tableForm.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "keyword", $$v)
                                },
                                expression: "tableForm.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.getList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path:
                            "" +
                            _vm.roterPre +
                            "/marketing/broadcast/addProduct",
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("添加直播商品")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "success" },
                      on: { click: _vm.batchAdd },
                    },
                    [_vm._v("批量添加直播商品")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.$index +
                                (_vm.tableForm.page - 1) * _vm.tableForm.limit +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "goods_id", label: "商品ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商品名称", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.cover_img,
                                "preview-src-list": [scope.row.cover_img],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "原价", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.product ? scope.row.product.price : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "直播价", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库存", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.product && scope.row.product.stock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.tableForm.status_tag !== 1
                ? _c("el-table-column", {
                    key: "3",
                    attrs: { label: "审核状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("liveReviewStatusFilter")(
                                      scope.row.status
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              scope.row.status === -1
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        "font-size": "12px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "原因 " + _vm._s(scope.row.error_msg)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2330978579
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否上架", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.status === 2
                          ? [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "上架",
                                  "inactive-text": "下架",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onchangeIsShow(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.is_mer_show,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "is_mer_show", $$v)
                                  },
                                  expression: "scope.row.is_mer_show",
                                },
                              }),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onProDetails(
                                  scope.row.broadcast_goods_id
                                )
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(
                                  scope.row.broadcast_goods_id
                                )
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.broadcast_goods_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", { ref: "ProDetail", on: { getList: _vm.getList } }),
      _vm._v(" "),
      _c("batch-add", { ref: "batchAdd", on: { "get-list": _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }