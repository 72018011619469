"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ICopyright',
  data: function data() {
    return {
      copyright: 'Copyright © 2022 西安众邦网络科技有限公司',
      openVersion: '0',
      copyright_status: '0',
      version: {}
    };
  },
  mounted: function mounted() {
    this.getVersion();
  },
  methods: {
    getVersion: function getVersion() {
      var _this = this;
      (0, _accounts.getVersion)().then(function (res) {
        _this.version = res.data;
        _this.copyright = res.data.copyright;
        _this.openVersion = res.data.sys_open_version;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    }
  }
};