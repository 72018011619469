var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: "",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "block" },
                          attrs: { label: "状态：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.status_tag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "status_tag", $$v)
                                },
                                expression: "tableForm.status_tag",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待审核"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("审核已通过"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("审核未通过")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商城显示状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.show_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "show_type", $$v)
                                },
                                expression: "tableForm.show_type",
                              },
                            },
                            _vm._l(_vm.studioShowStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.live_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "live_status", $$v)
                                },
                                expression: "tableForm.live_status",
                              },
                            },
                            _vm._l(_vm.studioStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder:
                                  "请输入直播间名称/ID/主播昵称/微信号",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "keyword", $$v)
                                },
                                expression: "tableForm.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path:
                            "" + _vm.roterPre + "/marketing/studio/creatStudio",
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("创建直播间")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.$index +
                                (_vm.tableForm.page - 1) * _vm.tableForm.limit +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "直播间名称",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "broadcast_room_id",
                  label: "直播间ID",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchor_name",
                  label: "主播昵称",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchor_wechat",
                  label: "主播微信号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "主播手机号",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "start_time",
                  "min-width": "150",
                  label: "直播开始时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "end_time",
                  "min-width": "150",
                  label: "直播计划结束时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "直播状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("broadcastStatusFilter")(
                                scope.row.live_status
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  "min-width": "150",
                  label: "创建时间",
                },
              }),
              _vm._v(" "),
              _vm.tableForm.status_tag !== -1
                ? _c("el-table-column", {
                    key: "5",
                    attrs: { label: "显示状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "显示",
                                  "inactive-text": "隐藏",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onchangeIsShow(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.is_mer_show,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "is_mer_show", $$v)
                                  },
                                  expression: "scope.row.is_mer_show",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2851256410
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                key: "15",
                attrs: { label: "开启收录", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 2
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsFeeds(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.is_feeds_public,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "is_feeds_public", $$v)
                                },
                                expression: "scope.row.is_feeds_public",
                              },
                            })
                          : _c("el-switch", {
                              attrs: {
                                disabled: "",
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              model: {
                                value: scope.row.is_feeds_public,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "is_feeds_public", $$v)
                                },
                                expression: "scope.row.is_feeds_public",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "16",
                attrs: { label: "禁言", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 2
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": 0,
                                "inactive-value": 1,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsCommen(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.close_comment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "close_comment", $$v)
                                },
                                expression: "scope.row.close_comment",
                              },
                            })
                          : _c("el-switch", {
                              attrs: {
                                disabled: "",
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              model: {
                                value: scope.row.close_comment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "close_comment", $$v)
                                },
                                expression: "scope.row.close_comment",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "17",
                attrs: { label: "客服开关", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 2
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": 0,
                                "inactive-value": 1,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsKf(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.close_kf,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "close_kf", $$v)
                                },
                                expression: "scope.row.close_kf",
                              },
                            })
                          : _c("el-switch", {
                              attrs: {
                                disabled: "",
                                "active-value": 0,
                                "inactive-value": 1,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              model: {
                                value: scope.row.close_kf,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "close_kf", $$v)
                                },
                                expression: "scope.row.close_kf",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商城显示", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.is_mer_show == 1 && scope.row.is_show == 1
                          ? _c("span", [_vm._v("显示")])
                          : scope.row.is_mer_show == 0 && scope.row.is_show == 1
                          ? _c("span", [_vm._v("商户关闭")])
                          : scope.row.is_mer_show == 1 && scope.row.is_show == 0
                          ? _c("span", [_vm._v("平台关闭")])
                          : _c("span", [_vm._v("关闭")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("liveReviewStatusFilter")(scope.row.status)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.status === -1
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "block",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v("原因 " + _vm._s(scope.row.error_msg))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "直播商品", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 2
                          ? _c(
                              "el-button",
                              {
                                key: "4",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleImport(scope.row)
                                  },
                                },
                              },
                              [_vm._v("导入")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onStudioDetails(
                                  scope.row.broadcast_room_id,
                                  "detail"
                                )
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEditAssistant(
                                  scope.row.broadcast_room_id
                                )
                              },
                            },
                          },
                          [_vm._v("编辑助手")]
                        ),
                        _vm._v(" "),
                        scope.row.status === 2 && scope.row.live_status === 102
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onStudioDetails(
                                      scope.row.broadcast_room_id,
                                      "edit"
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.live_status === 102 &&
                        (scope.row.status === -1 || scope.row.status === 0)
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(
                                      scope.row.broadcast_room_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", { ref: "studioDetail" }),
      _vm._v(" "),
      _c("import-goods", { ref: "uploadGoods" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }