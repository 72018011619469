"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodList',
  data: function data() {
    return {
      dialogVisible: false,
      templateRadio: 0,
      merCateList: [],
      roterPre: _settings.roterPre,
      listLoading: true,
      selectedGoods: [],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 5,
        mer_cate_id: '',
        cate_id: '',
        keyword: '',
        type: '1',
        is_gift_bag: ''
      },
      multipleSelection: [],
      checked: [],
      broadcast_room_id: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 提交
    submitProduct: function submitProduct() {
      console.log(this.multipleSelection);
      var set = this.deteleObject(this.multipleSelection);
      this.$emit('get-goods', set);
      this.dialogVisible = false;
    },
    deteleObject: function deteleObject(obj) {
      var uniques = [];
      var stringify = {};
      for (var i = 0; i < obj.length; i++) {
        var keys = Object.keys(obj[i]);
        keys.sort(function (a, b) {
          return Number(a) - Number(b);
        });
        var str = '';
        for (var j = 0; j < keys.length; j++) {
          str += JSON.stringify(keys[j]);
          str += JSON.stringify(obj[i][keys[j]]);
        }
        if (!stringify.hasOwnProperty(str)) {
          uniques.push(obj[i]);
          stringify[str] = true;
        }
      }
      return uniques;
    },
    // 列表
    getList: function getList(arr, num) {
      var _this = this;
      this.listLoading = true;
      this.selectedGoods = arr;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        if (arr && arr.length > 0) {
          _this.$nextTick(function () {
            arr.forEach(function (row) {
              _this.$refs.multipleSelection.toggleRowSelection(row, true);
            });
          });
        }
        if (num) _this.pageChange(1);
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};