"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'picture_cube',
  cname: '图片魔方',
  configName: 'c_picture_cube',
  icon: 'icontupianmofang',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'pictureCube',
  // 外面匹配名称
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'pictureCube',
        timestamp: this.num,
        tabConfig: {
          title: '选择样式',
          tabVal: 0,
          type: 1,
          tabList: [{
            name: '样式一',
            icon: 'iconyangshi1',
            count: 1
          }, {
            name: '样式二',
            icon: 'iconyangshi2',
            count: 2
          }, {
            name: '样式三',
            icon: 'iconyangshi3',
            count: 3
          }, {
            name: '样式四',
            icon: 'iconyangshi9',
            count: 3
          }, {
            name: '样式五',
            icon: 'iconyangshi8',
            count: 4
          }, {
            name: '样式六',
            icon: 'iconyangshi4',
            count: 4
          }]
        },
        picStyle: {
          tabVal: 0,
          picList: []
        },
        menuConfig: {
          title: '',
          maxList: 1,
          isCube: 1,
          list: [{
            img: '',
            info: [{
              title: '链接',
              tips: '请输入链接',
              value: '',
              max: 100
            }]
          }]
        },
        bgColor: {
          title: '背景颜色',
          default: [{
            item: '#fff'
          }],
          color: [{
            item: '#fff'
          }]
        },
        bgStyle: {
          title: '背景样式',
          name: 'bgStyle',
          type: 0,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        prConfig: {
          title: '背景边距',
          val: 0,
          min: 0
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        }
      },
      pageData: {},
      style: 0,
      picList: [],
      bgColor: [],
      slider: 0,
      bgStyle: 0,
      prConfig: 0
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.tabConfig) {
        this.style = data.tabConfig.tabVal;
        this.bgStyle = data.bgStyle.type;
        this.prConfig = data.prConfig.val;
        this.slider = data.mbConfig.val;
        this.bgColor = data.bgColor.color[0].item;
        if (!data.picStyle.picList.length) {
          this.picList = [{
            image: '',
            link: ''
          }];
        } else {
          this.picList = data.picStyle.picList;
        }
      }
    }
  }
};