"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_hot_word',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      hotWordList: [],
      hotIndex: 1,
      defaults: {},
      configData: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        // this.hotWordList = nVal.hotList
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  methods: {
    addHotTxt: function addHotTxt() {
      // let obj = {}
      // if(this.configData.list.length){
      //     obj = JSON.parse(JSON.stringify(this.configData.list[this.configData.list.length - 1]))
      // }else {
      //     obj = {
      //         val: ''
      //     }
      // }
      var obj = {
        val: ''
      };
      this.configData.list.push(obj);
      // this.$emit('input', this.hotWordList);
    },
    // 删除数组
    bindDelete: function bindDelete(index) {
      this.configData.list.splice(index, 1);
    }
  }
};