var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "选择预售商品" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "填写基础信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "修改商品详情" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择商品：", prop: "image" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.add()
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品主图：", prop: "image" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品轮播图：",
                                prop: "slider_image",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _vm._l(
                                    _vm.formValidate.slider_image,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "pictrue",
                                          attrs: { draggable: "false" },
                                          on: {
                                            dragstart: function ($event) {
                                              return _vm.handleDragStart(
                                                $event,
                                                item
                                              )
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDragOver(
                                                $event,
                                                item
                                              )
                                            },
                                            dragenter: function ($event) {
                                              return _vm.handleDragEnter(
                                                $event,
                                                item
                                              )
                                            },
                                            dragend: function ($event) {
                                              return _vm.handleDragEnd(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", { attrs: { src: item } }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.formValidate.slider_image.length < 10
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "sp100" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品名称：",
                                prop: "store_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入商品名称" },
                                model: {
                                  value: _vm.formValidate.store_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "store_name",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.store_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "sp100" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "预售活动简介：",
                                prop: "store_info",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入秒杀活动简介",
                                },
                                model: {
                                  value: _vm.formValidate.store_info,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "store_info",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.store_info",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预售设置：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.wayChange },
                                  model: {
                                    value: _vm.formValidate.presell_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "presell_type",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.presell_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 1 },
                                    },
                                    [_vm._v("全款预售")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("定金预售"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "预售活动日期：", required: "" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  align: "right",
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.timeVal,
                                  callback: function ($$v) {
                                    _vm.timeVal = $$v
                                  },
                                  expression: "timeVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.presell_type === 2
                        ? _c(
                            "el-col",
                            _vm._b({}, "el-col", _vm.grid2, false),
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "尾款支付日期：",
                                    required: "",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetimerange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      align: "right",
                                    },
                                    on: { change: _vm.onchangeTime2 },
                                    model: {
                                      value: _vm.timeVal2,
                                      callback: function ($$v) {
                                        _vm.timeVal2 = $$v
                                      },
                                      expression: "timeVal2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发货时间：",
                                prop: "delivery_type",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _vm.formValidate.presell_type === 1
                                    ? _c(
                                        "el-select",
                                        {
                                          staticClass: "selWidthd1 mr20",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.formValidate.delivery_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "delivery_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.delivery_type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.deliveryTime,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.date_id,
                                              attrs: {
                                                label: item.name,
                                                value: item.date_id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "padding-right": "10px",
                                          },
                                        },
                                        [_vm._v("尾款支付后")]
                                      ),
                                  _vm._v(" "),
                                  _c("el-input-number", {
                                    staticClass: "mr20",
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      min: 1,
                                      placeholder: "请输入天数",
                                    },
                                    model: {
                                      value: _vm.formValidate.delivery_day,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "delivery_day",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.delivery_day",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                天之内\n              "
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "限购：" } },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, placeholder: "请输入数量" },
                                model: {
                                  value: _vm.formValidate.pay_count,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "pay_count", $$v)
                                  },
                                  expression: "formValidate.pay_count",
                                },
                              }),
                              _vm._v(
                                "\n              默认“0” ，为不限制购买数量\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动状态：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.is_show,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "is_show", $$v)
                                    },
                                    expression: "formValidate.is_show",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 0 },
                                    },
                                    [_vm._v("关闭")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("开启"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序：" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "请输入排序序号" },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "送货方式：",
                                prop: "delivery_way",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.formValidate.delivery_way,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "delivery_way",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.delivery_way",
                                      },
                                    },
                                    _vm._l(_vm.deliveryList, function (item) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: item.value,
                                          attrs: { label: item.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.name) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.delivery_way.length == 2 ||
                      (_vm.formValidate.delivery_way.length == 1 &&
                        _vm.formValidate.delivery_way[0] == 2)
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否包邮：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.formValidate.delivery_free,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "delivery_free",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.delivery_free",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "radio",
                                          attrs: { label: 0 },
                                        },
                                        [_vm._v("否")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("是"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.delivery_free == 0 &&
                      (_vm.formValidate.delivery_way.length == 2 ||
                        (_vm.formValidate.delivery_way.length == 1 &&
                          _vm.formValidate.delivery_way[0] == 2))
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "运费模板：",
                                    prop: "temp_id",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "selWidthd mr20",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.formValidate.temp_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "temp_id",
                                                $$v
                                              )
                                            },
                                            expression: "formValidate.temp_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.shippingList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.shipping_template_id,
                                              attrs: {
                                                label: item.name,
                                                value:
                                                  item.shipping_template_id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "mr15",
                                          attrs: { size: "small" },
                                          on: { click: _vm.addTem },
                                        },
                                        [_vm._v("添加运费模板")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.labelList.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品标签：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd",
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.formValidate.mer_labels,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "mer_labels",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.mer_labels",
                                      },
                                    },
                                    _vm._l(_vm.labelList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "平台保障服务：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd mr20",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate
                                            .guarantee_template_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "guarantee_template_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.guarantee_template_id",
                                      },
                                    },
                                    _vm._l(_vm.guaranteeList, function (item) {
                                      return _c("el-option", {
                                        key: item.guarantee_template_id,
                                        attrs: {
                                          label: item.template_name,
                                          value: item.guarantee_template_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: { size: "small" },
                                      on: { click: _vm.addServiceTem },
                                    },
                                    [_vm._v("添加服务说明模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", { attrs: { gutter: 24 } }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                        [
                          _vm.formValidate.spec_type === 0
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "tabNumWidth",
                                      attrs: {
                                        data: _vm.OneattrValue,
                                        border: "",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "图片",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalPicTap(
                                                            "1",
                                                            "dan",
                                                            "pi"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.formValidate.image
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue tabPic",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: scope.row
                                                                    .image,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad tabPic",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1357914119
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "市场价",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(scope.row["price"])
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1703924291
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "预售价",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      type: "number",
                                                      min: 0,
                                                      max: scope.row["price"],
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.limitPrice(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row[
                                                          "presell_price"
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "presell_price",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row['presell_price']",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1536945194
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm.formValidate.presell_type === 2
                                        ? _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "预售定金",
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        staticClass: "priceBox",
                                                        attrs: {
                                                          type: "number",
                                                          min: 0,
                                                          max:
                                                            scope.row["price"] *
                                                            0.2,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.restrictedRange(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row[
                                                              "down_price"
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "down_price",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row['down_price']",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              68943973
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formValidate.presell_type === 2
                                        ? _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "尾款",
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row[
                                                              "presell_price"
                                                            ] &&
                                                              scope.row[
                                                                "down_price"
                                                              ]
                                                              ? (
                                                                  scope.row[
                                                                    "presell_price"
                                                                  ] -
                                                                  scope.row[
                                                                    "down_price"
                                                                  ]
                                                                ).toFixed(2)
                                                              : scope.row[
                                                                  "presell_price"
                                                                ]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3276774772
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "成本价",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(scope.row["cost"])
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4236060069
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "库存",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row["old_stock"]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1655454038
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "限量",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      type: "number",
                                                      max: scope.row[
                                                        "old_stock"
                                                      ],
                                                      min: 0,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.limitInventory(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row["stock"],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "stock",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row['stock']",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3327557396
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "商品编号",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row["bar_code"]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2057585133
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "重量（KG）",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row["weight"]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1649766542
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "体积（m³）",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row["volume"]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2118841126
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _vm.formValidate.spec_type === 1
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "规格列表：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleSelection",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    "tooltip-effect": "dark",
                                    "row-key": function (row) {
                                      return row.id
                                    },
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      type: "selection",
                                      "reserve-selection": true,
                                      "min-width": "50",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4035746379
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "市场价",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row["price"])
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1703924291
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "预售价",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: 0,
                                                  max: scope.row["price"],
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.limitPrice(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    scope.row["presell_price"],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "presell_price",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row['presell_price']",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1536945194
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.presell_type === 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "预售定金",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      type: "number",
                                                      min: 0,
                                                      max:
                                                        scope.row["price"] *
                                                        0.2,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row["down_price"],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "down_price",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row['down_price']",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3412214765
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.presell_type === 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "尾款",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row[
                                                          "presell_price"
                                                        ] &&
                                                          scope.row[
                                                            "down_price"
                                                          ]
                                                          ? scope.row[
                                                              "presell_price"
                                                            ] -
                                                              scope.row[
                                                                "down_price"
                                                              ]
                                                          : scope.row[
                                                              "presell_price"
                                                            ]
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3936705349
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "成本价",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row["cost"])
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4236060069
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "库存",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row["old_stock"])
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1655454038
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "限量",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "priceBox",
                                                attrs: {
                                                  type: "number",
                                                  min: 0,
                                                  max: scope.row["old_stock"],
                                                },
                                                model: {
                                                  value: scope.row["stock"],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "stock",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row['stock']",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4025255182
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "商品编号",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row["bar_code"])
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2057585133
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "重量（KG）",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row["weight"])
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1649766542
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "体积（m³）",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row["volume"])
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2118841126
                                    ),
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情：" } },
                        [
                          _c("ueditorFrom", {
                            attrs: { content: _vm.formValidate.content },
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 0,
                          expression: "currentTab == 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest1("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 1,
                          expression: "currentTab == 1",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest2("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 2,
                          expression: "currentTab === 2",
                        },
                      ],
                      staticClass: "submission",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("goods-list", {
        ref: "goodsList",
        attrs: { "resell-show": true },
        on: { getProduct: _vm.getProduct },
      }),
      _vm._v(" "),
      _c("guarantee-service", {
        ref: "serviceGuarantee",
        on: { "get-list": _vm.getGuaranteeList },
      }),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: { "product-type": 2, "preview-key": _vm.previewKey },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }