var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("添加商品分类")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "samll",
                "highlight-current-row": "",
                "row-key": "store_category_id",
                "default-expand-all": false,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "分类名称", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.cate_name +
                                "  [ " +
                                scope.row.store_category_id +
                                "  ]"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分类图标", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.pic ? scope.row.pic : _vm.moren,
                                "preview-src-list": [
                                  scope.row.pic ? scope.row.pic : _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "是否显示",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.is_show,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "is_show", $$v)
                            },
                            expression: "scope.row.is_show",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row.store_category_id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.store_category_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }