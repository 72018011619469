"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = configApi;
exports.createStoreApi = createStoreApi;
exports.deliveryStoreDelete = deliveryStoreDelete;
exports.deliveryStoreDetail = deliveryStoreDetail;
exports.deliveryStoreLst = deliveryStoreLst;
exports.deliveryStoreRemark = deliveryStoreRemark;
exports.deliveryStoreStatus = deliveryStoreStatus;
exports.deliveryStoreUpdate = deliveryStoreUpdate;
exports.getCityLst = getCityLst;
exports.getConfigApi = getConfigApi;
exports.getDeliveryCate = getDeliveryCate;
exports.modifyStoreApi = modifyStoreApi;
exports.replyAddApi = replyAddApi;
exports.replyDeleteApi = replyDeleteApi;
exports.replyEditApi = replyEditApi;
exports.replyListApi = replyListApi;
exports.replyStatusApi = replyStatusApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 验证码
 */
function configApi(key) {
  return _request.default.get("config/".concat(key));
}
function modifyStoreApi() {
  return _request.default.get("update/form");
}
/**
 * @description 验证码
 */
function getDeliveryCate() {
  return _request.default.get("delivery/station/business");
}
/**
 * @description 添加门店
 */
function createStoreApi(data) {
  return _request.default.post("delivery/station/create", data);
}
/**
 * @description 门店管理 -- 列表
 */
function deliveryStoreLst(data) {
  return _request.default.get("delivery/station/lst", data);
}
/**
 * @description 门店管理 -- 详情
 */
function deliveryStoreDetail(id) {
  return _request.default.get("delivery/station/detail/".concat(id));
}
/**
 * @description 门店管理 -- 备注
 */
function deliveryStoreRemark(id) {
  return _request.default.get("delivery/station/mark/".concat(id, "/form"));
}
/**
 * @description 门店管理 -- 修改显示状态（上下架）
 */
function deliveryStoreStatus(id, data) {
  return _request.default.post("delivery/station/status/".concat(id), data);
}
/**
 * @description 门店管理 -- 获取地图key
 */
function getConfigApi() {
  return _request.default.get("config");
}
/**
 * @description 门店管理 -- 编辑
 */
function deliveryStoreUpdate(id, data) {
  return _request.default.post("delivery/station/update/".concat(id), data);
}
/**
 * @description 门店管理 -- 删除
 */
function deliveryStoreDelete(id) {
  return _request.default.delete("delivery/station/delete/".concat(id));
}
/**
 * @description 门店管理 -- 城市列表
 */
function getCityLst() {
  return _request.default.get("delivery/station/getCity");
}
/**
 * @description 客服自动回复 -- 添加
 */
function replyAddApi(data) {
  return _request.default.post("service/reply/create", data);
}
/**
 * @description 客服自动回复 -- 列表
 */
function replyListApi(page, limit) {
  return _request.default.get("service/reply/list", {
    page: page,
    limit: limit
  });
}
/**
 * @description 客服自动回复 -- 删除
 */
function replyDeleteApi(id) {
  return _request.default.delete("service/reply/delete/".concat(id));
}

/**
 * @description 客服自动回复 -- 编辑
 */
function replyEditApi(id, data) {
  return _request.default.post("service/reply/update/".concat(id), data);
}
/**
 * @description 客服自动回复 -- 修改状态
 */
function replyStatusApi(id, status) {
  return _request.default.post("service/reply/status/".concat(id), {
    status: status
  });
}