var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.defaults.goodsList
    ? _c(
        "div",
        { staticClass: "goods-box" },
        [
          _c(
            "div",
            { staticClass: "wrapper" },
            [
              _c(
                "draggable",
                {
                  staticClass: "dragArea list-group",
                  attrs: {
                    list: _vm.defaults.goodsList.list,
                    group: "peoples",
                  },
                },
                [
                  _vm.defaults.goodsList.list.length
                    ? _vm._l(
                        _vm.defaults.goodsList.list,
                        function (goods, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item" },
                            [
                              _c("img", {
                                attrs: { src: goods.image, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "iconfont-diy icondel_1",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.bindDelete(index)
                                  },
                                },
                              }),
                            ]
                          )
                        }
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "add-item item",
                      on: {
                        click: function ($event) {
                          _vm.modals = true
                        },
                      },
                    },
                    [_c("span", { staticClass: "iconfont-diy iconaddto" })]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "paymentFooter",
              attrs: {
                visible: _vm.modals,
                title: "商品列表",
                scrollable: "",
                width: "900px",
                "before-close": _vm.cancel,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.modals = $event
                },
              },
            },
            [
              _vm.modals
                ? _c("goods-list", {
                    ref: "goodslist",
                    attrs: { ischeckbox: true, isdiy: true },
                    on: { getProductId: _vm.getProductId },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }