var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("div", { staticClass: "header clearfix" }, [
        _c("div", { staticClass: "filter-container" }, [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", inline: "", "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "商品分类：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth mr20",
                          attrs: { placeholder: "请选择", clearable: "" },
                          on: { change: _vm.getList },
                          model: {
                            value: _vm.tableFrom.mer_cate_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "mer_cate_id", $$v)
                            },
                            expression: "tableFrom.mer_cate_id",
                          },
                        },
                        _vm._l(_vm.merCateList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "商品搜索：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请输入商品名称，关键字，产品编号",
                            clearable: "",
                            size: "small",
                          },
                          on: { change: _vm.getList },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keyword", $$v)
                            },
                            expression: "tableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            staticClass: "el-button-solt",
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "samll",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm.singleChoice == "1"
            ? _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: scope.row.product_id },
                              nativeOn: {
                                change: function ($event) {
                                  return _vm.getTemplateRow(scope.row)
                                },
                              },
                              model: {
                                value: _vm.templateRadio,
                                callback: function ($$v) {
                                  _vm.templateRadio = $$v
                                },
                                expression: "templateRadio",
                              },
                            },
                            [_vm._v(" ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3465899556
                ),
              })
            : _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "product_id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品图", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.image,
                            "preview-src-list": [scope.row.image],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "store_name",
              label: "商品名称",
              "min-width": "200",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block mb20" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [5, 10, 20],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }