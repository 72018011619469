"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aesEncrypt = aesEncrypt;
// import CryptoJS from 'crypto-js'
/**
 * @word 要加密的内容
 * @keyWord String  服务器随机返回的关键字
 *  */
function aesEncrypt(word) {
  var keyWord = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'XwKsGlMcdPMEhR1B';
  // var key = CryptoJS.enc.Utf8.parse(keyWord)
  // var srcs = CryptoJS.enc.Utf8.parse(word)
  // var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  // return encrypted.toString()
  return word;
}