var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "100px",
                        inline: "",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "时间选择：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: {
                                type: "button",
                                size: "small",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                              clearable: "",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "配送订单号" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    placeholder: "请输入订单号",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.keyword,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "keyword", $$v)
                                    },
                                    expression: "tableFrom.keyword",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "el-button-solt",
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getList(1)
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单号" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    placeholder: "请输入订单号",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.order_sn,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "order_sn", $$v)
                                    },
                                    expression: "tableFrom.order_sn",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "el-button-solt",
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getList(1)
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "width100",
                              attrs: { label: "发货点名称：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item selWidth mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.station_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "station_id", $$v)
                                    },
                                    expression: "tableFrom.station_id",
                                  },
                                },
                                _vm._l(_vm.storeList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "width100",
                              attrs: { label: "状态：" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item selWidth mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "status", $$v)
                                    },
                                    expression: "tableFrom.status",
                                  },
                                },
                                _vm._l(_vm.statusList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.$index +
                                (_vm.tableFrom.page - 1) * _vm.tableFrom.limit +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "station.station_name",
                  label: "发货点名称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "order_sn",
                  label: "配送订单号",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "storeOrder.order_sn",
                  label: "订单号",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "配送起点", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.station &&
                                scope.row.station.station_address
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "配送终点", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.to_address))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("runErrandStatus")(scope.row.status))
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.status == -1 && scope.row.reason
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "block",
                                  "font-size": "12px",
                                  color: "red",
                                },
                              },
                              [_vm._v("原因: " + _vm._s(scope.row.reason))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "distance",
                  label: "配送距离",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "fee", label: "配送费用", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "消费时间",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "mark", label: "备注", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status != -1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toCancle(
                                      scope.row.delivery_order_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }