var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "100px",
                        inline: "",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否开启：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item selWidth mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "status", $$v)
                                    },
                                    expression: "tableFrom.status",
                                  },
                                },
                                _vm._l(_vm.applyStatus, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "配送方：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item selWidth mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "type", $$v)
                                    },
                                    expression: "tableFrom.type",
                                  },
                                },
                                _vm._l(_vm.deliveryPoint, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "发货点名称：" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    placeholder: "请输入发货点名称",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.station_name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableFrom,
                                        "station_name",
                                        $$v
                                      )
                                    },
                                    expression: "tableFrom.station_name",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "el-button-solt",
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getList(1)
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "关键字：" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    placeholder: "请输入联系人姓名或电话",
                                    size: "small",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.keyword,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "keyword", $$v)
                                    },
                                    expression: "tableFrom.keyword",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "el-button-solt",
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getList(1)
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.add },
                    },
                    [_vm._v("添加发货点")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", prop: "station_id", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "station_name",
                  label: "发货点名称",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "配送方", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.type == 1 ? "达达" : "UU") +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "contact_name",
                  label: "联系人姓名",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "联系人电话",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否开启", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "是",
                            "inactive-text": "否",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row.station_id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onRemark(scope.row.station_id)
                              },
                            },
                          },
                          [_vm._v("备注")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row.station_id)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDelete(
                                  scope.row.station_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-store", {
        ref: "addStore",
        attrs: {
          "map-key": _vm.mapKey,
          "key-url": _vm.keyUrl,
          "delivery-type": _vm.delivery_type,
        },
        on: { getList: _vm.getList },
      }),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "发货点详情",
                visible: _vm.dialogVisible,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "description" }, [
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n            商户名称：" +
                            _vm._s(
                              _vm.storeDetail.merchant &&
                                _vm.storeDetail.merchant.mer_name
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "发货点名称：" + _vm._s(_vm.storeDetail.station_name)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "配送方：" +
                            _vm._s(_vm.storeDetail.type == 1 ? "达达" : "UU")
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v("联系电话：" + _vm._s(_vm.storeDetail.phone)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n            配送品类：" +
                            _vm._s(
                              _vm.storeDetail.business &&
                                _vm.storeDetail.business.label
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "发货点联系人：" +
                            _vm._s(_vm.storeDetail.contact_name)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "发货点联系电话：" + _vm._s(_vm.storeDetail.phone)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "经纬度：" +
                            _vm._s(_vm.storeDetail.lat) +
                            "," +
                            _vm._s(_vm.storeDetail.lng)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "详细地址：" + _vm._s(_vm.storeDetail.station_address)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v(
                          "\n            是否显示：" +
                            _vm._s(
                              _vm.storeDetail.status == 1 ? "显示" : "不显示"
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-term" }, [
                        _vm._v("备注：" + _vm._s(_vm.storeDetail.mark || "无")),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }