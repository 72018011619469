var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.openVersion !== "0"
    ? _c("div", { staticClass: "ivu-global-footer i-copyright" }, [
        _vm.version.status == -1
          ? _c("div", { staticClass: "ivu-global-footer-copyright" }, [
              _vm._v("\n    " + _vm._s("Copyright " + _vm.version.year + " ")),
              _c(
                "a",
                {
                  attrs: {
                    href: "http://" + _vm.version.url,
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(_vm.version.version))]
              ),
            ])
          : _c("div", { staticClass: "ivu-global-footer-copyright" }, [
              _vm._v(_vm._s(_vm.version.Copyright)),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }