var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "div",
            { staticClass: "c_row-item" },
            [
              _c("el-col", { staticClass: "label", attrs: { span: 4 } }, [
                _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "slider-box", attrs: { span: 19 } }, [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.getLink(_vm.configData.title)
                      },
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        icon:
                          _vm.configData.title == "链接"
                            ? "ios-arrow-forward"
                            : "",
                        readonly: _vm.configData.title == "链接" ? true : false,
                        placeholder: _vm.configData.place,
                        maxlength: _vm.configData.max,
                      },
                      model: {
                        value: _vm.configData.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.configData, "value", $$v)
                        },
                        expression: "configData.value",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("linkaddress", {
            ref: "linkaddres",
            on: { linkUrl: _vm.linkUrl },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }