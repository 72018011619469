var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c("div", { staticClass: "txt_tab" }, [
        _c(
          "div",
          { staticClass: "c_row-item" },
          [
            _c("el-row", { staticClass: "c_label" }, [
              _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n      "),
              _c("span", [
                _vm._v(_vm._s(_vm.configData.list[_vm.configData.type].val)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-row",
              { staticClass: "color-box" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { type: "button" },
                    on: {
                      change: function ($event) {
                        return _vm.radioChange($event)
                      },
                    },
                    model: {
                      value: _vm.configData.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.configData, "type", $$v)
                      },
                      expression: "configData.type",
                    },
                  },
                  _vm._l(_vm.configData.list, function (radio, key) {
                    return _c("el-radio", { key: key, attrs: { label: key } }, [
                      radio.icon
                        ? _c("span", {
                            staticClass: "iconfont-diy",
                            class: radio.icon,
                          })
                        : _c("span", [_vm._v(_vm._s(radio.val))]),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }