var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.footConfig
    ? _c(
        "div",
        [
          _c("p", { staticClass: "tips" }, [
            _vm._v("图片建议宽度81*81px；鼠标拖拽左侧圆点可调整导航顺序"),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "dragArea list-group",
              attrs: {
                list: _vm.footConfig,
                group: "peoples",
                handle: ".iconfont",
              },
            },
            _vm._l(_vm.footConfig, function (item, index) {
              return _c("div", { key: index, staticClass: "box-item" }, [
                _c("div", { staticClass: "left-tool" }, [
                  _c("span", { staticClass: "iconfont icondrag2" }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "img-wrapper" },
                    _vm._l(item.imgList, function (img, j) {
                      return _c(
                        "div",
                        {
                          key: j,
                          staticClass: "img-item",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(index, j)
                            },
                          },
                        },
                        [
                          img
                            ? _c("img", { attrs: { src: img, alt: "" } })
                            : _vm._e(),
                          _vm._v(" "),
                          img
                            ? _c("p", { staticClass: "txt" }, [
                                _vm._v(_vm._s(j == 0 ? "选中" : "未选中")),
                              ])
                            : _c("div", { staticClass: "empty-img" }, [
                                _c("span", {
                                  staticClass: "iconfont iconjiahao",
                                }),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(_vm._s(j == 0 ? "选中" : "未选中")),
                                ]),
                              ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c_row-item" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "label", attrs: { span: 4 } },
                        [_vm._v(" 名称 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "slider-box", attrs: { span: 19 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "选填不超过10个字" },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c_row-item" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "label", attrs: { span: 4 } },
                        [_vm._v(" 链接 ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "slider-box", attrs: { span: 19 } },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.getLink(index)
                                },
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  icon: "ios-arrow-forward",
                                  readonly: "",
                                  placeholder: "选填不超过10个字",
                                },
                                model: {
                                  value: item.link,
                                  callback: function ($$v) {
                                    _vm.$set(item, "link", $$v)
                                  },
                                  expression: "item.link",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "del-box",
                    on: {
                      click: function ($event) {
                        return _vm.deleteMenu(index)
                      },
                    },
                  },
                  [_c("span", { staticClass: "iconfont iconcha" })]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.footConfig.length < 5
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { type: "info", ghost: "" },
                  on: { click: _vm.addMenu },
                },
                [_vm._v("添加图文导航")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.modalPic,
                    width: "950px",
                    title: "上传底部菜单",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.modalPic = $event
                    },
                  },
                },
                [
                  _vm.modalPic
                    ? _c("uploadPictures", {
                        attrs: {
                          "is-choice": _vm.isChoice,
                          "grid-btn": _vm.gridBtn,
                          "grid-pic": _vm.gridPic,
                        },
                        on: { getPic: _vm.getPic },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("linkaddress", {
            ref: "linkaddres",
            on: { linkUrl: _vm.linkUrl },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }