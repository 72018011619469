var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: "",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "block" },
                          attrs: { label: "状态：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: {
                                type: "button",
                                size: "small",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList("")
                                },
                              },
                              model: {
                                value: _vm.tableFrom.product_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "product_status", $$v)
                                },
                                expression: "tableFrom.product_status",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入商品名称" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "助力活动状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "type", $$v)
                                },
                                expression: "tableFrom.type",
                              },
                            },
                            _vm._l(_vm.assistStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动商品状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: { change: _vm.getList },
                              model: {
                                value: _vm.tableFrom.us_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "us_status", $$v)
                                },
                                expression: "tableFrom.us_status",
                              },
                            },
                            _vm._l(_vm.productStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                filterable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.mer_labels,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "mer_labels", $$v)
                                },
                                expression: "tableFrom.mer_labels",
                              },
                            },
                            _vm._l(_vm.labelList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "" + _vm.roterPre + "/marketing/assist/create",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [
                      _c("i", { staticClass: "add" }, [_vm._v("+")]),
                      _vm._v(" 添加助力商品 "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "row-class-name": _vm.tableRowClassName,
              },
              on: {
                rowclick: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeEdit($event)
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "product_assist_id",
                  label: "ID",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "助力商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.product.image,
                                "preview-src-list": [scope.row.product.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "商品名称",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "助力价格", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.assistSku &&
                                scope.row.assistSku[0].assist_price
                                ? scope.row.assistSku[0].assist_price
                                : 0
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "stock",
                  label: "助力活动状态",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.assist_status === 0
                                ? "未开始"
                                : scope.row.assist_status === 1
                                ? "正在进行"
                                : "已结束"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动时间", "min-width": "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            开始日期：" +
                              _vm._s(
                                scope.row.start_time && scope.row.start_time
                                  ? scope.row.start_time.slice(0, 10)
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            结束日期：" +
                              _vm._s(
                                scope.row.end_time && scope.row.end_time
                                  ? scope.row.end_time.slice(0, 10)
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "助力成功人数/参与人次",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.success) +
                              " / " +
                              _vm._s(scope.row.all)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "限量", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.assistSku && scope.row.assistSku[0]
                                ? scope.row.assistSku[0].stock_count
                                : 0
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "限量剩余", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.assistSku && scope.row.assistSku[0]
                                ? scope.row.assistSku[0].stock
                                : 0
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "product.sort",
                  align: "center",
                  label: "排序",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.index === _vm.tabClickIndex
                          ? _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    maxlength: "300",
                                    size: "mini",
                                    autofocus: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.inputBlur(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row["product"]["sort"],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row["product"],
                                        "sort",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "scope.row['product']['sort']",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              {
                                on: {
                                  dblclick: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.tabClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row["product"]["sort"]))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "上/下架", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "上架",
                            "inactive-text": "下架",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.is_show,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "is_show", $$v)
                            },
                            expression: "scope.row.is_show",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "商品状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("productStatusFilter")(scope.row.us_status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "标签", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.mer_labels,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "label-list" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.product_status === 0
                                ? "待审核"
                                : scope.row.product_status === 1
                                ? "审核通过"
                                : "审核失败"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.product_status === -1
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [
                                _c("br"),
                                _vm._v(
                                  "\n            原因：" +
                                    _vm._s(scope.row.refusal) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product_status === 0
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      _vm.roterPre +
                                      "/marketing/assist/create/" +
                                      scope.row.product_assist_id,
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "small" },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEditLabel(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑标签")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.goDetail(scope.row.product_assist_id)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        scope.row.product_status !== 1 ||
                        scope.row.assist_status == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.product_assist_id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "助力商品详情",
                center: "",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "box-container" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("基本信息：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品ID："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.product_id) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品名称："),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formValidate.store_name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp100 image" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品图："),
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { "max-width": "150px", height: "80px" },
                          attrs: { src: _vm.formValidate.image },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [_vm._v("助力商品活动信息：")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", { staticClass: "list sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力活动简介："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.store_info) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力活动日期："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.start_time +
                              "-" +
                              _vm.formValidate.end_time
                          ) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力价："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.price) + "元\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("已售商品数："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.pay) +
                            _vm._s(_vm.formValidate.unit_name) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("限量："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.stock_count) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("限量剩余："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.stock) + "\n          "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("限购件数："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.pay_count) +
                            _vm._s(_vm.formValidate.unit_name) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力次数："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.assist_user_count) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力人数："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.assist_count) +
                            "人\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("审核状态："),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formValidate.reviewStatus === 0
                                ? "待审核"
                                : _vm.formValidate.reviewStatus === 1
                                ? "审核通过"
                                : "审核失败"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.formValidate.reviewStatus === -1
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [
                                _c("br"),
                                _vm._v(
                                  "\n              原因：" +
                                    _vm._s(_vm.formValidate.refusal) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力成功/参与人次："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.success) +
                            " /\n            " +
                            _vm._s(_vm.formValidate.all) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("助力活动状态："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.assist_status === 0
                              ? "未开始"
                              : _vm.formValidate.assist_status === 1
                              ? "正在进行"
                              : "已结束"
                          ) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("显示状态："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.is_show === 1 ? "显示" : "隐藏"
                          ) + "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("创建时间："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.create_time) + "\n          "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: {
                      "goods-id": _vm.goodsId,
                      "product-type": 3,
                      "preview-key": _vm.previewKey,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogLabel
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "选择标签",
                visible: _vm.dialogLabel,
                width: "800px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogLabel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "labelForm",
                  attrs: { model: _vm.labelForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            clearable: "",
                            multiple: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.labelForm.mer_labels,
                            callback: function ($$v) {
                              _vm.$set(_vm.labelForm, "mer_labels", $$v)
                            },
                            expression: "labelForm.mer_labels",
                          },
                        },
                        _vm._l(_vm.labelList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("labelForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }