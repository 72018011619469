var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-config" },
    [
      _vm._l(_vm.rCom, function (item, key) {
        return _c(
          "div",
          { key: key },
          [
            _c(item.components.name, {
              key: key,
              ref: "childData",
              refInFor: true,
              tag: "component",
              attrs: {
                "config-obj": _vm.configObj,
                "config-nme": item.configNme,
                index: _vm.activeIndex,
                num: item.num,
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("rightBtn", {
        attrs: { "active-index": _vm.activeIndex, "config-obj": _vm.configObj },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }