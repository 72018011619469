var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.bgStyle ? "pageOn" : "",
      style: {
        marginLeft: _vm.prConfig + "px",
        marginRight: _vm.prConfig + "px",
        marginTop: _vm.slider + "px",
        background: _vm.bgColor,
      },
    },
    [
      _vm.isOne
        ? _c("div", { staticClass: "mobile-page" }, [
            _c(
              "div",
              { staticClass: "list_menu" },
              _vm._l(_vm.vuexMenu, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    class:
                      _vm.number === 1
                        ? "four"
                        : _vm.number === 2
                        ? "five"
                        : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img-box",
                        class: _vm.menuStyle ? "on" : "",
                      },
                      [
                        item.img
                          ? _c("img", { attrs: { src: item.img, alt: "" } })
                          : _c("div", { staticClass: "empty-box on" }, [
                              _c("span", {
                                staticClass: "iconfont-diy icontupian",
                              }),
                            ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { style: { color: _vm.txtColor } }, [
                      _vm._v(_vm._s(item.info[0].value)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _c("div", { staticClass: "mobile-page" }, [
            _c(
              "div",
              { staticClass: "home_menu" },
              _vm._l(_vm.vuexMenu, function (item, index) {
                return _c("div", { key: index, staticClass: "menu-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "img-box",
                      class: _vm.menuStyle ? "on" : "",
                    },
                    [
                      item.img
                        ? _c("img", { attrs: { src: item.img, alt: "" } })
                        : _c("div", { staticClass: "empty-box on" }, [
                            _c("span", {
                              staticClass: "iconfont-diy icontupian",
                            }),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { style: { color: _vm.txtColor } }, [
                    _vm._v(_vm._s(item.info[0].value)),
                  ]),
                ])
              }),
              0
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }