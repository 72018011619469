import { render, staticRenderFns } from "./c_home_topic.vue?vue&type=template&id=dc585c14&scoped=true"
import script from "./c_home_topic.vue?vue&type=script&lang=js"
export * from "./c_home_topic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc585c14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dc585c14')) {
      api.createRecord('dc585c14', component.options)
    } else {
      api.reload('dc585c14', component.options)
    }
    module.hot.accept("./c_home_topic.vue?vue&type=template&id=dc585c14&scoped=true", function () {
      api.rerender('dc585c14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mobileConfig/c_home_topic.vue"
export default component.exports