"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_title',
  cname: '标题',
  icon: 'iconbiaoti2',
  configName: 'c_home_title',
  type: 2,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'titles',
  // 外面匹配名称
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      defaultConfig: {
        name: 'titles',
        timestamp: this.num,
        setUp: {
          tabVal: '0'
        },
        titleConfig: {
          title: '标题',
          value: '标题',
          place: '请输入标题',
          max: 10
        },
        linkConfig: {
          title: '链接',
          value: '',
          place: '请输入链接地址',
          max: 100
        },
        themeColor: {
          title: '字体颜色',
          name: 'themeColor',
          default: [{
            item: '#282828'
          }],
          color: [{
            item: '#282828'
          }]
        },
        titleColor: {
          title: '背景颜色',
          default: [{
            item: '#fff'
          }],
          color: [{
            item: '#fff'
          }]
        },
        bgStyle: {
          title: '背景样式',
          name: 'bgStyle',
          type: 0,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        prConfig: {
          title: '背景边距',
          val: 0,
          min: 0
        },
        textPosition: {
          title: '文本位置',
          type: 0,
          list: [{
            val: '居左',
            style: 'left',
            icon: 'icondoc_left'
          }, {
            val: '居中',
            style: 'center',
            icon: 'icondoc_center'
          }, {
            val: '居右',
            style: 'right',
            icon: 'icondoc_right'
          }]
        },
        textStyle: {
          title: '文本样式',
          type: 0,
          list: [{
            val: '正常',
            style: 'normal',
            icon: 'icondoc_general'
          }, {
            val: '斜体',
            style: 'italic',
            icon: 'icondoc_skew'
          }, {
            val: '加粗',
            style: 'bold',
            icon: 'icondoc_bold'
          }]
        },
        fontSize: {
          title: '文本大小',
          val: 12,
          min: 12
        },
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        }
      },
      titleTxt: '',
      link: '',
      txtPosition: '',
      txtStyle: '',
      fontSize: 0,
      mTOP: 0,
      titleColor: '',
      themeColor: '',
      prConfig: 0,
      bgStyle: 0,
      pageData: {}
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbConfig) {
        this.titleTxt = data.titleConfig.value;
        this.link = data.linkConfig.value;
        this.txtPosition = data.textPosition.list[data.textPosition.type].style;
        this.txtStyle = data.textStyle.list[data.textStyle.type].style;
        this.themeColor = data.themeColor.color[0].item;
        this.fontSize = data.fontSize.val;
        this.mTOP = data.mbConfig.val;
        this.prConfig = data.prConfig.val;
        this.bgStyle = data.bgStyle.type;
        this.titleColor = data.titleColor.color[0].item;
      }
    }
  }
};