"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodsList',
  props: {
    broadcastRoomId: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'detail'
    }
  },
  data: function data() {
    return {
      listLoading: true,
      multipleSelection: [],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 3,
        keyword: ''
      }
    };
  },
  watch: {
    broadcast_room_id: {
      deep: true,
      handler: function handler(val) {
        this.getList();
      }
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _marketing.studioProList)(this.broadcast_room_id, this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 上下架
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      (0, _marketing.studioProShowApi)(row.broadcast_room_id, {
        goods_id: row.broadcast_goods_id,
        status: row.on_sale
      }).then(function (_ref) {
        var message = _ref.message;
        _this2.$message.success(message);
        _this2.getList();
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this2.$message.error(message);
      });
    },
    // 删除
    handleDelete: function handleDelete(item, idx) {
      var _this3 = this;
      this.$modalSureDelete('确定删除该商品').then(function () {
        (0, _marketing.broadcastGoodsDeleteApi)({
          room_id: item.broadcast_room_id,
          id: item.broadcast_goods_id
        }).then(function (_ref3) {
          var message = _ref3.message;
          _this3.$message.success(message);
          _this3.tableData.data.splice(idx, 1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this3.$message.error(message);
        });
      });
    }
  }
};