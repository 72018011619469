"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _product = require("@/api/product");
require("../../../public/UEditor/dialogs/internal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Vide11o',
  props: {
    isDiy: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      upload: {
        videoIng: false // 是否显示进度条；
      },
      progress: 20,
      // 进度条默认0
      videoLink: '',
      formValidate: {
        video_link: ''
      }
    };
  },
  methods: {
    // 删除视频；
    delVideo: function delVideo() {
      var that = this;
      that.$set(that.formValidate, 'video_link', '');
    },
    zh_uploadFile: function zh_uploadFile() {
      if (this.videoLink) {
        this.formValidate.video_link = this.videoLink;
      } else {
        this.$refs.refid.click();
      }
    },
    zh_uploadFile_change: function zh_uploadFile_change(evfile) {
      var that = this;
      var suffix = evfile.target.files[0].name.substr(evfile.target.files[0].name.indexOf('.'));
      if (suffix !== '.mp4') {
        return that.$message.error('只能上传MP4文件');
      }
      (0, _product.productGetTempKeysApi)().then(function (res) {
        that.$videoCloud.videoUpload({
          type: res.data.type,
          evfile: evfile,
          res: res,
          uploading: function uploading(status, progress) {
            that.upload.videoIng = status;
            console.log(status, progress);
          }
        }).then(function (res) {
          that.formValidate.video_link = res.url || res.data.src;
          that.$message.success('视频上传成功');
          that.progress = 100;
          that.upload.videoIng = false;
        }).catch(function (res) {
          that.$message.error(res);
        });
      });
    },
    uploads: function uploads() {
      if (!this.formValidate.video_link && !this.videoLink) {
        this.$message.error('您还没有上传视频！');
        return;
      } else if (this.videoLink && !this.formValidate.video_link) {
        this.$message.error('请点击确认添加按钮！');
        return;
      }
      if (this.isDiy) {
        this.$emit('getVideo', this.formValidate.video_link);
      } else if (window.nowEditor) {
        window.nowEditor.dialog.close(true);
        window.nowEditor.editor.setContent("<video src='" + this.formValidate.video_link + "' controls='controls'></video>", true);
      }
    }
  }
};