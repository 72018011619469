"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_pink',
  cname: '拼团',
  icon: 'iconpintuan2',
  configName: 'c_home_pink',
  type: 1,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'combination',
  // 外面匹配名称
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'combination',
        timestamp: this.num,
        setUp: {
          tabVal: '0'
        },
        // numConfig: {
        //     val: 3
        // },
        priceShow: {
          title: '是否显示价格',
          val: true
        },
        bntShow: {
          title: '是否显示按钮',
          val: true
        },
        titleShow: {
          title: '是否显示名称',
          val: true
        },
        pinkShow: {
          title: '是否显示拼团标签',
          val: true
        },
        tabConfig: {
          title: '展示样式',
          tabVal: 0,
          type: 1,
          tabList: [{
            name: '单行展示',
            icon: 'icondanhang'
          }, {
            name: '多行展示',
            icon: 'iconduohang'
          }, {
            name: '板块模式',
            icon: 'iconyangshi9'
          }]
        },
        txtColor: {
          title: '文字背景色',
          name: 'themeColor',
          default: [{
            item: 'rgba(255,68,68,0.1)'
          }],
          color: [{
            item: 'rgba(255,68,68,0.1)'
          }]
        },
        bgStyle: {
          title: '背景样式',
          name: 'bgStyle',
          type: 1,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        conStyle: {
          title: '内容样式',
          name: 'conStyle',
          type: 1,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        bgColor: {
          title: '背景颜色',
          name: 'themeColor',
          default: [{
            item: '#fff'
          }],
          color: [{
            item: '#fff'
          }]
        },
        prConfig: {
          title: '背景边距',
          val: 10,
          min: 0
        },
        themeColor: {
          title: '主题风格',
          name: 'themeColor',
          default: [{
            item: '#E93323'
          }],
          color: [{
            item: '#E93323'
          }]
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        }
      },
      list: [{
        img: '',
        name: '小米家用电饭煲',
        price: '234',
        num: '1234'
      }, {
        img: '',
        name: '小米家用电饭煲',
        price: '234',
        num: '1234'
      }, {
        img: '',
        name: '小米家用电饭煲',
        price: '234',
        num: '1234'
      }, {
        img: '',
        name: '小米家用电饭煲',
        price: '234',
        num: '1234'
      }, {
        img: '',
        name: '小米家用电饭煲',
        price: '234',
        num: '1234'
      }, {
        img: '',
        name: '小米家用电饭煲',
        price: '234',
        num: '1234'
      }],
      txtColor: '',
      mTop: '',
      txtBg: '',
      pageData: {},
      priceShow: true,
      bntShow: true,
      titleShow: true,
      pinkShow: true,
      prConfig: 0,
      bgColor: '',
      conStyle: 1,
      bgStyle: 1,
      isOne: 0
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbConfig) {
        this.isOne = data.tabConfig.tabVal;
        this.txtColor = data.themeColor.color[0].item;
        this.mTop = data.mbConfig.val;
        this.txtBg = data.txtColor.color[0].item;
        this.priceShow = data.priceShow.val;
        this.bntShow = data.bntShow.val;
        this.titleShow = data.titleShow.val;
        this.pinkShow = data.pinkShow.val;
        this.prConfig = data.prConfig.val;
        this.bgColor = data.bgColor.color[0].item;
        this.conStyle = data.conStyle.type;
        this.bgStyle = data.bgStyle.type;
      }
    }
  }
};