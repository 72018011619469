var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-page" }, [
    _c("div", {
      staticClass: "box",
      style: {
        borderBottomWidth: _vm.cSlider + "px",
        borderBottomColor: _vm.bgColor,
        borderBottomStyle: _vm.style,
        marginLeft: _vm.edge + "px",
        marginRight: _vm.edge + "px",
        marginTop: _vm.udEdge + "px",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }