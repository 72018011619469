"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _diy = require("@/api/diy");
var _product = require("@/api/product");
var _index = _interopRequireDefault(require("@/components/mobileConfigRight/index.js"));
var _index2 = _interopRequireDefault(require("@/components/rightBtn/index.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_home_goods_list',
  componentsName: 'home_goods_list',
  cname: '商品列表',
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  props: {
    activeIndex: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    },
    index: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      configObj: {},
      rCom: [{
        components: _index.default.c_set_up,
        configNme: 'setUp'
      }],
      automatic: [{
        components: _index.default.c_tab,
        configNme: 'tabConfig'
      }, {
        components: _index.default.c_cascader,
        configNme: 'selectConfig'
      }, {
        components: _index.default.c_txt_tab,
        configNme: 'goodsSort'
      }, {
        components: _index.default.c_is_show,
        configNme: 'titleShow'
      }, {
        components: _index.default.c_is_show,
        configNme: 'opriceShow'
      }, {
        components: _index.default.c_is_show,
        configNme: 'priceShow'
      }, {
        components: _index.default.c_is_show,
        configNme: 'couponShow'
      }],
      manual: [{
        components: _index.default.c_tab,
        configNme: 'tabConfig'
      }, {
        components: _index.default.c_goods,
        configNme: 'goodsList'
      }, {
        components: _index.default.c_is_show,
        configNme: 'titleShow'
      },
      // {
      //     components: toolCom.c_is_show,
      //     configNme: 'opriceShow'
      // },
      {
        components: _index.default.c_is_show,
        configNme: 'priceShow'
      }, {
        components: _index.default.c_is_show,
        configNme: 'couponShow'
      }],
      setUp: 0,
      type: 0,
      lockStatus: false
    };
  },
  watch: {
    num: function num(nVal) {
      var value = JSON.parse(JSON.stringify(this.$store.state.mobildConfig.defaultArray[nVal]));
      this.configObj = value;
      if (!value.selectConfig.list || !value.selectConfig.list[0].value) {
        this.getCategory();
      }
    },
    configObj: {
      handler: function handler(nVal, oVal) {
        this.$store.commit('mobildConfig/UPDATEARR', {
          num: this.num,
          val: nVal
        });
      },
      deep: true
    },
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        this.setUp = nVal;
        var arr = [this.rCom[0]];
        if (+nVal === 0) {
          if (this.type) {
            this.rCom = arr.concat(this.manual);
          } else {
            this.rCom = arr.concat(this.automatic);
          }
        } else {
          var tempArr = [{
            components: _index.default.c_bg_color,
            configNme: 'themeColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'fontColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'labelColor'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'itemStyle'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'conStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'mbConfig'
          }];
          this.rCom = arr.concat(tempArr);
        }
      },
      deep: true
    },
    'configObj.tabConfig.tabVal': {
      handler: function handler(nVal, oVal) {
        this.type = nVal;
        var arr = [this.rCom[0]];
        if (+this.setUp === 0) {
          if (+nVal === 0) {
            this.rCom = arr.concat(this.automatic);
          } else {
            this.rCom = arr.concat(this.manual);
          }
        }
      },
      deep: true
    },
    'configObj.itemStyle.type': {
      handler: function handler(nVal, oVal) {
        this.type = nVal;
        var arr = [this.rCom[0]];
        if (+this.setUp === 0) {
          if (+nVal === 0) {
            this.rCom = arr.concat(this.automatic);
          } else {
            this.rCom = arr.concat(this.manual);
          }
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var value = JSON.parse(JSON.stringify(_this.$store.state.mobildConfig.defaultArray[_this.num]));
      _this.configObj = value;
      _this.getCategory();
    });
  },
  methods: {
    getConfig: function getConfig(data) {
      var _this2 = this;
      if (!data.name && +data === 1) {
        this.configObj.goodsList.list = [];
        return;
      }
      if (!data.name && +data === 0 && !this.configObj.selectConfig.activeValue.length) {
        this.configObj.goodsList.list = [];
        return;
      }
      var activeValue = this.configObj.selectConfig.activeValue;
      (0, _diy.getProduct)({
        // cate_pid: activeValue[activeValue.length-1],
        mer_cate_id: activeValue,
        page: 1,
        limit: this.configObj.numConfig.val,
        order: +this.configObj.goodsSort.type === 2 ? 'price_asc' : +this.configObj.goodsSort.type === 1 ? 'sales' : ''
      }).then(function (res) {
        _this2.configObj.productList.list = res.data.list;
      });
    },
    getCategory: function getCategory() {
      var _this3 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this3.$set(_this3.configObj.selectConfig, 'list', res.data);
      });
    }
  }
};