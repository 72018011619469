"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  components: {},
  data: function data() {
    return {
      moren: require('@/assets/images/f.png'),
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      timeVal: [],
      maxCols: 3,
      isShowSend: true,
      visible: false,
      user_type: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      row: '',
      userFrom: {
        date: '',
        nickname: '',
        keyword: '',
        page: 1,
        limit: 20
      },
      grid: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid2: {
        xl: 18,
        lg: 16,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid3: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      }
    };
  },
  mounted: function mounted() {
    this.getList('');
  },
  methods: {
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.userFrom.page = 1;
      this.userFrom.date = tab;
      this.getList('');
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.userFrom.page = 1;
      this.userFrom.date = e ? this.timeVal.join('-') : '';
      this.getList('');
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.userFrom.page = num || this.userFrom.page;
      this.userFrom.user_type = this.user_type;
      if (this.userFrom.user_type === '0') this.userFrom.user_type = '';
      (0, _user.userSearchLstApi)(this.userFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList('');
    },
    handleClick: function handleClick() {
      this.getList('');
    }
  }
};