"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'CouponList',
  props: {
    couponForm: {
      type: Object,
      required: true
    },
    checkedIds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    allCheck: {
      type: Boolean,
      default: false
    },
    userFrom: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 5,
        coupon_name: ''
      },
      multipleSelection: {
        coupon_id: ''
      },
      templateRadio: 0
    };
  },
  mounted: function mounted() {
    this.tableFrom.page = 1;
    this.getList(1);
  },
  methods: {
    getTemplateRow: function getTemplateRow(row) {
      this.multipleSelection = {
        coupon_id: row.coupon_id
      };
      // this.$emit("getCouponData", this.multipleSelection);
    },
    // 发送优惠券
    send: function send(id) {
      var _this = this;
      delete this.userFrom['page'];
      delete this.userFrom['limit'];
      var that = this;
      that.$confirm('确定要发送优惠券吗？发送优惠券后将无法恢复，请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params = {
          coupon_id: id,
          search: that.userFrom,
          mark: that.filter(_this.couponForm),
          is_all: that.allCheck ? 1 : 0,
          uid: that.checkedIds
        };
        (0, _marketing.couponSendApi)(params).then(function (res) {
          that.$message.success(res.message);
          that.$emit('sendSuccess');
        }).catch(function (res) {
          that.$message.error(res.message);
        });
      }).catch(function (action) {
        _this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    filter: function filter(data) {
      for (var key in data) {
        if (data[key] === '') {
          delete data[key];
        }
      }
      return data;
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.couponIssueListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};