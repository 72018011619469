var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt20 ml20" },
      [
        _c("el-input", {
          staticStyle: { width: "300px" },
          attrs: { placeholder: "请输入视频链接" },
          model: {
            value: _vm.videoLink,
            callback: function ($$v) {
              _vm.videoLink = $$v
            },
            expression: "videoLink",
          },
        }),
        _vm._v(" "),
        _c("input", {
          ref: "refid",
          staticStyle: { display: "none" },
          attrs: { type: "file" },
          on: { change: _vm.zh_uploadFile_change },
        }),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "ml10",
            attrs: { type: "primary", icon: "ios-cloud-upload-outline" },
            on: { click: _vm.zh_uploadFile },
          },
          [_vm._v(_vm._s(_vm.videoLink ? "确认添加" : "上传视频"))]
        ),
        _vm._v(" "),
        _vm.upload.videoIng
          ? _c("el-progress", {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                "stroke-width": 20,
                percentage: _vm.progress,
                "text-inside": true,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.formValidate.video_link
          ? _c("div", { staticClass: "iview-video-style" }, [
              _c(
                "video",
                {
                  staticStyle: {
                    width: "100%",
                    height: "100% !important",
                    "border-radius": "10px",
                  },
                  attrs: {
                    src: _vm.formValidate.video_link,
                    controls: "controls",
                  },
                },
                [_vm._v("\n        您的浏览器不支持 video 标签。\n      ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mark" }),
              _vm._v(" "),
              _c("i", {
                staticClass: "iconv el-icon-delete",
                on: { click: _vm.delVideo },
              }),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt50 ml20" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.uploads } },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }