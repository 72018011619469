"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_input_item',
  components: {
    linkaddress: _linkaddress.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      value: '',
      defaults: {},
      configData: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  methods: {
    linkUrl: function linkUrl(e) {
      this.configData.value = e;
    },
    getLink: function getLink(title) {
      if (title !== '链接') {
        return;
      }
      // this.$refs.linkaddres.modals = true
      this.$refs.linkaddres.init();
    }
  }
};