var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { padding: "0 " + _vm.prConfig + "px" } }, [
    _c(
      "div",
      {
        staticClass: "home_pink",
        class: _vm.bgStyle ? "" : "pinkOn",
        style: { background: _vm.bgColor, marginTop: _vm.mTop + "px" },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.isOne != 2
          ? _c(
              "div",
              { staticClass: "list-wrapper", class: "colum" + _vm.isOne },
              _vm._l(_vm.list, function (item, index) {
                return _c("div", { key: index, staticClass: "item" }, [
                  _c("div", { staticClass: "img-box" }, [
                    item.img
                      ? _c("img", { attrs: { src: item.img, alt: "" } })
                      : _c(
                          "div",
                          {
                            staticClass: "empty-box",
                            class: _vm.conStyle ? "" : "pinkOn",
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont-diy icontupian",
                            }),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _vm.titleShow
                      ? _c("div", { staticClass: "title line1" }, [
                          _vm._v(_vm._s(item.name)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "price" }, [
                      _vm.pinkShow
                        ? _c(
                            "span",
                            {
                              staticClass: "label",
                              style: {
                                background: _vm.txtBg,
                                color: _vm.txtColor,
                              },
                            },
                            [_vm._v("2人团")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.priceShow
                        ? _c(
                            "p",
                            {
                              staticClass: "num line1",
                              style: { color: _vm.txtColor },
                            },
                            [
                              _c("span", [_vm._v("￥")]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.price) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.bntShow
                    ? _c("div", { staticClass: "btn" }, [_vm._v("去拼团")])
                    : _vm._e(),
                ])
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "list-wrapper colum2" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    class: _vm.conStyle ? "" : "presellOn",
                  },
                  [
                    index < 3
                      ? [
                          _c("div", { staticClass: "info" }, [
                            _vm.titleShow
                              ? _c("div", { staticClass: "title line1" }, [
                                  _vm._v(_vm._s(item.name)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.priceShow
                              ? _c("div", { staticClass: "price line1" }, [
                                  _vm._v("¥" + _vm._s(item.price)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.bntShow
                              ? _c("span", { staticClass: "box-btn" }, [
                                  _vm._v("去拼团"),
                                  _c("span", {
                                    staticClass: "iconfont-diy iconjinru",
                                  }),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "img-box",
                              class: _vm.conStyle ? "" : "pinkOn",
                            },
                            [
                              item.img
                                ? _c("img", {
                                    attrs: { src: item.img, alt: "" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._m(1, true),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("div", { staticClass: "left" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/images/group_title.png"), alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _vm._v("超值团购"),
        _c("span", { staticClass: "iconfont-diy iconjinru" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-box" }, [
      _c("span", { staticClass: "iconfont-diy icontupian" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }