var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "选择秒杀商品" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "填写基础信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "修改商品详情" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "选择商品：",
                                prop: "spike_image",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.add()
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.spike_image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.spike_image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商品主图：", prop: "image" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicTap("1")
                                    },
                                  },
                                },
                                [
                                  _vm.formValidate.image
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.formValidate.image,
                                          },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品轮播图：",
                                prop: "slider_image",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _vm._l(
                                    _vm.formValidate.slider_image,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "pictrue",
                                          attrs: { draggable: "false" },
                                          on: {
                                            dragstart: function ($event) {
                                              return _vm.handleDragStart(
                                                $event,
                                                item
                                              )
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDragOver(
                                                $event,
                                                item
                                              )
                                            },
                                            dragenter: function ($event) {
                                              return _vm.handleDragEnter(
                                                $event,
                                                item
                                              )
                                            },
                                            dragend: function ($event) {
                                              return _vm.handleDragEnd(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", { attrs: { src: item } }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.formValidate.slider_image.length < 10
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "sp100" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品名称：",
                                prop: "store_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入商品名称" },
                                model: {
                                  value: _vm.formValidate.store_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "store_name",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.store_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "秒杀商品关键字：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入商品关键字" },
                                model: {
                                  value: _vm.formValidate.keyword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "keyword", $$v)
                                  },
                                  expression: "formValidate.keyword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "sp100" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "秒杀活动简介：",
                                prop: "store_info",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 3,
                                  placeholder: "请输入秒杀活动简介",
                                },
                                model: {
                                  value: _vm.formValidate.store_info,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "store_info",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.store_info",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "秒杀活动日期：" } },
                            [
                              _c("i", { staticClass: "required" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  size: "small",
                                  type: "daterange",
                                  placement: "bottom-end",
                                  placeholder: "请选择活动时间",
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.timeVal,
                                  callback: function ($$v) {
                                    _vm.timeVal = $$v
                                  },
                                  expression: "timeVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动日期内最多购买次数：",
                                prop: "all_pay_count",
                                "label-width": "210px",
                              },
                            },
                            [
                              _c("el-input-number", {
                                model: {
                                  value: _vm.formValidate.all_pay_count,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "all_pay_count",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.all_pay_count",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "秒杀活动时间：" } },
                            [
                              _c("i", { staticClass: "required" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd mr20",
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.onchangeTime2 },
                                      model: {
                                        value: _vm.timeVal2,
                                        callback: function ($$v) {
                                          _vm.timeVal2 = $$v
                                        },
                                        expression: "timeVal2",
                                      },
                                    },
                                    _vm._l(_vm.spikeTimeList, function (item) {
                                      return _c("el-option", {
                                        key: item.seckill_time_id,
                                        attrs: {
                                          label: item.name,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "秒杀时间段内最多购买次数：",
                                prop: "once_pay_count",
                                "label-width": "210px",
                              },
                            },
                            [
                              _c("el-input-number", {
                                model: {
                                  value: _vm.formValidate.once_pay_count,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "once_pay_count",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.once_pay_count",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单位：", prop: "unit_name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "250px" },
                                attrs: { placeholder: "请输入单位" },
                                model: {
                                  value: _vm.formValidate.unit_name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "unit_name", $$v)
                                  },
                                  expression: "formValidate.unit_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "排序：",
                                "label-width": "210px",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "200px" },
                                attrs: { placeholder: "请输入排序序号" },
                                model: {
                                  value: _vm.formValidate.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "sort", $$v)
                                  },
                                  expression: "formValidate.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "送货方式：",
                                prop: "delivery_way",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.formValidate.delivery_way,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "delivery_way",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.delivery_way",
                                      },
                                    },
                                    _vm._l(_vm.deliveryList, function (item) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: item.value,
                                          attrs: { label: item.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.name) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formValidate.delivery_way.length == 2 ||
                      (_vm.formValidate.delivery_way.length == 1 &&
                        _vm.formValidate.delivery_way[0] == 2)
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否包邮：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.formValidate.delivery_free,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "delivery_free",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.delivery_free",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          staticClass: "radio",
                                          attrs: { label: 0 },
                                        },
                                        [_vm._v("否")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("是"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.delivery_free == 0 &&
                      (_vm.formValidate.delivery_way.length == 2 ||
                        (_vm.formValidate.delivery_way.length == 1 &&
                          _vm.formValidate.delivery_way[0] == 2))
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "运费模板：",
                                    prop: "temp_id",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "acea-row" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "selWidthd mr20",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.formValidate.temp_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "temp_id",
                                                $$v
                                              )
                                            },
                                            expression: "formValidate.temp_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.shippingList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.shipping_template_id,
                                              attrs: {
                                                label: item.name,
                                                value:
                                                  item.shipping_template_id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "mr15",
                                          attrs: { size: "small" },
                                          on: { click: _vm.addTem },
                                        },
                                        [_vm._v("添加运费模板")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.labelList.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "商品标签：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd",
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.formValidate.mer_labels,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "mer_labels",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.mer_labels",
                                      },
                                    },
                                    _vm._l(_vm.labelList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "平台保障服务：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd mr20",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate
                                            .guarantee_template_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "guarantee_template_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.guarantee_template_id",
                                      },
                                    },
                                    _vm._l(_vm.guaranteeList, function (item) {
                                      return _c("el-option", {
                                        key: item.guarantee_template_id,
                                        attrs: {
                                          label: item.template_name,
                                          value: item.guarantee_template_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: { size: "small" },
                                      on: { click: _vm.addServiceTem },
                                    },
                                    [_vm._v("添加服务说明模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动状态：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.formValidate.is_show,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "is_show", $$v)
                                    },
                                    expression: "formValidate.is_show",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 0 },
                                    },
                                    [_vm._v("关闭")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("开启"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                        [
                          _vm.formValidate.spec_type === 0
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "tabNumWidth",
                                      attrs: {
                                        data: _vm.OneattrValue,
                                        border: "",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "图片",
                                          "min-width": "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upLoadPicBox",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.modalPicTap(
                                                            "1",
                                                            "dan",
                                                            "pi"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.formValidate.image
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue tabPic",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: scope.row
                                                                    .image,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "upLoad tabPic",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-camera cameraIconfont",
                                                              }),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1357914119
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.attrValue,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              label: _vm.formThead[iii].title,
                                              align: "center",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm.formThead[iii]
                                                        .title == "限量"
                                                        ? _c("el-input", {
                                                            staticClass:
                                                              "priceBox",
                                                            attrs: {
                                                              type: "number",
                                                              min: 0,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.judgInventory(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row[iii],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    iii,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row[iii]",
                                                            },
                                                          })
                                                        : _vm.formThead[iii]
                                                            .title == "秒杀价"
                                                        ? _c("el-input", {
                                                            staticClass:
                                                              "priceBox",
                                                            attrs: {
                                                              type:
                                                                _vm.formThead[
                                                                  iii
                                                                ].title ===
                                                                "商品编号"
                                                                  ? "text"
                                                                  : "number",
                                                              min: 0,
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row[iii],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    iii,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row[iii]",
                                                            },
                                                          })
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row[iii]
                                                              )
                                                            ),
                                                          ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _vm.formValidate.spec_type === 1 &&
                      _vm.formValidate.attr.length > 0
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "规格列表：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleSelection",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    "tooltip-effect": "dark",
                                    "row-key": function (row) {
                                      return row.id
                                    },
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      type: "selection",
                                      "reserve-selection": true,
                                      "min-width": "50",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4035746379
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm.formThead[iii].title ==
                                                "限量"
                                                  ? _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.judgInventory(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _vm.formThead[iii].title ==
                                                    "秒杀价"
                                                  ? _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type:
                                                          _vm.formThead[iii]
                                                            .title ===
                                                          "商品编号"
                                                            ? "text"
                                                            : "number",
                                                      },
                                                      model: {
                                                        value: scope.row[iii],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            iii,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[iii]",
                                                      },
                                                    })
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(scope.row[iii])
                                                      ),
                                                    ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商户商品分类：",
                                prop: "mer_cate_id",
                              },
                            },
                            [
                              _c("el-cascader", {
                                staticClass: "selWidth",
                                attrs: {
                                  options: _vm.merCateList,
                                  props: _vm.propsMer,
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.formValidate.mer_cate_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "mer_cate_id",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.mer_cate_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "平台商品分类：",
                                prop: "cate_id",
                              },
                            },
                            [
                              _c("el-cascader", {
                                staticClass: "selWidth",
                                attrs: {
                                  options: _vm.categoryList,
                                  props: _vm.props,
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.formValidate.cate_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "cate_id", $$v)
                                  },
                                  expression: "formValidate.cate_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 2,
                      expression: "currentTab === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情：" } },
                        [
                          _c("ueditorFrom", {
                            attrs: { content: _vm.formValidate.content },
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 0,
                          expression: "currentTab == 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest1("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 1,
                          expression: "currentTab == 1",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest2("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 2,
                          expression: "currentTab === 2",
                        },
                      ],
                      staticClass: "submission",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("goods-list", {
        ref: "goodsList",
        attrs: { "seckill-show": "" },
        on: { getProduct: _vm.getProduct },
      }),
      _vm._v(" "),
      _c("guarantee-service", {
        ref: "serviceGuarantee",
        on: { "get-list": _vm.getGuaranteeList },
      }),
      _vm._v(" "),
      _vm.previewVisible
        ? _c(
            "div",
            [
              _c("div", {
                staticClass: "bg",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.previewVisible = false
                  },
                },
              }),
              _vm._v(" "),
              _vm.previewVisible
                ? _c("preview-box", {
                    ref: "previewBox",
                    attrs: { "product-type": 1, "preview-key": _vm.previewKey },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }