var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showRecord
    ? _c(
        "el-dialog",
        {
          attrs: { title: "复制记录", visible: _vm.showRecord, width: "900px" },
          on: {
            "update:visible": function ($event) {
              _vm.showRecord = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", prop: "mer_id", "min-width": "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "使用次数",
                      prop: "num",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "复制商品平台名称",
                      prop: "type",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "剩余次数",
                      prop: "number",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品复制链接",
                      prop: "info",
                      "min-width": "180",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作时间",
                      prop: "create_time",
                      "min-width": "120",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }