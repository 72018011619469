var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "diy-page" },
    [
      _c("div", { staticClass: "product_tabs" }, [
        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
          _c(
            "div",
            { staticStyle: { float: "left" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-arrow-left",
                    type: "text",
                    size: "small",
                  },
                  on: { click: _vm.goBack },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "bnt",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.saveConfig },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "bnt ml20", on: { click: _vm.reast } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-card", { attrs: { bordered: false } }, [
        _c(
          "div",
          {
            staticClass: "diy-wrapper",
            style: "height:" + _vm.clientHeight + "px;",
          },
          [
            _c("div", { staticClass: "left" }, [
              _c(
                "div",
                { staticClass: "title-bar" },
                _vm._l(_vm.tabList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "title-item",
                      class: { on: _vm.tabCur == index },
                      on: {
                        click: function ($event) {
                          return _vm.bindTab(index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.tabCur == 0
                ? _c(
                    "div",
                    {
                      staticClass: "wrapper",
                      style: "height:" + (_vm.clientHeight - 46) + "px;",
                    },
                    _vm._l(_vm.leftMenu, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tips",
                              on: {
                                click: function ($event) {
                                  item.isOpen = !item.isOpen
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n\n              "
                              ),
                              !item.isOpen
                                ? _c("i", {
                                    staticClass: "el-icon-arrow-right",
                                    attrs: { size: "16" },
                                  })
                                : _c("i", {
                                    staticClass: "el-icon-arrow-down",
                                    attrs: { size: "16" },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "draggable",
                            {
                              staticClass: "dragArea list-group",
                              attrs: {
                                list: item.list,
                                group: {
                                  name: "people",
                                  pull: "clone",
                                  put: false,
                                },
                                clone: _vm.cloneDog,
                                "drag-class": "dragClass",
                                filter: ".search , .navbar",
                              },
                            },
                            _vm._l(item.list, function (element) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.isOpen,
                                      expression: "item.isOpen",
                                    },
                                  ],
                                  key: element.id,
                                  staticClass: "list-group-item",
                                  class: {
                                    search: element.cname == "搜索框",
                                    navbar: element.cname == "商品分类",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addDom(element, 1)
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "position",
                                        staticStyle: { display: "none" },
                                      },
                                      [_vm._v("释放鼠标将组建添加到此处")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "conter iconfont-diy",
                                      class: element.icon,
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "conter" }, [
                                      _vm._v(_vm._s(element.cname)),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    {
                      staticClass: "wrapper",
                      style: "height:" + (_vm.clientHeight - 46) + "px;",
                    },
                    _vm._l(_vm.urlList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "link-item" },
                        [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "link-txt" }, [
                            _vm._v("地址：" + _vm._s(item.url)),
                          ]),
                          _vm._v(" "),
                          item.parameter
                            ? _c("div", { staticClass: "params" }, [
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v("参数："),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.parameter))]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.example
                            ? _c(
                                "div",
                                { staticClass: "lable" },
                                [
                                  _c("p", { staticClass: "txt" }, [
                                    _vm._v("例如：" + _vm._s(item.example)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "copy copy-data",
                                      attrs: {
                                        size: "small",
                                        "data-clipboard-text": item.example,
                                      },
                                    },
                                    [_vm._v("复制\n              ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrapper-con",
                staticStyle: {
                  flex: "1",
                  background: "#f0f2f5",
                  display: "flex",
                  "justify-content": "center",
                  "padding-top": "20px",
                  height: "100%",
                },
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "contxt",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        overflow: "hidden",
                        height: "100%",
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "picture" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/electric.png"),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "page-title",
                            class: { on: _vm.activeIndex == -100 },
                            on: { click: _vm.showTitle },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.titleTxt) +
                                "\n                "
                            ),
                            _c("div", { staticClass: "delete-box" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "handle" }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "scrollCon" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "400px", margin: "0 auto" } },
                          [
                            _c("div", { staticClass: "store-home" }, [
                              _c("div", { staticClass: "header" }, [
                                _c("div", { staticClass: "head-menu" }, [
                                  _c("div", {
                                    staticClass: "iconfont-h5 icon-xiangzuo",
                                    staticStyle: { color: "#fff" },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "iconfont-h5 icon-shouye4",
                                    staticStyle: { color: "#fff" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "search" }, [
                                  _c("span", {
                                    staticClass: "iconfont-h5 icon-xiazai5",
                                  }),
                                  _vm._v("搜索商品"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "store" }, [
                                _c("image", {
                                  attrs: {
                                    src: "@/assets/images/mer_avatar.png",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "text" }, [
                                  _c("div", [
                                    _c("span", { staticClass: "font-bg-red" }, [
                                      _vm._v("自营"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v("店铺名称"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "iconfont-h5 icon-xiangyou",
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "score" }, [
                                    _c("div", { staticClass: "star" }, [
                                      _c("div"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("5.0")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "kefu" }, [
                                  _c("span", {
                                    staticClass: "iconfont-h5 icon-kefu3",
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "care button" }, [
                                  _c("span", {
                                    staticClass: "iconfont-h5 icon-guanzhu",
                                  }),
                                  _vm._v(
                                    "\n                      关注\n                    "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "nav" }, [
                                _c("div", { staticClass: "nav-cont" }, [
                                  _c("div", { staticClass: "item active" }, [
                                    _c("div", { staticClass: "cont" }, [
                                      _vm._v("精选"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item" }, [
                                    _c("div", { staticClass: "cont" }, [
                                      _vm._v("新品"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item" }, [
                                    _c("div", { staticClass: "cont" }, [
                                      _vm._v("活动"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "item" }, [
                                    _c("div", { staticClass: "cont" }, [
                                      _vm._v("领券"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "imgContainer",
                                staticClass: "scroll-box",
                                class:
                                  _vm.picTxt && _vm.tabValTxt == 2
                                    ? "fullsize noRepeat"
                                    : _vm.picTxt && _vm.tabValTxt == 1
                                    ? "repeat ysize"
                                    : "noRepeat ysize",
                                style:
                                  "background-color:" +
                                  (_vm.colorTxt ? _vm.colorPickerTxt : "") +
                                  ";background-image: url(" +
                                  (_vm.picTxt ? _vm.picUrlTxt : "") +
                                  ");height:" +
                                  _vm.rollHeight +
                                  "px;",
                              },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "dragArea list-group",
                                    attrs: {
                                      list: _vm.mConfig,
                                      group: "people",
                                      filter: ".top",
                                      move: _vm.onMove,
                                      animation: "300",
                                    },
                                    on: { change: _vm.log },
                                  },
                                  _vm._l(_vm.mConfig, function (item, key) {
                                    return _c(
                                      "div",
                                      {
                                        key: key,
                                        staticClass: "mConfig-item",
                                        class: {
                                          on: _vm.activeIndex == key,
                                          top:
                                            item.name == "search_box" ||
                                            item.name == "nav_bar",
                                        },
                                        style: _vm.colorTxt
                                          ? "background-color:" +
                                            _vm.colorPickerTxt +
                                            ";"
                                          : "background-color:#fff;",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.bindconfig(item, key)
                                          },
                                        },
                                      },
                                      [
                                        _c(item.name, {
                                          ref: "getComponentData",
                                          refInFor: true,
                                          tag: "component",
                                          attrs: {
                                            "config-data": _vm.propsObj,
                                            index: key,
                                            num: item.num,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "delete-box" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "handleType" },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "iconfont iconshanchu2",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.bindDelete(
                                                        item,
                                                        key
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "iconfont iconfuzhi",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.bindAddDom(
                                                        item,
                                                        0,
                                                        key
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "iconfont iconshangyi",
                                                  class: key === 0 ? "on" : "",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.movePage(
                                                        item,
                                                        key,
                                                        1
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "iconfont iconxiayi",
                                                  class:
                                                    key ===
                                                    _vm.mConfig.length - 1
                                                      ? "on"
                                                      : "",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.movePage(
                                                        item,
                                                        key,
                                                        0
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "handle" }),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "footer" },
                        _vm._l(_vm.tabs, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item",
                              class: { active: _vm.tabActive === item.value },
                            },
                            [
                              _c("div", { class: ["iconfont-h5", item.icon] }),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(item.name))]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right-box" },
              _vm._l(_vm.rConfig, function (item, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "mConfig-item",
                    staticStyle: { "background-color": "#fff" },
                  },
                  [
                    _c("div", { staticClass: "title-bar" }, [
                      _vm._v(_vm._s(item.cname)),
                    ]),
                    _vm._v(" "),
                    _c(item.configName, {
                      tag: "component",
                      attrs: {
                        "active-index": _vm.activeIndex,
                        num: item.num,
                        index: key,
                      },
                      on: { config: _vm.config },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }