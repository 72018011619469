var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.configData
      ? _c(
          "div",
          { staticClass: "c_row-item" },
          [
            _c("el-col", { staticClass: "c_label", attrs: { span: 8 } }, [
              _vm._v(_vm._s(_vm.configData.title)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "color-box" },
              [
                _c("el-switch", {
                  attrs: { "true-value": true, "false-value": false },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.configData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.configData, "status", $$v)
                    },
                    expression: "configData.status",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }