"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'OrderDetail',
  props: {
    orderDatalist: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      LogLoading: false,
      // orderDatalist: null,
      loading: false,
      listLoading: true,
      order_id: '',
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 5
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this = this;
      this.LogLoading = true;
      this.order_id = id;
      (0, _order.refundorderLogApi)(id, this.tableFromLog).then(function (res) {
        _this.tableDataLog.data = res.data.list;
        _this.tableDataLog.total = res.data.count;
        _this.LogLoading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.LogLoading = false;
      });
    },
    /** 查看物流 */getLoginstics: function getLoginstics() {
      this.$emit('get-logistics', this.orderDatalist);
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog(this.order_id);
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog(this.order_id);
    }
  }
};