var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-page" }, [
    _c(
      "div",
      {
        staticClass: "home_bargain",
        class: _vm.bgStyle === 0 ? "bargainOn" : "",
        style: { marginTop: _vm.mTop + "px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "bargin_count",
            class: _vm.bgStyle === 0 ? "bargainOn" : "",
            style: { background: "" + _vm.themeColor },
          },
          [
            _c(
              "div",
              {
                staticClass: "title-bar",
                class: _vm.bgStyle === 0 ? "bargainOn" : "",
              },
              [_vm._m(0), _vm._v(" "), _vm._m(1)]
            ),
            _vm._v(" "),
            _vm.isOne != 2
              ? _c(
                  "div",
                  { staticClass: "list-wrapper", class: "colum" + _vm.isOne },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item",
                        class: _vm.conStyle ? "" : "bargainOn",
                      },
                      [
                        _c("div", { staticClass: "img-box" }, [
                          item.img
                            ? _c("img", { attrs: { src: item.img, alt: "" } })
                            : _c(
                                "div",
                                {
                                  staticClass: "empty-box",
                                  class: _vm.conStyle ? "" : "bargainOn",
                                },
                                [
                                  _c("span", {
                                    staticClass: "iconfont-diy icontupian",
                                  }),
                                ]
                              ),
                          _vm._v(" "),
                          _vm.joinShow
                            ? _c("div", { staticClass: "box-num" }, [
                                _vm._v(_vm._s(item.num) + "人参与"),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.titleShow ||
                        _vm.priceShow ||
                        _vm.bntShow ||
                        _vm.barginShow
                          ? _c(
                              "div",
                              {
                                staticClass: "con-box",
                                class: _vm.conStyle ? "" : "bargainOn",
                              },
                              [
                                _c("div", { staticClass: "con-desc" }, [
                                  _vm.titleShow
                                    ? _c(
                                        "div",
                                        { staticClass: "title line1" },
                                        [_vm._v(_vm._s(item.store_name))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "price" }, [
                                    _vm.barginShow
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "price-name",
                                            style: "color:" + _vm.priceColor,
                                          },
                                          [_vm._v("助力价")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.priceShow
                                      ? _c(
                                          "p",
                                          { style: "color:" + _vm.priceColor },
                                          [
                                            _vm._v("\n                  ￥"),
                                            _c(
                                              "span",
                                              { staticClass: "price-label" },
                                              [_vm._v(_vm._s(item.price))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.bntShow && _vm.bgColor.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "btn",
                                        class: _vm.conStyle ? "" : "bargainOn",
                                        style: {
                                          background:
                                            "linear-gradient(180deg," +
                                            _vm.bgColor[0].item +
                                            " 0%," +
                                            _vm.bgColor[1].item +
                                            " 100%)",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              发起助力\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass: "list-wrapper colum2",
                    class: _vm.bgStyle === 0 ? "bargainOn" : "",
                  },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item",
                        class: _vm.conStyle ? "" : "bargainOn",
                      },
                      [
                        index < 3
                          ? [
                              _vm.titleShow || _vm.priceShow || _vm.bntShow
                                ? _c("div", { staticClass: "info" }, [
                                    _vm.titleShow
                                      ? _c(
                                          "div",
                                          { staticClass: "title line1" },
                                          [_vm._v(_vm._s(item.store_name))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.priceShow
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "price line1",
                                            style: "color:" + _vm.priceColor,
                                          },
                                          [
                                            _vm._v(
                                              "\n                ¥" +
                                                _vm._s(item.price) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.bntShow
                                      ? _c("span", { staticClass: "box-btn" }, [
                                          _vm._v("去助力"),
                                          _c("span", {
                                            staticClass:
                                              "iconfont-diy iconjinru",
                                          }),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "img-box" }, [
                                item.img
                                  ? _c("img", {
                                      attrs: { src: item.img, alt: "" },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "empty-box",
                                    class: _vm.conStyle ? "" : "bargainOn",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "iconfont-diy icontupian",
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-left" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/assist_title.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "avatar-wrapper" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/ren1.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("img", {
          attrs: { src: require("@/assets/images/ren2.png"), alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "num" }, [_vm._v("1234人助力成功")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("更多 "),
      _c("span", { staticClass: "iconfont-diy iconjinru" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }