"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uploadPicture = _interopRequireDefault(require("@/components/uploadPicture"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'pageTitle',
  components: {
    uploadPictures: _uploadPicture.default
  },
  data: function data() {
    return {
      value: '',
      name: '',
      isShow: true,
      picList: ['icondantu', 'iconpingpu', 'iconlashen'],
      bgColor: false,
      bgPic: false,
      tabVal: '0',
      colorPicker: '#f5f5f5',
      modalPic: false,
      isChoice: '单选',
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      },
      bgPicUrl: ''
    };
  },
  created: function created() {
    var state = this.$store.state.mobildConfig;
    this.value = state.pageTitle;
    this.name = state.pageName;
    this.isShow = !!state.pageShow;
    this.bgColor = !!state.pageColor;
    this.bgPic = !!state.pagePic;
    this.colorPicker = state.pageColorPicker;
    this.tabVal = state.pageTabVal;
    this.bgPicUrl = state.pagePicUrl;
  },
  methods: {
    // 点击图文封面
    modalPicTap: function modalPicTap() {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.bgPicUrl = img[0];
        _this.$store.commit('mobildConfig/UPPICURL', _this.bgPicUrl);
      });
    },
    bindDelete: function bindDelete() {
      this.bgPicUrl = '';
    },
    getPic: function getPic(pc) {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.bgPicUrl = pc.att_dir;
        _this2.modalPic = false;
        _this2.$store.commit('mobildConfig/UPPICURL', pc.att_dir);
      });
    },
    colorPickerTap: function colorPickerTap(colorPicker) {
      this.$store.commit('mobildConfig/UPPICKER', colorPicker);
    },
    radioTap: function radioTap(val) {
      this.$store.commit('mobildConfig/UPRADIO', val);
    },
    changVal: function changVal(val) {
      this.$store.commit('mobildConfig/UPTITLE', val);
    },
    changName: function changName(val) {
      this.$store.commit('mobildConfig/UPNAME', val);
    },
    changeState: function changeState(val) {
      this.$store.commit('mobildConfig/UPSHOW', val);
    },
    bgColorTap: function bgColorTap(val) {
      this.$store.commit('mobildConfig/UPCOLOR', val);
    },
    bgPicTap: function bgPicTap(val) {
      this.$store.commit('mobildConfig/UPPIC', val);
    }
  }
};