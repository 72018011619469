"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _vueQr = _interopRequireDefault(require("vue-qr"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 二维码组件
var _default = exports.default = {
  components: {
    VueQr: _vueQr.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      delivery_balance: '',
      listLoading: true,
      loading: true,
      tableFrom: {
        keyword: '',
        date: '',
        station_id: '',
        page: 1,
        limit: 20
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      amountList: [{
        label: '10.00元',
        value: 10
      }, {
        label: '50.00元',
        value: 50
      }, {
        label: '100.00元',
        value: 100
      }, {
        label: '200.00元',
        value: 200
      }, {
        label: '500.00元',
        value: 500
      }, {
        label: '1000.00元',
        value: 1000
      }],
      qrCode: '',
      endtime: '',
      price: 10,
      spinShow: false
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _order.rechargeLst)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.delivery_balance = res.data.delivery_balance;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 去充值
    toRecharge: function toRecharge() {
      this.dialogVisible = true;
      this.getQRCode();
    },
    getQRCode: function getQRCode() {
      var _this2 = this;
      this.spinShow = true;
      (0, _order.rechargeInfoApi)({
        price: this.price
      }).then(function (res) {
        _this2.$set(_this2, 'endtime', res.data.endtime);
        _this2.$set(_this2, 'qrCode', res.data.config);
        setTimeout(function () {
          _this2.spinShow = false;
        }, 300);
      }).catch(function (res) {
        _this2.spinShow = false;
        _this2.$message.error(res.message);
      });
    }
  }
};