"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'wechat_live',
  cname: '小程序直播',
  configName: 'c_wechat_live',
  type: 1,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'liveBroadcast',
  // 外面匹配名称
  icon: 'iconxiaochengxuzhibo3',
  props: {
    index: {
      type: null,
      default: -1
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'liveBroadcast',
        timestamp: this.num,
        bg: {
          title: '背景色',
          name: 'bg',
          default: [{
            item: '#fff'
          }],
          color: [{
            item: '#fff'
          }]
        },
        listStyle: {
          title: '列表样式',
          name: 'listStyle',
          type: 0,
          list: [{
            val: '单行',
            icon: 'icondanhang'
          }, {
            val: '多行',
            icon: 'iconduohang'
          }, {
            val: '双排',
            icon: 'iconlianglie'
          }]
        },
        bgStyle: {
          title: '背景样式',
          name: 'bgStyle',
          type: 0,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        }
      },
      live: [{
        title: '直播中',
        name: 'playBg',
        type: 2,
        color: '',
        icon: 'iconzhibozhong',
        goods: []
      }, {
        title: '回放',
        name: 'endBg',
        type: 0,
        color: '',
        icon: 'iconyijieshu',
        goods: [{
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }]
      }, {
        title: '预告',
        name: 'notBg',
        type: 1,
        color: '',
        icon: 'iconweikaishi',
        goods: [{
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }]
      }, {
        title: '直播中',
        name: 'playBg',
        type: 2,
        color: '',
        icon: 'iconzhibozhong',
        goods: [{
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.crmeb.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }]
      }],
      cSlider: '',
      confObj: {},
      pageData: {},
      listStyle: 0,
      bgStyle: 0,
      bg: ''
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbConfig) {
        this.cSlider = data.mbConfig.val;
        this.listStyle = data.listStyle.type;
        this.bgStyle = data.bgStyle.type;
        this.bg = data.bg.color[0].item;
      }
    }
  }
};