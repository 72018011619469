var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodList" },
    [
      _c(
        "el-form",
        { attrs: { size: "small", inline: "", "label-width": "150px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品分类：" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item selWidth mr20",
                  attrs: { placeholder: "请选择", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.mer_cate_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "mer_cate_id", $$v)
                    },
                    expression: "tableFrom.mer_cate_id",
                  },
                },
                _vm._l(_vm.merCateList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "商品搜索：" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "240px" },
                  attrs: { placeholder: "请输入商品名称,关键字,编号" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "keyword", $$v)
                    },
                    expression: "tableFrom.keyword",
                  },
                },
                [
                  _c("el-button", {
                    staticClass: "el-button-solt",
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { data: _vm.tableData.data },
          on: { "selection-change": _vm.changeCheckbox },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "product_id", label: "商品id", "min-width": "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品图", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.image,
                            "preview-src-list": [scope.row.image],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "store_name",
              label: "商品名称",
              "min-width": "200",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "acea-row row-right page" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.many === "many" && !_vm.diy
        ? _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "primary",
                    size: "large",
                    loading: _vm.modal_loading,
                  },
                  on: { click: _vm.ok },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }