"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = _interopRequireDefault(require("@/components/mobileConfigRight/index.js"));
var _index2 = _interopRequireDefault(require("@/components/rightBtn/index.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'pageFoot',
  cname: '底部菜单',
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  data: function data() {
    return {
      hotIndex: 1,
      configObj: {},
      // 配置对象
      rCom: [{
        components: _index.default.c_set_up,
        configNme: 'setUp'
      }] // 当前页面组件
    };
  },
  watch: {
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        var arr = [this.rCom[0]];
        if (nVal === 0) {
          var tempArr = [{
            components: _index.default.c_status,
            configNme: 'status'
          }, {
            components: _index.default.c_foot,
            configNme: 'menuList'
          }];
          this.rCom = arr.concat(tempArr);
        } else {
          var _tempArr = [{
            components: _index.default.c_bg_color,
            configNme: 'txtColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'activeTxtColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'bgColor'
          }];
          this.rCom = arr.concat(_tempArr);
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.configObj = this.$store.state.mobildConfig.pageFooter;
    console.log('2222', this.configObj);
  },
  methods: {}
};