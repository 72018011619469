"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _elementUi = _interopRequireDefault(require("element-ui"));
var _router = _interopRequireDefault(require("../router"));
var _settings = require("@/settings");
var _vue = _interopRequireDefault(require("vue"));
function bindEvent(vm) {
  vm.$on('notice', function (data) {
    this.$notify.info({
      title: data.title || '消息',
      message: data.message,
      duration: 5000,
      onClick: function onClick() {
        console.log('click');
      }
    });
  });
}
function createWebScoket(token) {
  return new WebSocket("".concat(_settingMer.default.wsSocketUrl, "?type=mer&token=").concat(token));
}
function notice(token) {
  var ws = createWebScoket(token);
  var vm = new _vue.default();
  var ping;
  function send(type, data) {
    ws.send(JSON.stringify({
      type: type,
      data: data
    }));
  }
  ws.onopen = function () {
    vm.$emit('open');
    ping = setInterval(function () {
      send('ping');
    }, 10000);
  };
  ws.onmessage = function (res) {
    vm.$emit('message', res);
    var data = JSON.parse(res.data);
    if (data.status === 200) {
      vm.$emit(data.data.status, data.data.result);
    }
    if (data.type === 'notice') {
      var h = vm.$createElement;
      _elementUi.default.Notification({
        title: data.data.data.title,
        duration: 0,
        message: h('a', {
          style: 'color: teal'
        }, data.data.data.message),
        onClick: function onClick() {
          if (data.data.type === 'min_stock' || data.data.type === 'product') {
            _router.default.push({
              path: "".concat(_settings.roterPre, "/product/list")
            });
          } else if (data.data.type === 'reply') {
            // 评价
            _router.default.push({
              path: "".concat(_settings.roterPre, "/product/reviews")
            });
          } else if (data.data.type === 'product_success') {
            // 普通商品审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/product/list?id=") + data.data.data.id + "&type=2"
            });
          } else if (data.data.type === 'product_fail') {
            // 普通商品审核未通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/product/list?id=") + data.data.data.id + "&type=7"
            });
          } else if (data.data.type === 'product_seckill_success') {
            // 秒杀商品审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/seckill/list?id=") + data.data.data.id + "&type=2"
            });
          } else if (data.data.type === 'product_seckill_fail') {
            // 秒杀商品审核未通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/seckill/list?id=") + data.data.data.id + "&type=7"
            });
          } else if (data.data.type === 'new_order') {
            _router.default.push({
              path: "".concat(_settings.roterPre, "/order/list?id=") + data.data.data.id
            });
          } else if (data.data.type === 'new_refund_order') {
            _router.default.push({
              path: "".concat(_settings.roterPre, "/order/refund?id=") + data.data.data.id
            });
          } else if (data.data.type === 'product_presell_success') {
            // 预售商品审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/presell/list?id=") + data.data.data.id + "&type=" + data.data.data.type + "&status=1"
            });
          } else if (data.data.type === 'product_presell_fail') {
            // 预售商品审核未通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/presell/list?id=") + data.data.data.id + "&type=" + data.data.data.type + "&status=-1"
            });
          } else if (data.data.type === 'product_group_success') {
            // 拼团商品审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/combination/combination_goods?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'product_group_fail') {
            // 拼团商品审核未通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/combination/combination_goods?id=") + data.data.data.id + "&status=-1"
            });
          } else if (data.data.type === 'product_assist_success') {
            // 助力商品审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/assist/list?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'product_assist_fail') {
            // 助力商品审核未通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/assist/list?id=") + data.data.data.id + "&status=-1"
            });
          } else if (data.data.type === 'broadcast_status_success') {
            // 直播间审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/studio/list?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'broadcast_status_fail') {
            // 直播间审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/studio/list?id=") + data.data.data.id + "&status=-1"
            });
          } else if (data.data.type === 'goods_status_success') {
            // 直播商品审核通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/broadcast/list?id=") + data.data.data.id + "&status=1"
            });
          } else if (data.data.type === 'goods_status_fail') {
            // 直播商品审核未通过
            _router.default.push({
              path: "".concat(_settings.roterPre, "/marketing/broadcast/list?id=") + data.data.data.id + "&status=-1"
            });
          }
        }
      });
    }
  };
  ws.onclose = function (e) {
    vm.$emit('close', e);
    clearInterval(ping);
  };
  bindEvent(vm);
  return function () {
    ws.close();
  };
}
var _default = exports.default = notice;