var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-page" }, [
    _c("div", {
      staticClass: "box",
      style: { height: _vm.cSlider + "px", background: _vm.bgColor },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }