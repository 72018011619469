var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.station_id ? "编辑发货点" : "添加发货点",
        visible: _vm.dialogVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "description" },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formValidate",
                  attrs: {
                    size: "small",
                    model: _vm.formValidate,
                    rules: _vm.rules,
                    "label-width": "140px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货点名称：", prop: "station_name" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入发货点名称",
                          size: "small",
                        },
                        model: {
                          value: _vm.formValidate.station_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "station_name", $$v)
                          },
                          expression: "formValidate.station_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送品类：", prop: "business" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.formValidate.business,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "business", $$v)
                            },
                            expression: "formValidate.business",
                          },
                        },
                        _vm._l(_vm.categoryList, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.label, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属城市：",
                        prop: "city_name",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.formValidate.city_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "city_name", $$v)
                            },
                            expression: "formValidate.city_name",
                          },
                        },
                        _vm._l(_vm.cityList, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.label, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "发货点地址：", prop: "station_address" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入发货点地址",
                          size: "small",
                        },
                        model: {
                          value: _vm.formValidate.station_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "station_address", $$v)
                          },
                          expression: "formValidate.station_address",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经纬度：", prop: "location" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "enter-button": "查找位置",
                            placeholder: "请查找位置",
                          },
                          model: {
                            value: _vm.formValidate.location,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "location", $$v)
                            },
                            expression: "formValidate.location",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "#46a6ff",
                                color: "#fff",
                                "border-radius": "0 4px 4px 0",
                              },
                              attrs: { slot: "append" },
                              on: { click: _vm.onSearchs },
                              slot: "append",
                            },
                            [_vm._v("查找位置")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("请点击查找位置选择位置")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人姓名：", prop: "contact_name" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入联系人姓名",
                          size: "small",
                        },
                        model: {
                          value: _vm.formValidate.contact_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "contact_name", $$v)
                          },
                          expression: "formValidate.contact_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人电话：", prop: "phone" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入联系人电话",
                          size: "small",
                        },
                        model: {
                          value: _vm.formValidate.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "phone", $$v)
                          },
                          expression: "formValidate.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人身份证：" } },
                    [
                      _c("el-input", {
                        staticClass: "selWidth",
                        attrs: {
                          placeholder: "请输入联系人身份证",
                          size: "small",
                        },
                        model: {
                          value: _vm.formValidate.id_card,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "id_card", $$v)
                          },
                          expression: "formValidate.id_card",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.deliveryType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "达达商家app账号：", required: "" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入app账号(必须是手机号)",
                              size: "small",
                            },
                            model: {
                              value: _vm.formValidate.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "username", $$v)
                              },
                              expression: "formValidate.username",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.deliveryType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "达达商家app密码：", required: "" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "请输入商家app密码",
                              size: "small",
                            },
                            model: {
                              value: _vm.formValidate.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "password", $$v)
                              },
                              expression: "formValidate.password",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-text": "开启",
                          "inactive-text": "关闭",
                        },
                        model: {
                          value: _vm.formValidate.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "status", $$v)
                          },
                          expression: "formValidate.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "dialog-footerdialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.modalMap
            ? _c(
                "el-dialog",
                {
                  staticClass: "mapBox",
                  attrs: {
                    visible: _vm.modalMap,
                    title: "选择位置",
                    "close-on-click-modal": "",
                    "custom-class": "dialog-scustom",
                    "append-to-body": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.modalMap = $event
                    },
                  },
                  model: {
                    value: _vm.modalMap,
                    callback: function ($$v) {
                      _vm.modalMap = $$v
                    },
                    expression: "modalMap",
                  },
                },
                [
                  _c("iframe", {
                    attrs: {
                      id: "mapPage",
                      width: "100%",
                      height: "500px",
                      frameborder: "0",
                      src: _vm.keyUrl,
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }