var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "box" },
        [
          _c("WangEditor", {
            staticStyle: { width: "100%", height: "60%" },
            attrs: { content: _vm.configData.val },
            on: { editorContent: _vm.getEditorContent },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }