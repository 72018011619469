"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Record',
  data: function data() {
    return {
      loading: false,
      roterPre: _settings.roterPre,
      timeVal: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        date: '',
        status: '',
        keyword: '',
        reconciliation_id: this.$route.query.reconciliation_id ? this.$route.query.reconciliation_id : ''
      },
      ruleForm: {
        status: '0'
      },
      dialogVisible: false,
      rules: {
        status: [{
          required: true,
          message: '请选择对账状态',
          trigger: 'change'
        }]
      },
      reconciliationId: 0
    };
  },
  computed: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    onMark: function onMark(id) {
      var _this = this;
      this.$modalForm((0, _accounts.reconciliationMarkApi)(id)).then(function () {
        return _this.getList();
      });
    },
    onAccounts: function onAccounts(id) {
      this.reconciliationId = id;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$refs['ruleForm'].resetFields();
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          (0, _accounts.reconciliationStatusApi)(_this2.reconciliationId, _this2.ruleForm).then(function (res) {
            _this2.$message.success(res.message);
            _this2.loading = false;
            _this2.handleClose();
            _this2.getList();
          }).catch(function (res) {
            _this2.$message.error(res.message);
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = this.timeVal ? this.timeVal.join('-') : '';
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _accounts.reconciliationListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.chkName = '';
      this.getList();
    }
  }
};