"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _selectedGoods = _interopRequireDefault(require("./selectedGoods"));
var _index = _interopRequireDefault(require("@/components/importGoods/index"));
var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StudioDetail',
  components: {
    selectedGoods: _selectedGoods.default,
    importGoods: _index.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      option: {
        form: {
          labelWidth: '150px'
        }
      },
      FormData: {},
      loading: false,
      type: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    getData: function getData(id, type) {
      var _this = this;
      this.loading = true;
      this.type = type;
      (0, _marketing.broadcastDetailApi)(id).then(function (res) {
        _this.FormData = res.data;
        _this.loading = false;
        console.log(_this.FormData);
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.loading = false;
      });
    },
    // 导入
    handleImport: function handleImport(row) {
      this.$refs.uploadGoods.importVisible = true;
      this.$refs.uploadGoods.broadcast_id = row.broadcast_room_id;
      this.$refs.uploadGoods.broadcast_room = row.name;
      this.$refs.uploadGoods.image = '';
      localStorage.setItem('liveRoom_id', row.broadcast_room_id);
    },
    handleRemarks: function handleRemarks(id) {
      var _this2 = this;
      (0, _marketing.broadcastRemarksApi)(this.FormData.broadcast_room_id, this.FormData.mark).then(function (res) {
        _this2.loading = false;
        _this2.$message.success(res.message);
        _this2.dialogVisible = false;
        _this2.$emit('getList');
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.loading = false;
      });
    },
    getList: function getList() {
      this.$refs.selectedGoods.getList();
    },
    handleClipboard: function handleClipboard(text, event) {
      (0, _clipboard.default)(text, event);
    }
  }
};