var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-page" }, [
    _vm.isUpdate
      ? _c(
          "div",
          [
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("布局")]),
            _vm._v(" "),
            _c("div", { staticClass: "tip" }, [
              _vm._v("选定布局区域，在下方添加图片，建议添加比例一致的图片"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "advert" },
              [
                _vm._l(_vm.configData.picList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "advertItem01 acea-row" },
                    [
                      _vm.style === 0
                        ? [
                            item.image
                              ? _c("img", { attrs: { src: item.image } })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._v("尺寸不限"),
                                ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _vm.style === 1
                  ? _c(
                      "div",
                      { staticClass: "advertItem02 acea-row" },
                      _vm._l(_vm.configData.picList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            class: _vm.currentIndex === index ? "on" : "",
                            on: {
                              click: function ($event) {
                                return _vm.currentTab(index, _vm.configData)
                              },
                            },
                          },
                          [
                            item.image
                              ? _c("img", { attrs: { src: item.image } })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._m(0, true),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.style === 2
                  ? _c(
                      "div",
                      { staticClass: "advertItem02 advertItem03 acea-row" },
                      _vm._l(_vm.configData.picList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            class: _vm.currentIndex === index ? "on" : "",
                            on: {
                              click: function ($event) {
                                return _vm.currentTab(index, _vm.configData)
                              },
                            },
                          },
                          [
                            item.image
                              ? _c("img", { attrs: { src: item.image } })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._m(1, true),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.style === 3
                  ? _c("div", { staticClass: "advertItem04 acea-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "item",
                          class: _vm.currentIndex === 0 ? "on" : "",
                          on: {
                            click: function ($event) {
                              return _vm.currentTab(0, _vm.configData)
                            },
                          },
                        },
                        [
                          _vm.configData.picList[0].image
                            ? _c("img", {
                                attrs: { src: _vm.configData.picList[0].image },
                              })
                            : _c("div", { staticClass: "empty-box" }, [
                                _vm._v("375*375像素或同比例"),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "pic",
                            class: _vm.currentIndex === 1 ? "on" : "",
                            on: {
                              click: function ($event) {
                                return _vm.currentTab(1, _vm.configData)
                              },
                            },
                          },
                          [
                            _vm.configData.picList[1].image
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.configData.picList[1].image,
                                  },
                                })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._v("375*188像素或同比例"),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "pic",
                            class: _vm.currentIndex === 2 ? "on" : "",
                            on: {
                              click: function ($event) {
                                return _vm.currentTab(2, _vm.configData)
                              },
                            },
                          },
                          [
                            _vm.configData.picList[2].image
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.configData.picList[2].image,
                                  },
                                })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._v("375*188像素或同比例"),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.style === 4
                  ? _c(
                      "div",
                      { staticClass: "advertItem02 advertItem05 acea-row" },
                      _vm._l(_vm.configData.picList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            class: _vm.currentIndex === index ? "on" : "",
                            on: {
                              click: function ($event) {
                                return _vm.currentTab(index, _vm.configData)
                              },
                            },
                          },
                          [
                            item.image
                              ? _c("img", { attrs: { src: item.image } })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._v("宽188像素高度不限"),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.style === 5
                  ? _c(
                      "div",
                      { staticClass: "advertItem06 acea-row" },
                      _vm._l(_vm.configData.picList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            class: _vm.currentIndex === index ? "on" : "",
                            on: {
                              click: function ($event) {
                                return _vm.currentTab(index, _vm.configData)
                              },
                            },
                          },
                          [
                            item.image
                              ? _c("img", { attrs: { src: item.image } })
                              : _c("div", { staticClass: "empty-box" }, [
                                  _vm._v("375*188像素或同比例"),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [_vm._v("宽375像素")]),
      _vm._v(" "),
      _c("div", [_vm._v("高度不限")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [_vm._v("宽250像素")]),
      _vm._v(" "),
      _c("div", [_vm._v("高度不限")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }