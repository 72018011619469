var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configData
    ? _c(
        "div",
        { staticClass: "c_product" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.configData.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-box" },
            [
              _c(
                "draggable",
                {
                  staticClass: "dragArea list-group",
                  attrs: {
                    list: _vm.configData.list,
                    group: "peoples",
                    handle: ".move-icon",
                  },
                },
                _vm._l(_vm.configData.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.activeBtn(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "move-icon" }, [
                        _c("span", { staticClass: "iconfont-diy icondrag2" }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm._l(item.chiild, function (list, key) {
                            return _c(
                              "div",
                              { key: key, staticClass: "con-item" },
                              [
                                _c("span", [_vm._v(_vm._s(list.title))]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { width: "100%" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getLink(index, key, item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: {
                                          readonly:
                                            key && !item.link ? true : false,
                                          placeholder: list.pla,
                                          maxlength: list.max,
                                        },
                                        model: {
                                          value: list.val,
                                          callback: function ($$v) {
                                            _vm.$set(list, "val", $$v)
                                          },
                                          expression: "list.val",
                                        },
                                      },
                                      [
                                        key && !item.link
                                          ? _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                icon: "el-icon-arrow-right",
                                              },
                                              slot: "append",
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          item.link
                            ? _c(
                                "div",
                                { staticClass: "con-item" },
                                [
                                  _c("span", [_vm._v(_vm._s(item.link.title))]),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.sliderChange(index)
                                        },
                                      },
                                      model: {
                                        value: item.link.activeVal,
                                        callback: function ($$v) {
                                          _vm.$set(item.link, "activeVal", $$v)
                                        },
                                        expression: "item.link.activeVal",
                                      },
                                    },
                                    _vm._l(
                                      item.link.optiops,
                                      function (item, j) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: j,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.label) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.bindDelete(index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-error",
                            staticStyle: { "font-size": "26px" },
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.configData.list
            ? _c("div", [
                _vm.configData.list.length < _vm.configData.max
                  ? _c(
                      "div",
                      { staticClass: "add-btn", on: { click: _vm.addHotTxt } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "100%",
                              height: "40px",
                              "border-color": "#1890ff",
                              color: "#1890ff",
                              "font-size": "12px",
                            },
                          },
                          [_vm._v("添加模块")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("linkaddress", {
            ref: "linkaddres",
            on: { linkUrl: _vm.linkUrl },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }