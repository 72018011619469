var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bgColor
    ? _c(
        "div",
        {
          style: {
            padding: "0 " + _vm.prConfig + "px",
            marginTop: _vm.mTOP + "px",
          },
        },
        [
          _vm.bgColor.length > 0
            ? _c(
                "div",
                {
                  staticClass: "home_coupon",
                  class: _vm.bgStyle === 0 ? "" : "couponOn",
                  style: { background: _vm.bgColor[0].item },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-wrapper",
                      style: { color: _vm.titleColor },
                    },
                    [_c("span", [_vm._v("领优惠券")]), _vm._v(" "), _vm._m(0)]
                  ),
                  _vm._v(" "),
                  _vm.couponBg.length > 0
                    ? _c("div", { staticClass: "coupon" }, [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            style: {
                              background:
                                "linear-gradient(90deg," +
                                _vm.couponBg[0].item +
                                " 0%," +
                                _vm.couponBg[1].item +
                                " 100%)",
                            },
                          },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "roll up-roll",
                              style: { background: _vm.bgColor[0].item },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "roll down-roll",
                              style: { background: _vm.bgColor[0].item },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "item gary" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _vm._m(4),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "roll up-roll",
                            style: { background: _vm.bgColor[0].item },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "roll down-roll",
                            style: { background: _vm.bgColor[0].item },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            style: {
                              background:
                                "linear-gradient(180deg," +
                                _vm.couponBg[0].item +
                                " 0%," +
                                _vm.couponBg[1].item +
                                " 100%)",
                            },
                          },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "roll up-roll",
                              style: { background: _vm.bgColor[0].item },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "roll down-roll",
                              style: { background: _vm.bgColor[0].item },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("查看更多 "),
      _c("span", { staticClass: "iconfont-diy iconjinru" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "num" }, [
        _c("span", [_vm._v("￥")]),
        _vm._v("50"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("满100元可用")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("立"),
      _c("br"),
      _vm._v("即"),
      _c("br"),
      _vm._v("领"),
      _c("br"),
      _vm._v("取"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "num" }, [
        _c("span", [_vm._v("￥")]),
        _vm._v("50"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("满100元可用")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("立"),
      _c("br"),
      _vm._v("即"),
      _c("br"),
      _vm._v("领"),
      _c("br"),
      _vm._v("取"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "num" }, [
        _c("span", [_vm._v("￥")]),
        _vm._v("50"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("满100元可用")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("立"),
      _c("br"),
      _vm._v("即"),
      _c("br"),
      _vm._v("领"),
      _c("br"),
      _vm._v("取"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }