"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = _interopRequireDefault(require("@/components/mobileConfigRight/index.js"));
var _index2 = _interopRequireDefault(require("@/components/rightBtn/index.vue"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_home_hot',
  componentsName: 'home_hot',
  cname: '推荐组',
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  props: {
    activeIndex: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    },
    index: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      configObj: {},
      configData: {},
      rCom: [{
        components: _index.default.c_set_up,
        configNme: 'setUp'
      }]
    };
  },
  watch: {
    num: function num(nVal) {
      var value = JSON.parse(JSON.stringify(this.$store.state.mobildConfig.defaultArray[nVal]));
      this.configObj = value;
    },
    configObj: {
      handler: function handler(nVal, oVal) {
        this.$store.commit('mobildConfig/UPDATEARR', {
          num: this.num,
          val: nVal
        });
      },
      deep: true
    },
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        var arr = [this.rCom[0]];
        if (+nVal === 0) {
          var tempArr = [{
            components: _index.default.c_menu_list,
            configNme: 'menuConfig'
          }];
          this.rCom = arr.concat(tempArr);
        } else {
          var _tempArr = [{
            components: _index.default.c_tab,
            configNme: 'tabConfig'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'boxColor'
          }, {
            components: _index.default.c_slider,
            configNme: 'mbConfig'
          }];
          this.rCom = arr.concat(_tempArr);
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var value = JSON.parse(JSON.stringify(_this.$store.state.mobildConfig.defaultArray[_this.num]));
      _this.configObj = value;
    });
  },
  methods: _objectSpread({
    getConfig: function getConfig(data) {},
    handleSubmit: function handleSubmit(name) {
      var obj = {};
      obj.activeIndex = this.activeIndex;
      obj.data = this.configObj;
      this.add(obj);
    }
  }, (0, _vuex.mapMutations)({
    add: 'mobildConfig/UPDATEARR'
  }))
};