"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TMap = TMap;
function TMap(key) {
  return new Promise(function (resolve, reject) {
    window.init = function () {
      resolve(window.qq); // 关键
    };
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://map.qq.com/api/js?v=2.exp&callback=init&key=".concat(key);
    script.onerror = reject;
    document.head.appendChild(script);
  });
}