var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("添加专场")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _vm._l(_vm.columns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.key,
                    label: item.title,
                    "min-width": item.minWidth,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            ["img", "image", "pic"].indexOf(item.key) > -1 ||
                            item.key.indexOf("pic") > -1 ||
                            item.key.indexOf("img") > -1 ||
                            item.key.indexOf("image") > -1 ||
                            item.key.indexOf("banner") > -1
                              ? _c(
                                  "div",
                                  { staticClass: "demo-image__preview" },
                                  [
                                    Array.isArray(scope.row[item.key])
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            scope.row[item.key],
                                            function (item, index) {
                                              return _c(
                                                "span",
                                                { key: index },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "36px",
                                                      height: "36px",
                                                      "margin-right": "5px",
                                                    },
                                                    attrs: { src: item },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "36px",
                                                height: "36px",
                                              },
                                              attrs: {
                                                src: scope.row[item.key],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ]
                                )
                              : item.key == "type"
                              ? _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row[item.key] == 1
                                          ? "小图"
                                          : scope.row[item.key] == 2
                                          ? "中图"
                                          : "大图"
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[item.key])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "是否显示",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row.group_data_id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.group_data_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableData.limit,
                  "current-page": _vm.tableData.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }