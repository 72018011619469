"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
function authPermission(permission) {
  var all_permission = '*:*:*';
  var permissions = _store.default.getters && _store.default.getters.permissions;
  if (permission && permission.length > 0) {
    return permissions.some(function (v) {
      return all_permission === v || v === permission;
    });
  } else {
    return false;
  }
}

// function authRole(role) {
//   const super_admin = 'admin'
//   const roles = store.getters && store.getters.roles
//   if (role && role.length > 0) {
//     return roles.some(v => {
//       return super_admin === v || v === role
//     })
//   } else {
//     return false
//   }
// }
var _default = exports.default = {
  // 验证用户是否具备某权限
  hasPermi: function hasPermi(permission) {
    return authPermission(permission);
  },
  // 验证用户是否含有指定权限，只需包含其中一个
  hasPermiOr: function hasPermiOr(permissions) {
    return permissions.some(function (item) {
      return authPermission(item);
    });
  },
  // 验证用户是否含有指定权限，必须全部拥有
  hasPermiAnd: function hasPermiAnd(permissions) {
    return permissions.every(function (item) {
      return authPermission(item);
    });
  }
};