"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CreatAttr',
  props: {
    currentRow: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      inputVisible: false,
      inputValue: '',
      spinShow: false,
      loading: false,
      grid: {
        xl: 3,
        lg: 3,
        md: 12,
        sm: 24,
        xs: 24
      },
      modal: false,
      index: 1,
      rules: {
        template_name: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'blur'
        }]
      },
      formDynamic: {
        template_name: '',
        template_value: []
      },
      attrsName: '',
      attrsVal: '',
      formDynamicNameData: [],
      isBtn: false,
      formDynamicName: [],
      results: [],
      result: [],
      ids: 0
    };
  },
  watch: {
    currentRow: {
      handler: function handler(val, oldVal) {
        this.formDynamic = val;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.formDynamic.template_value.map(function (item) {
      _this.$set(item, 'inputVisible', false);
    });
  },
  methods: {
    resetForm: function resetForm(formName) {
      this.$msgbox.close();
      this.clear();
      this.$refs[formName].resetFields();
    },
    // 添加按钮
    addBtn: function addBtn() {
      this.isBtn = true;
    },
    handleClose: function handleClose(item, index) {
      item.splice(index, 1);
    },
    // 取消
    offAttrName: function offAttrName() {
      this.isBtn = false;
    },
    // 删除
    handleRemove: function handleRemove(index) {
      this.formDynamic.template_value.splice(index, 1);
    },
    // 添加规则名称
    createAttrName: function createAttrName() {
      if (this.attrsName && this.attrsVal) {
        var data = {
          value: this.attrsName,
          detail: [this.attrsVal]
        };
        this.formDynamic.template_value.push(data);
        var hash = {};
        this.formDynamic.template_value = this.formDynamic.template_value.reduce(function (item, next) {
          /* eslint-disable */
          hash[next.value] ? '' : hash[next.value] = true && item.push(next);
          return item;
        }, []);
        this.attrsName = '';
        this.attrsVal = '';
        this.isBtn = false;
      } else {
        if (!this.attrsName) {
          this.$message.warning('请输入规格名称！');
          return;
        }
        if (!this.attrsVal) {
          this.$message.warning('请输入规格值！');
          return;
        }
      }
    },
    // 添加属性
    createAttr: function createAttr(num, idx) {
      if (num) {
        this.formDynamic.template_value[idx].detail.push(num);
        var hash = {};
        this.formDynamic.template_value[idx].detail = this.formDynamic.template_value[idx].detail.reduce(function (item, next) {
          /* eslint-disable */
          hash[next] ? '' : hash[next] = true && item.push(next);
          return item;
        }, []);
        this.formDynamic.template_value[idx].inputVisible = false;
      } else {
        this.$message.warning('请添加属性');
      }
    },
    showInput: function showInput(item) {
      this.$set(item, 'inputVisible', true);
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var _this2 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          if (_this2.formDynamic.template_value.length === 0) {
            return _this2.$message.warning('请至少添加一条属性规格！');
          }
          _this2.loading = true;
          setTimeout(function () {
            _this2.currentRow.attr_template_id ? (0, _product.attrEdittApi)(_this2.currentRow.attr_template_id, _this2.formDynamic).then(function (res) {
              _this2.$message.success(res.message);
              _this2.loading = false;
              setTimeout(function () {
                _this2.$msgbox.close();
              }, 500);
              setTimeout(function () {
                _this2.clear();
                _this2.$emit('getList');
              }, 600);
            }).catch(function (res) {
              _this2.loading = false;
              _this2.$message.error(res.message);
            }) : (0, _product.attrCreatApi)(_this2.formDynamic).then(function (res) {
              _this2.$message.success(res.message);
              _this2.loading = false;
              setTimeout(function () {
                _this2.$msgbox.close();
              }, 500);
              setTimeout(function () {
                _this2.$emit('getList');
                _this2.clear();
              }, 600);
            }).catch(function (res) {
              _this2.loading = false;
              _this2.$message.error(res.message);
            });
          }, 1200);
        } else {
          return false;
        }
      });
    },
    clear: function clear() {
      this.$refs['formDynamic'].resetFields();
      this.formDynamic.template_value = [];
      this.formDynamic.template_name = '';
      this.isBtn = false;
      this.attrsName = '';
      this.attrsVal = '';
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  }
};