"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _goodsList = _interopRequireDefault(require("./goodsList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodList',
  components: {
    selectGoods: _goodsList.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 3,
        status_tag: 1,
        keyword: ''
      },
      checked: [],
      broadcast_room_id: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    // 批量添加直播商品
    selectGoods: function selectGoods() {
      this.$refs.selectGoods.dialogVisible = true;
      this.$refs.selectGoods.getList(this.tableData.data, 1);
    },
    // 删除
    handleDelete: function handleDelete(idx) {
      this.tableData.data.splice(idx, 1);
    },
    // 提交
    submit: function submit() {
      var _this2 = this;
      var goodsList = this.filtersArr(this.tableData.data);
      (0, _marketing.batchAddBroadcastGoodsApi)({
        goods: goodsList
      }).then(function (res) {
        _this2.$message.success(res.message);
        _this2.dialogVisible = false;
        _this2.$emit('get-list');
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 过滤id
    filtersArr: function filtersArr(arr) {
      var newcurrentDateItemList = arr.map(function (item, index) {
        return Object.assign({}, {
          product_id: item.product_id,
          name: item.store_name,
          cover_img: item.image,
          price: item.price
        });
      });
      return newcurrentDateItemList;
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.tableData.data[i].image = img[0];
      }, tit);
    },
    // 列表
    getList: function getList(data) {
      this.tableData.data = data;
      this.tableData.total = data.length;
      this.listLoading = false;
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    }
  }
};