"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
var _product = require("@/api/product");
var _settings = require("@/settings");
var _taoBao = _interopRequireDefault(require("./taoBao"));
var _editAttr = _interopRequireDefault(require("./editAttr"));
var _index = _interopRequireDefault(require("@/components/previewBox/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ProductList',
  components: {
    taoBao: _taoBao.default,
    previewBox: _index.default,
    editAttr: _editAttr.default
  },
  data: function data() {
    return {
      props: {
        emitPath: false
      },
      roterPre: _settings.roterPre,
      headeNum: [],
      labelList: [],
      tempList: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        mer_cate_id: '',
        cate_id: '',
        keyword: '',
        temp_id: '',
        type: this.$route.query.type ? this.$route.query.type : '1',
        is_gift_bag: '',
        us_status: '',
        mer_labels: '',
        product_id: this.$route.query.id ? this.$route.query.id : ''
      },
      categoryList: [],
      // 平台
      merCateList: [],
      // 商户分类筛选
      modals: false,
      tabClickIndex: '',
      multipleSelection: [],
      productStatusList: [{
        label: '上架显示',
        value: 1
      }, {
        label: '下架',
        value: 0
      }, {
        label: '平台关闭',
        value: -1
      }],
      tempRule: {
        temp_id: [{
          required: true,
          message: '请选择运费模板',
          trigger: 'change'
        }]
      },
      goodsId: '',
      previewKey: '',
      product_id: '',
      previewVisible: false,
      dialogLabel: false,
      dialogFreight: false,
      is_audit: false,
      deliveryType: [],
      deliveryList: [],
      labelForm: {},
      tempForm: {},
      isBatch: false
    };
  },
  mounted: function mounted() {
    this.getLstFilterApi();
    this.getCategorySelect();
    this.getCategoryList();
    this.getList(1);
    this.getLabelLst();
    this.getTempLst();
    this.productCon();
  },
  methods: {
    // 把每一行的索引放进row
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      row.index = rowIndex;
    },
    // 添加明细原因 row 当前行 column 当前列
    tabClick: function tabClick(row) {
      this.tabClickIndex = row.index;
    },
    // 失去焦点初始化
    inputBlur: function inputBlur(scope) {
      var _this = this;
      if (!scope.row.sort || scope.row.sort < 0) scope.row.sort = 0;
      (0, _product.productSort)(scope.row.product_id, {
        sort: scope.row.sort
      }).then(function (res) {
        _this.closeEdit();
      }).catch(function (res) {});
    },
    closeEdit: function closeEdit() {
      this.tabClickIndex = null;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var data = [];
      this.multipleSelection.map(function (item) {
        data.push(item.product_id);
      });
      this.product_ids = data;
    },
    productCon: function productCon() {
      var _this2 = this;
      (0, _product.productConfigApi)().then(function (res) {
        _this2.is_audit = res.data.is_audit;
        _this2.deliveryType = res.data.delivery_way.map(String);
        if (_this2.deliveryType.length === 2) {
          _this2.deliveryList = [{
            value: '1',
            name: '到店自提'
          }, {
            value: '2',
            name: '快递配送'
          }];
        } else {
          if (_this2.deliveryType.length === 1 && _this2.deliveryType[0] === '1') {
            _this2.deliveryList = [{
              value: '1',
              name: '到店自提'
            }];
          } else {
            _this2.deliveryList = [{
              value: '2',
              name: '快递配送'
            }];
          }
        }
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    getSuccess: function getSuccess() {
      this.getLstFilterApi();
      this.getList(1);
    },
    handleClose: function handleClose() {
      this.dialogLabel = false;
    },
    handleFreightClose: function handleFreightClose() {
      this.dialogFreight = false;
    },
    // 添加淘宝商品成功
    onClose: function onClose() {
      this.modals = false;
    },
    // 复制淘宝
    onCopy: function onCopy() {
      this.$refs.taoBao.modals = true;
      this.$refs.taoBao.soure_link = '';
      this.$refs.taoBao.formValidate = {};
      this.$refs.taoBao.isData = false;
    },
    // 获取标签项
    getLabelLst: function getLabelLst() {
      var _this3 = this;
      (0, _product.getProductLabelApi)().then(function (res) {
        _this3.labelList = res.data;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    // 获取运费模板
    getTempLst: function getTempLst() {
      var _this4 = this;
      (0, _product.shippingListApi)().then(function (res) {
        _this4.tempList = res.data;
      }).catch(function (res) {
        _this4.$message.error(res.message);
      });
    },
    // 免审编辑
    onAuditFree: function onAuditFree(row) {
      this.$refs.editAttr.getAttrDetail(row.product_id);
    },
    // 批量上架
    batchShelf: function batchShelf() {
      var _this5 = this;
      if (this.multipleSelection.length === 0) return this.$message.warning('请先选择商品');
      var data = {
        status: 1,
        ids: this.product_ids
      };
      (0, _product.batchesOnOffApi)(data).then(function (res) {
        _this5.$message.success(res.message);
        _this5.getLstFilterApi();
        _this5.getList('');
      }).catch(function (res) {
        _this5.$message.error(res.message);
      });
    },
    // 批量下架
    batchOff: function batchOff() {
      var _this6 = this;
      if (this.multipleSelection.length === 0) return this.$message.warning('请先选择商品');
      var data = {
        status: 0,
        ids: this.product_ids
      };
      (0, _product.batchesOnOffApi)(data).then(function (res) {
        _this6.$message.success(res.message);
        _this6.getLstFilterApi();
        _this6.getList('');
      }).catch(function (res) {
        _this6.$message.error(res.message);
      });
    },
    // 批量设置标签
    batchLabel: function batchLabel() {
      this.labelForm = {
        mer_labels: [],
        ids: this.product_ids
      };
      this.isBatch = true;
      this.dialogLabel = true;
    },
    // 批量设置运费模板
    batchFreight: function batchFreight() {
      this.dialogFreight = true;
    },
    submitTempForm: function submitTempForm(name) {
      var _this7 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this7.tempForm.ids = _this7.product_ids;
          (0, _product.batchesTempApi)(_this7.tempForm).then(function (_ref2) {
            var message = _ref2.message;
            _this7.$message.success(message);
            _this7.getList('');
            _this7.dialogFreight = false;
          });
        } else {
          return;
        }
      });
    },
    handleRestore: function handleRestore(id) {
      var _this8 = this;
      this.$modalSure('恢复商品').then(function () {
        (0, _product.restoreApi)(id).then(function (res) {
          _this8.$message.success(res.message);
          _this8.getLstFilterApi();
          _this8.getList('');
        }).catch(function (res) {
          _this8.$message.error(res.message);
        });
      });
    },
    // 预览
    handlePreview: function handlePreview(id) {
      this.previewVisible = true;
      this.goodsId = id;
      this.previewKey = '';
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this9 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this9.merCateList = res.data;
      }).catch(function (res) {
        _this9.$message.error(res.message);
      });
    },
    // 平台分类；
    getCategoryList: function getCategoryList() {
      var _this10 = this;
      (0, _product.categoryListApi)().then(function (res) {
        _this10.categoryList = res.data;
      }).catch(function (res) {
        _this10.$message.error(res.message);
      });
    },
    // 列表表头；
    getLstFilterApi: function getLstFilterApi() {
      var _this11 = this;
      (0, _product.lstFilterApi)().then(function (res) {
        _this11.headeNum = res.data;
      }).catch(function (res) {
        _this11.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this12 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this12.tableData.data = res.data.list;
        _this12.tableData.total = res.data.count;
        _this12.listLoading = false;
      }).catch(function (res) {
        _this12.listLoading = false;
        _this12.$message.error(res.message);
      });
      this.getLstFilterApi();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this13 = this;
      this.$modalSure(this.tableFrom.type !== '5' ? '加入回收站' : '删除该商品').then(function () {
        _this13.tableFrom.type === '5' ? (0, _product.destoryApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this13.$message.success(message);
          _this13.getList('');
          _this13.getLstFilterApi();
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this13.$message.error(message);
        }) : (0, _product.productDeleteApi)(id).then(function (_ref5) {
          var message = _ref5.message;
          _this13.$message.success(message);
          _this13.getList('');
          _this13.getLstFilterApi();
        }).catch(function (_ref6) {
          var message = _ref6.message;
          _this13.$message.error(message);
        });
      });
    },
    // 编辑标签
    onEditLabel: function onEditLabel(row) {
      this.dialogLabel = true;
      this.product_id = row.product_id;
      if (row.mer_labels && row.mer_labels.length) {
        var arrNew = row.mer_labels.map(function (item) {
          return item.product_label_id;
        });
        this.labelForm = {
          mer_labels: arrNew
        };
      } else {
        this.labelForm = {
          mer_labels: []
        };
      }
    },
    submitForm: function submitForm(name) {
      var _this14 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this14.isBatch ? (0, _product.batchesLabelsApi)(_this14.labelForm).then(function (_ref7) {
            var message = _ref7.message;
            _this14.$message.success(message);
            _this14.getList('');
            _this14.dialogLabel = false;
            _this14.isBatch = false;
          }) : (0, _product.updatetProductLabel)(_this14.product_id, _this14.labelForm).then(function (_ref8) {
            var message = _ref8.message;
            _this14.$message.success(message);
            _this14.getList('');
            _this14.dialogLabel = false;
          });
        } else {
          return;
        }
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this15 = this;
      (0, _product.statusApi)(row.product_id, row.is_show).then(function (_ref9) {
        var message = _ref9.message;
        _this15.$message.success(message);
        _this15.getList('');
        _this15.getLstFilterApi();
      }).catch(function (_ref10) {
        var message = _ref10.message;
        _this15.$message.error(message);
      });
    }
  }
};