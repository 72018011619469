"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var configRouter = {
  path: "".concat(_settings.roterPre, "/config"),
  name: 'system_config',
  meta: {
    icon: 'dashboard',
    title: '系统配置'
  },
  alwaysShow: true,
  // 一直显示根路由
  component: _layout.default,
  children: [{
    path: 'picture',
    name: 'system_config_picture',
    meta: {
      title: '素材管理'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/config/picture'));
      });
    }
  }, {
    path: 'service',
    name: 'Service',
    meta: {
      title: '客服管理'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/service/index'));
      });
    }
  }, {
    path: 'guarantee',
    name: 'Guarantee',
    meta: {
      title: '保障服务',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/guarantee/index'));
      });
    }
  }, {
    path: 'freight',
    name: 'Freight',
    meta: {
      title: '物流设置'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/freight/index'));
      });
    },
    children: [{
      path: 'shippingTemplates',
      name: 'ShippingTemplates',
      meta: {
        title: '运费模板',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/system/freight/shippingTemplates'));
        });
      }
    }, {
      path: 'express',
      name: 'Express',
      meta: {
        title: '物流公司',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/system/freight/express/index'));
        });
      }
    }]
  }]
};
var _default = exports.default = configRouter;