var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.monacoBox,
              expression: "!monacoBox",
            },
          ],
        },
        [_c("div", { ref: "wang-editor", staticClass: "wang-editor" })]
      ),
      _vm._v(" "),
      _vm.monacoBox
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "bottom",
                  attrs: { type: "primary" },
                  on: { click: _vm.getHtmlint },
                },
                [_vm._v("可视化界面")]
              ),
              _vm._v(" "),
              _c("monaco", {
                attrs: { value: _vm.newHtml },
                on: { change: _vm.changeValue },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modalVideo,
            width: "1024px",
            title: "上传视频",
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalVideo = $event
            },
          },
        },
        [
          _c("uploadVideo", {
            attrs: { "is-diy": true },
            on: { getVideo: _vm.getvideo },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }