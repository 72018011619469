var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: { padding: "0 " + _vm.prConfig + "px" } }, [
    _c(
      "div",
      {
        staticClass: "seckill-box",
        class: _vm.bgStyle ? "" : "seckillOn",
        style: { background: _vm.bgColor, marginTop: _vm.mTOP + "px" },
      },
      [
        _c("div", { staticClass: "hd" }, [
          _c("div", { staticClass: "left" }, [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/images/spike_title.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "time" }, [
              _c(
                "span",
                {
                  staticClass: "text",
                  style: { background: _vm.countDownColor },
                },
                [_vm._v("距结束")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "num" }, [_vm._v("17:32:45")]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _vm.isOne != 2
          ? _c(
              "div",
              { staticClass: "list-wrapper", class: "colum" + _vm.isOne },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list-item",
                    class: _vm.conStyle ? "" : "seckillOn",
                    attrs: { index: index },
                  },
                  [
                    _c("div", { staticClass: "img-box" }, [
                      item.img
                        ? _c("img", { attrs: { src: item.img, alt: "" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "empty-box",
                          class: _vm.conStyle ? "" : "seckillOn",
                        },
                        [_c("span", { staticClass: "iconfont-diy icontupian" })]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _vm.titleShow
                        ? _c("div", { staticClass: "title line1" }, [
                            _vm._v(_vm._s(item.name)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.progressShow
                        ? _c(
                            "div",
                            {
                              staticClass: "label",
                              style: {
                                borderColor: _vm.themeColor,
                                color: _vm.themeColor,
                              },
                            },
                            [_vm._v("\n            20%\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "price" }, [
                        _c(
                          "span",
                          {
                            staticClass: "num-label",
                            style: { color: _vm.themeColor },
                          },
                          [_vm._v("￥")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "num",
                            style: { color: _vm.themeColor },
                          },
                          [_vm._v(_vm._s(item.price))]
                        ),
                        _vm._v(" "),
                        _vm.priceShow
                          ? _c("span", { staticClass: "ot_price" }, [
                              _vm._v("￥" + _vm._s(item.ot_price)),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "list-wrapper colum2" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list-item",
                    class: _vm.conStyle ? "" : "seckillOn",
                    attrs: { index: index },
                  },
                  [
                    index < 3
                      ? [
                          _c("div", { staticClass: "info" }, [
                            _vm.titleShow
                              ? _c("div", { staticClass: "title line1" }, [
                                  _vm._v(_vm._s(item.name)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.priceShow
                              ? _c("div", { staticClass: "price line1" }, [
                                  _vm._v("¥" + _vm._s(item.price)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(1, true),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "img-box" }, [
                            item.img
                              ? _c("img", { attrs: { src: item.img, alt: "" } })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "empty-box",
                                class: _vm.conStyle ? "" : "seckillOn",
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont-diy icontupian",
                                }),
                              ]
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("更多惊喜"),
      _c("span", { staticClass: "iconfont-diy iconjinru" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "box-btn" }, [
      _vm._v("去抢购"),
      _c("span", { staticClass: "iconfont-diy iconjinru" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }