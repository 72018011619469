var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hot_imgs" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(
        "最多可添加4个板块片建议尺寸140 * 140px；鼠标拖拽左侧圆点可 调整板块顺序"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-box" },
      [
        _c(
          "draggable",
          {
            staticClass: "dragArea list-group",
            attrs: {
              list: _vm.defaults.menu,
              group: "people",
              handle: ".move-icon",
            },
          },
          _vm._l(_vm.defaults.menu, function (item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "move-icon" },
                [
                  _c("Icon", {
                    attrs: { type: "ios-keypad-outline", size: "22" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "img-box",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("单选", index)
                    },
                  },
                },
                [
                  item.img
                    ? _c("img", { attrs: { src: item.img, alt: "" } })
                    : _c(
                        "div",
                        { staticClass: "upload-box" },
                        [
                          _c("Icon", {
                            attrs: { type: "ios-camera-outline", size: "36" },
                          }),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            visible: _vm.modalPic,
                            width: "950px",
                            title: "上传图片",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.modalPic = $event
                            },
                          },
                        },
                        [
                          _vm.modalPic
                            ? _c("uploadPictures", {
                                attrs: {
                                  "is-choice": _vm.isChoice,
                                  "grid-btn": _vm.gridBtn,
                                  "grid-pic": _vm.gridPic,
                                },
                                on: { getPic: _vm.getPic },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info" },
                _vm._l(item.info, function (infos, key) {
                  return _c("div", { key: key, staticClass: "info-item" }, [
                    _c("span", [_vm._v(_vm._s(infos.title))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-box" },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: infos.tips,
                            maxlength: infos.max,
                          },
                          model: {
                            value: infos.value,
                            callback: function ($$v) {
                              _vm.$set(infos, "value", $$v)
                            },
                            expression: "infos.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ])
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.defaults.menu.length < 4
      ? _c(
          "div",
          { staticClass: "add-btn" },
          [
            _c(
              "Button",
              {
                staticStyle: { width: "100%", height: "40px" },
                on: { click: _vm.addBox },
              },
              [_vm._v("添加板块")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }