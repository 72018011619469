"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _uploadPicture = _interopRequireDefault(require("@/components/uploadPicture"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_foot',
  components: {
    uploadPictures: _uploadPicture.default,
    linkaddress: _linkaddress.default,
    draggable: _vuedraggable.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      val1: '',
      val2: '',
      footConfig: [],
      modalPic: false,
      isChoice: '单选',
      itemIndex: 0,
      itemChildIndex: 0,
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      }
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.footConfig = nVal[this.configNme];
      },
      deep: true
    }
  },
  created: function created() {
    this.footConfig = this.configObj[this.configNme];
  },
  methods: {
    linkUrl: function linkUrl(e) {
      this.footConfig[this.itemIndex].link = e;
    },
    getLink: function getLink(index) {
      this.itemIndex = index;
      // this.$refs.linkaddres.modals = true
      this.$refs.linkaddres.init();
    },
    // 点击图文封面
    modalPicTap: function modalPicTap(parent, child) {
      var _this = this;
      _this.itemIndex = parent;
      _this.itemChildIndex = child;
      // this.modalPic = true;
      _this.$modalUpload(function (img) {
        _this.footConfig[parent].imgList[child] = img[0];
        _this.$forceUpdate();
        _this.getPic(img[0]);
        //    _this.$set(_this,"footConfig"[parent]['imgList']['child'],img[0])
      });
    },
    // 获取图片信息
    getPic: function getPic(pc) {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.footConfig[_this2.itemIndex].imgList[_this2.itemChildIndex] = pc;
        // this.modalPic = false;
        _this2.$store.commit('mobildConfig/footUpdata', _this2.footConfig);
      });
    },
    // 添加模块
    addMenu: function addMenu() {
      var obj = {
        imgList: ['', ''],
        name: '自定义',
        link: ''
      };
      this.footConfig.push(obj);
    },
    deleteMenu: function deleteMenu(index) {
      var _this3 = this;
      // this.$Modal.confirm({
      //     title: '提示',
      //     content: '是否确定删除该菜单',
      //     onOk: () => {
      //         this.footConfig.splice(index,1)
      //     },
      //     onCancel: () => {
      //     }
      // });

      this.$confirm('是否确定删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.footConfig.splice(index, 1);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};