"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _dashboard = require("@/api/dashboard");
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      default: 'lately7'
    }
  },
  data: function data() {
    return {
      chart: null,
      horizontalAxis: [],
      PaymentAmount: [],
      orderNumber: [],
      user: []
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    },
    date: {
      deep: true,
      handler: function handler(val) {
        this.date = val;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
    },
    getOrderData: function getOrderData(params) {
      var _this2 = this;
      var that = this;
      (0, _dashboard.orderApi)(params).then(function (res) {
        if (res.status === 200) {
          that.horizontalAxis.splice(0, that.horizontalAxis.length);
          that.PaymentAmount.splice(0, that.PaymentAmount.length);
          that.orderNumber.splice(0, that.orderNumber.length);
          that.user.splice(0, that.user.length);
          res.data.map(function (item) {
            that.horizontalAxis.push(item.day);
            that.PaymentAmount.push(item.pay_price);
            that.orderNumber.push(item.total);
            that.user.push(item.user);
          });
          var horizontalAxis = that.horizontalAxis;
          var PaymentAmount = that.PaymentAmount;
          console.log(PaymentAmount);
          var orderNumber = that.orderNumber;
          var user = that.user;
          that.chart.setOption({
            xAxis: {
              data: horizontalAxis,
              axisLine: {
                lineStyle: {
                  color: '#dfdfdf'
                }
              },
              boundaryGap: false,
              axisTick: {
                show: false
              },
              axisLabel: {
                interval: 0
              }
            },
            grid: {
              left: 50,
              right: 50,
              bottom: 20,
              top: 70,
              containLabel: true
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              },
              padding: [5, 10]
            },
            yAxis: [{
              name: '订单/支付人数',
              max: parseFloat(_this2.arrayMax(orderNumber)) + 5,
              type: 'value',
              axisLabel: {
                formatter: '{value}'
              }
            }, {
              name: '支付金额',
              type: 'value',
              max: parseFloat(_this2.arrayMax(PaymentAmount)) + 50,
              min: _this2.arrayMin(PaymentAmount),
              splitLine: {
                show: false
              }
            }],
            legend: {
              data: ['订单数', '支付人数', '支付金额'],
              left: 10
            },
            series: [{
              name: '订单数',
              markPoint: {
                data: [{
                  type: 'max',
                  name: '峰值'
                }]
              },
              itemStyle: {
                normal: {
                  color: '#5b8ff9',
                  lineStyle: {
                    color: '#5b8ff9',
                    width: 2
                  }
                }
              },
              smooth: false,
              type: 'line',
              data: orderNumber,
              animationDuration: 2800,
              animationEasing: 'cubicInOut'
            }, {
              name: '支付人数',
              smooth: false,
              type: 'line',
              markPoint: {
                data: [{
                  type: 'max',
                  name: '峰值'
                }]
              },
              itemStyle: {
                normal: {
                  color: '#5d7092',
                  lineStyle: {
                    color: '#5d7092',
                    width: 2
                  },
                  areaStyle: {
                    color: 'rgba(255,255,255,.4)'
                  }
                }
              },
              data: user,
              animationDuration: 2800,
              animationEasing: 'quadraticOut'
            }, {
              name: '支付金额',
              yAxisIndex: 1,
              smooth: false,
              type: 'line',
              markPoint: {
                data: [{
                  type: 'max',
                  name: '峰值'
                }]
              },
              itemStyle: {
                normal: {
                  color: '#5ad8a6',
                  lineStyle: {
                    color: '#5ad8a6',
                    width: 2
                  },
                  areaStyle: {
                    color: 'rgba(255,255,255,.4)'
                  }
                }
              },
              data: PaymentAmount,
              animationDuration: 2800,
              animationEasing: 'quadraticOut'
            }]
          });
        }
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    setOptions: function setOptions() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        expectedData = _ref.expectedData,
        actualData = _ref.actualData,
        payer = _ref.payer;
    },
    arrayMin: function arrayMin(arrs) {
      var min = arrs[0];
      for (var i = 1, ilen = arrs.length; i < ilen; i += 1) {
        if (arrs[i] < min) {
          min = arrs[i];
        }
      }
      return min;
    },
    arrayMax: function arrayMax(arrs) {
      var max = arrs[0];
      for (var i = 1, ilen = arrs.length; i < ilen; i++) {
        if (Number(arrs[i]) > max) {
          max = arrs[i];
        }
      }
      return max;
    }
  }
};