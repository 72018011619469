"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _settings = require("@/settings");
var _layout = _interopRequireDefault(require("@/layout"));
var _systemForm = _interopRequireDefault(require("./modules/systemForm"));
var _config = _interopRequireDefault(require("./modules/config"));
var _setting = _interopRequireDefault(require("./modules/setting"));
var _group = _interopRequireDefault(require("./modules/group"));
var _product = _interopRequireDefault(require("./modules/product"));
var _marketing = _interopRequireDefault(require("./modules/marketing"));
var _order = _interopRequireDefault(require("./modules/order"));
var _accounts = _interopRequireDefault(require("./modules/accounts"));
var _user = _interopRequireDefault(require("./modules/user"));
var _station = _interopRequireDefault(require("./modules/station"));
var _exportFile = _interopRequireDefault(require("./modules/exportFile"));
var _delivery = _interopRequireDefault(require("./modules/delivery"));
var _devise = _interopRequireDefault(require("./modules/devise"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [_systemForm.default, _config.default, _setting.default, _group.default, _product.default, _marketing.default, _order.default, _accounts.default, _user.default, _station.default, _exportFile.default, _delivery.default, _devise.default, {
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: _settings.roterPre,
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/dashboard"),
  children: [{
    path: "".concat(_settings.roterPre, "/dashboard"),
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/',
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/dashboard"),
  children: [{
    path: "".concat(_settings.roterPre, "/dashboard"),
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: "".concat(_settings.roterPre, "/login"),
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: _settings.roterPre + '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: _settings.roterPre + '/setting/icons',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/iconFrom/index'));
    });
  },
  name: 'icons'
}, {
  path: _settings.roterPre + '/setting/uploadPicture',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/uploadPicture/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/setting/storeProduct',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/goodList/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/setting/broadcastProduct',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/importGoods/goodList.vue'));
    });
  },
  name: 'broadcastProduct'
}, {
  path: _settings.roterPre + '/setting/userList',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/userList/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/order/export',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/exportFile/index.vue'));
    });
  },
  name: 'exportFileList'
}, {
  path: _settings.roterPre + '/admin/widget/image',
  name: "images",
  meta: {
    title: '上传图片'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/uploadPicture/index'));
    });
  }
}, {
  path: _settings.roterPre + '/admin/widget/video',
  name: "video",
  meta: {
    title: '上传视频'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/components/uploadVideo/index'));
    });
  }
}, {
  path: '*',
  redirect: _settings.roterPre + '/404',
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;