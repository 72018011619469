var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "批量添加直播商品",
                visible: _vm.dialogVisible,
                width: "900px",
                "custom-class": "customHeight",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.selectGoods },
                },
                [_vm._v("选择商品")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "div",
                    { staticClass: "table-cont" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData.data,
                            size: "mini",
                            "highlight-current-row": "",
                            "row-key": function (row) {
                              return row.product_id
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "product_id",
                              label: "ID",
                              "min-width": "50",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "商品名称", "min-width": "200" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.store_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "store_name",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.store_name",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1215066990
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "商品图", "min-width": "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap(
                                                "1",
                                                "duo",
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          scope.row.image
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "pictrue tabPic",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: scope.row.image,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "upLoad tabPic",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-camera cameraIconfont",
                                                  }),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4035746379
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: "直播价",
                              "min-width": "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "库存", "min-width": "80" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.stock)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2684418342
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              "min-width": "150",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "mr10",
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1061971268
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "80px", "margin-top": "30px" },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("select-goods", {
        ref: "selectGoods",
        on: { "get-goods": _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }