"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = _interopRequireDefault(require("@/settings"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
//
//
//
//
//
//
//
//
//
//
//
//
//

var title = _settings.default.title;
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: title,
      logo: JSON.parse(_jsCookie.default.get('MerInfo')).menu_logo,
      slogo: JSON.parse(_jsCookie.default.get('MerInfo')).menu_slogo
    };
  }
};