var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mobile-page paddingBox",
      style: { marginTop: _vm.slider + "px" },
    },
    [
      _c(
        "div",
        { staticClass: "home_presell", class: _vm.bgStyle ? "" : "presellOn" },
        [
          _c(
            "div",
            {
              staticClass: "title-wrapper",
              class: _vm.bgStyle ? "" : "presellOn",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/presell_title.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
          _vm._v(" "),
          _vm.isOne != 2
            ? _c(
                "div",
                { staticClass: "list-wrapper", class: "colum" + _vm.isOne },
                _vm._l(_vm.list, function (item, index) {
                  return _c("div", { key: index, staticClass: "item" }, [
                    _c("div", { staticClass: "img-box" }, [
                      item.image
                        ? _c("img", { attrs: { src: item.image, alt: "" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "empty-box",
                          class: _vm.conStyle ? "" : "presellOn",
                        },
                        [_c("span", { staticClass: "iconfont-diy icontupian" })]
                      ),
                      _vm._v(" "),
                      _vm.presellShow
                        ? _c(
                            "span",
                            {
                              staticClass: "box-bg",
                              class: _vm.conStyle ? "" : "presellOn",
                            },
                            [_vm._v("火热预定中")]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _vm.priceShow
                        ? _c(
                            "div",
                            {
                              staticClass: "price line1",
                              style: "color:" + _vm.themeColor,
                            },
                            [
                              _c("span", [_vm._v("预售价:")]),
                              _vm._v("¥" + _vm._s(item.price) + "\n          "),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.titleShow
                        ? _c("div", { staticClass: "title line1" }, [
                            _vm._v(_vm._s(item.store_name)),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "list-wrapper colum2" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      class: _vm.conStyle ? "" : "presellOn",
                    },
                    [
                      index < 3
                        ? [
                            _c("div", { staticClass: "info" }, [
                              _vm.titleShow
                                ? _c("div", { staticClass: "title line1" }, [
                                    _vm._v(_vm._s(item.store_name)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.priceShow
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "price line1",
                                      style: "color:" + _vm.themeColor,
                                    },
                                    [
                                      _vm._v(
                                        "\n              ¥" +
                                          _vm._s(item.price) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.presellShow
                                ? _c("span", { staticClass: "box-btn" }, [
                                    _vm._v("去预定"),
                                    _c("span", {
                                      staticClass: "iconfont-diy iconjinru",
                                    }),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "img-box" }, [
                              item.image
                                ? _c("img", {
                                    attrs: { src: item.image, alt: "" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._m(1, true),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _vm._v("进去逛逛 "),
      _c("span", { staticClass: "iconfont-diy iconjinru" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-box" }, [
      _c("span", { staticClass: "iconfont-diy icontupian" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }