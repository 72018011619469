"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _notice = _interopRequireDefault(require("@/libs/notice"));
require("@/assets/js/canvas-nest.min");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _Verify = _interopRequireDefault(require("@/components/verifition/Verify"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  components: {
    Verify: _Verify.default
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入用户名'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入密码'));
      } else {
        if (value.length < 6) {
          callback(new Error('请输入不少于6位数的密码'));
        } else {
          callback();
        }
      }
    };
    return {
      fullWidth: document.body.clientWidth,
      swiperOption: {
        pagination: {
          el: '.pagination'
        },
        autoplay: {
          enabled: true,
          disableOnInteraction: false,
          delay: 3000
        }
      },
      captchatImg: '',
      loginLogo: '',
      beian_sn: '',
      swiperList: [],
      loginForm: {
        account: '',
        password: '',
        key: '',
        code: ''
      },
      loginRules: {
        account: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        code: [{
          required: true,
          message: '请输入正确的验证码',
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      copyright: ''
    };
  },
  watch: {
    $route: {
      fullWidth: function fullWidth(val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = val;
          this.timer = true;
          var that = this;
          setTimeout(function () {
            // 打印screenWidth变化的值
            that.timer = false;
          }, 400);
        }
      },
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;
    document.onkeydown = function (e) {
      if (_this.$route.path.indexOf('login') !== -1) {
        var key = window.event.keyCode;
        if (key === 13) {
          _this.handleLogin();
        }
      }
    };
    window.addEventListener('resize', this.handleResize);
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getInfo();
    this.$nextTick(function () {
      if (_this2.screenWidth < 768) {
        document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
      } else {
        document.getElementsByTagName('canvas')[0].className = 'index_bg';
      }
    });
    this.getCaptcha();
    this.getVersion();
  },
  beforeCreate: function beforeCreate() {
    if (this.fullWidth < 768) {
      document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
    } else {
      document.getElementsByTagName('canvas')[0].className = 'index_bg';
    }
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _user.loginConfigApi)().then(function (_ref) {
        var data = _ref.data;
        _this3.swiperList = data.login_banner;
        _this3.loginLogo = data.login_logo;
        _this3.beian_sn = data.beian_sn;
        _jsCookie.default.set('MerInfo', JSON.stringify(data));
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this3.$message.error(message);
      });
    },
    getVerify: function getVerify() {
      var that = this;
      if (!that.loginForm.account) return that.$message.error('请填写账号码');
      if (!that.loginForm.password) return that.$message.error('请填写密码');
      this.$refs.verify.show();
    },
    getCaptcha: function getCaptcha() {
      var _this4 = this;
      (0, _user.captchaApi)().then(function (_ref3) {
        var data = _ref3.data;
        _this4.captchatImg = data.captcha;
        _this4.loginForm.key = data.key;
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this4.$message.error(message);
      });
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this5 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this5.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this6 = this;
      this.loginForm.captchaVerification = '';
      this.$refs['loginForm'].validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          (0, _system.ajCaptchaStatus)({
            account: _this6.loginForm.account
          }).then(function (res) {
            if (res.data.status) {
              _this6.getVerify();
            } else {
              _this6.loginIn();
            }
          }).catch(function (res) {
            _this6.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    },
    loginIn: function loginIn() {
      var _this7 = this;
      this.$store.dispatch('user/login', this.loginForm).then(function (res) {
        console.log(res);
        // this.$router.push({
        //   path: this.redirect || "/",
        //   query: this.otherQuery
        // });
        _this7.$router.push({
          path: '/'
        });
        _this7.loading = false;
        _this7.$root.closeNotice();
        _this7.$root.notice = (0, _notice.default)(res.token);
      }).catch(function (error) {
        _this7.loginForm.code = '';
        _this7.$message.error(error.message);
        _this7.loading = false;
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    handleResize: function handleResize(event) {
      this.fullWidth = document.body.clientWidth;
      if (this.fullWidth < 768) {
        document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
      } else {
        document.getElementsByTagName('canvas')[0].className = 'index_bg';
      }
    },
    getVersion: function getVersion() {
      var _this8 = this;
      (0, _user.getVersion)().then(function (res) {
        _this8.copyright = res.data;
      });
    },
    success: function success(data) {
      this.isShow = false;
      this.loginForm.captchaType = 'blockPuzzle';
      this.loginForm.captchaVerification = data.captchaVerification;
      this.loginIn();
    }
  }
};