var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goods_detail" }, [
    _c(
      "div",
      {
        staticClass: "goods_detail_wrapper",
        class: _vm.previewKey || _vm.goodsId ? "on" : "",
      },
      [
        _vm.previewKey
          ? _c("iframe", {
              staticStyle: { width: "100%", height: "600px" },
              attrs: {
                src:
                  "/pages/admin/goods_details/index?preview_key=" +
                  _vm.previewKey +
                  "&product_type=" +
                  _vm.productType +
                  "&inner_frame=1",
                frameborder: "0",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.goodsId
          ? _c("iframe", {
              staticStyle: { width: "100%", height: "600px" },
              attrs: {
                src:
                  "/pages/admin/goods_details/index?product_id=" +
                  _vm.goodsId +
                  "&product_type=" +
                  _vm.productType +
                  "&inner_frame=1",
                frameborder: "0",
              },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }