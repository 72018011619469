var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "0 10px" } }, [
    _c(
      "div",
      {
        staticClass: "mobile-page paddingBox",
        class: _vm.bgStyle === 0 ? "" : "pageOn",
        style: { marginTop: _vm.slider + "px", background: _vm.activeColor },
      },
      [
        _c(
          "div",
          { staticClass: "home_product" },
          [
            _vm.itemStyle == 0
              ? [
                  _vm.list.length > 0
                    ? _c(
                        "div",
                        { staticClass: "list-wrapper itemA" },
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item",
                              class: _vm.conStyle ? "" : "itemOn",
                            },
                            [
                              _c("div", { staticClass: "img-box" }, [
                                item.image
                                  ? _c("img", {
                                      attrs: { src: item.image, alt: "" },
                                    })
                                  : _c("div", { staticClass: "empty-box" }, [
                                      _c("span", {
                                        staticClass: "iconfont-diy icontupian",
                                      }),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "hd" }, [
                                  _vm.titleShow
                                    ? _c(
                                        "div",
                                        { staticClass: "title line2" },
                                        [_vm._v(_vm._s(item.store_name))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "label",
                                        style: { background: _vm.labelColor },
                                      },
                                      [_vm._v("官方旗舰店")]
                                    ),
                                    _vm._v(" "),
                                    item.couponId &&
                                    item.couponId.length &&
                                    _vm.couponShow
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "coupon",
                                            style:
                                              "border:1px solid " +
                                              _vm.labelColor +
                                              ";color:" +
                                              _vm.labelColor,
                                          },
                                          [
                                            _vm._v(
                                              "\n                    领券\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ship" }, [
                                      _vm._v("包邮"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "price",
                                    style: { color: _vm.fontColor },
                                  },
                                  [
                                    _vm.priceShow
                                      ? _c("div", { staticClass: "num" }, [
                                          _vm._v("\n                  ￥"),
                                          _c("span", [
                                            _vm._v(_vm._s(item.price)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.opriceShow
                                      ? _c(
                                          "div",
                                          { staticClass: "old-price" },
                                          [_vm._v("¥" + _vm._s(item.ot_price))]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "list-wrapper itemA" }, [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(0),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "hd" }, [
                                _vm.titleShow
                                  ? _c("div", { staticClass: "title line2" }, [
                                      _vm._v("商品名称"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label",
                                    style: { background: _vm.labelColor },
                                  },
                                  [_vm._v("官方旗舰店")]
                                ),
                                _vm._v(" "),
                                _vm.couponShow
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "coupon",
                                        style:
                                          "border:1px solid " +
                                          _vm.labelColor +
                                          ";color:" +
                                          _vm.labelColor,
                                      },
                                      [
                                        _vm._v(
                                          "\n                  领券\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "ship" }, [
                                  _vm._v("包邮"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("div", { staticClass: "num" }, [
                                        _vm._v("¥"),
                                        _c("span", [_vm._v("199")]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.opriceShow
                                    ? _c("div", { staticClass: "old-price" }, [
                                        _vm._v("¥399"),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "hd" }, [
                                _vm.titleShow
                                  ? _c("div", { staticClass: "title line2" }, [
                                      _vm._v("商品名称"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "label",
                                    style: { background: _vm.labelColor },
                                  },
                                  [_vm._v("官方旗舰店")]
                                ),
                                _vm._v(" "),
                                _vm.couponShow
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "coupon",
                                        style:
                                          "border:1px solid " +
                                          _vm.labelColor +
                                          ";color:" +
                                          _vm.labelColor,
                                      },
                                      [
                                        _vm._v(
                                          "\n                  领券\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "ship" }, [
                                  _vm._v("包邮"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("div", { staticClass: "num" }, [
                                        _vm._v("¥"),
                                        _c("span", [_vm._v("199")]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.opriceShow
                                    ? _c("div", { staticClass: "old-price" }, [
                                        _vm._v("¥399"),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.itemStyle == 1
              ? [
                  _vm.list.length > 0
                    ? _c(
                        "div",
                        { staticClass: "list-wrapper itemC" },
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item",
                              class: _vm.conStyle ? "" : "itemOn",
                            },
                            [
                              _c("div", { staticClass: "img-box" }, [
                                item.image
                                  ? _c("img", {
                                      attrs: { src: item.image, alt: "" },
                                    })
                                  : _c("div", { staticClass: "empty-box" }, [
                                      _c("span", {
                                        staticClass: "iconfont-diy icontupian",
                                      }),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "hd" }, [
                                  _vm.titleShow
                                    ? _c(
                                        "div",
                                        { staticClass: "title line2" },
                                        [_vm._v(_vm._s(item.store_name))]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "price",
                                    style: { color: _vm.fontColor },
                                  },
                                  [
                                    _vm.priceShow
                                      ? _c("div", { staticClass: "num" }, [
                                          _vm._v("\n                  ￥"),
                                          _c("span", [
                                            _vm._v(_vm._s(item.price)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "text" }, [
                                  index == 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "label",
                                          style: { background: _vm.labelColor },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  官方旗舰店\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.couponId &&
                                  item.couponId.length &&
                                  _vm.couponShow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "coupon",
                                          class: _vm.priceShow ? "" : "on",
                                          style:
                                            "border:1px solid " +
                                            _vm.labelColor +
                                            ";color:" +
                                            _vm.labelColor,
                                        },
                                        [
                                          _vm._v(
                                            "\n                  领券\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "list-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "hd" }, [
                                _vm.titleShow
                                  ? _c("div", { staticClass: "title line2" }, [
                                      _vm._v("商品名称"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("div", { staticClass: "num" }, [
                                        _vm._v("￥"),
                                        _c("span", [_vm._v("66.66")]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _vm.index == 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "label",
                                        style: { background: _vm.labelColor },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  官方旗舰店\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.couponShow
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "coupon",
                                        style:
                                          "border:1px solid " +
                                          _vm.labelColor +
                                          ";color:" +
                                          _vm.labelColor,
                                      },
                                      [
                                        _vm._v(
                                          "\n                  领券\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(3),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "hd" }, [
                                _vm.titleShow
                                  ? _c("div", { staticClass: "title line2" }, [
                                      _vm._v("商品名称"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("div", { staticClass: "num" }, [
                                        _vm._v("￥"),
                                        _c("span", [_vm._v("66.66")]),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _vm.index == 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "label",
                                        style: { background: _vm.labelColor },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  官方旗舰店\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.couponShow
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "coupon",
                                        style:
                                          "border:1px solid " +
                                          _vm.labelColor +
                                          ";color:" +
                                          _vm.labelColor,
                                      },
                                      [
                                        _vm._v(
                                          "\n                  领券\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.itemStyle == 2
              ? [
                  _vm.list.length > 0
                    ? _c(
                        "div",
                        { staticClass: "list-wrapper itemB" },
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item",
                              class: _vm.conStyle ? "" : "itemOn",
                            },
                            [
                              _c("div", { staticClass: "img-box" }, [
                                item.image
                                  ? _c("img", {
                                      attrs: { src: item.image, alt: "" },
                                    })
                                  : _c("div", { staticClass: "empty-box" }, [
                                      _c("span", {
                                        staticClass: "iconfont-diy icontupian",
                                      }),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "hd" }, [
                                  _vm.titleShow
                                    ? _c(
                                        "div",
                                        { staticClass: "title line2" },
                                        [_vm._v(_vm._s(item.store_name))]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "price",
                                    style: { color: _vm.fontColor },
                                  },
                                  [
                                    _vm.priceShow
                                      ? _c("div", { staticClass: "num" }, [
                                          _vm._v("\n                  ￥"),
                                          _c("span", [
                                            _vm._v(_vm._s(item.price)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.opriceShow
                                      ? _c(
                                          "div",
                                          { staticClass: "old-price" },
                                          [_vm._v("¥" + _vm._s(item.ot_price))]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "list-wrapper itemB" }, [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(4),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "hd" }, [
                                _vm.titleShow
                                  ? _c("div", { staticClass: "title line2" }, [
                                      _vm._v("商品名称"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("div", { staticClass: "num" }, [
                                        _vm._v("¥"),
                                        _c("span", [_vm._v("66.66")]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.opriceShow
                                    ? _c("div", { staticClass: "old-price" }, [
                                        _vm._v("¥99.99"),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "hd" }, [
                                _vm.titleShow
                                  ? _c("div", { staticClass: "title line2" }, [
                                      _vm._v("商品名称"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("div", { staticClass: "num" }, [
                                        _vm._v("¥"),
                                        _c("span", [_vm._v("66.66")]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.opriceShow
                                    ? _c("div", { staticClass: "old-price" }, [
                                        _vm._v("¥99.99"),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.itemStyle == 3
              ? [
                  _vm.list.length > 0
                    ? _c(
                        "div",
                        { staticClass: "listBig" },
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "itemBig",
                              class: _vm.conStyle ? "" : "itemOn",
                            },
                            [
                              _c("div", { staticClass: "img-box" }, [
                                item.image
                                  ? _c("img", {
                                      attrs: { src: item.image, alt: "" },
                                    })
                                  : _c("div", { staticClass: "empty-box" }, [
                                      _c("span", {
                                        staticClass: "iconfont-diy icontupian",
                                      }),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "name line2" }, [
                                _vm.titleShow
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.store_name)),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "price",
                                  style: { color: _vm.fontColor },
                                },
                                [
                                  _vm.priceShow
                                    ? _c("span", [
                                        _vm._v("￥"),
                                        _c("span", { staticClass: "num" }, [
                                          _vm._v(_vm._s(item.price)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.opriceShow
                                    ? _c("span", { staticClass: "old-price" }, [
                                        _vm._v("¥" + _vm._s(item.ot_price)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "listBig" }, [
                        _c(
                          "div",
                          {
                            staticClass: "itemBig",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", { staticClass: "name line2" }, [
                              _vm.titleShow
                                ? _c("span", [_vm._v("商品名称")])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "price",
                                style: { color: _vm.fontColor },
                              },
                              [
                                _vm.priceShow
                                  ? _c("span", [
                                      _vm._v("¥"),
                                      _c("span", { staticClass: "num" }, [
                                        _vm._v("66.66"),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.opriceShow
                                  ? _c("span", { staticClass: "old-price" }, [
                                      _vm._v("¥99.99"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "itemBig",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(7),
                            _vm._v(" "),
                            _c("div", { staticClass: "name line2" }, [
                              _vm.titleShow
                                ? _c("span", [_vm._v("商品名称")])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "price",
                                style: { color: _vm.fontColor },
                              },
                              [
                                _vm.priceShow
                                  ? _c("span", [
                                      _vm._v("¥"),
                                      _c("span", { staticClass: "num" }, [
                                        _vm._v("66.66"),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.opriceShow
                                  ? _c("span", { staticClass: "old-price" }, [
                                      _vm._v("¥99.99"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "itemBig",
                            class: _vm.conStyle ? "" : "itemOn",
                          },
                          [
                            _vm._m(8),
                            _vm._v(" "),
                            _c("div", { staticClass: "name line2" }, [
                              _vm.titleShow
                                ? _c("span", [_vm._v("商品名称")])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "price",
                                style: { color: _vm.fontColor },
                              },
                              [
                                _vm.priceShow
                                  ? _c("span", [
                                      _vm._v("¥"),
                                      _c("span", { staticClass: "num" }, [
                                        _vm._v("66.66"),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.opriceShow
                                  ? _c("span", { staticClass: "old-price" }, [
                                      _vm._v("¥99.99"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-box" }, [
        _c("span", { staticClass: "iconfont-diy icontupian" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }