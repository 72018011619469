var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-page", style: { marginTop: _vm.mTOP + "px" } },
    [
      _vm.bgColor.length > 0 && _vm.isShow
        ? _c("div", {
            staticClass: "bg",
            style: {
              background:
                "linear-gradient(180deg," +
                _vm.bgColor[0].item +
                " 0%," +
                _vm.bgColor[1].item +
                " 100%)",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "banner",
          class: _vm.bgColor.length > 0 && _vm.isShow ? "on" : "",
          style: {
            paddingLeft: _vm.edge + "px",
            paddingRight: _vm.edge + "px",
          },
        },
        [
          _vm.imgSrc
            ? _c("img", {
                class: { doc: _vm.imgStyle },
                attrs: { src: _vm.imgSrc, alt: "" },
              })
            : _c(
                "div",
                { staticClass: "empty-box", class: { on: _vm.imgStyle } },
                [_c("span", { staticClass: "iconfont-diy icontupian" })]
              ),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _vm.docStyle == 0
          ? _c(
              "div",
              {
                staticClass: "dot",
                style: {
                  paddingLeft: _vm.edge + 10 + "px",
                  paddingRight: _vm.edge + 10 + "px",
                  justifyContent:
                    _vm.dotPosition === 1
                      ? "center"
                      : _vm.dotPosition === 2
                      ? "flex-end"
                      : "flex-start",
                },
              },
              [
                _c("div", {
                  staticClass: "dot-item",
                  staticStyle: { background: "#fff" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "dot-item" }),
                _vm._v(" "),
                _c("div", { staticClass: "dot-item" }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.docStyle == 1
          ? _c(
              "div",
              {
                staticClass: "dot line-dot",
                style: {
                  paddingLeft: _vm.edge + 10 + "px",
                  paddingRight: _vm.edge + 10 + "px",
                  justifyContent:
                    _vm.dotPosition === 1
                      ? "center"
                      : _vm.dotPosition === 2
                      ? "flex-end"
                      : "flex-start",
                },
              },
              [
                _c("div", {
                  staticClass: "line_dot-item",
                  staticStyle: { background: "#fff" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "line_dot-item" }),
                _vm._v(" "),
                _c("div", { staticClass: "line_dot-item" }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.docStyle == 2
          ? _c("div", {
              staticClass: "dot number",
              style: {
                paddingLeft: _vm.edge + 10 + "px",
                paddingRight: _vm.edge + 10 + "px",
                justifyContent:
                  _vm.dotPosition === 1
                    ? "center"
                    : _vm.dotPosition === 2
                    ? "flex-end"
                    : "flex-start",
              },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }