var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-notice right-menu-item" },
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" } },
        [
          _c(
            "span",
            { staticClass: "el-dropdown-link" },
            [
              _vm.count > 0
                ? _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { "is-dot": "", value: _vm.count },
                    },
                    [_c("i", { staticClass: "el-icon-message-solid" })]
                  )
                : _c("span", { staticClass: "item" }, [
                    _c("i", { staticClass: "el-icon-message-solid" }),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            {
              attrs: { slot: "dropdown", placement: "top-end" },
              slot: "dropdown",
            },
            [
              _c(
                "el-dropdown-item",
                { staticClass: "clearfix" },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _vm.messageList.length > 0
                        ? _c(
                            "el-card",
                            { staticClass: "box-card" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [_c("span", [_vm._v("消息")])]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.messageList, function (item, i) {
                                return _c(
                                  "router-link",
                                  {
                                    key: i,
                                    staticClass: "text item_content",
                                    attrs: {
                                      to: {
                                        path:
                                          _vm.roterPre +
                                          "/station/notice/" +
                                          item.notice_log_id,
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.HandleDelete(i)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-badge", {
                                      staticClass: "item",
                                      attrs: { "is-dot": "" },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(item.notice_title) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _c(
                            "div",
                            { staticClass: "ivu-notifications-container-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "ivu-notifications-tab-empty" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ivu-notifications-tab-empty-text",
                                    },
                                    [_vm._v("目前没有通知")]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass:
                                      "ivu-notifications-tab-empty-img",
                                    attrs: {
                                      src: "https://file.iviewui.com/iview-pro/icon-no-message.svg",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }