var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.configData
      ? _c(
          "div",
          { staticClass: "c_row-item" },
          [
            _c("el-col", { staticClass: "c_label", attrs: { span: 8 } }, [
              _vm._v(_vm._s(_vm.configData.title)),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { staticClass: "color-box", attrs: { span: 14 } },
              _vm._l(_vm.configData.color, function (color, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "color-item" },
                  [
                    _c("el-color-picker", {
                      attrs: { alpha: "" },
                      on: {
                        change: function ($event) {
                          return _vm.changeColor($event, color)
                        },
                      },
                      model: {
                        value: color.item,
                        callback: function ($$v) {
                          _vm.$set(color, "item", $$v)
                        },
                        expression: "color.item",
                      },
                    }),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.resetBgA(color, _vm.index, key)
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }