var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slider-box" }, [
    _c(
      "div",
      { staticClass: "c_row-item" },
      [
        _vm.configData.title
          ? _c("el-col", { staticClass: "label", attrs: { span: 4 } }, [
              _vm._v("\n      " + _vm._s(_vm.configData.title) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-col",
          { staticClass: "slider-box", attrs: { span: 19 } },
          [
            _c(
              "Select",
              {
                on: { change: _vm.sliderChange },
                model: {
                  value: _vm.configData.activeValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.configData, "activeValue", $$v)
                  },
                  expression: "configData.activeValue",
                },
              },
              _vm._l(_vm.configData.list, function (item, index) {
                return _c(
                  "Option",
                  { key: index, attrs: { value: item.activeValue } },
                  [_vm._v(_vm._s(item.title))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }