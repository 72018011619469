var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { padding: "0 10px 10px" } }, [
    _c(
      "div",
      {
        staticClass: "mobile-page",
        class: _vm.bgStyle === 0 ? "" : "pageOn",
        style: [{ background: _vm.bg }, { marginTop: _vm.cSlider + "px" }],
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.listStyle == 0
          ? _c(
              "div",
              { staticClass: "live-wrapper-a live-wrapper-c" },
              _vm._l(_vm.live, function (item, index) {
                return _c("div", { key: index, staticClass: "live-item-a" }, [
                  _c("div", { staticClass: "img-box" }, [
                    _vm._m(1, true),
                    _vm._v(" "),
                    item.type == 1
                      ? _c("div", { staticClass: "label bgblue" }, [
                          _vm._m(2, true),
                          _vm._v(" "),
                          _c("span", { staticClass: "msg" }, [
                            _vm._v("7/29 10:00"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 0
                      ? _c("div", { staticClass: "label bggary" }, [
                          _c("span", {
                            staticClass: "iconfont-diy iconyijieshu",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v("回放\n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 2
                      ? _c("div", { staticClass: "label bgred" }, [
                          _c("span", {
                            staticClass: "iconfont-diy iconzhibozhong",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v("直播中\n          "),
                        ])
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.listStyle == 1
          ? _c(
              "div",
              { staticClass: "live-wrapper-a" },
              _vm._l(_vm.live, function (item, index) {
                return _c("div", { key: index, staticClass: "live-item-a" }, [
                  _c("div", { staticClass: "img-box" }, [
                    _vm._m(3, true),
                    _vm._v(" "),
                    item.type == 1
                      ? _c("div", { staticClass: "label bgblue" }, [
                          _vm._m(4, true),
                          _vm._v(" "),
                          _c("span", { staticClass: "msg" }, [
                            _vm._v("7/29 10:00"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 0
                      ? _c("div", { staticClass: "label bggary" }, [
                          _c("span", {
                            staticClass: "iconfont-diy iconyijieshu",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v("回放\n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 2
                      ? _c("div", { staticClass: "label bgred" }, [
                          _c("span", {
                            staticClass: "iconfont-diy iconzhibozhong",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v("直播中\n          "),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("直播标题直播标题直播标 题直播标题"),
                    ]),
                    _vm._v(" "),
                    _vm._m(5, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "goods-wrapper" },
                      [
                        item.goods.length > 0
                          ? _vm._l(item.goods, function (goods, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "goods-item" },
                                [
                                  _c("img", {
                                    attrs: { src: goods.img, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v("￥" + _vm._s(goods.price)),
                                  ]),
                                ]
                              )
                            })
                          : [
                              _c("div", { staticClass: "empty-goods" }, [
                                _vm._v("暂无商品"),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.listStyle == 2
          ? _c(
              "div",
              { staticClass: "live-wrapper-b" },
              _vm._l(_vm.live, function (item, index) {
                return _c("div", { key: index, staticClass: "live-item-b" }, [
                  _c("div", { staticClass: "img-box" }, [
                    _vm._m(6, true),
                    _vm._v(" "),
                    item.type == 1
                      ? _c("div", { staticClass: "label bgblue" }, [
                          _vm._m(7, true),
                          _vm._v(" "),
                          _c("span", { staticClass: "msg" }, [
                            _vm._v("7/29 10:00"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 0
                      ? _c("div", { staticClass: "label bggary" }, [
                          _c("span", {
                            staticClass: "iconfont-diy iconyijieshu",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v("回放\n          "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 2
                      ? _c("div", { staticClass: "label bgred" }, [
                          _c("span", {
                            staticClass: "iconfont-diy iconzhibozhong",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v("直播中\n          "),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._m(8, true),
                ])
              }),
              0
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("span", [
        _c("img", {
          staticClass: "icon",
          attrs: {
            src: require("@/assets/images/broadcast_title.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v("进入频道"),
        _c("span", { staticClass: "iconfont-diy iconjinru" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-box on" }, [
      _c("span", { staticClass: "iconfont-diy icontupian" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "txt" }, [
      _c("span", {
        staticClass: "iconfont-diy iconweikaishi",
        staticStyle: { "margin-right": "5px" },
      }),
      _vm._v("预告"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-box on" }, [
      _c("span", { staticClass: "iconfont-diy icontupian" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "txt" }, [
      _c("span", {
        staticClass: "iconfont-diy iconweikaishi",
        staticStyle: { "margin-right": "5px" },
      }),
      _vm._v("预告"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "people" }, [
      _c("span", [_vm._v("樱桃小丸子")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-box on" }, [
      _c("span", { staticClass: "iconfont-diy icontupian" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "txt" }, [
      _c("span", {
        staticClass: "iconfont-diy iconweikaishi",
        staticStyle: { "margin-right": "5px" },
      }),
      _vm._v("预告"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("直播标题直播标题直播标 题直播标题"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "people" }, [
        _c("span", [_vm._v("樱桃小丸子")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }