"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyTransferApi = applyTransferApi;
exports.capitalFlowExportApi = capitalFlowExportApi;
exports.capitalFlowLstApi = capitalFlowLstApi;
exports.downloadFinancialApi = downloadFinancialApi;
exports.finaHeaderDataApi = finaHeaderDataApi;
exports.financialDetailApi = financialDetailApi;
exports.financialLstApi = financialLstApi;
exports.getStatisticsApi = getStatisticsApi;
exports.getVersion = getVersion;
exports.paymentTypeApi = paymentTypeApi;
exports.paymentTypeSaveApi = paymentTypeSaveApi;
exports.reconciliationListApi = reconciliationListApi;
exports.reconciliationMarkApi = reconciliationMarkApi;
exports.reconciliationOrderApi = reconciliationOrderApi;
exports.reconciliationRefundApi = reconciliationRefundApi;
exports.reconciliationStatusApi = reconciliationStatusApi;
exports.transferDetailApi = transferDetailApi;
exports.transferLstApi = transferLstApi;
exports.transferMarkApi = transferMarkApi;
exports.transferRecordsExportApi = transferRecordsExportApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 财务对账 -- 对账单列表
 */
function reconciliationListApi(data) {
  return _request.default.get("store/order/reconciliation/lst", data);
}

/**
 * @description 财务对账 -- 确认打款
 */
function reconciliationStatusApi(id, data) {
  return _request.default.post("store/order/reconciliation/status/".concat(id), data);
}

/**
 * @description 财务对账 -- 查看订单
 */
function reconciliationOrderApi(id, data) {
  return _request.default.get("store/order/reconciliation/".concat(id, "/order"), data);
}

/**
 * @description 财务对账 -- 退款订单
 */
function reconciliationRefundApi(id, data) {
  return _request.default.get("store/order/reconciliation/".concat(id, "/refund"), data);
}

/**
 * @description 财务对账 -- 备注
 */
function reconciliationMarkApi(id) {
  return _request.default.get("store/order/reconciliation/mark/".concat(id, "/form"));
}
/**
 * @description 资金流水 -- 列表
 */
function capitalFlowLstApi(data) {
  return _request.default.get("financial_record/list", data);
}
/**
 * @description 资金流水 -- 导出
 */
function capitalFlowExportApi(data) {
  return _request.default.get("financial_record/export", data);
}
/**
 * @description 转账记录 -- 导出
 */
function transferRecordsExportApi(data) {
  return _request.default.get("financial/export", data);
}
/**
 * @description 获取版本号
 */
function getVersion() {
  return _request.default.get("version");
}
/**
 * @description 收款方式
 */
function paymentTypeApi() {
  return _request.default.get("financial/account/form");
}
/**
 * @description 保存收款方式信息
 */
function paymentTypeSaveApi(data) {
  return _request.default.post("financial/account", data);
}
/**
 * @description 申请转账
 */
function applyTransferApi() {
  return _request.default.get("financial/create/form");
}
/**
 * @description 申请转账列表
 */
function transferLstApi(data) {
  return _request.default.get("financial/lst", data);
}
/**
 * @description 转账信息
 */
function transferDetailApi(id) {
  return _request.default.get("financial/detail/".concat(id));
}
/**
 * @description 申请转账 -- 备注
 */
function transferMarkApi(id) {
  return _request.default.get("financial/mark/".concat(id, "/form"));
}
/**
 * @description 财务账单 -- 列表
 */
function financialLstApi(data) {
  return _request.default.get("financial_record/lst", data);
}
/**
 * @description 财务账单 -- 详情
 */
function financialDetailApi(type, data) {
  return _request.default.get("financial_record/detail/".concat(type), data);
}
/**
 * @description 财务账单 -- 头部数据
 */
function finaHeaderDataApi(data) {
  return _request.default.get("financial_record/title", data);
}
/**
 * @description 财务账单 -- 下载账单
 */
function downloadFinancialApi(type, data) {
  return _request.default.get("financial_record/detail_export/".concat(type), data);
}
/**
 * @description 资金流水 -- 统计数据
 */
function getStatisticsApi(data) {
  return _request.default.get("financial_record/count", data);
}