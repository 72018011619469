"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponUser',
  filters: {
    failFilter: function failFilter(status) {
      var statusMap = {
        receive: '自己领取',
        send: '后台发送',
        give: '满赠',
        new: '新人',
        buy: '买赠送'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: '未使用',
        1: '已使用',
        2: '已过期'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      tableFromIssue: {
        page: 1,
        limit: 10,
        coupon_id: '',
        status: '',
        username: '',
        type: ''
      },
      issueData: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.getIssueList();
  },
  methods: {
    // 列表
    getIssueList: function getIssueList() {
      var _this = this;
      this.Loading = true;
      (0, _marketing.issueApi)(this.tableFromIssue).then(function (res) {
        _this.issueData.data = res.data.list;
        _this.issueData.total = res.data.count;
        _this.Loading = false;
      }).catch(function (res) {
        _this.Loading = false;
        _this.$message.error(res.message);
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      this.getIssueList();
    }
  }
};