"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _order = require("@/api/order");
var _setting = require("@/api/setting");
var _orderDetail = _interopRequireDefault(require("./orderDetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index4 = _interopRequireDefault(require("@/components/deliveryRecord/index"));
var _index5 = _interopRequireDefault(require("@/components/cards/index"));
var _auth = require("@/utils/auth");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detailsFrom: _orderDetail.default,
    cardsData: _index5.default,
    fileList: _fileList.default,
    deliveryRecord: _index4.default
  },
  data: function data() {
    return {
      fileUrl: _settingMer.default.https + '/store/import/delivery',
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      orderId: 0,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      roterPre: _settings.roterPre,
      tableFrom: {
        order_sn: this.$route.query.order_sn ? this.$route.query.order_sn : '',
        group_order_sn: '',
        order_type: '-1',
        keywords: '',
        store_name: '',
        status: '',
        date: '',
        page: 1,
        limit: 20,
        type: '1',
        username: '',
        order_id: this.$route.query.id ? this.$route.query.id : '',
        activity_type: ''
      },
      activityList: [{
        value: 0,
        label: '普通订单'
      }, {
        value: 1,
        label: '秒杀订单'
      }, {
        value: 2,
        label: '预售订单'
      }, {
        value: 3,
        label: '助力订单'
      }, {
        value: 4,
        label: '拼团订单'
      }, {
        value: 5,
        label: '0元购订单'
      }, {
        value: 11,
        label: '消费券订单'
      }],
      orderChartType: {},
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      ids: '',
      tableFromLog: {
        page: 1,
        limit: 10
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      LogLoading: false,
      dialogVisible: false,
      fileVisible: false,
      editVisible: false,
      sendVisible: false,
      pictureVisible: false,
      cardLists: [],
      orderDatalist: null,
      headeNum: [],
      editId: '',
      formValidate: {
        total_price: '',
        pay_postage: '',
        pay_price: '',
        coupon_price: ''
      },
      deliveryList: [],
      eleTempsLst: [],
      productList: [],
      // 订单商品
      productNum: 0,
      storeList: [],
      // 门店列表
      multipleSelection: [],
      shipment: {
        delivery_type: 1,
        station_id: '',
        is_split: '0',
        split: []
      },
      original: {
        delivery_name: '',
        delivery_id: ''
      },
      isResend: false,
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      isBatch: false,
      delivery_name: '',
      isDump: false,
      noLogistics: false,
      orderType: 0,
      activityType: 0,
      rules: {
        delivery_type: [{
          required: true,
          message: '请选择发送货方式',
          trigger: 'change'
        }],
        station_id: [{
          required: true,
          message: '请选择发货点',
          trigger: 'change'
        }],
        delivery_name: [{
          required: true,
          message: '请选择快递公司',
          trigger: 'change'
        }],
        to_name: [{
          required: true,
          message: '请输入送货人姓名',
          trigger: 'blur'
        }],
        delivery_id: [{
          required: true,
          message: '请输入快递单号',
          trigger: 'blur'
        }],
        cargo_weight: [{
          required: true,
          message: '请输入包裹重量',
          trigger: 'blur'
        }],
        to_phone: [{
          required: true,
          message: '请输入送货人手机号',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }],
        temp_id: [{
          required: true,
          message: '请选择电子面单',
          trigger: 'change'
        }],
        from_name: [{
          required: true,
          message: '请输入寄件人姓名',
          trigger: 'blur'
        }],
        from_tel: [{
          required: true,
          message: '请输入寄件人电话',
          trigger: 'blur'
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: '请输入正确的联系方式',
          trigger: 'blur'
        }],
        from_addr: [{
          required: true,
          message: '请输入寄件人地址',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    if (this.$route.query.hasOwnProperty('order_sn')) {
      this.tableFrom.order_sn = this.$route.query.order_sn;
    } else {
      this.tableFrom.order_sn = '';
    }
    this.isOpenDump();
    this.headerList();
    this.getCardList();
    this.getExpressLst();
    this.getList(1);
    this.getHeaderList();
    this.getStoreList();
  },
  methods: {
    limitCount: function limitCount(row) {
      if (row.stock > row.product_num) row.stock = row.product_num;
    },
    // 分单发货选择商品
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var data = [];
      this.multipleSelection.map(function (item) {
        data.push({
          id: item.order_product_id,
          num: item.product_num
        });
      });
      this.ids = data;
    },
    // 是否开启电子面单
    isOpenDump: function isOpenDump() {
      var _this = this;
      (0, _setting.serveInfoApi)().then(function (res) {
        _this.isDump = res.data.crmeb_serve_dump;
        if (res.data.crmeb_serve_dump) _this.getEleTempData();
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 获取快递公司列表
    getExpressLst: function getExpressLst() {
      var _this2 = this;
      (0, _order.expressLst)().then(function (res) {
        _this2.deliveryList = res.data;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 获取电子面单列表
    getTempsLst: function getTempsLst(name) {
      var _this3 = this;
      (0, _order.exprTempsLst)({
        com: name
      }).then(function (res) {
        _this3.eleTempsLst = res.data.data;
      });
    },
    // 获取电子面单默认数据
    getEleTempData: function getEleTempData() {
      var _this4 = this;
      (0, _order.getEleTempData)().then(function (res) {
        var data = res.data;
        var delivery_type = _this4.shipment.delivery_type;
        _this4.shipment = {
          from_name: data.mer_from_name,
          from_addr: data.mer_from_addr,
          from_tel: data.mer_from_tel,
          delivery_type: delivery_type,
          delivery_name: data.mer_from_com,
          temp_id: data.mer_config_temp_id
        };
        _this4.getTempsLst(data.mer_from_com);
      }).catch(function (res) {
        _this4.$message.error(res.message);
      });
    },
    // 获取门店列表
    getStoreList: function getStoreList() {
      var _this5 = this;
      (0, _order.getDeliveryStoreLst)().then(function (res) {
        _this5.storeList = res.data;
      }).catch(function (res) {
        _this5.$message.error(res.message);
      });
    },
    changeSend: function changeSend(e) {
      this.$refs['shipment'].clearValidate();
      if (e === 3) {
        // 如果是无需配送，就重置拆单信息
        this.shipment.is_split = '0';
        delete this.shipment.split;
      }
    },
    getPicture: function getPicture(name) {
      var _this6 = this;
      if (this.shipment.temp_id) {
        this.eleTempsLst.forEach(function (o, i) {
          if (o['temp_id'] === _this6.shipment.temp_id) {
            _this6.pictureVisible = true;
            _this6.pictureUrl = o['pic'];
          }
        });
      } else {
        this.$message.error('选择电子面单后才可以预览');
      }
    },
    // 批量发送货
    batchSend: function batchSend() {
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择订单');
      } else {
        this.isBatch = true;
        this.sendVisible = true;
        this.shipment.delivery_type = 2;
        this.shipment.order_id = this.checkedIds;
        // this.$refs['shipment'].clearValidate();
      }
    },
    handleClose: function handleClose() {
      this.sendReset();
      this.sendVisible = false;
      // this.$refs['shipment'].resetFields();
      //   this.sendReset()
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.tableFrom.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this7 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.order_id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this7.checkedIds.indexOf(id);
          if (index === -1) {
            _this7.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this7.checkedIds.indexOf(id);
          if (index > -1) {
            _this7.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 分开选择
    changeOne: function changeOne(v, row) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(row.order_id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(row.order_id);
          if (_index === -1) this.checkedIds.push(row.order_id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(row.order_id);
          if (_index2 === -1) this.noChecked.push(row.order_id);
        } else {
          var _index3 = this.checkedIds.indexOf(row.order_id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    // 头部
    getHeaderList: function getHeaderList() {
      var _this8 = this;
      (0, _order.orderHeadListApi)().then(function (res) {
        _this8.headeNum = res.data;
      }).catch(function (res) {
        _this8.$message.error(res.message);
      });
    },
    // 订单筛选
    orderFilter: function orderFilter(item) {
      var status = false;
      item.orderProduct.forEach(function (el) {
        if (el.refund_num < el.product_num) {
          status = true;
        }
      });
      return status;
    },
    // 退款详情页
    onRefundDetail: function onRefundDetail(sn) {
      this.$router.push({
        path: 'refund',
        query: {
          sn: sn
        }
      });
    },
    // 表格某一行添加特定的样式
    addTdClass: function addTdClass(val) {
      if (val.row.status > 0 && val.row.paid === 1) {
        for (var i = 0; i < val.row.orderProduct.length; i++) {
          if (val.row.orderProduct[i].refund_num >= 0 && val.row.orderProduct[i].refund_num < val.row.orderProduct[i].product_num) {
            return 'row-bg';
          }
        }
      } else {
        return ' ';
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.dialogVisible = true;
      this.$refs.orderDetail.onOrderDetails(id);
    },
    // 生成订单
    exportOrder: function exportOrder() {
      var _this9 = this;
      (0, _order.exportOrderApi)(this.tableFrom).then(function (res) {
        var h = _this9.$createElement;
        _this9.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {
          _this9.$router.push({
            path: _this9.roterPre + '/export/list'
          });
        });
      }).catch(function (res) {
        _this9.$message.error(res.message);
      });
    },
    // 生成发货单
    exportInvoice: function exportInvoice() {
      var _this10 = this;
      (0, _order.exportInvoiceApi)(this.tableFrom).then(function (res) {
        var h = _this10.$createElement;
        _this10.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {
          _this10.$router.push({
            path: _this10.roterPre + '/export/list'
          });
        });
      }).catch(function (res) {
        _this10.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList();
    },
    // 批量发货记录
    getDeliveryList: function getDeliveryList() {
      this.$refs.deliveryList.getList();
    },
    // 上传成功
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success(response.message);
        // this.getFileList("");
      } else {
        this.$message.error(response.message);
      }
    },
    // 下载物流公司对照表
    downloadLogistics: function downloadLogistics() {
      window.open(_settingMer.default.https + "/excel/download/express?token=" + (0, _auth.getToken)());
    },
    // 订单核销
    orderCancellation: function orderCancellation() {
      var _this11 = this;
      var that = this;
      this.$prompt('', '提示', {
        confirmButtonText: '立即核销',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入核销码',
        inputErrorMessage: '请输入核销码'
      }).then(function (_ref) {
        var value = _ref.value;
        that.handleCancellation(value);
      }).catch(function () {
        _this11.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 去核销
    handleCancellation: function handleCancellation(code) {
      var _this12 = this;
      this.$confirm('确定核销此订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.orderCancellationApi)(code).then(function (res) {
          _this12.$message.success(res.message);
          _this12.getList('');
        }).catch(function (res) {
          _this12.$message.error(res.message);
          _this12.LogLoading = false;
        });
      }).catch(function () {
        _this12.$message({
          type: 'info',
          message: '已取消核销'
        });
      });
    },
    // 重新下单
    handleReorderClick: function handleReorderClick(row) {
      var _this13 = this;
      this.$confirm('确定重新下单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.schlCreate)(row.order_id).then(function (res) {
          _this13.$message.success(res.message);
          _this13.getList('');
        }).catch(function (res) {
          _this13.$message.error(res.message);
          _this13.LogLoading = false;
        });
      }).catch(function () {
        _this13.$message({
          type: 'info',
          message: '已重新下单'
        });
      });
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this14 = this;
      this.dialogVisible = true;
      this.LogLoading = true;
      (0, _order.orderLogApi)(id, this.tableFromLog).then(function (res) {
        _this14.tableDataLog.data = res.data.list;
        _this14.tableDataLog.total = res.data.count;
        _this14.LogLoading = false;
      }).catch(function (res) {
        _this14.$message.error(res.message);
        _this14.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 打印订单
    printOrder: function printOrder(id) {
      var _this15 = this;
      (0, _order.orderPrintApi)(id).then(function (res) {
        _this15.$message.success(res.message);
      }).catch(function (res) {
        _this15.$message.error(res.message);
      });
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this16 = this;
      if (row.is_del === 1) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)(row.order_id).then(function (_ref2) {
            var message = _ref2.message;
            _this16.$message.success(message);
            _this16.tableData.data.splice(idx, 1);
          }).catch(function (_ref3) {
            var message = _ref3.message;
            _this16.$message.error(message);
          });
        });
      } else {
        this.$confirm('您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        });
      }
    },
    // 备注
    onOrderMark: function onOrderMark(id) {
      var _this17 = this;
      this.$modalForm((0, _order.orderRemarkApi)(id)).then(function () {
        return _this17.getList('');
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getCardList();
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getCardList();
      this.getList(1);
    },
    // 编辑
    edit: function edit(id) {
      var _this18 = this;
      // this.$modalForm(orderUpdateApi(id)).then(() => this.getList(''));
      this.editId = id;
      this.editVisible = true;
      (0, _order.orderDetailApi)(id).then(function (res) {
        _this18.formValidate = {
          pay_postage: res.data.pay_postage,
          total_price: res.data.total_price,
          coupon_price: res.data.coupon_price,
          pay_price: (Number(res.data.total_price) + Number(res.data.pay_postage) - Number(res.data.coupon_price)).toFixed(2)
        };
        _this18.loading = false;
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this18.loading = false;
        _this18.$message.error(message);
      });
    },
    editConfirm: function editConfirm() {
      var _this19 = this;
      (0, _order.orderUpdateApi)(this.editId, this.formValidate).then(function (res) {
        _this19.editVisible = false;
        _this19.$message.success(res.message);
        _this19.getList('');
      }).catch(function (_ref5) {
        var message = _ref5.message;
        _this19.$message.error(message);
      });
    },
    changePrice: function changePrice() {
      this.formValidate.pay_price = (this.formValidate.total_price + this.formValidate.pay_postage - this.formValidate.coupon_price).toFixed(2);
    },
    // 发货
    send: function send(row, id) {
      //   this.$modalForm(orderDeliveryApi(id)).then(() => this.getList(''))
      this.isBatch = false;
      this.sendVisible = true;
      this.isResend = false;
      this.orderId = id;
      this.activityType = row.activity_type;
      this.orderType = row.is_virtual;
      this.shipment.delivery_type = this.orderType === 1 ? 3 : 1;
      row.orderProduct.forEach(function (item) {
        item.stock_num = item.product_num;
      });
      this.productList = row.orderProduct;
      this.productNum = row.orderProduct && row.orderProduct[0] && row.orderProduct[0]['product_num'] || 0;
      delete this.shipment.order_id;
      if (this.tableFrom.order_type === 2) this.shipment.delivery_type = 3;
    },
    sendReset: function sendReset() {
      this.shipment = {
        mark: '',
        delivery_type: 1,
        station_id: '',
        cargo_weight: undefined,
        delivery_name: '',
        delivery_id: '',
        from_name: '',
        from_addr: '',
        from_tel: '',
        temp_id: '',
        to_name: '',
        to_phone: '',
        remark: ''
      };
    },
    // 修改发货信息
    reSend: function reSend(id) {
      var _this20 = this;
      this.isBatch = false;
      this.sendVisible = true;
      this.orderId = id;
      this.isResend = true;
      delete this.shipment.order_id;
      (0, _order.orderDetailApi)(id).then(function (res) {
        var data = res.data;
        _this20.shipment.delivery_type = !_this20.isDump && data.delivery_type === 4 ? 1 : Number(data.delivery_type);
        _this20.noLogistics = data.delivery_type;
        _this20.loading = false;
        _this20.original = {
          delivery_name: data.delivery_name,
          delivery_id: data.delivery_id
        };
        _this20.loading = false;
      }).catch(function (_ref6) {
        var message = _ref6.message;
        _this20.loading = false;
        _this20.$message.error(message);
      });
    },
    submitForm: function submitForm(name) {
      var _this21 = this;
      if (this.shipment.is_split !== '0' && this.shipment.is_split && this.orderType !== 2) {
        if (!this.multipleSelection.length) {
          return this.$message.warning('请选择拆单商品!');
        }
        var data = [];
        this.multipleSelection.map(function (item) {
          data.push({
            id: item.order_product_id,
            num: item.product_num_input
          });
        });
        this.ids = data;
        this.shipment.split = this.ids;
      }
      this.$refs[name].validate(function (valid) {
        if (valid) {
          if (_this21.shipment.delivery_type === 2) {
            _this21.shipment.delivery_name = _this21.shipment.to_name;
            _this21.shipment.delivery_id = _this21.shipment.to_phone;
          }
          delete _this21.shipment.to_name;
          delete _this21.shipment.to_phone;
          console.log(_this21.shipment);
          // return
          _this21.isBatch ? (0, _order.batchDeliveryApi)(_this21.shipment).then(function (res) {
            _this21.sendVisible = false;
            _this21.$message.success(res.message);
            _this21.getList('');
            // this.$refs[name].resetFields()
            _this21.sendReset();
          }).catch(function (_ref7) {
            var message = _ref7.message;
            _this21.$message.error(message);
          }) : (0, _order.orderDeliveryApi)(_this21.orderId, _this21.shipment).then(function (res) {
            _this21.sendVisible = false;
            _this21.$message.success(res.message);
            _this21.getList('');
            //   this.$refs[name].resetFields()
            _this21.sendReset();
          }).catch(function (_ref8) {
            var message = _ref8.message;
            _this21.$message.error(message);
          });
        } else {
          return;
        }
      });
    },
    // 列表
    getList: function getList(num) {
      var _this22 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.orderProduct.forEach(function (cell) {
            cell.product_num_input = cell.refund_num;
          });
        });
        _this22.tableData.data = res.data.list;
        _this22.tableData.total = res.data.count;
        //   this.cardLists = res.data.stat
        _this22.listLoading = false;
      }).catch(function (res) {
        _this22.$message.error(res.message);
        _this22.listLoading = false;
      });
    },
    getCardList: function getCardList() {
      var _this23 = this;
      (0, _order.cardListApi)(this.tableFrom).then(function (res) {
        _this23.cardLists = res.data;
      }).catch(function (res) {
        _this23.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    headerList: function headerList() {
      var _this24 = this;
      (0, _order.chartApi)().then(function (res) {
        _this24.orderChartType = res.data;
      }).catch(function (res) {
        _this24.$message.error(res.message);
      });
    }
  }
};