"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _setting = require("@/api/setting");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  data: function data() {
    return {
      formValidate: {
        mer_config_temp_id: '',
        mer_from_com: '',
        mer_from_name: '',
        mer_config_siid: '',
        mer_from_tel: '',
        mer_from_addr: ''
      },
      mer_config_temp_id: '',
      deliveryList: [],
      dumpList: [],
      loading: false,
      rules: {
        mer_from_com: [{
          required: true,
          message: '请选择快递公司',
          trigger: 'change'
        }],
        mer_config_temp_id: [{
          required: true,
          message: '请选择电子面单',
          trigger: 'change'
        }],
        mer_from_name: [{
          required: true,
          message: '请输入寄件人姓名',
          trigger: 'blur'
        }],
        mer_config_siid: [{
          required: true,
          message: '请输入云打印机编号',
          trigger: 'blur'
        }],
        mer_from_tel: [{
          required: true,
          message: '请输入寄件人电话',
          trigger: 'blur'
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
          message: '请输入正确的联系方式',
          trigger: 'blur'
        }],
        mer_from_addr: [{
          required: true,
          message: '请输入寄件人地址',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getData();
    this.getExpressLst();
  },
  methods: {
    // 获取快递公司列表
    getExpressLst: function getExpressLst() {
      var _this = this;
      (0, _order.expressLst)().then(function (res) {
        _this.deliveryList = res.data;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 获取电子面单列表
    getDumpList: function getDumpList(type) {
      var _this2 = this;
      (0, _setting.getdumpLst)({
        com: this.formValidate.mer_from_com
      }).then(function (res) {
        _this2.dumpList = res.data.data;
        if (res.data.data.length) {
          _this2.formValidate.mer_config_temp_id = _this2.mer_config_temp_id;
        } else {
          _this2.formValidate.mer_config_temp_id = '';
        }
        if (type !== 'first') _this2.formValidate.mer_config_temp_id = '';
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    getData: function getData() {
      var _this3 = this;
      (0, _setting.getdumpDataApi)().then(function (res) {
        _this3.formValidate = res.data;
        _this3.mer_config_temp_id = res.data.mer_config_temp_id;
        _this3.formValidate.mer_config_temp_id = '';
        if (res.data.mer_from_com) {
          _this3.getDumpList('first');
        }
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    submitForm: function submitForm(name) {
      var _this4 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          (0, _setting.updatedumpDataApi)(_this4.formValidate).then(function (res) {
            _this4.$message.success(res.message);
          }).catch(function (res) {
            _this4.$message.error(res.message);
          });
        } else {
          return;
        }
      });
    }
  }
};