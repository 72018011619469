"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _proDetail = _interopRequireDefault(require("./proDetail"));
var _index = _interopRequireDefault(require("../batchAdd/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'BroadcastProList',
  components: {
    detailsFrom: _proDetail.default,
    batchAdd: _index.default
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      listLoading: true,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        status_tag: this.$route.query.status ? this.$route.query.status : '',
        keyword: '',
        broadcast_goods_id: this.$route.query.id ? this.$route.query.id : ''
      },
      broadcast_goods_id: this.$route.query.id ? this.$route.query.id : '',
      liveRoomStatus: ''
    };
  },
  watch: {
    broadcast_goods_id: function broadcast_goods_id(newName, oldName) {
      this.getList('');
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 批量添加直播商品
    batchAdd: function batchAdd() {
      this.$refs.batchAdd.dialogVisible = true;
      this.$refs.batchAdd.getList([]);
    },
    // 详情
    onProDetails: function onProDetails(id) {
      this.broadcast_goods_id = id;
      this.$refs.ProDetail.dialogVisible = true;
      this.$refs.ProDetail.getData(id);
    },
    // 编辑
    handleUpdate: function handleUpdate(id) {
      var _this = this;
      this.$modalForm((0, _marketing.updateBroadcastApi)(id)).then(function () {
        return _this.getList();
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSureDelete().then(function () {
        (0, _marketing.broadcastProDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success(message);
          _this2.tableData.data.splice(idx, 1);
          _this2.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this2.$message.error(message);
        });
      });
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFormIssue.limit = val;
      this.getIssueList();
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      console.log(this.tableForm);
      (0, _marketing.broadcastProListApi)(this.tableForm).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this4 = this;
      (0, _marketing.changeProDisplayApi)(row.broadcast_goods_id, {
        is_show: row.is_mer_show
      }).then(function (_ref3) {
        var message = _ref3.message;
        _this4.$message.success(message);
        _this4.getList();
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this4.$message.error(message);
      });
    }
  }
};