"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponList',
  data: function data() {
    return {
      Loading: false,
      dialogVisible: false,
      detailDialog: false,
      roterPre: _settings.roterPre,
      listLoading: true,
      title: '领取/发放记录',
      receiveTime: '领取时间',
      receiveType: 0,
      id: '',
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        status: '',
        coupon_name: '',
        type: '',
        send_type: ''
      },
      tableFromIssue: {
        page: 1,
        limit: 10,
        coupon_id: 0
      },
      issueData: {
        data: [],
        total: 0
      },
      relateData: {
        data: [],
        total: 0
      },
      tableFromRelate: {
        page: 1,
        limit: 5
      },
      couponDetail: {},
      type: 0,
      sendType: {
        0: '手动领取',
        1: '消费满赠券',
        2: '新人券',
        3: '赠送券',
        4: '首单立减券'
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this = this;
      this.$modalSure().then(function () {
        (0, _marketing.couponDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this.$message.success(message);
          _this.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this.$message.error(message);
        });
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 详情
    details: function details(id) {
      var _this2 = this;
      this.detailDialog = true;
      this.type = 0;
      (0, _marketing.couponDetailApi)(id).then(function (res) {
        _this2.couponDetail = res.data;
        _this2.type = res.data.type;
        _this2.id = id;
        if (res.data.type === 1) _this2.getRelateList(id);
      }).catch(function (_ref3) {
        var message = _ref3.message;
        _this2.$message.error(message);
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this3 = this;
      this.$modalForm((0, _marketing.couponUpdateApi)(id)).then(function () {
        return _this3.getList('');
      });
    },
    // 领取记录
    receive: function receive(id) {
      this.dialogVisible = true;
      this.title = '领取/发放记录';
      this.receiveTime = '领取时间';
      this.receiveType = 0;
      this.tableFromIssue.coupon_id = id;
      this.getIssueList('');
    },
    // 使用记录
    usedRecord: function usedRecord(id) {
      this.dialogVisible = true;
      this.title = '使用记录';
      this.receiveTime = '使用时间';
      this.receiveType = 1;
      this.tableFromIssue.coupon_id = id;
      this.getIssueList(1);
    },
    // 列表
    getIssueList: function getIssueList(status) {
      var _this4 = this;
      this.Loading = true;
      this.tableFromIssue.status = status;
      (0, _marketing.issueApi)(this.tableFromIssue).then(function (res) {
        _this4.issueData.data = res.data.list;
        _this4.issueData.total = res.data.count;
        _this4.Loading = false;
      }).catch(function (res) {
        _this4.Loading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      var status = this.receiveType === 1 ? 1 : '';
      this.getIssueList(status);
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      var status = this.receiveType === 1 ? 1 : '';
      this.getIssueList(status);
    },
    // 列表
    getRelateList: function getRelateList(id) {
      var _this5 = this;
      this.Loading = true;
      this.relateData.data = [];
      (0, _marketing.couponRelateProLst)(id, this.tableFromRelate).then(function (res) {
        _this5.relateData.data = res.data.list;
        _this5.relateData.total = res.data.count;
        _this5.Loading = false;
      }).catch(function (res) {
        _this5.Loading = false;
        _this5.$message.error(res.message);
      });
    },
    pageChangeRelate: function pageChangeRelate(page) {
      this.tableFromRelate.page = page;
      this.getRelateList(this.id);
    },
    handleSizeChangeRelate: function handleSizeChangeRelate(val) {
      this.tableFromRelate.limit = val;
      this.getRelateList(this.id);
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.couponIssueListApi)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        _this6.listLoading = false;
        _this6.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this7 = this;
      (0, _marketing.couponIssueStatusApi)(row.coupon_id, row.status).then(function (_ref4) {
        var message = _ref4.message;
        _this7.$message.success(message);
        _this7.getList('');
      }).catch(function (_ref5) {
        var message = _ref5.message;
        _this7.$message.error(message);
      });
    }
  }
};