"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_goods_list',
  cname: '商品列表',
  configName: 'c_home_goods_list',
  icon: 'iconshangpinliebiao2',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'goodList',
  // 外面匹配名称
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'goodList',
        timestamp: this.num,
        setUp: {
          tabVal: '0'
        },
        tabConfig: {
          title: '选择模板',
          tabVal: 0,
          type: 1,
          tabList: [{
            name: '自动选择',
            icon: 'iconzidongxuanze'
          }, {
            name: '手动选择',
            icon: 'iconshoudongxuanze'
          }]
        },
        titleShow: {
          title: '是否显示名称',
          val: true
        },
        opriceShow: {
          title: '是否显示原价',
          val: true
        },
        priceShow: {
          title: '是否显示价格',
          val: true
        },
        couponShow: {
          title: '是否显示优惠券',
          val: true
        },
        selectConfig: {
          title: '商品分类',
          activeValue: [],
          list: [{
            value: '',
            label: ''
          }, {
            value: '',
            label: ''
          }]
        },
        goodsSort: {
          title: '商品排序',
          name: 'goodsSort',
          type: 0,
          list: [{
            val: '综合',
            icon: 'iconComm_whole'
          }, {
            val: '销量',
            icon: 'iconComm_number'
          }, {
            val: '价格',
            icon: 'iconComm_Price'
          }]
        },
        numConfig: {
          val: 6
        },
        themeColor: {
          title: '背景颜色',
          name: 'themeColor',
          default: [{
            item: '#fff'
          }],
          color: [{
            item: '#fff'
          }]
        },
        fontColor: {
          title: '价格颜色',
          name: 'fontColor',
          default: [{
            item: '#e93323'
          }],
          color: [{
            item: '#e93323'
          }]
        },
        labelColor: {
          title: '活动标签',
          name: 'labelColor',
          default: [{
            item: '#e93323'
          }],
          color: [{
            item: '#e93323'
          }]
        },
        itemStyle: {
          title: '显示类型',
          name: 'itemSstyle',
          type: 0,
          list: [{
            val: '单列',
            icon: 'iconzuoyoutuwen'
          }, {
            val: '两列',
            icon: 'iconlianglie'
          }, {
            val: '三列',
            icon: 'iconsanlie'
          }, {
            val: '大图',
            icon: 'icondanlie'
          }]
        },
        bgStyle: {
          title: '背景样式',
          name: 'bgStyle',
          type: 0,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        conStyle: {
          title: '内容样式',
          name: 'conStyle',
          type: 1,
          list: [{
            val: '直角',
            icon: 'iconPic_square'
          }, {
            val: '圆角',
            icon: 'iconPic_fillet'
          }]
        },
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        },
        productList: {
          title: '商品列表',
          list: []
        },
        goodsList: {
          max: 20,
          list: []
        }
      },
      navlist: [],
      imgStyle: '',
      txtColor: '',
      slider: '',
      tabCur: 0,
      list: [],
      activeColor: '',
      fontColor: '',
      labelColor: '',
      pageData: {},
      itemStyle: 0,
      titleShow: true,
      opriceShow: true,
      priceShow: true,
      couponShow: true,
      bgStyle: 0,
      conStyle: 1
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbConfig) {
        this.itemStyle = data.itemStyle.type || 0;
        this.activeColor = data.themeColor.color[0].item;
        this.fontColor = data.fontColor.color[0].item;
        this.labelColor = data.labelColor.color[0].item;
        this.slider = data.mbConfig.val;
        this.titleShow = data.titleShow.val;
        this.opriceShow = data.opriceShow.val;
        this.priceShow = data.priceShow.val;
        this.couponShow = data.couponShow.val;
        this.bgStyle = data.bgStyle.type;
        this.conStyle = data.conStyle.type;
        if (data.tabConfig.tabVal) {
          this.list = data.goodsList.list || [];
        } else {
          this.list = data.productList.list || [];
        }
      }
    }
  }
};