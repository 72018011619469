"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = _interopRequireDefault(require("@form-create/element-ui"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ImportGoods',
  components: {
    formCreate: _elementUi.default.$form()
  },
  data: function data() {
    return {
      option: {
        form: {
          labelWidth: '150px'
        },
        global: {
          upload: {
            props: {
              onSuccess: function onSuccess(rep, file) {
                if (rep.status === 200) {
                  file.url = rep.data.src;
                }
              }
            }
          }
        }
      },
      importVisible: false,
      selectedProducts: '',
      broadcast_room: '',
      broadcast_id: '',
      image: '',
      FormData: {
        action: '/mer/studio/goods/create.html',
        config: {},
        rule: [{
          field: 'product_id',
          props: {
            height: '536px',
            maxLength: 5,
            modal: {
              modal: false
            },
            src: '/merchant/setting/broadcastProduct?field=product_id',
            srcKey: 'src',
            title: '请选择商品',
            type: 'image',
            width: '60%'
          },
          title: '商品:',
          type: 'frame',
          value: ''
        }]
      },
      loading: false,
      selectedGoods: []
    };
  },
  mounted: function mounted() {},
  methods: {
    handleClose: function handleClose() {
      this.importVisible = false;
      this.FormData.rule[0].value = '';
    },
    getFrom: function getFrom() {},
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.image = img[0];
        }
      }, tit);
    },
    onSubmit: function onSubmit(formData) {
      var _this2 = this;
      this.listLoading = true;
      this.selectedProducts = JSON.parse(localStorage.getItem('broadcastPro')) || [];
      var ids = this.filtersArr(this.selectedProducts);
      var roomId = this.broadcast_id;
      var args = {
        ids: ids,
        room_id: roomId
      };
      (0, _marketing.broadcastGoodsImportApi)(args).then(function (res) {
        _this2.$message.success(res.message);
        _this2.listLoading = false;
        _this2.importVisible = false;
        // localStorage.setItem('noGoods', '1')
        _this2.FormData.rule[0].value = '';
        localStorage.removeItem('broadcastPro');
        _this2.$emit('getList');
      }).catch(function (res) {
        _this2.$emit('getList');
        _this2.listLoading = false;
        _this2.$message.error(res.message);
        _this2.FormData.rule[0].value = '';
        _this2.importVisible = false;
      });
    },
    // 过滤id
    filtersArr: function filtersArr(arr) {
      var _this3 = this;
      this.selectedGoods = [];
      arr.map(function (item) {
        _this3.selectedGoods.push(item.broadcast_goods_id);
      });
      return this.selectedGoods;
    }
  }
};