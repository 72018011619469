"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _systemForm = require("@/api/systemForm");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AddStore',
  props: {
    mapKey: {
      type: String,
      default: ''
    },
    keyUrl: {
      type: String,
      default: ''
    },
    deliveryType: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      listLoading: true,
      modalMap: false,
      station_id: '',
      formValidate: {
        location: ''
      },
      categoryList: [],
      cityList: [],
      rules: {
        station_name: [{
          required: true,
          message: '请输入发货点名称',
          trigger: 'blur'
        }],
        business: [{
          required: true,
          message: '请选择配送品类',
          trigger: 'change'
        }],
        station_address: [{
          required: true,
          message: '请输入发货点地址',
          trigger: 'blur'
        }],
        location: [{
          required: true,
          message: '请选择经纬度',
          trigger: 'blur'
        }],
        contact_name: [{
          required: true,
          message: '请输入联系人姓名',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入联系人电话',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    window.addEventListener('message', function (event) {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data;
      if (loc && loc.module === 'locationPicker') {
        // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        window.parent.selectAdderss(loc);
      }
    }, false);
    window.selectAdderss = this.selectAdderss;
    this.getCategoryList();
    this.getCityList();
  },
  methods: {
    // 查找位置
    onSearchs: function onSearchs() {
      if (!this.mapKey || this.mapKey === '') {
        this.$message.error('平台未配置腾讯地图KEY');
      } else this.modalMap = true;
    },
    // 选择经纬度
    selectAdderss: function selectAdderss(data) {
      this.formValidate.lat = data.latlng.lat;
      this.formValidate.lng = data.latlng.lng;
      this.formValidate.location = data.latlng.lat + ',' + data.latlng.lng;
      this.modalMap = false;
    },
    // 获取配送品类
    getCategoryList: function getCategoryList() {
      var _this = this;
      (0, _systemForm.getDeliveryCate)().then(function (res) {
        _this.categoryList = res.data;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 获取城市列表
    getCityList: function getCityList() {
      var _this2 = this;
      (0, _systemForm.getCityLst)().then(function (res) {
        _this2.cityList = res.data;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 获取详情数据
    getDetails: function getDetails(id) {
      var _this3 = this;
      this.dialogVisible = true;
      this.station_id = id;
      (0, _systemForm.deliveryStoreDetail)(id).then(function (res) {
        var info = res.data;
        _this3.formValidate = {
          station_name: info.station_name,
          business: info.business && info.business.key,
          station_address: info.station_address,
          location: info.lat + ',' + info.lng,
          contact_name: info.contact_name,
          phone: info.phone,
          id_card: info.id_card,
          username: info.username,
          password: info.password,
          status: info.status,
          city_name: ''
        };
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    submitForm: function submitForm(name) {
      var _this4 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this4.formValidate.lat = _this4.formValidate.location.split(',')[0];
          _this4.formValidate.lng = _this4.formValidate.location.split(',')[1];
          // delete this.formValidate.location
          var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if (_this4.formValidate.id_card && !reg.test(_this4.formValidate.id_card)) {
            return _this4.$message.warning('请输入正确的身份证号！');
          }
          _this4.station_id ? (0, _systemForm.deliveryStoreUpdate)(_this4.station_id, _this4.formValidate).then(function (res) {
            _this4.dialogVisible = false;
            _this4.station_id = '';
            _this4.$message.success(res.message);
            _this4.$emit('getList', '');
          }).catch(function (_ref) {
            var message = _ref.message;
            _this4.$message.error(message);
          }) : (0, _systemForm.createStoreApi)(_this4.formValidate).then(function (res) {
            _this4.dialogVisible = false;
            _this4.$message.success(res.message);
            _this4.$emit('getList', '');
          }).catch(function (_ref2) {
            var message = _ref2.message;
            _this4.$message.error(message);
          });
        }
      });
    },
    addStore: function addStore() {
      this.dialogVisible = true;
      this.station_id = '';
      this.formValidate = {
        station_name: '',
        business: '',
        station_address: '',
        location: '',
        contact_name: '',
        phone: '',
        id_card: '',
        username: '',
        password: '',
        status: '',
        city_name: ''
      };
    }
  }
};