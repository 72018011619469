"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _dashboard = require("@/api/dashboard");
//
//
//
//

require('echarts/theme/shine'); // echarts theme
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    amount: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: 'lately7'
    }
  },
  data: function data() {
    return {
      chart: null,
      newData: '',
      oldData: '',
      Comment: []
    };
  },
  watch: {
    amount: {
      deep: true,
      handler: function handler(val) {
        this.amount = val;
        this.getTurnoverRatio();
      }
    },
    date: {
      deep: true,
      handler: function handler(val) {
        this.date = val;
        // this.getTurnoverRatio();
      }
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getTurnoverRatio: function getTurnoverRatio() {
      var that = this;
      (0, _dashboard.customerRatioApi)({
        date: this.date
      }).then(function (res) {
        if (res.status === 200) {
          that.orderCustomer = res.data;
          that.newData = that.amount ? res.data.newTotalPrice : res.data.newUser;
          that.oldData = that.amount ? res.data.oldTotalPrice : res.data.oldUser;
          that.chart = _echarts.default.init(that.$el, 'shine');
          that.chart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              orient: 'vertical',
              bottom: 0,
              left: '5%',
              data: ['新用户', '老用户']
            },
            series: [{
              name: that.amount ? '金额' : '客户数',
              type: 'pie',
              // roseType: "radius",
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [{
                value: that.newData,
                name: '新用户',
                itemStyle: {
                  color: '#6394F9'
                }
              }, {
                value: that.oldData,
                name: '老用户',
                itemStyle: {
                  color: '#EFAE23'
                }
              }],
              animationEasing: 'cubicInOut',
              animationDuration: 2600
            }]
          });
        }
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    }
  }
};