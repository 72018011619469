"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemForm = require("@/api/systemForm");
var _addStore = _interopRequireDefault(require("./addStore"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    addStore: _addStore.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      storeDetail: {},
      tableData: {
        data: [],
        total: 0
      },
      mapKey: '',
      keyUrl: '',
      listLoading: true,
      loading: true,
      tableFrom: {
        keyword: '',
        status: '',
        station_name: '',
        page: 1,
        limit: 20
      },
      applyStatus: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      deliveryPoint: [{
        value: 1,
        label: '达达'
      }, {
        value: 2,
        label: 'UU'
      }],
      delivery_type: 1
    };
  },
  mounted: function mounted() {
    this.getMapKey();
    this.getList(1);
  },
  methods: {
    // 添加门店
    add: function add() {
      this.$refs.addStore.addStore();
    },
    // 编辑门店
    onEdit: function onEdit(id) {
      this.$refs.addStore.getDetails(id);
    },
    // 获取KEY值
    getMapKey: function getMapKey() {
      var _this = this;
      (0, _systemForm.getConfigApi)().then(function (res) {
        _this.mapKey = res.data.tx_map_key;
        var keys = res.data.tx_map_key;
        _this.keyUrl = "https://apis.map.qq.com/tools/locpicker?type=1&key=".concat(keys, "&referer=myapp");
        _this.delivery_type = res.data.delivery_type;
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _systemForm.deliveryStoreLst)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 详情
    onDetails: function onDetails(id) {
      var _this3 = this;
      this.dialogVisible = true;
      (0, _systemForm.deliveryStoreDetail)(id).then(function (res) {
        _this3.storeDetail = res.data;
        _this3.loading = false;
      }).catch(function (res) {
        _this3.$message.error(res.message);
        _this3.loading = false;
      });
    },
    // 备注
    onRemark: function onRemark(id) {
      var _this4 = this;
      this.$modalForm((0, _systemForm.deliveryStoreRemark)(id)).then(function () {
        return _this4.getList('');
      });
    },
    // 删除
    onDelete: function onDelete(id, idx) {
      var _this5 = this;
      this.$modalSureDelete('确定删除该门店').then(function () {
        (0, _systemForm.deliveryStoreDelete)(id).then(function (_ref) {
          var message = _ref.message;
          _this5.$message.success(message);
          _this5.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this5.$message.error(message);
        });
      });
    },
    // 是否开通
    onchangeIsShow: function onchangeIsShow(row) {
      var _this6 = this;
      (0, _systemForm.deliveryStoreStatus)(row.station_id, {
        status: row.status
      }).then(function (_ref3) {
        var message = _ref3.message;
        _this6.$message.success(message);
        _this6.getList();
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this6.$message.error(message);
      });
    }
  }
};