"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_status',
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      configData: {
        status: false
      }
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  methods: {
    change: function change(status) {
      var _this = this;
      this.$nextTick(function () {
        _this.configData.status = status;
        _this.$store.commit('mobildConfig/footStatus', status);
      });
      //   this.$emit("getConfig", this.configData);
    }
  }
};