"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _monaco = _interopRequireDefault(require("./monaco"));
var _wangeditor = _interopRequireDefault(require("wangeditor"));
var _editor = _interopRequireDefault(require("./editor"));
var _html = _interopRequireDefault(require("./html"));
var _uploadVideo = _interopRequireDefault(require("@/components/uploadVideo"));
var _bus = _interopRequireDefault(require("../../utils/bus"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  components: {
    uploadVideo: _uploadVideo.default,
    monaco: _monaco.default
  },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      monacoBox: false,
      value: '',
      modalPic: false,
      isChoice: '多选',
      picTit: 'danFrom',
      img: '',
      modalVideo: false,
      editor: null,
      uploadSize: 2,
      video: '',
      newHtml: ''
    };
  },
  computed: {
    initEditor: function initEditor() {
      return this.content && this.editor;
    }
  },
  watch: {
    initEditor: function initEditor(val) {
      if (val) {
        this.editor.txt.html(this.content);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    this.createEditor();
    _bus.default.$on('Video', function (Video) {
      _this2.getvideoint();
    });
    _bus.default.$on('Html', function (Html) {
      _this2.getHtmlint();
    });
  },
  methods: {
    changeValue: function changeValue(value) {
      this.newHtml = value;
      this.$emit('editorContent', value);
      this.$emit('input', value);
    },
    // 获取多张图信息
    getPic: function getPic(pc) {
      var _this = this;
      _this.img = pc.att_dir;
      _this.modalPic = false;
      this.editor.cmd.do('insertHTML', "<img src=\"".concat(_this.img, "\" style=\"max-width:100%;\"/>"));
    },
    getimg: function getimg() {
      var _this = this;
      _this.isChoice = '多选';
      _this.$modalUpload(function (img) {
        img.map(function (d) {
          _this.editor.cmd.do('insertHTML', "<img src=\"".concat(d, "\" style=\"max-width:100%;\"/>"));
        });
      });
    },
    getvideoint: function getvideoint() {
      this.modalVideo = true;
    },
    getHtmlint: function getHtmlint() {
      this.monacoBox = !this.monacoBox;
      this.value = this.newHtml;
      if (!this.monacoBox) {
        this.editor.txt.html(this.newHtml);
      }
    },
    getPicD: function getPicD(data) {
      var _this3 = this;
      var _this = this;
      _this.modalPic = false;
      data.map(function (d) {
        _this3.editor.cmd.do('insertHTML', "<img src=\"".concat(d.att_dir, "\" style=\"max-width:100%;\"/>"));
      });
    },
    getvideo: function getvideo(data) {
      var _this = this;
      _this.modalVideo = false;
      this.video = data;
      this.editor.cmd.do('insertHTML', "<video src=\"".concat(_this.video, "\" controls style=\"max-width:100%;\"/>"));
    },
    createEditor: function createEditor() {
      var _this4 = this;
      var _this = this;
      var menuKey = 'alertMenuKey';
      var html = 'alertHtml';
      this.editor = new _wangeditor.default(this.$refs['wang-editor']);
      this.editor.menus.extend(menuKey, _editor.default);
      this.editor.menus.extend(html, _html.default);
      this.editor.config.menus = this.editor.config.menus.concat(html);
      this.editor.config.menus = this.editor.config.menus.concat(menuKey);
      this.editor.config.uploadImgFromMedia = function () {
        _this.getimg();
      };
      // this.editor.config.uploadVideoHeaders = _this.header;
      this.editor.config.height = 600;
      this.editor.config.menus = ['alertHtml', 'head', 'bold', 'fontSize', 'fontName', 'italic', 'underline', 'strikeThrough', 'indent', 'lineHeight', 'foreColor', 'backColor', 'link', 'list',
      // "todo",
      'justify', 'quote', 'emoticon', 'image', 'alertMenuKey',
      // "table",
      'code', 'splitLine'];
      // 配置全屏功能按钮是否展示
      //   this.editor.config.showFullScreen = false
      this.editor.config.uploadImgShowBase64 = true;
      //   this.editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
      this.editor.config.zIndex = 0;
      //   this.editor.config.uploadImgMaxSize = this.uploadSize * 1024 * 1024
      this.editor.config.compatibleMode = function () {
        // 返回 true 表示使用兼容模式；返回 false 使用标准模式
        return true;
      };
      this.editor.config.onchange = function (newHtml) {
        _this4.newHtml = newHtml;
        _this4.$emit('editorContent', newHtml);
      };
      this.editor.config.onchangeTimeout = 800; // change后多久更新数据

      this.editor.create();
    }
  }
};