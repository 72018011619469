"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_hot',
  cname: '推荐组',
  icon: 'icontuijianzu',
  configName: 'c_home_hot',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'activeParty',
  // 外面匹配名称
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'activeParty',
        timestamp: this.num,
        setUp: {
          tabVal: '0'
        },
        tabConfig: {
          title: '展示样式',
          tabVal: 0,
          type: 1,
          tabList: [{
            name: '单行展示',
            icon: 'icondanhang'
          }, {
            name: '多行展示',
            icon: 'iconduohang'
          }]
        },
        menuConfig: {
          title: '最多可添加4个板块，图片建议尺寸140 * 140px；鼠标拖拽左侧圆点可调整板块顺序',
          maxList: 4,
          list: [{
            img: '',
            info: [{
              title: '标题',
              value: '首发新品',
              tips: '选填，不超过4个字',
              max: 4
            }, {
              title: '简介',
              value: '新品抢先购',
              tips: '选填，不超过6个字',
              max: 6
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100
            }]
          }, {
            img: '',
            info: [{
              title: '标题',
              value: '热门榜单',
              tips: '选填，不超过4个字',
              max: 4
            }, {
              title: '简介',
              value: '剁手必备指南',
              tips: '选填，不超过20个字',
              max: 20
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100
            }]
          }, {
            img: '',
            info: [{
              title: '标题',
              value: '精品推荐',
              tips: '选填，不超过4个字',
              max: 4
            }, {
              title: '简介',
              value: '发现品质好物',
              tips: '选填，不超过20个字',
              max: 20
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100
            }]
          }, {
            img: '',
            info: [{
              title: '标题',
              value: '促销单品',
              tips: '选填，不超过4个字',
              max: 4
            }, {
              title: '简介',
              value: '惊喜折扣价',
              tips: '选填，不超过20个字',
              max: 20
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100
            }]
          }]
        },
        themeColor: {
          title: '主题颜色',
          name: 'themeColor',
          default: [{
            item: '#fc3c3e'
          }],
          color: [{
            item: '#fc3c3e'
          }]
        },
        bgColor: {
          title: '标签背景颜色',
          name: 'bgColor',
          default: [{
            item: '#F62C2C'
          }, {
            item: '#F96E29'
          }],
          color: [{
            item: '#F62C2C'
          }, {
            item: '#F96E29'
          }]
        },
        boxColor: {
          title: '背景颜色',
          name: 'boxColor',
          default: [{
            item: '#ffe5e3'
          }],
          color: [{
            item: '#f5f5f5'
          }]
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        }
      },
      slider: '',
      hotList: [],
      txtColor: '',
      bgColor: [],
      pageData: {},
      boxColor: '',
      isOne: 0
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbConfig) {
        this.slider = data.mbConfig.val;
        this.hotList = data.menuConfig.list;
        this.txtColor = data.themeColor.color[0].item;
        this.bgColor = data.bgColor.color;
        this.boxColor = data.boxColor.color[0].item;
        this.isOne = data.tabConfig.tabVal;
      }
    }
  }
};