"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assistProSort = assistProSort;
exports.batchAddBroadcastGoodsApi = batchAddBroadcastGoodsApi;
exports.broadcastDeleteApi = broadcastDeleteApi;
exports.broadcastDetailApi = broadcastDetailApi;
exports.broadcastGoodsDeleteApi = broadcastGoodsDeleteApi;
exports.broadcastGoodsImportApi = broadcastGoodsImportApi;
exports.broadcastListApi = broadcastListApi;
exports.broadcastProDeleteApi = broadcastProDeleteApi;
exports.broadcastProDetailApi = broadcastProDetailApi;
exports.broadcastProListApi = broadcastProListApi;
exports.broadcastProRemarksApi = broadcastProRemarksApi;
exports.broadcastRemarksApi = broadcastRemarksApi;
exports.changeProDisplayApi = changeProDisplayApi;
exports.changeStudioRoomDisplayApi = changeStudioRoomDisplayApi;
exports.combinationActivityLst = combinationActivityLst;
exports.combinationCreateProApi = combinationCreateProApi;
exports.combinationDataApi = combinationDataApi;
exports.combinationDetailApi = combinationDetailApi;
exports.combinationProDeleteApi = combinationProDeleteApi;
exports.combinationProDetailApi = combinationProDetailApi;
exports.combinationProLst = combinationProLst;
exports.combinationProSort = combinationProSort;
exports.combinationProUpdateApi = combinationProUpdateApi;
exports.combinationStatusApi = combinationStatusApi;
exports.couponCloneApi = couponCloneApi;
exports.couponCreateApi = couponCreateApi;
exports.couponDeleteApi = couponDeleteApi;
exports.couponDetailApi = couponDetailApi;
exports.couponIssueApi = couponIssueApi;
exports.couponIssueDeleteApi = couponIssueDeleteApi;
exports.couponIssueListApi = couponIssueListApi;
exports.couponIssuePushApi = couponIssuePushApi;
exports.couponIssueStatusApi = couponIssueStatusApi;
exports.couponListApi = couponListApi;
exports.couponRelateProLst = couponRelateProLst;
exports.couponSelectApi = couponSelectApi;
exports.couponSendApi = couponSendApi;
exports.couponSendLstApi = couponSendLstApi;
exports.couponUpdateApi = couponUpdateApi;
exports.createBroadcastApi = createBroadcastApi;
exports.createBroadcastProApi = createBroadcastProApi;
exports.discountsChangeStatus = discountsChangeStatus;
exports.discountsDelete = discountsDelete;
exports.discountsGetDetails = discountsGetDetails;
exports.discountsList = discountsList;
exports.discountsSave = discountsSave;
exports.discountsUpdate = discountsUpdate;
exports.doCreateZeroPurchaseGoods = doCreateZeroPurchaseGoods;
exports.doDeleteZeroPurchaseGoods = doDeleteZeroPurchaseGoods;
exports.doUpdateZeroPurchaseGoods = doUpdateZeroPurchaseGoods;
exports.doUpdateZeroPurchaseGoodsLabels = doUpdateZeroPurchaseGoodsLabels;
exports.doUpdateZeroPurchaseGoodsSort = doUpdateZeroPurchaseGoodsSort;
exports.doUpdateZeroPurchaseGoodsStatus = doUpdateZeroPurchaseGoodsStatus;
exports.fetchZeroPurchaseActivityDetail = fetchZeroPurchaseActivityDetail;
exports.fetchZeroPurchaseActivityList = fetchZeroPurchaseActivityList;
exports.fetchZeroPurchaseArrondiList = fetchZeroPurchaseArrondiList;
exports.fetchZeroPurchaseGoodsDetailById = fetchZeroPurchaseGoodsDetailById;
exports.fetchZeroPurchaseGoodsList = fetchZeroPurchaseGoodsList;
exports.fetchZeroPurchaseGoodsPreview = fetchZeroPurchaseGoodsPreview;
exports.gnsOrderList = gnsOrderList;
exports.integralConfigApi = integralConfigApi;
exports.integralLstApi = integralLstApi;
exports.integralTitleApi = integralTitleApi;
exports.issueApi = issueApi;
exports.openCollectionApi = openCollectionApi;
exports.openCommontApi = openCommontApi;
exports.presellProSort = presellProSort;
exports.productAttrsApi = productAttrsApi;
exports.productGnsList = productGnsList;
exports.productGnsStatus = productGnsStatus;
exports.seckillDetailApi = seckillDetailApi;
exports.seckillProSort = seckillProSort;
exports.studioAssistant = studioAssistant;
exports.studioAssistantAdd = studioAssistantAdd;
exports.studioAssistantDelete = studioAssistantDelete;
exports.studioAssistantUpdate = studioAssistantUpdate;
exports.studioCloseKfApi = studioCloseKfApi;
exports.studioEdit = studioEdit;
exports.studioEditAssistant = studioEditAssistant;
exports.studioProList = studioProList;
exports.studioProShowApi = studioProShowApi;
exports.studioPushMessageApi = studioPushMessageApi;
exports.updateBroadcastApi = updateBroadcastApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 优惠券模板 -- 列表
 */
function couponListApi(data) {
  return _request.default.get('store/coupon/lst', data);
}
/**
 * @description 优惠券模板 -- 新增表单
 */
function couponCreateApi() {
  return _request.default.get('store/coupon/create/form');
}
/**
 * @description 优惠券模板 -- 编辑表单
 */
function couponUpdateApi(id) {
  return _request.default.get("store/coupon/update/".concat(id, "/form"));
}
/**
 * @description 优惠券模板 -- 发布优惠券
 */
function couponIssueApi(id) {
  return _request.default.get("store/coupon/issue/create/form/".concat(id));
}

/**
 * @description 已发布优惠券 -- 列表
 */
function couponIssueListApi(data) {
  return _request.default.get('store/coupon/lst', data);
}
/**
 * @description 已发布优惠券 -- 修改状态
 */
function couponIssueStatusApi(id, status) {
  return _request.default.post("store/coupon/status/".concat(id), {
    status: status
  });
}
/**
 * @description 已发布优惠券 -- 添加优惠券
 */
function couponIssuePushApi() {
  return _request.default.get("store/coupon/create/form");
}
/**
 * @description 优惠券列表 -- 删除
 */
function couponIssueDeleteApi(id) {
  return _request.default.delete("store/coupon/issue/".concat(id));
}
/**
 * @description 优惠券列表 -- 复制
 */
function couponCloneApi(id) {
  return _request.default.get("store/coupon/clone/form/".concat(id));
}
/**
 * @description 优惠券列表 -- 领取记录
 */
function issueApi(data) {
  return _request.default.get("store/coupon/issue", data);
}
/**
 * @description 赠送优惠券组件列表 -- 列表
 */
function couponSelectApi(data) {
  return _request.default.get("store/coupon/select", data);
}
/**
 * @description 优惠券列表 -- 详情
 */
function couponDetailApi(coupon_id) {
  return _request.default.get("store/coupon/detail/".concat(coupon_id));
}
/**
 * @description 优惠劵 -- 删除
 */
function couponDeleteApi(coupon_id) {
  return _request.default.delete("store/coupon/delete/".concat(coupon_id));
}
/**
 * @description 优惠劵 -- 发送
 */
function couponSendApi(data) {
  return _request.default.post("store/coupon/send", data);
}
/**
 * @description 优惠券发送记录 -- 列表
 */
function couponSendLstApi(data) {
  return _request.default.get("store/coupon_send/lst", data);
}
/**
 * @description 直播间 -- 创建直播间
 */
function createBroadcastApi() {
  return _request.default.get("broadcast/room/create/form");
}

/**
 * @description 直播间 -- 直播间列表
 */
function broadcastListApi(data) {
  return _request.default.get("broadcast/room/lst", data);
}
/**
 * @description 直播间 -- 直播间详情
 */
function broadcastDetailApi(id) {
  return _request.default.get("broadcast/room/detail/".concat(id));
}
/**
 * @description 直播间 -- 备注
 */
function broadcastRemarksApi(id, mark) {
  return _request.default.post("broadcast/room/mark/".concat(id), {
    mark: mark
  });
}
/**
 * @description 直播间商品 -- 创建直播间商品
 */
function createBroadcastProApi() {
  return _request.default.get("broadcast/goods/create/form");
}
/**
 * @description 直播间商品 -- 编辑
 */
function updateBroadcastApi(id) {
  return _request.default.get("broadcast/goods/update/form/".concat(id));
}
/**
 * @description 直播间商品 -- 直播间商品列表
 */
function broadcastProListApi(data) {
  return _request.default.get("broadcast/goods/lst", data);
}
/**
 * @description 直播间商品 -- 直播间商品详情
 */
function broadcastProDetailApi(id) {
  return _request.default.get("broadcast/goods/detail/".concat(id));
}
/**
 * @description 直播间商品 -- 修改显示状态（上下架）
 */
function changeProDisplayApi(id, data) {
  return _request.default.post("broadcast/goods/status/".concat(id), data);
}
/**
 * @description 直播间 -- 商品导入
 */
function broadcastGoodsImportApi(data) {
  return _request.default.post("broadcast/room/export_goods", data);
}
/**
 * @description 直播间 -- 备注
 */
function broadcastProRemarksApi(id, mark) {
  return _request.default.post("broadcast/goods/mark/".concat(id), {
    mark: mark
  });
}

/**
 * @description 直播间-- 修改显示状态（上下架）
 */
function changeStudioRoomDisplayApi(id, data) {
  return _request.default.post("broadcast/room/status/".concat(id), data);
}
/**
 * @description 直播间 -- 直播间商品
 */
function studioProList(id, data) {
  return _request.default.get("broadcast/room/goods/".concat(id), data);
}
/**
 * @description 直播间商品 -- 删除
 */
function broadcastProDeleteApi(broadcast_goods_id) {
  return _request.default.delete("broadcast/goods/delete/".concat(broadcast_goods_id));
}
/**
 * @description 直播间 -- 删除
 */
function broadcastDeleteApi(broadcast_room_id) {
  return _request.default.delete("broadcast/room/delete/".concat(broadcast_room_id));
}
/**
 * @description 直播间商品 -- 批量添加
 */
function batchAddBroadcastGoodsApi(data) {
  return _request.default.post("broadcast/goods/batch_create", data);
}
/**
 * @description 直播间 -- 开启收录
 */
function openCollectionApi(id, status) {
  return _request.default.post("broadcast/room/feedsPublic/".concat(id), {
    status: status
  });
}
/**
 * @description 直播间 -- 商品上下架
 */
function studioProShowApi(id, data) {
  return _request.default.post("broadcast/room/on_sale/".concat(id), data);
}
/**
 * @description 直播间 -- 禁言
 */
function openCommontApi(id, status) {
  return _request.default.post("broadcast/room/comment/".concat(id), {
    status: status
  });
}
/**
 * @description 直播间 -- 客服开关
 */
function studioCloseKfApi(id, status) {
  return _request.default.post("broadcast/room/closeKf/".concat(id), {
    status: status
  });
}
/**
 * @description 直播间 -- 推送消息
 */
function studioPushMessageApi(id) {
  return _request.default.get("broadcast/room/push_message/".concat(id));
}
/**
 * @description 直播间已导入商品 -- 删除
 */
function broadcastGoodsDeleteApi(data) {
  return _request.default.post("broadcast/room/rm_goods", data);
}
/**
 * @description 直播间 -- 编辑
 */
function studioEdit(id) {
  return _request.default.get("broadcast/room/update/form/".concat(id));
}
/**
 * @description 直播间 -- 添加助手
 */
function studioAssistantAdd() {
  return _request.default.get("broadcast/assistant/create/form");
}
/**
 * @description 直播间 -- 编辑助手
 */
function studioAssistantUpdate(id) {
  return _request.default.get("broadcast/assistant/update/".concat(id, "/form"));
}
/**
 * @description 直播间 -- 删除助手
 */
function studioAssistantDelete(id) {
  return _request.default.delete("broadcast/assistant/delete/".concat(id));
}
/**
 * @description 直播间 -- 直播助手列表
 */
function studioAssistant(data) {
  return _request.default.get("broadcast/assistant/lst", data);
}
/**
 * @description 直播间 -- 编辑直播助手
 */
function studioEditAssistant(id) {
  return _request.default.get("broadcast/room/addassistant/form/".concat(id));
}
/**
 * @description 拼团 -- 获取数据
 */
function combinationDataApi() {
  return _request.default.get("config/others/group_buying");
}
/**
 * @description 拼团 -- 创建拼团商品
 */
function combinationCreateProApi(data) {
  return _request.default.post("store/product/group/create", data);
}
/**
 * @description 拼团 -- 编辑拼团商品
 */
function combinationProUpdateApi(id, data) {
  return _request.default.post("store/product/group/update/".concat(id), data);
}
/**
 * @description 拼团 -- 商品列表
 */
function combinationProLst(data) {
  return _request.default.get("store/product/group/lst", data);
}
/**
 * @description 拼团 -- 商品列表（查看详情）
 */
function combinationProDetailApi(id) {
  return _request.default.get("store/product/group/detail/".concat(id));
}
/**
 * @description 拼团 -- 商品列表（删除）
 */
function combinationProDeleteApi(id) {
  return _request.default.delete("store/product/group/delete/".concat(id));
}
/**
 * @description 拼团商品列表 -- 显示状态（上下架）
 */
function combinationStatusApi(id, status) {
  return _request.default.post("store/product/group/status/".concat(id), {
    status: status
  });
}
/**
 * @description 拼团活动 -- 活动列表
 */
function combinationActivityLst(data) {
  return _request.default.get("store/product/group/buying/lst", data);
}
/**
 * @description 拼团活动 -- 查看详情
 */
function combinationDetailApi(id, data) {
  return _request.default.get("store/product/group/buying/detail/".concat(id), data);
}
/**
 * @description 秒杀活动 -- 查看详情
 */
function seckillDetailApi(id, data) {
  return _request.default.get("store/seckill_product/detail/".concat(id), data);
}
/**
 * @description 拼团商品 -- 列表排序
 */
function combinationProSort(id, data) {
  return _request.default.post("/store/product/group/sort/".concat(id), data);
}
/**
 * @description 秒杀商品 -- 列表排序
 */
function seckillProSort(id, data) {
  return _request.default.post("/store/seckill_product/sort/".concat(id), data);
}
/**
 * @description 预售商品 -- 列表排序
 */
function presellProSort(id, data) {
  return _request.default.post("/store/product/presell/sort/".concat(id), data);
}
/**
 * @description 助力商品 -- 列表排序
 */
function assistProSort(id, data) {
  return _request.default.post("/store/product/assist/sort/".concat(id), data);
}
/**
 * @description 优惠券详情 -- 关联商品列表
 */
function couponRelateProLst(id, data) {
  return _request.default.get("/store/coupon/product/".concat(id), data);
}
/**
 * @description 积分 -- 积分配置
 */
function integralConfigApi(key) {
  return _request.default.get("config/".concat(key));
}
/**
 * @description 积分 -- 积分统计
 */
function integralTitleApi() {
  return _request.default.get("integral/title");
}
/**
 * @description 积分 -- 积分列表
 */
function integralLstApi(data) {
  return _request.default.get("integral/lst", data);
}
/**
 * @description 创建套餐 -- 获取商品规格
 */
function productAttrsApi(id) {
  return _request.default.get("store/product/attr_value/".concat(id));
}
/**
 * @description 创建套餐 -- 获取商品规格
 */
function discountsSave(data) {
  return _request.default.post("discounts/create", data);
}

/**
 * @description 套餐列表 -- 列表数据
 */
function discountsList(data) {
  return _request.default.get("discounts/lst", data);
}

/**
 * @description 套餐列表 -- 显示状态（上下架）
 */
function discountsChangeStatus(id, status) {
  return _request.default.post("discounts/status/".concat(id), {
    status: status
  });
}
/**
 * @description 套餐列表 -- 详情
 */
function discountsGetDetails(id) {
  return _request.default.get("discounts/detail/".concat(id));
}
/**
 * @description 套餐列表 -- 删除
 */
function discountsDelete(id) {
  return _request.default.delete("discounts/delete/".concat(id));
}
/**
 * @description 创建套餐 -- 获取商品规格
 */
function discountsUpdate(id, data) {
  return _request.default.post("discounts/update/".concat(id), data);
}
/**
 * @description 获取0元购专区列表
 */
function fetchZeroPurchaseArrondiList() {
  return _request.default.get('store/product/purchase/catlst');
}
/**
 * @description 更新0元购商品状态
 */
function doUpdateZeroPurchaseGoodsStatus(id, status) {
  return _request.default.post("store/product/purchase/status/".concat(id), {
    status: status
  });
}
/**
 * @description 获取0元购商品列表
 */
function fetchZeroPurchaseGoodsList(data) {
  return _request.default.get('store/product/purchase/lst', data);
}
/**
 * @description 获取0元购商品详情
 */
function fetchZeroPurchaseGoodsDetailById(id) {
  return _request.default.get("store/product/purchase/detail/".concat(id));
}
/**
 * @description 删除0元购商品
 */
function doDeleteZeroPurchaseGoods(id) {
  return _request.default.delete("store/product/purchase/delete/".concat(id));
}
/**
 * @description 更新0元购商品排序
 */
function doUpdateZeroPurchaseGoodsSort(id, data) {
  return _request.default.post("/store/product/purchase/sort/".concat(id), data);
}
/**
 * @description 0元购商品预览
 */
function fetchZeroPurchaseGoodsPreview(data) {
  return _request.default.get("store/product/purchase/preview", data);
}
/**
 * @description 添加0元购商品
 */
function doCreateZeroPurchaseGoods(data) {
  return _request.default.post("store/product/purchase/create", data);
}
/**
 * @description 编辑0元购商品
 */
function doUpdateZeroPurchaseGoods(id, data) {
  return _request.default.post("store/product/purchase/update/".concat(id), data);
}
/**
 * @description 编辑0元购商品标签
 */
function doUpdateZeroPurchaseGoodsLabels(id, data) {
  return _request.default.post("store/product/purchase/labels/".concat(id), data);
}
/**
 * @description 获取0元购活动列表
 * /mer/store/product/purchase/buying/lst?page=1&limit=20&keyword=&date=&status=&user_name=
 */
function fetchZeroPurchaseActivityList(data) {
  return _request.default.get("store/product/purchase/buying/lst", data);
}
/**
 * @description 获取0元购活动详情
 */
function fetchZeroPurchaseActivityDetail(id, data) {
  return _request.default.get("store/product/purchase/buying/detail/".concat(id), data);
}
/**
 * @description 兑换专区列表
 */
function productGnsList(data) {
  return _request.default.get("store/product/gns/lst", data);
}
/**
 * @description 兑换专区列表切换状态
 */
function productGnsStatus(id, data) {
  return _request.default.post("/store/product/gns/status/".concat(id), data);
}

/**
 * @description 兑换专区记录列表
 */
function gnsOrderList(data) {
  return _request.default.get("store/gns_order/lst", data);
}