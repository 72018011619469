var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _vm.FormData
            ? _c("form-create", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "fc",
                staticClass: "formBox",
                attrs: {
                  option: _vm.option,
                  rule: _vm.FormData.rule,
                  "handle-icon": "false",
                },
                on: { "on-submit": _vm.onSubmit },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "type-alert",
                      fn: function (ref) {
                        var model = ref.model
                        return [
                          _c("pre", { staticClass: "text-alert" }, [
                            _vm._v(_vm._s(model.value)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3931030092
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }