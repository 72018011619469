var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.user_type,
                    callback: function ($$v) {
                      _vm.user_type = $$v
                    },
                    expression: "user_type",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "全部用户", name: "" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "微信用户", name: "wechat" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "小程序用户", name: "routine" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "H5用户", name: "h5" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "APP", name: "app" } }),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { label: "PC", name: "pc" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: "",
                        size: "small",
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "用户昵称：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: "请输入昵称",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.userFrom.nickname,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userFrom,
                                              "nickname",
                                              $$v
                                            )
                                          },
                                          expression: "userFrom.nickname",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "用户UID：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: "请输入UID",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.userFrom.uid,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.userFrom, "uid", $$v)
                                          },
                                          expression: "userFrom.uid",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "用户手机号：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: "请输入手机号",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.userFrom.phone,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.userFrom, "phone", $$v)
                                          },
                                          expression: "userFrom.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.collapse
                            ? [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户标签：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                  filterable: "",
                                                },
                                                model: {
                                                  value: _vm.userFrom.label_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "label_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.label_id",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("全部")]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.labelLists,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        value: item.label_id,
                                                        label: item.label_name,
                                                      },
                                                    })
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "性别：" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { type: "button" },
                                                model: {
                                                  value: _vm.userFrom.sex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "userFrom.sex",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "" } },
                                                  [_c("span", [_vm._v("全部")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "1" } },
                                                  [_c("span", [_vm._v("男")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "2" } },
                                                  [_c("span", [_vm._v("女")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "0" } },
                                                  [_c("span", [_vm._v("保密")])]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "身份：" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { type: "button" },
                                                model: {
                                                  value:
                                                    _vm.userFrom.is_promoter,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "is_promoter",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.is_promoter",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "" } },
                                                  [_c("span", [_vm._v("全部")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v("推广员"),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v("普通用户"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "访问情况：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.userFrom.user_time_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "user_time_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.user_time_type",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "visit",
                                                    label: "最后访问",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "add_time",
                                                    label: "首次访问",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "消费情况：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.userFrom.pay_count,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "pay_count",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.pay_count",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "-1",
                                                    label: "0次",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "0",
                                                    label: "1次及以上",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "1",
                                                    label: "2次及以上",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "2",
                                                    label: "3次及以上",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "3",
                                                    label: "4次及以上",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "4",
                                                    label: "5次及以上",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "timeBox",
                                            attrs: { label: "访问时间：" },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "selWidth",
                                              attrs: {
                                                "value-format": "yyyy/MM/dd",
                                                align: "right",
                                                "unlink-panels": "",
                                                format: "yyyy/MM/dd",
                                                size: "small",
                                                type: "daterange",
                                                placement: "bottom-end",
                                                placeholder: "自定义时间",
                                                "picker-options":
                                                  _vm.pickerOptions,
                                              },
                                              on: { change: _vm.onchangeTime },
                                              model: {
                                                value: _vm.timeVal,
                                                callback: function ($$v) {
                                                  _vm.timeVal = $$v
                                                },
                                                expression: "timeVal",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right userFrom",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "ios-search",
                                        label: "default",
                                        size: "small",
                                      },
                                      on: { click: _vm.userSearchs },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ResetSearch mr10",
                                      attrs: { size: "small", type: "reset" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset("userFrom")
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ivu-ml-8",
                                      on: {
                                        click: function ($event) {
                                          _vm.collapse = !_vm.collapse
                                        },
                                      },
                                    },
                                    [
                                      !_vm.collapse
                                        ? [
                                            _vm._v(" 展开 "),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-down",
                                            }),
                                          ]
                                        : [
                                            _vm._v(" 收起 "),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-up",
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "mr15",
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: {
                    type: "primary",
                    icon: "ios-search",
                    label: "default",
                    size: "small",
                  },
                  on: { click: _vm.sendCoupon },
                },
                [_vm._v("发送优惠券")]
              ),
              _vm._v(" "),
              _vm.checkedIds.length > 0 || _vm.allCheck
                ? _c("el-alert", {
                    attrs: {
                      title: _vm.allCheck
                        ? "已选择  " + _vm.tableData.total + "  项"
                        : "已选择  " + _vm.checkedIds.length + "  项",
                      type: "info",
                      "show-icon": "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "duo" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("duo")
                                    },
                                  },
                                },
                                [_vm._v("选中全部")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(_vm.userFrom.page) >
                                      -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.uid) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.uid) === -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "user_merchant_id",
                  label: "ID",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uid", label: "UID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.avatar
                                  ? scope.row.avatar
                                  : _vm.moren,
                                "preview-src-list": [
                                  scope.row.avatar || _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "acea-row" }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.sex === 1,
                                expression: "row.sex === 1",
                              },
                            ],
                            staticClass: "el-icon-male mr5",
                            staticStyle: {
                              "font-size": "15px",
                              "margin-top": "3px",
                              color: "#2db7f5",
                            },
                          }),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.sex === 2,
                                expression: "row.sex === 2",
                              },
                            ],
                            staticClass: "el-icon-female mr5",
                            staticStyle: {
                              "font-size": "15px",
                              "margin-top": "3px",
                              color: "#ed4014",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            domProps: { textContent: _vm._s(row.nickname) },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.vip_name,
                                expression: "row.vip_name",
                              },
                            ],
                            staticClass: "vipName",
                          },
                          [_vm._v(_vm._s(row.vip_name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "首次访问时间",
                  prop: "create_time",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "brokerage_price",
                  label: "可用佣金",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "lock_brokerage",
                  label: "冻结佣金",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户类型", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.user_type === "routine"
                                ? "小程序"
                                : row.user_type === "wechat"
                                ? "公众号"
                                : row.user_type === "app"
                                ? "App"
                                : row.user_type === "pc"
                                ? "PC"
                                : "H5"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "首次消费时间",
                  prop: "first_pay_time",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "最近消费时间",
                  prop: "last_pay_time",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(row.label.join("、"))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "130", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.setLabel(scope.row.user_merchant_id)
                              },
                            },
                          },
                          [_vm._v("设置标签")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleDetail
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "用户详情",
                visible: _vm.visibleDetail,
                width: "1000px",
                "before-close": _vm.Close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleDetail = $event
                },
              },
            },
            [
              _vm.visibleDetail
                ? _c("user-details", {
                    ref: "userDetails",
                    attrs: { uid: _vm.uid, row: _vm.row },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleCoupon
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "优惠券列表",
                visible: _vm.visibleCoupon,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleCoupon = $event
                },
              },
            },
            [
              _vm.visibleCoupon
                ? _c("coupon-List", {
                    ref: "couponList",
                    attrs: {
                      "coupon-form": _vm.couponForm,
                      "checked-ids": _vm.checkedIds,
                      "all-check": _vm.allCheck,
                      "user-from": _vm.userFrom,
                    },
                    on: { sendSuccess: _vm.sendSuccess },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }