"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_core-js@2.6.12@core-js/modules/es6.array.iterator.js");
require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_core-js@2.6.12@core-js/modules/es6.promise.js");
require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_core-js@2.6.12@core-js/modules/es6.object.assign.js");
require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_core-js@2.6.12@core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _moment = _interopRequireDefault(require("moment"));
var _cascader = _interopRequireDefault(require("element-ui/lib/cascader"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
require("@/styles/iconfont/iconfont.css");
require("@/styles/iconfont2/iconfont.css");
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _elementUi2 = _interopRequireDefault(require("@form-create/element-ui"));
var _uploadFrom = _interopRequireDefault(require("./components/uploadPicture/uploadFrom"));
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _attrFrom = _interopRequireDefault(require("./components/attrFrom"));
var _templatesFrom = _interopRequireDefault(require("./components/templatesFrom"));
var _couponList = _interopRequireDefault(require("./components/couponList"));
require("./icons");
require("./permission");
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
require("swiper/dist/css/swiper.css");
var _modalForm = _interopRequireDefault(require("@/libs/modal-form"));
var _modalAttr = _interopRequireDefault(require("@/libs/modal-attr"));
var _modalTemplates = _interopRequireDefault(require("@/libs/modal-templates"));
var _videoCloud = _interopRequireDefault(require("@/utils/videoCloud"));
var _modalCoupon = _interopRequireDefault(require("@/libs/modal-coupon"));
var _public = require("@/libs/public");
var filters = _interopRequireWildcard(require("./filters"));
var _notice2 = _interopRequireDefault(require("@/libs/notice"));
var _auth = require("./utils/auth");
var _plugins = _interopRequireDefault(require("./plugins"));
var _directive = _interopRequireDefault(require("./directive"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// a modern alternative to CSS resets

// global css

// 懒加载

// icon
// permission control
// swiper

// global filters modalTemplates
// global filters

// directive

_vue.default.use(_uploadFrom.default);
_vue.default.use(_elementUi2.default);
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.use(_vueLazyload.default, {
  preLoad: 1.3,
  error: require("./assets/images/no.png"),
  loading: require("./assets/images/moren.jpg"),
  attempt: 1,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
});
_vue.default.component('vue-ueditor-wrap', _vueUeditorWrap.default);
_vue.default.component('attrFrom', _attrFrom.default);
_vue.default.component('templatesFrom', _templatesFrom.default);
_vue.default.component('couponList', _couponList.default);
_vue.default.prototype.$modalForm = _modalForm.default;
_vue.default.prototype.$modalSure = _public.modalSure;
_vue.default.prototype.$videoCloud = _videoCloud.default;
_vue.default.prototype.$modalSureDelete = _public.modalSureDelete;
_vue.default.prototype.$modalAttr = _modalAttr.default;
_vue.default.prototype.$modalTemplates = _modalTemplates.default;
_vue.default.prototype.$modalCoupon = _modalCoupon.default;
_vue.default.prototype.moment = _moment.default;
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});
_vue.default.use(_cascader.default);
_vue.default.use(_plugins.default);
_vue.default.use(_directive.default);

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement('script');
  hm.src = 'https://cdn.oss.9gt.net/js/es.js?version=merchantv2.0';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(hm, s);
})();
_router.default.beforeEach(function (to, from, next) {
  /* 路由发生变化修改页面title */
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title + '-' + JSON.parse(_jsCookie.default.get('MerInfo')).login_title;
  }
  next();
});
var token = (0, _auth.getToken)();
var _notice;
if (token) {
  _notice = (0, _notice2.default)(token);
}
_vue.default.config.productionTip = false;
var _default = exports.default = new _vue.default({
  el: '#app',
  data: {
    notice: _notice
  },
  methods: {
    closeNotice: function closeNotice() {
      this.notice && this.notice();
    }
  },
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});