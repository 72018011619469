"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var marketingRouter = {
  path: "".concat(_settings.roterPre, "/marketing"),
  name: 'marketing',
  meta: {
    title: '营销'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'coupon',
    name: 'Coupon',
    meta: {
      title: '优惠券',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/coupon/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'CouponList',
      meta: {
        title: '优惠劵列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/couponList/index'));
        });
      }
    }, {
      path: 'user',
      name: 'CouponUser',
      meta: {
        title: '优惠券领取记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/couponUser/index'));
        });
      }
    }, {
      path: 'creatCoupon/:id?',
      name: 'CreatCoupon',
      meta: {
        title: '添加优惠劵',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/couponList/creatCoupon'));
        });
      }
    }, {
      path: 'send',
      name: 'CouponSend',
      meta: {
        title: '优惠券发送记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/coupon/couponSend/index'));
        });
      }
    }]
  }, {
    path: 'studio',
    name: 'Studio',
    meta: {
      title: '直播间',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/studio/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'StudioList',
      meta: {
        title: '直播间管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/studio/studioList/index'));
        });
      }
    }, {
      path: 'creatStudio',
      name: 'CreatStudio',
      meta: {
        title: '创建直播间',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/studio/studioList/creatStudio'));
        });
      }
    }, {
      path: 'assistant',
      name: 'AssistantStudio',
      meta: {
        title: '直播助手',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/studio/assistant/index'));
        });
      }
    }]
  }, {
    path: 'broadcast',
    name: 'Broadcast',
    meta: {
      title: '直播',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/broadcast/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'BroadcastList',
      meta: {
        title: '直播商品管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/broadcast/broadcastList/index'));
        });
      }
    }, {
      path: 'addProduct',
      name: 'BroadcastProduct',
      meta: {
        title: '创建直播商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/broadcast/broadcastList/addProduct'));
        });
      }
    }]
  }, {
    path: 'seckill',
    name: 'Seckill',
    meta: {
      title: '秒杀管理',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/seckill/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'SpikeGoods',
      meta: {
        title: '秒杀商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillGoods/index'));
        });
      }
    }, {
      path: 'createGoods/:id?/:edit?',
      name: 'CreateSpikeGoods',
      meta: {
        title: '添加秒杀商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillGoods/createGoods'));
        });
      }
    }]
  }, {
    path: 'presell',
    name: 'preSell',
    meta: {
      title: '预售商品管理',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/seckill/index'));
      });
    },
    children: [{
      path: 'list',
      name: "preSaleList",
      meta: {
        title: '预售商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/preSale/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "preSaleCreate",
      meta: {
        title: '添加预售商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/preSale/create'));
        });
      }
    }]
  }, {
    path: 'assist',
    name: 'assist',
    meta: {
      title: '助力活动商品',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/seckill/index'));
      });
    },
    children: [{
      path: 'list',
      name: "assistProductList",
      meta: {
        title: '助力活动商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/assist/assist_list/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "assistProductCreate",
      meta: {
        title: '添加助力活动商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/assist/assist_list/create'));
        });
      }
    }, {
      path: 'assist_set',
      name: "assistSet",
      meta: {
        title: '助力活动列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/assist/assist_activity/index'));
        });
      }
    }]
  }, {
    path: 'combination',
    name: 'combinAtion',
    meta: {
      title: '拼团管理',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/combination/index'));
      });
    },
    children: [{
      path: 'combination_goods',
      name: "combinationGoods",
      meta: {
        title: '拼团商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/combination/combination_goods/index'));
        });
      }
    }, {
      path: 'combination_list',
      name: "combinationLoods",
      meta: {
        title: '拼团列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/combination/store_combination/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "combinationCreate",
      meta: {
        title: '添加拼团商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/combination/combination_goods/create'));
        });
      }
    }]
  }, {
    path: 'integral',
    name: 'integral',
    meta: {
      title: '积分',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/integral/index'));
      });
    },
    children: [{
      path: 'config',
      name: "integralConfig",
      meta: {
        title: '积分配置',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/config/index'));
        });
      }
    }, {
      path: 'log',
      name: "integralLog",
      meta: {
        title: '积分记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/log/index'));
        });
      }
    }]
  }, {
    path: 'discounts',
    name: 'discounts',
    meta: {
      title: '套餐',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/integral/index'));
      });
    },
    children: [{
      path: 'list',
      name: "discountsList",
      meta: {
        title: '套餐列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/discounts/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "discountsCreate",
      meta: {
        title: '添加套餐',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/discounts/create'));
        });
      }
    }]
  }, {
    path: 'zeroPurchase',
    name: 'zeroPurchase',
    meta: {
      title: '0元购',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/zeroPurchase/index'));
      });
    },
    children: [{
      path: 'goodsList',
      name: "zeroPurchaseGoodsList",
      meta: {
        title: '0元购商品列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/zeroPurchase/goodsList'));
        });
      }
    }, {
      path: 'activityList',
      name: "zeroPurchaseActivityList",
      meta: {
        title: '0元购活动列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/zeroPurchase/activityList'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: 'zeroPurchaseGoodsCreate',
      meta: {
        title: '添加0元购商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/zeroPurchase/create'));
        });
      }
    }]
  }, {
    path: 'couponExchange',
    name: 'couponExchange',
    meta: {
      title: '消费券兑换',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/couponExchange/index'));
      });
    },
    children: [{
      path: 'goodsList',
      name: "couponExchangeGoodsList",
      meta: {
        title: '消费券兑换商品列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/couponExchange/goods/index'));
        });
      }
    }, {
      path: 'recordList',
      name: "couponExchangeRecordList",
      meta: {
        title: '消费券兑换记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/couponExchange/record/index'));
        });
      }
    }]
  }]
};
var _default = exports.default = marketingRouter;