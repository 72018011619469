"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hasPermi = _interopRequireDefault(require("./permission/hasPermi"));
var install = function install(Vue) {
  Vue.directive('hasPermi', _hasPermi.default);
};
if (window.Vue) {
  window['hasPermi'] = _hasPermi.default;
  Vue.use(install); // eslint-disable-line
}
var _default = exports.default = install;