var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "订单信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _vm.orderDatalist
                    ? _c(
                        "div",
                        { staticClass: "description" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("用户信息"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "用户昵称：" +
                                  _vm._s(_vm.orderDatalist.user.nickname)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "收货人/订购人：" +
                                  _vm._s(_vm.orderDatalist.real_name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "联系电话：" +
                                  _vm._s(_vm.orderDatalist.user_phone)
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.orderDatalist.is_virtual == 0 &&
                            _vm.orderDatalist.order_type == 0
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            收货地址：" +
                                      _vm._s(_vm.orderDatalist.user_address) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v("收货信息"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "订单编号：" +
                                  _vm._s(_vm.orderDatalist.order_sn)
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.orderDatalist.order_type === 0 &&
                            !_vm.orderDatalist.pay_time
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            订单状态：待付款\n          "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDatalist.order_type === 0 &&
                            _vm.orderDatalist.pay_time
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            订单状态：" +
                                      _vm._s(
                                        _vm._f("orderStatusFilter")(
                                          _vm.orderDatalist.status
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDatalist.order_type === 1 &&
                            _vm.orderDatalist.pay_time
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            订单状态：" +
                                      _vm._s(
                                        _vm._f("cancelOrderStatusFilter")(
                                          _vm.orderDatalist.status
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "商品总数：" +
                                  _vm._s(_vm.orderDatalist.total_num)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "订单总价：" +
                                  _vm._s(_vm.orderDatalist.total_price)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "交付邮费：" +
                                  _vm._s(_vm.orderDatalist.pay_postage)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "优惠券金额：" +
                                  _vm._s(_vm.orderDatalist.coupon_price)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            实际支付：" +
                                  _vm._s(
                                    _vm.orderDatalist.finalOrder
                                      ? parseFloat(
                                          _vm.orderDatalist.finalOrder.pay_price
                                        ) +
                                          parseFloat(
                                            _vm.orderDatalist.pay_price
                                          )
                                      : _vm.orderDatalist.pay_price
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.orderDatalist.integral > 0
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            积分抵扣：使用了" +
                                      _vm._s(_vm.orderDatalist.integral) +
                                      "个积分，抵扣了" +
                                      _vm._s(_vm.orderDatalist.integral_price) +
                                      "元\n          "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDatalist.give_integral > 0
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            赠送积分：" +
                                      _vm._s(_vm.orderDatalist.give_integral) +
                                      "个\n          "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "创建时间：" +
                                  _vm._s(_vm.orderDatalist.create_time)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            支付方式：" +
                                  _vm._s(
                                    _vm._f("payTypeFilter")(
                                      _vm.orderDatalist.pay_type
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            !_vm.orderDatalist.activity_type
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            一级佣金：" +
                                      _vm._s(
                                        parseFloat(
                                          _vm.orderDatalist.extension_one
                                        ) +
                                          parseFloat(
                                            _vm.orderDatalist
                                              .refund_extension_one
                                          )
                                      ) +
                                      "\n            "
                                  ),
                                  _vm.orderDatalist.refund_extension_one > 0
                                    ? _c(
                                        "em",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-style": "normal",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "(-" +
                                              _vm._s(
                                                _vm.orderDatalist
                                                  .refund_extension_one
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.orderDatalist.activity_type
                              ? _c("div", { staticClass: "description-term" }, [
                                  _vm._v(
                                    "\n            二级佣金：" +
                                      _vm._s(
                                        parseFloat(
                                          _vm.orderDatalist.extension_two
                                        ) +
                                          parseFloat(
                                            _vm.orderDatalist
                                              .refund_extension_two
                                          )
                                      ) +
                                      "\n            "
                                  ),
                                  _vm.orderDatalist.refund_extension_two > 0
                                    ? _c(
                                        "em",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-style": "normal",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "(-" +
                                              _vm._s(
                                                _vm.orderDatalist
                                                  .refund_extension_two
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "商家备注：" + _vm._s(_vm.orderDatalist.remark)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.orderDatalist.order_extend
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("用户提交信息"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "acea-row" },
                                  _vm._l(
                                    _vm.orderDatalist.order_extend,
                                    function (item, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "description-term",
                                        },
                                        [
                                          !Array.isArray(item)
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(i) +
                                                    "：" +
                                                    _vm._s(item)
                                                ),
                                              ])
                                            : _c(
                                                "span",
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(i) +
                                                      "：\n                "
                                                  ),
                                                  _vm._l(
                                                    item,
                                                    function (pic, idx) {
                                                      return _c("img", {
                                                        key: idx,
                                                        staticStyle: {
                                                          width: "30px",
                                                          height: "30px",
                                                          position: "relative",
                                                          top: "10px",
                                                        },
                                                        attrs: { src: pic },
                                                      })
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.delivery_type === "1" ||
                          _vm.orderDatalist.delivery_type === "4"
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("物流信息"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "快递公司：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_name
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "\n              快递单号：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_id
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: { click: _vm.openLogistics },
                                        },
                                        [_vm._v("物流查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.delivery_type === "2"
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("配送信息"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "送货人姓名：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_name
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "送货人电话：" +
                                          _vm._s(_vm.orderDatalist.delivery_id)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.mark
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("用户备注"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [_vm._v(_vm._s(_vm.orderDatalist.mark))]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流查询",
            visible: _vm.dialogLogistics,
            width: "350px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogistics = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c("logistics-from", {
                attrs: {
                  "order-datalist": _vm.orderDatalist,
                  result: _vm.result,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }