var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-row",
        { staticClass: "ivu-mt box-wrapper" },
        [
          _c(
            "el-col",
            { staticClass: "right-wrapper", attrs: { span: 24 } },
            [
              _vm.cardShow == 0
                ? _c(
                    "el-card",
                    [
                      _vm.cardShow == 0
                        ? _c(
                            "el-row",
                            [
                              _vm.isDiy
                                ? _c(
                                    "el-col",
                                    {
                                      staticStyle: {
                                        width: "310px",
                                        height: "550px",
                                        "margin-right": "30px",
                                        position: "relative",
                                      },
                                    },
                                    [
                                      _c("iframe", {
                                        ref: "iframe",
                                        staticClass: "iframe-box",
                                        attrs: {
                                          src: _vm.imgUrl,
                                          frameborder: "0",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "mask" }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  class: _vm.isDiy ? "table" : "",
                                  attrs: { span: 24 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "acea-row row-between-wrapper",
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { type: "flex" } },
                                        [
                                          _c(
                                            "el-col",
                                            _vm._b(
                                              {},
                                              "el-col",
                                              _vm.grid,
                                              false
                                            ),
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "button acea-row row-middle",
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: { click: _vm.add },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-plus",
                                                        staticStyle: {
                                                          "margin-right": "4px",
                                                        },
                                                      }),
                                                      _vm._v("添加"),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading,
                                          expression: "loading",
                                        },
                                      ],
                                      ref: "table",
                                      staticClass: "tables",
                                      attrs: {
                                        data: _vm.list,
                                        size: "mini",
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "id",
                                          label: "页面ID",
                                          "min-width": "80",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "name",
                                          label: "模板名称",
                                          "min-width": "100",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "add_time",
                                          label: "添加时间",
                                          "min-width": "100",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "update_time",
                                          label: "更新时间",
                                          "min-width": "100",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          "min-width": "150",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  (scope.row.status ||
                                                    scope.row.is_diy) &&
                                                  scope.row.is_default == 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.edit(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.id != 1 &&
                                                  scope.row.is_diy &&
                                                  scope.row.is_default == 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.del(
                                                                scope.row.id,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.status != 1
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setStatus(
                                                                scope.row,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("设为首页")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !scope.row.is_diy
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.recovery(
                                                                      scope.row,
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "恢复初始设置"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                size: "small",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.del(
                                                                      scope.row,
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  scope.row.status ||
                                                  scope.row.is_diy
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDiyCopy(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("复制")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1878188763
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "block" },
                                    [
                                      _c("el-pagination", {
                                        attrs: {
                                          "page-sizes": [20, 40, 60, 80],
                                          "page-size": _vm.diyFrom.limit,
                                          "current-page": _vm.diyFrom.page,
                                          layout:
                                            "total, sizes, prev, pager, next, jumper",
                                          total: _vm.total,
                                        },
                                        on: {
                                          "size-change": _vm.handleSizeChange,
                                          "current-change": _vm.pageChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.modal, title: "预览" },
          on: {
            "update:visible": function ($event) {
              _vm.modal = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                directives: [{ name: "viewer", rawName: "v-viewer" }],
                staticClass: "acea-row row-around code",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "acea-row row-column-around row-between-wrapper",
                  },
                  [
                    _c("div", { ref: "qrCodeUrl", staticClass: "QRpic" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt10" }, [
                      _vm._v("公众号二维码"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "acea-row row-column-around row-between-wrapper",
                  },
                  [
                    _c("div", { staticClass: "QRpic" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.qrcodeImg,
                            expression: "qrcodeImg",
                          },
                        ],
                      }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt10" }, [
                      _vm._v("小程序二维码"),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }