"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'banner',
  // 组件名称
  cname: '轮播图',
  // 标题名称
  icon: 'iconlunbotu',
  defaultName: 'swiperBg',
  // 外面匹配名称
  configName: 'c_banner',
  // 右侧配置名称
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  props: {
    index: {
      type: null,
      default: 0
    },
    num: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'swiperBg',
        timestamp: this.num,
        setUp: {
          tabVal: '0'
        },
        // 图片列表
        swiperConfig: {
          title: '最多可添加10张图片，建议宽度750px；鼠标拖拽左侧圆点可调整图片 顺序',
          maxList: 10,
          list: [{
            img: '',
            info: [{
              title: '标题',
              value: '今日推荐',
              tips: '选填，不超过4个字',
              max: 4
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100
            }]
          }]
        },
        isShow: {
          title: '是否显示背景色',
          val: true
        },
        // 背景颜色
        bgColor: {
          title: '背景颜色(渐变)',
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 10,
          min: 0
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        },
        // 轮播图点样式
        docConfig: {
          cname: 'swiper',
          title: '指示器样式',
          type: 0,
          list: [{
            val: '圆形',
            icon: 'iconDot'
          }, {
            val: '直线',
            icon: 'iconSquarepoint'
          }, {
            val: '无指示器',
            icon: 'iconjinyong'
          }]
        },
        txtStyle: {
          title: '指示器位置',
          type: 0,
          list: [{
            val: '居左',
            icon: 'icondoc_left'
          }, {
            val: '居中',
            icon: 'icondoc_center'
          }, {
            val: '居右',
            icon: 'icondoc_right'
          }]
        },
        // 图片样式
        imgConfig: {
          cname: 'docStyle',
          title: '轮播图样式',
          type: 0,
          list: [{
            val: '圆角',
            icon: 'iconPic_fillet'
          }, {
            val: '直角',
            icon: 'iconPic_square'
          }]
        }
      },
      pageData: {},
      bgColor: [],
      mTOP: 0,
      edge: 0,
      imgStyle: 0,
      imgSrc: '',
      docStyle: 0,
      dotPosition: 0,
      isShow: true
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data.mbConfig) {
        this.isShow = data.isShow.val;
        this.bgColor = data.bgColor.color;
        this.mTOP = data.mbConfig.val;
        this.edge = data.lrConfig.val;
        this.imgStyle = data.imgConfig.type;
        this.imgSrc = data.swiperConfig.list.length ? data.swiperConfig.list[0].img : '';
        this.docStyle = data.docConfig.type;
        this.dotPosition = data.txtStyle.type;
      }
    }
  }
};