"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserDetails',
  props: {
    uid: {
      type: Number,
      default: null
    },
    row: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      moren: require('@/assets/images/f.png'),
      loading: false,
      columns: [],
      Visible: false,
      list: [{
        val: '0',
        label: '消费记录'
      }, {
        val: '3',
        label: '持有优惠券'
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 6
      },
      psInfo: null,
      type: '0'
    };
  },
  mounted: function mounted() {
    if (this.uid) {
      this.getHeader();
      this.getInfo('0');
    }
  },
  methods: {
    changeType: function changeType(key) {
      this.type = key;
      this.tableFrom.page = 1;
      this.getInfo(key);
    },
    getInfo: function getInfo(key) {
      var _this = this;
      this.loading = true;
      switch (key) {
        case '0':
          (0, _user.userOrderApi)(this.uid, this.tableFrom).then(function (res) {
            _this.tableData.data = res.data.list;
            _this.tableData.total = res.data.count;
            _this.columns = [{
              title: '订单ID',
              key: 'order_id',
              minWidth: 250
            }, {
              title: '收货人',
              key: 'real_name',
              minWidth: 90
            }, {
              title: '商品数量',
              key: 'total_num',
              minWidth: 80
            }, {
              title: '商品总价',
              key: 'total_price',
              minWidth: 90
            }, {
              title: '实付金额',
              key: 'pay_price',
              minWidth: 90
            }, {
              title: '交易完成时间',
              key: 'pay_time',
              minWidth: 160
            }];
            _this.loading = false;
          }).catch(function () {
            _this.loading = false;
          });
          break;
        case '3':
          (0, _user.userCouponApi)(this.uid, this.tableFrom).then(function (res) {
            _this.tableData.data = res.data.list;
            _this.tableData.total = res.data.count;
            _this.columns = [{
              title: '优惠券名称',
              key: 'coupon_title',
              minWidth: 120
            }, {
              title: '面值',
              key: 'coupon_price',
              minWidth: 120
            }, {
              title: '最低消费额',
              key: 'use_min_price',
              minWidth: 120
            }, {
              title: '兑换时间',
              key: 'use_time',
              minWidth: 120
            }];
            _this.loading = false;
          }).catch(function () {
            _this.loading = false;
          });
          break;
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getInfo(this.type);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getInfo(this.type);
    },
    getHeader: function getHeader() {
      this.psInfo = this.row;
    }
  }
};