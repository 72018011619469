"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
var _freight = require("@/api/freight");
var _lazyCascader = _interopRequireDefault(require("../lazyCascader"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultRole = {
  name: '',
  type: 0,
  appoint: 0,
  sort: 0,
  info: '',
  region: [{
    first: 1,
    first_price: 0,
    continue: 1,
    continue_price: 0,
    city_id: [],
    city_ids: []
  }],
  undelivery: 0,
  free: [],
  undelives: {},
  city_id3: []
};
var cacheAddress = {};
var kg = '重量（kg）';
var m = '体积（m³）';
var statusMap = [{
  title: '首件',
  title2: '续件',
  title3: '最低购买件数'
}, {
  title: "\u9996\u4EF6".concat(kg),
  title2: "\u7EED\u4EF6".concat(kg),
  title3: "\u6700\u4F4E\u8D2D\u4E70".concat(kg)
}, {
  title: "\u9996\u4EF6".concat(m),
  title2: "\u7EED\u4EF6".concat(m),
  title3: "\u6700\u4F4E\u8D2D\u4E70".concat(m)
}];
var _default = exports.default = {
  name: 'CreatTemplates',
  components: {
    LazyCascader: _lazyCascader.default
  },
  props: {
    tempId: {
      type: Number,
      default: 0
    },
    componentKey: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'change'
        }],
        info: [{
          required: true,
          message: '请输入运费说明',
          trigger: 'blur'
        }, {
          min: 3,
          max: 500,
          message: '长度在 3 到 500 个字符',
          trigger: 'blur'
        }],
        free: [{
          type: 'array',
          required: true,
          message: '请至少添加一个地区',
          trigger: 'change'
        }],
        appoint: [{
          required: true,
          message: '请选择是否指定包邮',
          trigger: 'change'
        }],
        undelivery: [{
          required: true,
          message: '请选择是否指定区域不配送',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择计费方式',
          trigger: 'change'
        }],
        region: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }]
        // city_id3: [
        //   { type: 'array', required: true, message: '请至少选择一个地区', trigger: 'remove-tag' }
        // ]
      },
      nodeKey: 'city_id',
      props: {
        children: 'children',
        label: 'name',
        value: 'id',
        multiple: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
        checkStrictly: true
      },
      dialogVisible: false,
      ruleForm: Object.assign({}, defaultRole),
      listLoading: false,
      cityList: [],
      columns: {
        title: '首件',
        title2: '续件',
        title3: '最低购买件数'
      }
    };
  },
  watch: {
    componentKey: {
      handler: function handler(val, oldVal) {
        if (val) {
          this.getInfo();
        } else {
          this.ruleForm = {
            name: '',
            type: 0,
            appoint: 0,
            sort: 0,
            region: [{
              first: 1,
              first_price: 0,
              continue: 1,
              continue_price: 0,
              city_id: [],
              city_ids: []
            }],
            undelivery: 0,
            free: [],
            undelives: {},
            city_id3: []
          };
        }
      }
      // immediate: true
    }
  },
  mounted: function mounted() {
    // this.getCityList()
    if (this.tempId > 0) this.getInfo();
  },
  methods: {
    resetForm: function resetForm(formName) {
      this.$msgbox.close();
      this.$refs[formName].resetFields();
    },
    onClose: function onClose(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    confirmEdit: function confirmEdit(row, index) {
      row.splice(index, 1);
    },
    changeRadio: function changeRadio(num) {
      this.columns = Object.assign({}, statusMap[num]);
    },
    // 添加配送区域
    addRegion: function addRegion(region) {
      region.push(Object.assign({}, {
        first: 1,
        first_price: 1,
        continue: 1,
        continue_price: 0,
        city_id: [],
        city_ids: []
      }));
    },
    addFree: function addFree(Free) {
      Free.push(Object.assign({}, {
        city_id: [],
        number: 1,
        price: 0.01,
        city_ids: []
      }));
    },
    lazyLoad: function lazyLoad(node, resolve) {
      var _this = this;
      if (cacheAddress[node]) {
        cacheAddress[node]().then(function (res) {
          resolve((0, _toConsumableArray2.default)(res.data));
        });
      } else {
        var p = (0, _freight.cityListV2)(node);
        cacheAddress[node] = function () {
          return p;
        };
        p.then(function (res) {
          res.data.forEach(function (item) {
            item.leaf = item.snum === 0;
          });
          cacheAddress[node] = function () {
            return new Promise(function (resolve1) {
              setTimeout(function () {
                return resolve1(res);
              }, 300);
            });
          };
          resolve(res.data);
        }).catch(function (res) {
          _this.$message.error(res.message);
        });
      }
    },
    // 详情
    getInfo: function getInfo() {
      var _this2 = this;
      this.loading = true;
      (0, _freight.templateDetailApi)(this.tempId).then(function (res) {
        _this2.dialogVisible = true;
        var info = res.data;
        _this2.ruleForm = {
          name: info.name,
          type: info.type,
          info: info.info,
          appoint: info.appoint,
          sort: info.sort,
          region: info.region,
          undelivery: info.undelivery,
          free: info.free,
          undelives: info.undelives,
          city_id3: info.undelives.city_ids || []
        };
        _this2.ruleForm.region.map(function (item) {
          _this2.$set(item, 'city_id', item.city_ids[0]);
          _this2.$set(item, 'city_ids', item.city_ids);
        });
        _this2.ruleForm.free.map(function (item) {
          _this2.$set(item, 'city_id', item.city_ids[0]);
          _this2.$set(item, 'city_ids', item.city_ids);
        });
        _this2.changeRadio(info.type);
        // this.$refs.changeType.click(info.type)
        // this.$refs.refid.click();
        _this2.loading = false;
      }).catch(function (res) {
        _this2.$message.error(res.message);
        _this2.loading = false;
      });
    },
    // 列表
    // getCityList() {
    //   cityListV2(0).then(res => {
    //     this.cityList = res.data
    //   }).catch(res => {
    //     this.$message.error(res.message)
    //   })
    // },
    change: function change(idBox) {
      idBox.map(function (item) {
        var ids = [];
        if (item.city_ids.length === 0) return;
        item.city_ids.map(function (j) {
          // j.splice(0, 1)
          ids.push(j[j.length - 1]);
        });
        item.city_id = ids;
      });
      return idBox;
    },
    changeOne: function changeOne(idBox) {
      var city_ids = [];
      if (idBox.length === 0) return;
      idBox.map(function (item) {
        // item.splice(0, 1)
        city_ids.push(item[item.length - 1]);
      });
      return city_ids;
    },
    onsubmit: function onsubmit(formName) {
      var _this3 = this;
      // this.ruleForm.region = this.change(this.ruleForm.region)
      // this.ruleForm.free = this.change(this.ruleForm.free)
      // this.ruleForm.undelives.city_id = this.changeOne(this.ruleForm.city_id3)
      var data = {
        name: this.ruleForm.name,
        type: this.ruleForm.type,
        info: this.ruleForm.info,
        appoint: this.ruleForm.appoint,
        sort: this.ruleForm.sort,
        region: this.change(this.ruleForm.region),
        undelivery: this.ruleForm.undelivery,
        free: this.change(this.ruleForm.free),
        undelives: {
          city_id: this.changeOne(this.ruleForm.city_id3)
        }
      };
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.tempId === 0 ? (0, _freight.templateCreateApi)(data).then(function (res) {
            _this3.$message.success(res.message);
            setTimeout(function () {
              _this3.$msgbox.close();
            }, 500);
            setTimeout(function () {
              _this3.$emit('getList');
              _this3.$refs[formName].resetFields();
            }, 600);
          }).catch(function (res) {
            _this3.$message.error(res.message);
          }) : (0, _freight.templateUpdateApi)(_this3.tempId, data).then(function (res) {
            _this3.$message.success(res.message);
            setTimeout(function () {
              _this3.$msgbox.close();
            }, 500);
            setTimeout(function () {
              _this3.$emit('getList');
              _this3.$refs[formName].resetFields();
            }, 600);
          }).catch(function (res) {
            _this3.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    }
  }
};