"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
var _ueditorFrom = _interopRequireDefault(require("@/components/ueditorFrom"));
var _copyRecord = _interopRequireDefault(require("./copyRecord"));
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
var _settings = require("@/settings");
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var defaultObj = {
  store_name: '',
  cate_id: '',
  temp_id: '',
  type: 0,
  guarantee_template_id: '',
  keyword: '',
  unit_name: '',
  store_info: '',
  image: '',
  slider_image: [],
  content: '',
  ficti: 0,
  once_count: 0,
  give_integral: 0,
  is_show: 0,
  price: 0,
  cost: 0,
  ot_price: 0,
  stock: 0,
  soure_link: '',
  attrs: [],
  items: [],
  delivery_way: [],
  mer_labels: [],
  delivery_free: 0,
  spec_type: 0,
  is_copoy: 1,
  attrValue: [{
    image: '',
    price: 0,
    cost: 0,
    ot_price: 0,
    stock: 0,
    bar_code: '',
    weight: 0,
    volume: 0
  }]
};
var objTitle = {
  price: {
    title: '售价'
  },
  cost: {
    title: '成本价'
  },
  ot_price: {
    title: '市场价'
  },
  stock: {
    title: '库存'
  },
  bar_code: {
    title: '商品编号'
  },
  weight: {
    title: '重量（KG）'
  },
  volume: {
    title: '体积(m³)'
  }
};
var _default2 = exports.default = {
  name: 'CopyTaoBao',
  components: {
    ueditorFrom: _ueditorFrom.default,
    copyRecord: _copyRecord.default
  },
  props: {
    deliveryList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    deliveryType: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var url = _settingMer.default.https + '/upload/image/0/file?ueditor=1&token=' + (0, _auth.getToken)();
    return {
      roterPre: _settings.roterPre,
      modals: false,
      loading: false,
      loading1: false,
      BaseURL: _settingMer.default.https || 'http://localhost:8080',
      OneattrValue: [Object.assign({}, defaultObj.attrValue[0])],
      // 单规格
      ManyAttrValue: [Object.assign({}, defaultObj.attrValue[0])],
      // 多规格
      columnsBatch: [{
        title: '图片',
        slot: 'image',
        align: 'center',
        minWidth: 80
      }, {
        title: '售价',
        slot: 'price',
        align: 'center',
        minWidth: 95
      }, {
        title: '成本价',
        slot: 'cost',
        align: 'center',
        minWidth: 95
      }, {
        title: '市场价',
        slot: 'ot_price',
        align: 'center',
        minWidth: 95
      }, {
        title: '库存',
        slot: 'stock',
        align: 'center',
        minWidth: 95
      }, {
        title: '商品编号',
        slot: 'bar_code',
        align: 'center',
        minWidth: 120
      }, {
        title: '重量（KG）',
        slot: 'weight',
        align: 'center',
        minWidth: 95
      }, {
        title: '体积(m³)',
        slot: 'volume',
        align: 'center',
        minWidth: 95
      }],
      manyTabDate: {},
      count: 0,
      modal_loading: false,
      images: '',
      soure_link: '',
      modalPic: false,
      isChoice: '',
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      },
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      columns: [],
      virtual: [{
        tit: '普通商品',
        id: 0,
        tit2: '物流发货'
      }, {
        tit: '虚拟商品',
        id: 1,
        tit2: '虚拟发货'
      }],
      categoryList: [],
      // 平台
      merCateList: [],
      // 商户
      BrandList: [],
      // 品牌
      propsMer: {
        emitPath: false,
        multiple: true
      },
      tableFrom: {
        mer_cate_id: '',
        cate_id: '',
        keyword: '',
        type: '1',
        is_gift_bag: ''
      },
      ruleInline: {
        cate_id: [{
          required: true,
          message: '请选择商品分类',
          trigger: 'change'
        }],
        mer_cate_id: [{
          required: true,
          message: '请选择商户分类',
          trigger: 'change',
          type: 'array',
          min: '1'
        }],
        temp_id: [{
          required: true,
          message: '请选择运费模板',
          trigger: 'change',
          type: 'number'
        }],
        brand_id: [{
          required: true,
          message: '请选择品牌',
          trigger: 'change'
        }],
        store_info: [{
          required: true,
          message: '请输入商品简介',
          trigger: 'blur'
        }],
        delivery_way: [{
          required: true,
          message: '请选择送货方式',
          trigger: 'change'
        }]
      },
      grid: {
        xl: 8,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid2: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      myConfig: {
        autoHeightEnabled: false,
        // 编辑器不自动被内容撑高
        initialFrameHeight: 500,
        // 初始容器高度
        initialFrameWidth: '100%',
        // 初始容器宽度
        UEDITOR_HOME_URL: '/UEditor/',
        serverUrl: url,
        imageUrl: url,
        imageFieldName: 'file',
        imageUrlPrefix: '',
        imageActionName: 'upfile',
        imageMaxSize: 2048000,
        imageAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
      },
      formThead: Object.assign({}, objTitle),
      formValidate: Object.assign({}, defaultObj),
      items: [{
        image: '',
        price: 0,
        cost: 0,
        ot_price: 0,
        stock: 0,
        bar_code: '',
        weight: 0,
        volume: 0
      }],
      shippingList: [],
      guaranteeList: [],
      // 服务保障模板
      isData: false,
      artFrom: {
        type: 'taobao',
        url: ''
      },
      tableIndex: 0,
      labelPosition: 'right',
      labelWidth: '120',
      isMore: '',
      taoBaoStatus: {},
      attrInfo: {},
      labelList: [],
      oneFormBatch: [{
        image: '',
        price: 0,
        cost: 0,
        ot_price: 0,
        stock: 0,
        bar_code: '',
        weight: 0,
        volume: 0
      }]
    };
  },
  computed: {
    attrValue: function attrValue() {
      var obj = Object.assign({}, defaultObj.attrValue[0]);
      delete obj.image;
      return obj;
    } // oneFormBatch() {
    //   const obj = [Object.assign({}, defaultObj.attrValue[0])]
    //   delete obj[0].bar_code
    //   return obj
    // }
  },
  watch: {},
  created: function created() {
    this.goodsCategory();
    this.getCategorySelect();
    this.getBrandListApi();
  },
  mounted: function mounted() {
    this.productGetTemplate();
    this.getGuaranteeList();
    this.getCopyCount();
    this.getLabelLst();
  },
  methods: {
    // productCon() {
    //   productConfigApi()
    //     .then(res => {
    //       this.deliveryType = res.data.delivery_way.map(String)
    //       if (this.deliveryType.length == 2) {
    //         this.deliveryList = [
    //           { value: '1', name: '到店自提' },
    //           { value: '2', name: '快递配送' }
    //         ]
    //       } else {
    //         if (this.deliveryType.length == 1 && this.deliveryType[0] == '1') {
    //           this.deliveryList = [
    //             { value: '1', name: '到店自提' }
    //           ]
    //         } else {
    //           this.deliveryList = [
    //             { value: '2', name: '快递配送' }
    //           ]
    //         }
    //       }
    //     })
    //     .catch(res => {
    //       this.$message.error(res.message)
    //     })
    // },
    // 获取标签项
    getLabelLst: function getLabelLst() {
      var _this2 = this;
      (0, _product.getProductLabelApi)().then(function (res) {
        _this2.labelList = res.data;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 获取剩余复制次数
    getCopyCount: function getCopyCount() {
      var _this3 = this;
      (0, _product.productCopyCountApi)().then(function (res) {
        _this3.count = res.data.count;
      });
    },
    // 查看复制记录
    openRecords: function openRecords() {
      this.$refs.copyRecord.getRecord();
    },
    batchDel: function batchDel() {
      this.oneFormBatch = [{
        image: '',
        price: 0,
        cost: 0,
        ot_price: 0,
        stock: 0,
        bar_code: '',
        weight: 0,
        volume: 0
      }];
    },
    batchAdd: function batchAdd() {
      var _iterator = _createForOfIteratorHelper(this.ManyAttrValue),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var val = _step.value;
          this.$set(val, 'image', this.oneFormBatch[0].image);
          this.$set(val, 'price', this.oneFormBatch[0].price);
          this.$set(val, 'cost', this.oneFormBatch[0].cost);
          this.$set(val, 'ot_price', this.oneFormBatch[0].ot_price);
          this.$set(val, 'stock', this.oneFormBatch[0].stock);
          this.$set(val, 'bar_code', this.oneFormBatch[0].bar_code);
          this.$set(val, 'weight', this.oneFormBatch[0].weight);
          this.$set(val, 'volume', this.oneFormBatch[0].volume);
          this.$set(val, 'extension_one', this.oneFormBatch[0].extension_one);
          this.$set(val, 'extension_two', this.oneFormBatch[0].extension_two);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    // 删除表格中的属性
    delAttrTable: function delAttrTable(index) {
      this.ManyAttrValue.splice(index, 1);
    },
    // 获取运费模板；
    productGetTemplate: function productGetTemplate() {
      var _this4 = this;
      (0, _product.shippingListApi)().then(function (res) {
        _this4.shippingList = res.data;
      });
    },
    // 获取服务保障模板
    getGuaranteeList: function getGuaranteeList() {
      var _this5 = this;
      (0, _product.guaranteeListApi)().then(function (res) {
        _this5.guaranteeList = res.data;
      });
    },
    // 删除图片
    handleRemove: function handleRemove(i) {
      this.formValidate.slider_image.splice(i, 1);
    },
    // 选择主图
    checked: function checked(item, index) {
      this.formValidate.image = item;
    },
    // 商品分类；
    goodsCategory: function goodsCategory() {
      var _this6 = this;
      (0, _product.categoryListApi)().then(function (res) {
        _this6.categoryList = res.data;
      }).catch(function (res) {
        _this6.$message.error(res.message);
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this7 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this7.merCateList = res.data;
      }).catch(function (res) {
        _this7.$message.error(res.message);
      });
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this8 = this;
      (0, _product.categoryBrandListApi)().then(function (res) {
        _this8.BrandList = res.data;
      }).catch(function (res) {
        _this8.$message.error(res.message);
      });
    },
    virtualbtn: function virtualbtn(id, type) {
      this.formValidate.type = id;
      this.productCon();
    },
    watCh: function watCh(val) {
      var _this9 = this;
      var tmp = {};
      var tmpTab = {};
      this.formValidate.attr.forEach(function (o, i) {
        tmp['value' + i] = {
          title: o.value
        };
        tmpTab['value' + i] = '';
      });
      this.ManyAttrValue = this.attrFormat(val);
      console.log(this.ManyAttrValue);
      this.ManyAttrValue.forEach(function (val, index) {
        var key = Object.values(val.detail).sort().join('/');
        if (_this9.attrInfo[key]) _this9.ManyAttrValue[index] = _this9.attrInfo[key];
        val.image = _this9.formValidate.image;
      });
      this.attrInfo = {};
      this.ManyAttrValue.forEach(function (val) {
        if (val.detail !== 'undefined' && val.detail !== null) {
          _this9.attrInfo[Object.values(val.detail).sort().join('/')] = val;
        }
      });
      this.manyTabTit = tmp;
      this.manyTabDate = tmpTab;
      this.formThead = Object.assign({}, this.formThead, tmp);
    },
    attrFormat: function attrFormat(arr) {
      var data = [];
      var res = [];
      return format(arr);
      function format(arr) {
        if (arr.length > 1) {
          arr.forEach(function (v, i) {
            if (i === 0) data = arr[i]['detail'];
            var tmp = [];
            data.forEach(function (vv) {
              arr[i + 1] && arr[i + 1]['detail'] && arr[i + 1]['detail'].forEach(function (g) {
                var rep2 = (i !== 0 ? '' : arr[i]['value'] + '_$_') + vv + '-$-' + arr[i + 1]['value'] + '_$_' + g;
                tmp.push(rep2);
                if (i === arr.length - 2) {
                  var rep4 = {
                    image: '',
                    price: 0,
                    cost: 0,
                    ot_price: 0,
                    stock: 0,
                    bar_code: '',
                    weight: 0,
                    volume: 0,
                    brokerage: 0,
                    brokerage_two: 0
                  };
                  rep2.split('-$-').forEach(function (h, k) {
                    var rep3 = h.split('_$_');
                    if (!rep4['detail']) rep4['detail'] = {};
                    rep4['detail'][rep3[0]] = rep3.length > 1 ? rep3[1] : '';
                  });
                  Object.values(rep4.detail).forEach(function (v, i) {
                    rep4['value' + i] = v;
                  });
                  res.push(rep4);
                }
              });
            });
            data = tmp.length ? tmp : [];
          });
        } else {
          var dataArr = [];
          arr.forEach(function (v, k) {
            v['detail'].forEach(function (vv, kk) {
              dataArr[kk] = v['value'] + '_' + vv;
              res[kk] = {
                image: '',
                price: 0,
                cost: 0,
                ot_price: 0,
                stock: 0,
                bar_code: '',
                weight: 0,
                volume: 0,
                brokerage: 0,
                brokerage_two: 0,
                detail: (0, _defineProperty2.default)({}, v['value'], vv)
              };
              Object.values(res[kk].detail).forEach(function (v, i) {
                res[kk]['value' + i] = v;
              });
            });
          });
          data.push(dataArr.join('$&'));
        }
        console.log(res);
        return res;
      }
    },
    // 生成表单
    add: function add() {
      var _this10 = this;
      if (this.soure_link) {
        var reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
        if (!reg.test(this.soure_link)) {
          return this.$message.warning('请输入以http开头的地址！');
        }
        this.artFrom.url = this.soure_link;
        this.loading = true;
        (0, _product.crawlFromApi)(this.artFrom).then(function (res) {
          var info = res.data.info;
          _this10.columns = info.info && info.info.header || _this10.columnsBatch;
          _this10.taoBaoStatus = info.info ? info.info : '';
          // this.formValidate = info
          _this10.formValidate = {
            content: info.description || '',
            is_show: 0,
            type: 0,
            soure_link: _this10.soure_link,
            attr: info.info && info.info.attr || [],
            delivery_way: info.delivery_way && info.delivery_way.length ? info.delivery_way.map(String) : _this10.deliveryType,
            delivery_free: info.delivery_free ? info.delivery_free : 0,
            attrValue: info.info && info.info.value || [{
              image: '',
              price: 0,
              cost: 0,
              ot_price: 0,
              stock: 0,
              bar_code: '',
              weight: 0,
              volume: 0
            }],
            spec_type: info.spec_type,
            image: info.image,
            slider_image: info.slider_image,
            store_info: info.store_info,
            store_name: info.store_name,
            unit_name: info.unit_name
          };
          // this.formValidate.content = info.description || ''
          // this.formValidate.is_show = 0
          // this.formValidate.soure_link = this.soure_link
          // this.formValidate.attr = info.info && info.info.attr || []
          // this.formValidate.delivery_way = (info.delivery_way && info.delivery_way.length) ? info.delivery_way.map(String) : this.deliveryType
          // this.formValidate.delivery_free = info.delivery_free ? info.delivery_free : 0
          // this.formValidate.attrValue = info.info && info.info.value || [{
          //   image: '',
          //   price: 0,
          //   cost: 0,
          //   ot_price: 0,
          //   stock: 0,
          //   bar_code: '',
          //   weight: 0,
          //   volume: 0
          // }]
          // this.formValidate.spec_type = info.spec_type
          if (_this10.formValidate.spec_type === 0) {
            _this10.OneattrValue = info.info && info.info.value || [{
              image: _this10.formValidate.image,
              price: 0,
              cost: 0,
              ot_price: 0,
              stock: 0,
              bar_code: '',
              weight: 0,
              volume: 0
            }];
          } else {
            _this10.ManyAttrValue = info.info && info.info.value || [{
              image: '',
              price: 0,
              cost: 0,
              ot_price: 0,
              stock: 0,
              bar_code: '',
              weight: 0,
              volume: 0
            }];
            _this10.watCh(_this10.formValidate.attr);
          }
          if (_this10.formValidate.image) {
            _this10.oneFormBatch[0].image = _this10.formValidate.image;
          }
          _this10.isData = true;
          _this10.loading = false;
        }).catch(function (res) {
          _this10.$message.error(res.message);
          _this10.loading = false;
        });
      } else {
        this.$message.warning('请输入链接地址！');
      }
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var _this11 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this11.modal_loading = true;
          _this11.formValidate.cate_id = _this11.formValidate.cate_id instanceof Array ? _this11.formValidate.cate_id.pop() : _this11.formValidate.cate_id;
          _this11.formValidate.once_count = _this11.formValidate.once_count || 0;
          if (_this11.formValidate.spec_type === 1) {
            _this11.formValidate.attrValue = _this11.ManyAttrValue;
          } else {
            _this11.formValidate.attrValue = _this11.OneattrValue;
            _this11.formValidate.attr = [];
          }
          _this11.formValidate.is_copoy = 1;
          // this.formValidate.attrValue = this.items
          _this11.loading1 = true;
          (0, _product.productCopyApi)(_this11.formValidate).then(function (res) {
            _this11.$message.success('商品默认为不上架状态请手动上架商品!');
            _this11.loading1 = false;
            setTimeout(function () {
              _this11.modal_loading = false;
            }, 500);
            setTimeout(function () {
              _this11.modals = false;
            }, 600);
            _this11.$emit('getSuccess');
          }).catch(function (res) {
            _this11.modal_loading = false;
            _this11.$message.error(res.message);
            _this11.loading1 = false;
          });
        } else {
          if (!_this11.formValidate.cate_id) {
            _this11.$message.warning('请填写商品分类！');
          }
        }
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, index) {
      this.tableIndex = index;
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(_this.formValidate.attr[_this.tableIndex]);
        if (tit === '1') {
          if (index === 'pi') {
            _this.oneFormBatch[0].image = img[0];
          } else {
            _this.OneattrValue[0].image = img[0];
          }
        }
        if (tit === '2') {
          _this.ManyAttrValue[_this.tableIndex].image = img[0];
        }
        _this.modalPic = false;
        // _this.formValidate.image = img[0];
        // _this.OneattrValue[0].image = img[0];
      }, tit);
    },
    // 获取单张图片信息
    getPic: function getPic(pc) {
      this.callback(pc);
      this.formValidate.attr[this.tableIndex].pic = pc.att_dir;
      this.modalPic = false;
    },
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    // 首先把div变成可以放置的元素，即重写dragenter/dragover
    handleDragOver: function handleDragOver(e) {
      // e.dataTransfer.dropEffect="move";//在dragenter中针对放置目标来设置!
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragEnter: function handleDragEnter(e, item) {
      // 为需要移动的元素设置dragstart事件
      e.dataTransfer.effectAllowed = 'move';
      if (item === this.dragging) {
        return;
      }
      var newItems = (0, _toConsumableArray2.default)(this.formValidate.slider_image);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat((0, _toConsumableArray2.default)(newItems.splice(src, 1))));
      this.formValidate.slider_image = newItems;
    },
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      var _this12 = this;
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        var dialog = new window.UE.ui.Dialog({
          iframeUrl: '/admin/widget.images/index.html?fodder=dialog',
          editor: editor,
          name: uiName,
          title: '上传图片',
          cssRules: 'width:1200px;height:500px;padding:20px;'
        });
        _this12.dialog = dialog;
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(../../../assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            dialog.render();
            dialog.open();
          }
        });
        return btn;
      });
    }
  }
};