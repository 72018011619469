"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CreatGuarantee',
  data: function data() {
    return {
      isEdit: false,
      dialogVisible: false,
      loading: false,
      guarantee_id: '',
      guarantee_name: '',
      termsService: [],
      termsList: [],
      formValidate: {
        template_name: '',
        template_value: [],
        sort: ''
      },
      ruleInline: {
        template_name: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'blur'
        }],
        template_value: [{
          required: true,
          message: '请选择服务条款',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {},
  mounted: function mounted() {
    this.getServiceTerms();
  },
  methods: {
    /** 获取服务条款 */getServiceTerms: function getServiceTerms() {
      var _this = this;
      (0, _product.guaranteeSelectApi)({
        keyword: this.guarantee_name
      }).then(function (res) {
        if (_this.guarantee_name) {
          _this.getSearchItem(res.data);
        } else {
          _this.termsList = res.data;
          _this.termsList.forEach(function (item, index) {
            item.isShow = true;
          });
        }
      }).catch(function (_ref) {
        var message = _ref.message;
        _this.$message.error(message);
      });
    },
    /** 把搜索结果显示出来 */getSearchItem: function getSearchItem(arr) {
      var _this2 = this;
      this.termsList.forEach(function (item, index) {
        if (arr.length > 0) {
          arr.forEach(function (itemn, indexn) {
            if (itemn.guarantee_id === item.guarantee_id) {
              item.isShow = true;
            } else {
              item.isShow = false;
            }
          });
        } else {
          item.isShow = false;
        }
        _this2.$set(_this2.termsList, index, item);
        console.log(_this2.termsList);
      });
    },
    handleCheckedTermsChange: function handleCheckedTermsChange(value) {
      this.getSelectedItems(value);
    },
    handleCloseItems: function handleCloseItems(tag) {
      var _this3 = this;
      this.termsService.splice(this.termsService.indexOf(tag), 1);
      this.formValidate.template_value = [];
      this.termsService.map(function (item) {
        _this3.formValidate.template_value.push(item.guarantee_id);
      });
    },
    /** 取出选择的服务条款 */getSelectedItems: function getSelectedItems(arr) {
      var _this4 = this;
      this.termsService = [];
      this.termsList.forEach(function (item, index) {
        arr.forEach(function (itemn, indexn) {
          if (itemn === item.guarantee_id) {
            _this4.termsService.push(item);
          }
        });
      });
    },
    // 编辑
    handleEdit: function handleEdit(id) {
      var _this5 = this;
      this.isEdit = true;
      this.dialogVisible = true;
      this.loading = false;
      this.guarantee_id = id;
      this.$refs['formValidate'].clearValidate();
      (0, _product.guaranteeDetailApi)(id).then(function (res) {
        var data = res.data;
        _this5.formValidate = {
          template_name: data.template_name,
          template_value: data.template_value,
          sort: data.sort
        };
        _this5.getSelectedItems(data.template_value);
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this5.$message.error(message);
      });
    },
    add: function add() {
      this.isEdit = false;
      this.dialogVisible = true;
      this.loading = false;
      this.formValidate = {
        template_name: '',
        template_value: [],
        sort: ''
      };
      this.termsService = [];
    },
    /** 添加 */createGuarantee: function createGuarantee(name) {
      var _this6 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          (0, _product.guaranteeAddApi)(_this6.formValidate).then(function (_ref3) {
            var message = _ref3.message;
            _this6.$message.success(message);
            _this6.dialogVisible = false;
            _this6.loading = false;
            _this6.$emit('get-list', '');
          }).catch(function (_ref4) {
            var message = _ref4.message;
            _this6.loading = false;
            _this6.$message.error(message);
          });
        }
      });
    },
    /** 编辑 */updateGuarantee: function updateGuarantee(name) {
      var _this7 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this7.loading = true;
          (0, _product.guaranteeUpdateApi)(_this7.guarantee_id, _this7.formValidate).then(function (_ref5) {
            var message = _ref5.message;
            _this7.$message.success(message);
            _this7.dialogVisible = false;
            _this7.loading = false;
            _this7.$emit('get-list', '');
          }).catch(function (_ref6) {
            var message = _ref6.message;
            _this7.loading = false;
            _this7.$message.error(message);
          });
        }
      });
    }
  }
};