"use strict";

var _interopRequireDefault = require("/home/jenkins/workspace/zero-buy-B-admin-release/node_modules/_@babel_runtime@7.24.5@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_product',
  components: {
    linkaddress: _linkaddress.default,
    draggable: _vuedraggable.default
  },
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    },
    index: {
      type: null,
      default: 0
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      itemObj: {},
      activeIndex: 0
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
    });
  },
  methods: {
    linkUrl: function linkUrl(e) {
      this.configData.list[this.activeIndex].chiild[1].val = e;
    },
    getLink: function getLink(index, key, item) {
      if (!key || item.link) {
        return;
      }
      this.activeIndex = index;
      // this.$refs.linkaddres.modals = true
      this.$refs.linkaddres.init();
    },
    addHotTxt: function addHotTxt() {
      if (this.configData.list.length === 0) {
        var storage = window.localStorage;
        this.itemObj = JSON.parse(storage.getItem('itemObj'));
        if (this.itemObj.link) {
          this.itemObj.link.activeVal = 0;
        }
        this.itemObj.chiild[0].val = '';
        this.itemObj.chiild[1].val = '';
        this.configData.list.push(this.itemObj);
      } else {
        var obj = JSON.parse(JSON.stringify(this.configData.list[this.configData.list.length - 1]));
        if (obj.chiild[0].empty) {
          obj.chiild[0].val = '';
          obj.chiild[1].val = '';
        }
        this.configData.list.push(obj);
      }
    },
    // 删除数组
    bindDelete: function bindDelete(index) {
      if (this.configData.list.length === 1) {
        var itemObj = this.configData.list[0];
        this.itemObj = itemObj;
        var storage = window.localStorage;
        storage.setItem('itemObj', JSON.stringify(itemObj));
      }
      this.configData.list.splice(index, 1);
      this.configData.tabCur = 0;
      this.$emit('getConfig', {
        name: 'delete',
        indexs: 0
      });
    },
    sliderChange: function sliderChange(index) {
      this.configData.tabCur = index;
      this.$emit('getConfig', {
        name: 'product',
        indexs: index
      });
    },
    activeBtn: function activeBtn(index) {
      this.configData.tabCur = index;
      this.$emit('getConfig', {
        name: 'product',
        indexs: index
      });
    }
  }
};