var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "splitGuide" },
          [
            _vm.open_wx_sub_mch
              ? _c("span", { staticClass: "color_red" }, [
                  _vm._v(
                    "温馨提示：平台已开通电商收付通，子商户可在此处提交相应资料，审核完成后，即可实现自动分账"
                  ),
                ])
              : _c("span", { staticClass: "color_red" }, [
                  _vm._v("温馨提示：平台未开通电商收付通，此处不许做任何操作"),
                ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "guideBtn",
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              },
              [_vm._v("申请分账商户指南")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.open_wx_sub_mch
          ? _c(
              "div",
              [
                _vm.status == -1 ||
                _vm.status == 0 ||
                _vm.status == 40 ||
                !_vm.applyment_id
                  ? _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.fullscreenLoading,
                            expression: "fullscreenLoading",
                          },
                        ],
                        ref: "formValidate",
                        staticClass: "formValidate mt20",
                        attrs: {
                          rules: _vm.ruleValidate,
                          model: _vm.formValidate,
                          "label-width": "180px",
                        },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _vm.formValidate.out_request_no
                              ? _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "业务申请编号：" } },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formValidate.out_request_no
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "主体类型：",
                                      prop: "organization_type",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.typeChange },
                                        model: {
                                          value:
                                            _vm.formValidate.organization_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "organization_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.organization_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "radio",
                                            attrs: { label: 2401 },
                                          },
                                          [_vm._v("小微商户")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2500 } },
                                          [_vm._v("个人卖家")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2 } },
                                          [_vm._v("企业")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 4 } },
                                          [_vm._v("个体工商户")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 3 } },
                                          [_vm._v("党政、机关及事业单")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1708 } },
                                          [_vm._v("其他组织")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formValidate.organization_type != 2401 &&
                            _vm.formValidate.organization_type != 2500
                              ? _c(
                                  "el-col",
                                  [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formValidate
                                              .organization_type == 2 ||
                                              _vm.formValidate
                                                .organization_type == 4
                                              ? "营业执照信息"
                                              : "登记证书信息"
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件扫描件：",
                                          prop: "business_license_copy",
                                        },
                                      },
                                      [
                                        _vm.formValidate
                                          .business_license_copy &&
                                        _vm.formValidate.business_license_copy[
                                          "dir"
                                        ]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .business_license_copy[
                                                          "dir"
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .business_license_copy[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.business_license_copy =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件注册号：",
                                          required: "",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            min: 15,
                                            max: 18,
                                            placeholder:
                                              "请输入证件注册号,须为15位数字或 18位数字|大写字母",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate
                                                .business_license_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "business_license_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.business_license_number",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "商户名称：",
                                          prop: "merchant_name",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "请填写营业执照/登记证书的商家名称，2~110个字符，支持括号",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.merchant_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "merchant_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.merchant_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "经营者/法定代表人姓名：",
                                          prop: "legal_person",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "请输入经营者/法定代表人姓名",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.legal_person,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "legal_person",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.legal_person",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "注册地址：" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "请填写登记证书的注册地址",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.company_address,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "company_address",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.company_address",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "营业期限：" } },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            size: "small",
                                            type: "date",
                                            placement: "bottom-end",
                                            placeholder: "开始时间",
                                            "picker-options":
                                              _vm.pickerOptions3,
                                          },
                                          on: {
                                            change: _vm.onchangeBusinessStart,
                                          },
                                          model: {
                                            value: _vm.business_start,
                                            callback: function ($$v) {
                                              _vm.business_start = $$v
                                            },
                                            expression: "business_start",
                                          },
                                        }),
                                        _vm._v(" "),
                                        !_vm.business_long
                                          ? _c("el-date-picker", {
                                              staticStyle: { width: "250px" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                format: "yyyy-MM-dd",
                                                size: "small",
                                                type: "date",
                                                placement: "bottom-end",
                                                placeholder: "结束时间",
                                                "picker-options":
                                                  _vm.pickerOptions4,
                                              },
                                              on: {
                                                change: _vm.onchangeBusinessEnd,
                                              },
                                              model: {
                                                value: _vm.business_end,
                                                callback: function ($$v) {
                                                  _vm.business_end = $$v
                                                },
                                                expression: "business_end",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-checkbox",
                                          {
                                            on: { change: _vm.changeTime3 },
                                            model: {
                                              value: _vm.business_long,
                                              callback: function ($$v) {
                                                _vm.business_long = $$v
                                              },
                                              expression: "business_long",
                                            },
                                          },
                                          [_vm._v("长期")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("证件类型"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "经营者/法人证件类型：",
                                      prop: "id_doc_type",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "selWidth",
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.formValidate.id_doc_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "id_doc_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.id_doc_type",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "中国大陆居民-身份证",
                                            value: "1",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.formValidate.organization_type !=
                                          2401 &&
                                        _vm.formValidate.organization_type !=
                                          2500
                                          ? _c("el-option", {
                                              attrs: {
                                                label:
                                                  "其他国家或地区居民-护照",
                                                value: "2",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formValidate.organization_type !=
                                          2401 &&
                                        _vm.formValidate.organization_type !=
                                          2500
                                          ? _c("el-option", {
                                              attrs: {
                                                label:
                                                  "中国香港居民–来往内地通行证",
                                                value: "3",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formValidate.organization_type !=
                                          2401 &&
                                        _vm.formValidate.organization_type !=
                                          2500
                                          ? _c("el-option", {
                                              attrs: {
                                                label:
                                                  "中国澳门居民–来往内地通行证",
                                                value: "4",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formValidate.organization_type !=
                                          2401 &&
                                        _vm.formValidate.organization_type !=
                                          2500
                                          ? _c("el-option", {
                                              attrs: {
                                                label:
                                                  "中国台湾居民–来往大陆通行证",
                                                value: "5",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formValidate.id_doc_type == 1
                              ? _c(
                                  "el-col",
                                  [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("经营者/法人身份证信息"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "身份证人像面照片：",
                                          prop: "id_card_copy",
                                        },
                                      },
                                      [
                                        _vm.formValidate.id_card_copy &&
                                        _vm.formValidate.id_card_copy["dir"]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .id_card_copy["dir"],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .id_card_copy[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.id_card_copy =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess3,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "身份证国徽面照片：",
                                          prop: "id_card_national",
                                        },
                                      },
                                      [
                                        _vm.formValidate.id_card_national &&
                                        _vm.formValidate.id_card_national["dir"]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .id_card_national[
                                                          "dir"
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .id_card_national[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.id_card_national =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess4,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "身份证姓名：",
                                          prop: "id_card_name",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "请输入经营者/法定代表人对应身份证的姓名",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.id_card_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "id_card_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.id_card_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "身份证号码：",
                                          prop: "id_card_number",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            min: 15,
                                            max: 18,
                                            placeholder:
                                              "请输入经营者/法定代表人对应身份证的号码",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.id_card_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "id_card_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.id_card_number",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "身份证开始时间:",
                                          required: "",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            size: "small",
                                            type: "date",
                                            placement: "bottom-end",
                                            placeholder: "开始时间",
                                          },
                                          on: { change: _vm.onchangeIdBegin },
                                          model: {
                                            value: _vm.idCard_start_time,
                                            callback: function ($$v) {
                                              _vm.idCard_start_time = $$v
                                            },
                                            expression: "idCard_start_time",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "身份证有效期限:",
                                          required: "",
                                        },
                                      },
                                      [
                                        !_vm.effective_long
                                          ? _c("el-date-picker", {
                                              staticStyle: { width: "250px" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                format: "yyyy-MM-dd",
                                                size: "small",
                                                type: "date",
                                                placement: "bottom-end",
                                                placeholder: "到期时间",
                                                "picker-options":
                                                  _vm.pickerOptions0,
                                              },
                                              on: {
                                                change: _vm.onchangeEffective,
                                              },
                                              model: {
                                                value: _vm.effective_time,
                                                callback: function ($$v) {
                                                  _vm.effective_time = $$v
                                                },
                                                expression: "effective_time",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-checkbox",
                                          {
                                            on: { change: _vm.changeTime1 },
                                            model: {
                                              value: _vm.effective_long,
                                              callback: function ($$v) {
                                                _vm.effective_long = $$v
                                              },
                                              expression: "effective_long",
                                            },
                                          },
                                          [_vm._v("长期")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.formValidate.organization_type == 2
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "身份证居住地址:",
                                              prop: "id_card_address",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入身份证居住地址",
                                              },
                                              model: {
                                                value:
                                                  _vm.formValidate
                                                    .id_card_address,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formValidate,
                                                    "id_card_address",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formValidate.id_card_address",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.formValidate.id_doc_type != 1
                              ? _c(
                                  "el-col",
                                  [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("经营者/法人其他类型证件信息"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件姓名：",
                                          prop: "id_doc_name",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "请输入经营者/法人姓名",
                                          },
                                          model: {
                                            value: _vm.formValidate.id_doc_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "id_doc_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.id_doc_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件号码：",
                                          prop: "id_doc_number",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入证件号码",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.id_doc_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "id_doc_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.id_doc_number",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件正面照片：",
                                          prop: "id_doc_copy",
                                        },
                                      },
                                      [
                                        _vm.formValidate.id_doc_copy &&
                                        _vm.formValidate.id_doc_copy["dir"]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .id_doc_copy["dir"],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .id_doc_copy[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.id_doc_copy =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess5,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.formValidate.id_doc_type != 2
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "证件反面照片：" },
                                          },
                                          [
                                            _vm.formValidate.id_doc_copy_back &&
                                            _vm.formValidate.id_doc_copy_back[
                                              "dir"
                                            ]
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "pictrue",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm
                                                              .formValidate
                                                              .id_doc_copy_back[
                                                              "dir"
                                                            ],
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getPicture(
                                                                _vm.formValidate
                                                                  .id_doc_copy_back[
                                                                  "dir"
                                                                ]
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-error btndel",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.formValidate.id_doc_copy_back =
                                                                []
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "el-upload",
                                                  {
                                                    staticClass:
                                                      "upload-demo mr10 mb15",
                                                    attrs: {
                                                      action: _vm.fileUrl,
                                                      "on-success":
                                                        _vm.handleSuccess9,
                                                      headers: _vm.myHeaders,
                                                      "show-file-list": false,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upLoadPicBox",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoad",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-camera cameraIconfont",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件有效期开始时间:",
                                          required: "",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            size: "small",
                                            type: "date",
                                            placement: "bottom-end",
                                            placeholder: "到期时间",
                                          },
                                          on: { change: _vm.onchangeDoc },
                                          model: {
                                            value: _vm.cer_start_time,
                                            callback: function ($$v) {
                                              _vm.cer_start_time = $$v
                                            },
                                            expression: "cer_start_time",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "证件结束日期:" } },
                                      [
                                        !_vm.cer_long
                                          ? _c("el-date-picker", {
                                              staticStyle: { width: "250px" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                format: "yyyy-MM-dd",
                                                size: "small",
                                                type: "date",
                                                placement: "bottom-end",
                                                placeholder: "到期时间",
                                                "picker-options":
                                                  _vm.pickerOptions0,
                                              },
                                              on: { change: _vm.onchangeCer },
                                              model: {
                                                value: _vm.cer_end_time,
                                                callback: function ($$v) {
                                                  _vm.cer_end_time = $$v
                                                },
                                                expression: "cer_end_time",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-checkbox",
                                          {
                                            on: { change: _vm.changeTime2 },
                                            model: {
                                              value: _vm.cer_long,
                                              callback: function ($$v) {
                                                _vm.cer_long = $$v
                                              },
                                              expression: "cer_long",
                                            },
                                          },
                                          [_vm._v("长期")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "证件居住地址:" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入证件居住地址",
                                          },
                                          model: {
                                            value:
                                              _vm.formValidate.id_doc_address,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formValidate,
                                                "id_doc_address",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidate.id_doc_address",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("结算银行账户"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "账户类型：",
                                      required: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.formValidate.bank_account_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "bank_account_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.bank_account_type",
                                        },
                                      },
                                      [
                                        _vm.formValidate.organization_type ==
                                          2 ||
                                        _vm.formValidate.organization_type ==
                                          3 ||
                                        _vm.formValidate.organization_type ==
                                          1708 ||
                                        _vm.formValidate.organization_type == 4
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticClass: "radio",
                                                attrs: { label: 74 },
                                              },
                                              [_vm._v("对公账户")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formValidate.organization_type ==
                                          2401 ||
                                        _vm.formValidate.organization_type ==
                                          2500 ||
                                        _vm.formValidate.organization_type == 4
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticClass: "radio",
                                                attrs: { label: 75 },
                                              },
                                              [_vm._v("对私账户")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开户银行：",
                                      required: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item selWidth",
                                        attrs: {
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.formValidate.account_bank,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "account_bank",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.account_bank",
                                        },
                                      },
                                      _vm._l(_vm.brankList, function (item) {
                                        return _c("el-option", {
                                          key: item.label,
                                          attrs: {
                                            label: item.value,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开户名称：",
                                      required: "",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入开户名称" },
                                      model: {
                                        value: _vm.formValidate.account_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "account_name",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.account_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "selWidth",
                                    attrs: {
                                      label: "开户银行省市编码：",
                                      prop: "bank_address_code",
                                    },
                                  },
                                  [
                                    _c("el-cascader", {
                                      attrs: {
                                        options: _vm.provinceCodeList,
                                        clearable: "",
                                        "show-all-levels": false,
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate.bank_address_code,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "bank_address_code",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.bank_address_code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "开户银行联行号：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        max: 64,
                                        placeholder: "请输入开户银行联行号",
                                      },
                                      model: {
                                        value: _vm.formValidate.bank_branch_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "bank_branch_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.bank_branch_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开户银行全称 （含支行）：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        max: 128,
                                        placeholder: "请输入开户银行全称",
                                      },
                                      model: {
                                        value: _vm.formValidate.bank_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "bank_name",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.bank_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "银行帐号：",
                                      prop: "account_number",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: 40,
                                        placeholder: "请输入银行帐号",
                                      },
                                      model: {
                                        value: _vm.formValidate.account_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "account_number",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.account_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("超级管理员信息"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "超级管理员类型：",
                                      prop: "contact_type",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.formValidate.contact_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formValidate,
                                              "contact_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formValidate.contact_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "radio",
                                            attrs: { label: 65 },
                                          },
                                          [_vm._v("经营者/法人")]
                                        ),
                                        _vm._v(" "),
                                        _vm.formValidate.organization_type !=
                                          2401 &&
                                        _vm.formValidate.organization_type !=
                                          2500
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticClass: "radio",
                                                attrs: { label: 66 },
                                              },
                                              [_vm._v("经办人")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.formValidate.contact_type == 66
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "超级管理员证件类型：",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "selWidth",
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择",
                                            },
                                            model: {
                                              value:
                                                _vm.formValidate
                                                  .contact_id_doc_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formValidate,
                                                  "contact_id_doc_type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formValidate.contact_id_doc_type",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "中国大陆居民-身份证",
                                                value: "1",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label:
                                                  "其他国家或地区居民-护照",
                                                value: "2",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label:
                                                  "中国香港居民–来往内地通行证",
                                                value: "3",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label:
                                                  "中国澳门居民–来往内地通行证",
                                                value: "4",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label:
                                                  "中国台湾居民–来往大陆通行证",
                                                value: "5",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "外国人居留证",
                                                value: "6",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "港澳居民证",
                                                value: "7",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "台湾居留证",
                                                value: "8",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.contact_type == 66
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "证件正面照片：" } },
                                      [
                                        _vm.formValidate.contact_id_doc_copy &&
                                        _vm.formValidate.contact_id_doc_copy[
                                          "dir"
                                        ]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .contact_id_doc_copy[
                                                          "dir"
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .contact_id_doc_copy[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.contact_id_doc_copy =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess10,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.contact_type == 66 &&
                                _vm.formValidate.contact_id_doc_type != "2"
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "证件反面照片：" } },
                                      [
                                        _vm.formValidate
                                          .contact_id_doc_copy_back &&
                                        _vm.formValidate
                                          .contact_id_doc_copy_back["dir"]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .contact_id_doc_copy_back[
                                                          "dir"
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .contact_id_doc_copy_back[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.contact_id_doc_copy_back =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess11,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.contact_type == 66
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件有效期开始时间:",
                                          required: "",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            size: "small",
                                            type: "date",
                                            placement: "bottom-end",
                                            placeholder: "开始时间",
                                          },
                                          on: {
                                            change: _vm.onchangeContactStart,
                                          },
                                          model: {
                                            value: _vm.contact_start_time,
                                            callback: function ($$v) {
                                              _vm.contact_start_time = $$v
                                            },
                                            expression: "contact_start_time",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.contact_type == 66
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件结束日期:",
                                          required: "",
                                        },
                                      },
                                      [
                                        !_vm.contact_long
                                          ? _c("el-date-picker", {
                                              staticStyle: { width: "250px" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                format: "yyyy-MM-dd",
                                                size: "small",
                                                type: "date",
                                                placement: "bottom-end",
                                                placeholder: "到期时间",
                                                "picker-options":
                                                  _vm.pickerOptions0,
                                              },
                                              on: {
                                                change: _vm.onchangeContactEnd,
                                              },
                                              model: {
                                                value: _vm.contact_end_time,
                                                callback: function ($$v) {
                                                  _vm.contact_end_time = $$v
                                                },
                                                expression: "contact_end_time",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-checkbox",
                                          {
                                            on: { change: _vm.changeTime4 },
                                            model: {
                                              value: _vm.contact_long,
                                              callback: function ($$v) {
                                                _vm.contact_long = $$v
                                              },
                                              expression: "contact_long",
                                            },
                                          },
                                          [_vm._v("长期")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.contact_type == 66
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "业务办理授权函：",
                                          prop: "business_authorization_letter",
                                        },
                                      },
                                      [
                                        _vm.formValidate
                                          .business_authorization_letter &&
                                        _vm.formValidate
                                          .business_authorization_letter["dir"]
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pictrue" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm.formValidate
                                                          .business_authorization_letter[
                                                          "dir"
                                                        ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPicture(
                                                            _vm.formValidate
                                                              .business_authorization_letter[
                                                              "dir"
                                                            ]
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-error btndel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.formValidate.business_authorization_letter =
                                                            []
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess12,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "超级管理员姓名：",
                                      prop: "contact_name",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入超级管理员姓名",
                                      },
                                      model: {
                                        value: _vm.formValidate.contact_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "contact_name",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.contact_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "超级管理员身份证件号码：",
                                      prop: "contact_id_card_number",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder:
                                          "请输入超级管理员身份证件号码",
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate
                                            .contact_id_card_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "contact_id_card_number",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.contact_id_card_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "超级管理员手机：",
                                      prop: "mobile_phone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入超级管理员手机号",
                                      },
                                      model: {
                                        value: _vm.formValidate.mobile_phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "mobile_phone",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.mobile_phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "超级管理员邮箱：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入超级管理员邮箱",
                                      },
                                      model: {
                                        value: _vm.formValidate.contact_email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "contact_email",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.contact_email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("店铺信息"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "店铺名称：",
                                      prop: "store_name",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入店铺名称" },
                                      model: {
                                        value: _vm.formValidate.store_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "store_name",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.store_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "店铺链接：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入店铺链接" },
                                      model: {
                                        value: _vm.formValidate.store_url,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "store_url",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.store_url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "店铺二维码：" } },
                                  [
                                    _vm.formValidate.store_qr_code &&
                                    _vm.formValidate.store_qr_code["dir"]
                                      ? _c(
                                          "div",
                                          { staticClass: "upLoadPicBox" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm.formValidate
                                                      .store_qr_code["dir"],
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getPicture(
                                                        _vm.formValidate
                                                          .store_qr_code["dir"]
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-error btndel",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.formValidate.store_qr_code =
                                                        []
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "el-upload",
                                          {
                                            staticClass:
                                              "upload-demo mr10 mb15",
                                            attrs: {
                                              action: _vm.fileUrl,
                                              data: { water: 1 },
                                              "on-success": _vm.handleSuccess6,
                                              headers: _vm.myHeaders,
                                              "show-file-list": false,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "upLoad" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-camera cameraIconfont",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "小程序AppID：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入小程序AppID",
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate
                                            .mini_program_sub_appid,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "mini_program_sub_appid",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.mini_program_sub_appid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商户简称：",
                                      prop: "merchant_shortname",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        placeholder: "请输入商户简称",
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate.merchant_shortname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "merchant_shortname",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.merchant_shortname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "特殊资质：" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "acea-row" },
                                      [
                                        _vm.formValidate.qualifications &&
                                        _vm.formValidate.qualifications.length
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              _vm._l(
                                                _vm.formValidate.qualifications,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass: "pictrue",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: item.dir,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getPicture(
                                                              item.dir
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-error btndel",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRemove(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formValidate.qualifications.length <
                                        5
                                          ? _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess7,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "补充材料：" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "acea-row" },
                                      [
                                        _vm.formValidate.business_addition_pics
                                          .length
                                          ? _c(
                                              "div",
                                              { staticClass: "upLoadPicBox" },
                                              _vm._l(
                                                _vm.formValidate
                                                  .business_addition_pics,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass: "pictrue",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: item.dir,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getPicture(
                                                              item.dir
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-error btndel",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRemove2(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formValidate.business_addition_pics
                                          .length < 5
                                          ? _c(
                                              "el-upload",
                                              {
                                                staticClass:
                                                  "upload-demo mr10 mb15",
                                                attrs: {
                                                  action: _vm.fileUrl,
                                                  "on-success":
                                                    _vm.handleSuccess8,
                                                  headers: _vm.myHeaders,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "upLoad" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-camera cameraIconfont",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "补充说明：",
                                      required:
                                        _vm.formValidate.organization_type ==
                                        "2500",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        max: 512,
                                        placeholder:
                                          "个人卖家主体请填写：该商户已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元。",
                                      },
                                      model: {
                                        value:
                                          _vm.formValidate
                                            .business_addition_desc,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "business_addition_desc",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.business_addition_desc",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.message
                              ? _c(
                                  "el-col",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "审核结果：" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.message)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticStyle: { "margin-top": "30px" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "submission",
                                attrs: {
                                  loading: _vm.loading,
                                  type: "primary",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSubmit("formValidate")
                                  },
                                },
                              },
                              [_vm._v("提交")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "box-container" }, [
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("业务申请编号："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.formValidate.out_request_no) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("主体类型："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm._f("organizationType")(
                                    _vm.formValidate.organization_type
                                  )
                                ) + "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.formValidate.organization_type != 2401 &&
                          _vm.formValidate.organization_type != 2500
                            ? _c("div", { staticClass: "acea-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "margin-top": "20px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.formValidate.organization_type ==
                                            2 ||
                                            _vm.formValidate
                                              .organization_type == 4
                                            ? "请上传营业执照"
                                            : "请上传登记证书"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "list sp100 image" }, [
                                  _c("label", { staticClass: "name" }, [
                                    _vm._v("证件扫描件："),
                                  ]),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticStyle: {
                                      "max-width": "150px",
                                      height: "80px",
                                    },
                                    attrs: {
                                      src:
                                        _vm.formValidate
                                          .business_license_copy &&
                                        _vm.formValidate.business_license_copy[
                                          "dir"
                                        ],
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "list sp100" }, [
                                  _c("label", { staticClass: "name" }, [
                                    _vm._v("证件注册号："),
                                  ]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.formValidate.business_license_number
                                    ) + "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "list sp100" }, [
                                  _c("label", { staticClass: "name" }, [
                                    _vm._v("商户名称："),
                                  ]),
                                  _vm._v(
                                    _vm._s(_vm.formValidate.merchant_name) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "list sp" }, [
                                  _c("label", { staticClass: "name" }, [
                                    _vm._v("经营者/法定代表人姓名："),
                                  ]),
                                  _vm._v(
                                    _vm._s(_vm.formValidate.legal_person) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.formValidate.company_address
                                  ? _c("div", { staticClass: "list sp" }, [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("注册地址："),
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          _vm.formValidate.company_address
                                        ) + "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formValidate.business_time
                                  ? _c("div", { staticClass: "list sp" }, [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("营业期限："),
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          _vm.formValidate.business_start +
                                            "-" +
                                            _vm.formValidate.business_end
                                        ) + "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formValidate.id_doc_type == 1
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "margin-top": "20px" },
                                  },
                                  [_vm._v("经营者/法人身份证信息：")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "list sp100 image" },
                                    [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("身份证人像面照片："),
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticStyle: {
                                          "max-width": "150px",
                                          height: "80px",
                                        },
                                        attrs: {
                                          src:
                                            _vm.formValidate.id_card_copy &&
                                            _vm.formValidate.id_card_copy[
                                              "dir"
                                            ],
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "list sp100 image" },
                                    [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("身份证国徽面照片："),
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticStyle: {
                                          "max-width": "150px",
                                          height: "80px",
                                        },
                                        attrs: {
                                          src:
                                            _vm.formValidate.id_card_national &&
                                            _vm.formValidate.id_card_national[
                                              "dir"
                                            ],
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("身份证姓名："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.id_card_name) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("身份证号码："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.id_card_number) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("身份证有效开始时间："),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formValidate
                                          .id_card_valid_time_begin
                                      ) + "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("身份证有效期限："),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formValidate.id_card_valid_time
                                      ) + "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.formValidate.id_card_address
                                    ? _c("div", { staticClass: "list sp" }, [
                                        _c("label", { staticClass: "name" }, [
                                          _vm._v("身份证居住地址："),
                                        ]),
                                        _vm._v(
                                          _vm._s(
                                            _vm.formValidate.id_card_address
                                          ) + "\n              "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ])
                            : _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "margin-top": "20px" },
                                  },
                                  [_vm._v("经营者/法人其他类型证件信息：")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("证件姓名："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.id_doc_name) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("证件号码："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.id_doc_number) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "list sp100 image" },
                                    [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("证件照片："),
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticStyle: {
                                          "max-width": "150px",
                                          height: "80px",
                                        },
                                        attrs: {
                                          src:
                                            _vm.formValidate.id_doc_copy &&
                                            _vm.formValidate.id_doc_copy["dir"],
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("证件有效开始日期："),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formValidate.doc_period_begin
                                      ) + "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("证件结束日期："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.doc_period_end) +
                                        "\n              "
                                    ),
                                  ]),
                                ]),
                              ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [_vm._v("结算银行账户：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("账户类型："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.formValidate.bank_account_type == 74
                                      ? "对公账户"
                                      : "对私账户"
                                  ) + "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("开户银行："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.account_bank) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("开户名称："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.account_name) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "list sp" },
                                [
                                  _c("label", { staticClass: "name" }, [
                                    _vm._v("开户银行省市编码："),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-cascader", {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "300px",
                                    },
                                    attrs: {
                                      options: _vm.provinceCodeList,
                                      "show-all-levels": false,
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.formValidate.bank_address_code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "bank_address_code",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.bank_address_code",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.formValidate.bank_branch_id
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("开户银行联行号："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.bank_branch_id) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.bank_name
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("开户银行全称 （含支行）："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.bank_name) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("银行帐号："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.account_number) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [_vm._v("超级管理员信息：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("超级管理员类型："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.formValidate.contact_type == 65
                                      ? "经营者/法人"
                                      : "负责人"
                                  ) + "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("超级管理员姓名："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.contact_name) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("超级管理员身份证件号码："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.formValidate.contact_id_card_number
                                  ) + "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("超级管理员手机："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.mobile_phone) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.formValidate.contact_email
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("超级管理员邮箱："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.contact_email) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [_vm._v("店铺信息：")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "acea-row" }, [
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("店铺名称："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.store_name) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.formValidate.store_url
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("店铺链接："),
                                    ]),
                                    _vm._v(
                                      _vm._s(_vm.formValidate.store_url) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.store_qr_code
                                ? _c(
                                    "div",
                                    { staticClass: "list sp100 image" },
                                    [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("店铺二维码："),
                                      ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticStyle: {
                                          "max-width": "150px",
                                          height: "80px",
                                        },
                                        attrs: {
                                          src:
                                            _vm.formValidate.store_qr_code &&
                                            _vm.formValidate.store_qr_code[
                                              "dir"
                                            ],
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.mini_program_sub_appid
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("小程序AppID："),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formValidate.mini_program_sub_appid
                                      ) + "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "list sp" }, [
                                _c("label", { staticClass: "name" }, [
                                  _vm._v("商户简称：："),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.formValidate.merchant_shortname) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.formValidate.qualifications &&
                              _vm.formValidate.qualifications.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "list sp100 image" },
                                    [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("特殊资质："),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formValidate.qualifications,
                                        function (item, index) {
                                          return _c("img", {
                                            key: index,
                                            staticStyle: {
                                              "max-width": "150px",
                                              height: "80px",
                                            },
                                            attrs: { src: item["dir"] },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.business_addition_pics &&
                              _vm.formValidate.business_addition_pics.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "list sp100 image" },
                                    [
                                      _c("label", { staticClass: "name" }, [
                                        _vm._v("补充材料："),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.formValidate.business_addition_pics,
                                        function (item, index) {
                                          return _c("img", {
                                            key: index,
                                            staticStyle: {
                                              "max-width": "150px",
                                              height: "80px",
                                            },
                                            attrs: { src: item["dir"] },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.business_addition_desc
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v("补充说明："),
                                    ]),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formValidate.business_addition_desc
                                      ) + "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.message
                                ? _c("div", { staticClass: "list sp" }, [
                                    _c("label", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.status == -1 || _vm.status == 40
                                            ? "驳回原因"
                                            : _vm.status == 11
                                            ? "需验证操作"
                                            : "审核结果"
                                        ) + "："
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      class:
                                        _vm.status == -1 || _vm.status == 40
                                          ? "color_red"
                                          : "",
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.formValidate.message) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.pictureVisible
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.pictureVisible, width: "700px" },
              on: {
                "update:visible": function ($event) {
                  _vm.pictureVisible = $event
                },
              },
            },
            [
              _c("img", {
                staticClass: "pictures",
                attrs: { src: _vm.pictureUrl },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisible, width: "1000px" },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "guideContainer" }, [
                _c("h1", { staticClass: "title1" }, [
                  _vm._v("申请分账商户指南"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c("p", [
                    _vm._v(
                      "\n          申请分账商户是指，平台已开通电商收付通后，子商户在此处提交相关资料进行二级商户入驻申请，微信支付验证及签约。\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          子商户申请分账商户成功后，平台与子商户关于微信入口的资金无需线下转账，用户确认收货后15天微信将自动分账。\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h2", [_vm._v("1.主体选择")]),
                  _vm._v(" "),
                  _c("table", { staticClass: "guideTable" }, [
                    _c("thead", [
                      _c("th", [_vm._v("类型")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("定义")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("所需资料")]),
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v("小微")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v("无营业执照、免办理工商注册登记的商户"),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v("需提供小微经营者的个人身份证")]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("个人卖家")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                无营业执照，已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元的个人商家（电商平台需核实已满足上述条件）\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v("需提供个人卖家的个人身份证")]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("个体工商户")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "营业执照上的主体类型一般为个体户、个体工商户、个体经营"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v("需提供营业执照、经营者证件")]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("企业")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "营业执照上的主体类型一般为有限公司、有限责任公司"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "需提供营业执照、法人证件、组织机构代码证（未三证合一提供）"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("党政、机关及事业单位")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                包括国内各级、各类政府机构、事业单位等。如：公安、党团、司法、交通、旅游、工商税务、市政、医疗、教育、学校等机构\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "需提供登记证书、法人证件、组织机构代码证（未三证合一提供）"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("其他组织")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                不属于企业、政府/事业单位的组织机构，如社会团体、民办非企业、基金会。要求机构已办理组织机构代码证。\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "需提供登记证书、法人证件、组织机构代码证（未三证合一提供）"
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "desc" }, [
                    _c("h3", [_vm._v("说明：")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "（1） 正常日收款额度为200万，若交易良好则自动提升额度，若交易异常则下降额度；"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("（2） 无信用卡收款额度限制。")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("（3）其他主体类型，没有收款额度限制；")]),
                  ]),
                  _vm._v(" "),
                  _c("h2", [_vm._v("2.入驻流程")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("下图中“二级商户”指：正在申请分账商户的子商户"),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { "max-width": "100%" },
                    attrs: {
                      src: require("../../../assets/images/split_guide.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("h2", [_vm._v("3.环节说明")]),
                  _vm._v(" "),
                  _c("p", [_vm._v("审核、签约、账户验证，为三个并行流程。")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "（1）审核：指平台和微信支付审核申请单资料，2-5个工作日完成"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "（2）账户验证：指判断指定条件的商家，需通过指定方式核实开户意愿及账户有效性；"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "（3）签约：指商家超级管理员，使用微信打开制定页面，核实签约。"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "完成上述三个环节，即完成入驻，如有任何疑问，请联系入驻平台的管理员。"
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }