"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DeliveryList',
  data: function data() {
    return {
      fileVisible: false,
      dialogVisible: false,
      loading: false,
      timeVal: [],
      deliveryStatusList: [{
        label: '全部',
        value: ''
      }, {
        label: '全部完成',
        value: 1
      }, {
        label: '部分完成',
        value: 10
      }, {
        label: '处理中',
        value: 0
      }, {
        label: '处理失败',
        value: -1
      }],
      deliveryTypeList: [{
        label: '全部',
        value: ''
      }, {
        label: '发货',
        value: 1
      }, {
        label: '送货',
        value: 2
      }, {
        label: '无需物流',
        value: 3
      }, {
        label: '电子面单',
        value: 4
      }],
      tableData: {
        data: [],
        total: 0
      },
      recordData: {
        data: [],
        total: 0
      },
      recordFrom: {
        page: 1,
        limit: 20
      },
      tableFrom: {
        page: 1,
        limit: 10,
        date: '',
        status: ''
      },
      recordId: ''
    };
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.loading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _order.deliveryRecordListApi)(this.tableFrom).then(function (res) {
        _this.fileVisible = true;
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.loading = false;
      }).catch(function (res) {
        _this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    // 下载
    downLoad: function downLoad(id) {
      var _this2 = this;
      (0, _order.deliveryRecordImportApi)(id).then(function (res) {
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: teal'
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 详情
    detail: function detail(id) {
      var _this3 = this;
      this.recordId = id;
      (0, _order.deliveryRecordDetailApi)(id, this.recordFrom).then(function (res) {
        _this3.dialogVisible = true;
        _this3.recordData.data = res.data.list;
        _this3.recordData.total = res.data.count;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    pageChange1: function pageChange1(page) {
      this.recordFrom.page = page;
      this.getList('');
      this.detail(this.recordId);
    },
    handleSizeChange1: function handleSizeChange1(val) {
      this.recordFrom.limit = val;
      this.getList('');
      this.detail(this.recordId);
    }
  }
};